export enum Roles {
  ADMIN = 1,
  ORGANIZATION_ADMIN = 2,
  MEMBER = 3,
  MANAGER = 4,
}

// TODO - add other roles
export enum Role {
  ADMIN = "Administrators",
  MEMBER = "member",
}
