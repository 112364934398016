import { Accordion, Button, Group, Space, Text } from "@mantine/core";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Color } from "enums/common";
import { useDeleteChapterPage } from "../../../../hooks/chapters/chapterpages/useDeleteChapterPage";
import { useDeleteChapterQuiz } from "../../../../hooks/chapters/chapterquizes/useDeleteChapterQuiz";
import { Page } from "../../../../types/chapters/chapterpages";
import {
  ChapterDetails,
  Quiz,
} from "../../../../types/chapters/chapterDetails";
import { DeleteChapterPageModal } from "../../DeleteChapterPageModal/DeleteChapterPageModal";
import { DeleteQuizModal } from "../../DeleteQuizModal/DeleteQuizModal";
import { AccordionLabel } from "./AccordionLabel";
import { AccordionPages } from "./AccordionPages";
import { Exercise } from "../../../../types/exercises/exercises";
import { useDeleteChapterPractice } from "../../../../hooks/chapters/chapterpractices/useDeleteChapterPractice";
import { DeleteChapterExerciseModal } from "components/chapters/DeleteChapterExerciseModal/DeleteChapterExerciseModal";

const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const RightContainer = styled.div`
  flex: 30%;
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
`;

const LeftContainer = styled.div`
  flex: 70%;
  display: flex;
  align-items: center;
`;

export interface ChapterOverviewProps {
  chapters: ChapterDetails[];
  courseId: number;
  onEditChapter: (chapter: ChapterDetails) => void;
  onDeleteChapter: (Chapter: ChapterDetails) => void;
}

export function AccordionDropdown({
  chapters,
  courseId,
  onEditChapter,
  onDeleteChapter,
}: ChapterOverviewProps) {
  const { t } = useTranslation();

  const [selectedChapter, setSelectedChapter] = useState<number>(0);
  const [selectedPage, setSelectedPage] = useState<Page>();
  const [selectedQuiz, setSelectedQuiz] = useState<Quiz>();
  const [selectedExercise, setSelectedExercise] = useState<Exercise>();

  const [isDeleteChapterPageModalOpen, setIsDeleteChapterPageModalOpen] =
    useState(false);
  const [isDeleteQuizModalOpen, setIsDeleteQuizModalOpen] = useState(false);
  const [
    isDeleteChapterExerciseModalOpen,
    setIsDeleteChapterExerciseModalOpen,
  ] = useState(false);

  const deleteChapterPageMutation = useDeleteChapterPage();
  const deleteQuizMutation = useDeleteChapterQuiz();
  const deleteChapterExerciseMutation = useDeleteChapterPractice();

  const handleDeleteChapterPage = (page: Page) => {
    setSelectedPage(page);
    setIsDeleteChapterPageModalOpen(true);
  };

  const handleDeleteQuiz = (quiz: Quiz) => {
    setSelectedQuiz(quiz);
    setIsDeleteQuizModalOpen(true);
  };

  const handleDeleteChapterExercise = (exercise: Exercise) => {
    setSelectedExercise(exercise);
    setIsDeleteChapterExerciseModalOpen(true);
  };

  const items =
    chapters && chapters.length
      ? chapters?.map((chapter) => {
          return (
            <Accordion.Item
              onClick={() => {
                setSelectedChapter(chapter.id);
              }}
              key={chapter.id}
              label={<AccordionLabel chapter={chapter} />}
            >
              <>
                {selectedChapter && selectedChapter !== 0 && (
                  <AccordionPages
                    chapterId={selectedChapter}
                    quiz={chapter.quiz}
                    exercises={chapter.exercises}
                    onDeleteChapterPage={handleDeleteChapterPage}
                    onDeleteQuiz={handleDeleteQuiz}
                    onDeleteChapterExercise={handleDeleteChapterExercise}
                  />
                )}

                <Space h="xl" />
                {selectedPage && (
                  <DeleteChapterPageModal
                    page={selectedPage}
                    title={t("Pages:modals.deleteModalTitle")}
                    text={t("Pages:modals.deleteModalText")}
                    onClose={() => setIsDeleteChapterPageModalOpen(false)}
                    opened={isDeleteChapterPageModalOpen}
                    mutation={deleteChapterPageMutation}
                  />
                )}
                {selectedQuiz && (
                  <DeleteQuizModal
                    quiz={selectedQuiz}
                    title={t("Quiz:modals.deleteModalTitle")}
                    text={t("Quiz:modals.deleteModalText")}
                    onClose={() => setIsDeleteQuizModalOpen(false)}
                    opened={isDeleteQuizModalOpen}
                    mutation={deleteQuizMutation}
                  />
                )}
                {selectedExercise && (
                  <DeleteChapterExerciseModal
                    exercise={selectedExercise}
                    title={t("Exercises:modals.deleteModalTitle")}
                    text={t("Exercises:modals.deleteModalText")}
                    onClose={() => setIsDeleteChapterExerciseModalOpen(false)}
                    opened={isDeleteChapterExerciseModalOpen}
                    mutation={deleteChapterExerciseMutation}
                  />
                )}
              </>
              <Group>
                <ButtonsContainer>
                  <LeftContainer>
                    <Button
                      component={Link}
                      to={`/courses/${courseId}/chapters/${chapter.id}/newpage`}
                      sx={() => ({
                        borderColor: Color.DARK,
                        backgroundColor: Color.WHITE,
                        cursor: "pointer",
                        marginRight: 10,

                        "&:hover": {
                          backgroundColor: Color.WHITE,
                        },
                      })}
                    >
                      <Text
                        styles={() => ({
                          root: {
                            fontSize: 14,
                            fontWeight: 600,
                            fontFamily: "Montserrat",
                            color: Color.DARK,
                          },
                        })}
                      >
                        {t("Courses:createCourse.newPage")}
                      </Text>
                    </Button>
                    {!!!chapter.quiz && (
                      <Button
                        component={Link}
                        to={`/courses/${courseId}/chapters/${chapter.id}/addquiz`}
                        sx={() => ({
                          borderColor: Color.DARK,
                          backgroundColor: Color.WHITE,
                          cursor: "pointer",
                          marginRight: 10,

                          "&:hover": {
                            backgroundColor: Color.WHITE,
                          },
                        })}
                      >
                        <Text
                          styles={() => ({
                            root: {
                              fontSize: 14,
                              fontWeight: 600,
                              fontFamily: "Montserrat",
                              color: Color.DARK,
                            },
                          })}
                        >
                          {t("Courses:createCourse.newQuiz")}
                        </Text>
                      </Button>
                    )}

                    <Button
                      component={Link}
                      to={`/courses/${courseId}/chapters/${chapter.id}/addscript`}
                      sx={() => ({
                        borderColor: Color.DARK,
                        backgroundColor: Color.WHITE,
                        cursor: "pointer",

                        "&:hover": {
                          backgroundColor: Color.WHITE,
                        },
                      })}
                    >
                      <Text
                        styles={() => ({
                          root: {
                            fontSize: 14,
                            fontWeight: 600,
                            fontFamily: "Montserrat",
                            color: Color.DARK,
                          },
                        })}
                      >
                        {t("Courses:createCourse.newPractice")}
                      </Text>
                    </Button>
                  </LeftContainer>
                  <RightContainer>
                    <Button
                      component={Link}
                      to={`/courses/${courseId}/chapters/${chapter.id}/edit`}
                      styles={() => ({
                        root: {
                          fontSize: 14,
                          fontFamily: "Montserrat",
                          fontWeight: 600,
                          borderColor: Color.DARK,
                          backgroundColor: Color.WHITE,
                          color: Color.DARK,
                          marginRight: 10,

                          "&:hover": {
                            backgroundColor: Color.WHITE,
                          },
                        },
                      })}
                      onClick={() => onEditChapter(chapter)}
                    >
                      Edit
                    </Button>
                    <Button
                      styles={() => ({
                        root: {
                          fontSize: 14,
                          fontFamily: "Montserrat",
                          fontWeight: 600,
                          backgroundColor: Color.DARK,

                          "&:hover": {
                            backgroundColor: Color.DARK,
                          },
                        },
                      })}
                      onClick={() => onDeleteChapter(chapter)}
                    >
                      Delete
                    </Button>
                  </RightContainer>
                </ButtonsContainer>
              </Group>
              <Space h="lg" />
            </Accordion.Item>
          );
        })
      : "";

  return (
    <>
      <Accordion
        sx={() => ({
          backgroundColor: Color.WHITE,
          "&:hover": {
            backgroundColor: Color.WHITE,
          },
        })}
        initialItem={-1}
        iconPosition="right"
      >
        {items}
      </Accordion>
    </>
  );
}
