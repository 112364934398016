import { useMutation, useQuery } from "@tanstack/react-query";

import { createChapter, getChapters } from "../../api/chapters/chapters";
import { queryClient } from "../../App";
import i18n from "../../i18n";
import { CreateChapterPayload } from "../../types/chapters/chapters";
import { generateRandomString } from "../../utils/common/commonUtils";
import {
  endNotification,
  startNotification,
} from "../../utils/common/notifications";

export const useChapters = (courseId: number) => {
  return useQuery(["chapters", courseId], () => getChapters(courseId));
};

export const useCreateChapter = () => {
  const randomId = generateRandomString(20);

  return useMutation(
    (payload: CreateChapterPayload) => createChapter(payload),
    {
      onMutate: () => {
        startNotification(randomId);
      },
      onSuccess: () => {
        endNotification(
          randomId,
          i18n.t("Chapters:notifications.createSuccess"),
          true
        );
        queryClient.invalidateQueries(["chapters"]);
      },
      onError: () => {
        endNotification(
          randomId,
          i18n.t("Chapters:notifications.createError"),
          false
        );
      },
    }
  );
};
