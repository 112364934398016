export const requiredNumber = (
  value: number | null,
  message?: string
): string | null => {
  const errorMessage = message ? message : "Field is required";

  if (!value || isNaN(value)) {
    return errorMessage;
  }

  return null;
};

export const requiredString = (
  value: string,
  message?: string
): string | null => {
  const errorMessage = message ? message : "Field is required";

  if (!value) {
    return errorMessage;
  }

  return null;
};
