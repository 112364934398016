import React from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";
import { Text, Button, Container, Space, Group } from "@mantine/core";

import { BasePage } from "../../components/common/BasePage/BasePage";
import { Banner } from "../../components/common";
import { Link, useParams } from "react-router-dom";
import { Color } from "enums/common";
import { useCourse } from "../../hooks/courses/useCourse";
import { ChapterOverview } from "../../components/chapters/ChaptersOverview/ChaptersOverview";
import { useChapters } from "../../hooks/chapters/useChapters";

const StyledBanner = styled(Banner)`
  margin: 34px 0 40px 0;
`;

export const CourseDetails: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { data } = useCourse(Number(id));

  const chaptersQuery = useChapters(Number(id));

  return (
    <BasePage>
      <Container data-testid="courses-page">
        {data && (
          <StyledBanner title={data.name} text={data.summary}>
            <Space h="lg" />
            <Button
              component={Link}
              to={`/courses/${id}/chapters/create/`}
              size="xl"
              styles={(theme) => ({
                root: {
                  fontSize: 18,
                  fontFamily: "Montserrat",
                  backgroundColor: Color.DARK,
                  fontWeight: 600,

                  "&:hover": {
                    backgroundColor: theme.fn.lighten(Color.DARK, 0.1),
                  },
                },
              })}
            >
              {t("Course:banner.newChapter")}
            </Button>
          </StyledBanner>
        )}
        <Text weight={500} size="xl" color="#2B1467;">
          {t("Course:banner.newTitle")}
        </Text>
        <Space h="lg" />
      </Container>
      <Container data-testid="courses-page">
        {!chaptersQuery.isLoading && chaptersQuery.data && (
          <ChapterOverview
            chapters={chaptersQuery.data}
            courseId={Number(id)}
          />
        )}
        <Space h="xl" />
        <Space h="xl" />
        <Group>
          <Button
            component={Link}
            to={"/courses/all"}
            variant="outline"
            styles={() => ({
              root: {
                borderColor: Color.DARK,
                color: Color.DARK,
              },
            })}
          >
            {t("Course:buttons.backToCourses")}
          </Button>
        </Group>
        <Space h="lg" />
      </Container>
    </BasePage>
  );
};
