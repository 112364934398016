import { Table } from "@mantine/core";
import { Color } from "enums/common";
import { useTranslation } from "react-i18next";
import { User, UsersObject } from "types/user/User";

export interface AssignManagerTableProps {
  organizationManagers: User[];
  selectedManager: User | unknown;
  setSelectedManager: (manager: UsersObject) => void;
}

export const AssignManagerTable: React.FC<AssignManagerTableProps> = ({
  organizationManagers,
  setSelectedManager,
}) => {
  const { t } = useTranslation();

  const handleManagerSelection = (managerId: string, checked: boolean) => {
    const manager = organizationManagers.find(
      (x) => x.id.toString() === managerId
    );

    if (manager) {
      const customManager = {
        ...manager,
        checked: checked,
      };
      setSelectedManager(customManager);
    }
  };

  return (
    <Table
      data-testid="assignDepartment-table"
      highlightOnHover
      verticalSpacing={6}
      style={{ marginTop: 5, marginBottom: 20, textAlign: "center" }}
      sx={() => ({
        backgroundColor: Color.WHITE,
        boxShadow: "box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.15)",
      })}
    >
      <thead>
        <tr>
          <th></th>
          <th>{t("Department:table.name")}</th>
          <th>{t("Department:table.nickName")}</th>
          <th>{t("Department:table.email")}</th>
        </tr>
      </thead>
      <tbody>
        {organizationManagers?.map((organizationManager, index) => (
          <tr key={organizationManager.id}>
            <td>
              <input
                type="radio"
                name="radio"
                id={organizationManager.id.toString()}
                onChange={(event) =>
                  handleManagerSelection(event.target.id, event.target.checked)
                }
              ></input>
            </td>
            <td> {organizationManager.fullName}</td>
            <td>{organizationManager.nickName}</td>
            <td>{organizationManager.email}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
