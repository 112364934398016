import React from "react";

export const AvatarPlaceholder: React.FC = () => {
  return (
    <svg
      data-testid="avatar-placeholder"
      width="55"
      height="55"
      viewBox="0 0 55 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.5 55C42.6878 55 55 42.6878 55 27.5C55 12.3122 42.6878 0 27.5 0C12.3122 0 0 12.3122 0 27.5C0 42.6878 12.3122 55 27.5 55Z"
        fill="#D2ECE8"
      />
      <mask
        id="mask0_908_3036"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="55"
        height="55"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.015625 27.4558C0.015625 42.5863 12.2882 54.852 27.4269 54.852C42.5655 54.852 54.8379 42.5863 54.8379 27.4558C54.8379 12.3255 42.5655 0.0600586 27.4269 0.0600586C12.2882 0.0600586 0.015625 12.3255 0.015625 27.4558Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_908_3036)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M67.464 -14.3776C86.0469 -7.63081 95.6388 12.8946 88.8883 31.4672C82.1376 50.0396 61.6009 59.6265 43.0183 52.8795C24.4355 46.1327 14.8436 25.6073 21.594 7.03472C28.3445 -11.5377 48.8812 -21.1243 67.464 -14.3776Z"
          fill="#E6A2C7"
        />
      </g>
      <mask
        id="mask1_908_3036"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="55"
        height="55"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.015625 27.4558C0.015625 42.5863 12.2882 54.852 27.4269 54.852C42.5655 54.852 54.8379 42.5863 54.8379 27.4558C54.8379 12.3255 42.5655 0.0600586 27.4269 0.0600586C12.2882 0.0600586 0.015625 12.3255 0.015625 27.4558Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_908_3036)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M63.3595 -3.08719C75.7038 1.39486 82.0756 15.0294 77.5913 27.367C73.107 39.7045 59.4648 46.0728 47.1206 41.5909C34.7762 37.1091 28.4045 23.4744 32.8886 11.1368C37.3731 -1.20079 51.0153 -7.56901 63.3595 -3.08719Z"
          fill="#55539E"
        />
      </g>
      <mask
        id="mask2_908_3036"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="55"
        height="55"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.015625 27.4558C0.015625 42.5863 12.2882 54.852 27.4269 54.852C42.5655 54.852 54.8379 42.5863 54.8379 27.4558C54.8379 12.3255 42.5655 0.0600586 27.4269 0.0600586C12.2882 0.0600586 0.015625 12.3255 0.015625 27.4558Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask2_908_3036)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M58.6827 9.77823C63.9176 11.6789 66.6197 17.4608 64.718 22.6928C62.8162 27.9247 57.0312 30.6252 51.7963 28.7247C46.5615 26.824 43.8596 21.0421 45.7611 15.8102C47.6626 10.5782 53.4479 7.87752 58.6827 9.77823Z"
          fill="#FEFEFE"
        />
      </g>
      <mask
        id="mask3_908_3036"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="55"
        height="55"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.015625 27.4558C0.015625 42.5863 12.2882 54.852 27.4269 54.852C42.5655 54.852 54.8379 42.5863 54.8379 27.4558C54.8379 12.3255 42.5655 0.0600586 27.4269 0.0600586C12.2882 0.0600586 0.015625 12.3255 0.015625 27.4558Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask3_908_3036)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.8793 38.1583C18.1178 40.4234 21.338 47.3141 19.0716 53.5491C16.8054 59.7839 9.91093 63.0026 3.67246 60.7374C-2.56602 58.4725 -5.7862 51.5818 -3.52002 45.3468C-1.25362 39.1117 5.64083 35.8931 11.8793 38.1583Z"
          fill="#F2767D"
        />
      </g>
      <mask
        id="mask4_908_3036"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="55"
        height="55"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.015625 27.4558C0.015625 42.5863 12.2882 54.852 27.4269 54.852C42.5655 54.852 54.8379 42.5863 54.8379 27.4558C54.8379 12.3255 42.5655 0.0600586 27.4269 0.0600586C12.2882 0.0600586 0.015625 12.3255 0.015625 27.4558Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask4_908_3036)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.9821 21.3725C22.5272 23.0227 24.8732 28.0429 23.222 32.5855C21.5709 37.1281 16.548 39.4728 12.0029 37.8226C7.45784 36.1724 5.11185 31.1522 6.76297 26.6096C8.41408 22.067 13.437 19.7223 17.9821 21.3725Z"
          fill="#55539E"
        />
      </g>
      <mask
        id="mask5_908_3036"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="55"
        height="55"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.015625 27.4558C0.015625 42.5863 12.2882 54.852 27.4269 54.852V54.852C42.5655 54.852 54.8379 42.5863 54.8379 27.4558V27.4558C54.8379 12.3255 42.5655 0.0600586 27.4269 0.0600586V0.0600586C12.2882 0.0600586 0.015625 12.3255 0.015625 27.4558"
          fill="white"
        />
      </mask>
      <g mask="url(#mask5_908_3036)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.5584 38.55C33.6777 39.6825 35.2877 43.1277 34.1546 46.2454C33.0215 49.363 29.5742 50.9721 26.4549 49.8396C23.3357 48.7072 21.7257 45.2617 22.8588 42.1443C23.9919 39.0267 27.439 37.4176 30.5584 38.55"
          fill="#75C2B7"
        />
      </g>
    </svg>
  );
};
