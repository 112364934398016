export enum Exercises {
  GET_EXERCISES = "exercise/all",
  CREATE_EXERCISES = "exercise/create",
  EXERCISES = "exercise",
  GET_USER_EXERCISES = "user-exercise/all",
  CREATE_EXERCISE_VOICEBOT = "exercise",
  CONTENT_OVERVIEW = "exercise/content-overview",
}

export enum EXERCISEPAGES {
  GET_EXERCISEPAGES = "exercise-pages",
  CREATE_EXERCISEPAGES = "exercise-pages/create",
  EXERCISEPAGES = "exercise-pages",
}
