import { useQuery } from "@tanstack/react-query";

import { getUserExercises } from "../../api/userExercises/userExercises";

export const useUserExercises = (
  page: number,
  searchKey: string,
  size: number
) => {
  return useQuery(["userExercises", page, searchKey, size], () =>
    getUserExercises(page, searchKey, size)
  );
};
