import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { UserAvatar } from "./UserAvatar/UserAvatar";

import { Logo } from "components/Icons";

import { Color } from "enums/common";

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 115px;
  align-items: center;
  height: 94px;
  background-color: ${Color.DARK};

  a {
    display: flex;
    align-items: center;
    text-decoration: none;
  }
`;

const HeaderItem = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 18px;
  color: ${Color.WHITE};
  margin-right: 35px;
`;

export interface HeaderProps {
  userName: string;
}

export const Header: React.FC<HeaderProps> = ({ userName }) => {
  const { t } = useTranslation("Common");

  return (
    <HeaderContainer data-testid="header">
      <Logo />
      <div style={{ display: "flex" }}>
        <Link data-testid="dashboard-link" to="/">
          <HeaderItem>{t("dashboard")}</HeaderItem>
        </Link>
        <Link data-testid="organizations-link" to="/organizations">
          <HeaderItem>{t("organizations")}</HeaderItem>
        </Link>
        <Link data-testid="userManagement-link" to="/userManagement">
          <HeaderItem>{t("userManagement")}</HeaderItem>
        </Link>
        <Link data-testid="courses-link" to="/courses">
          <HeaderItem>{t("courses")}</HeaderItem>
        </Link>
        <Link data-testid="templates-link" to="/exercises/all">
          <HeaderItem>{t("exercises", { ns: "Common" })}</HeaderItem>
        </Link>
        <UserAvatar userName={userName} />
      </div>
    </HeaderContainer>
  );
};
