import { useMutation } from "@tanstack/react-query";

import i18n from "../../i18n";
import { queryClient } from "../../App";

import {
  startNotification,
  endNotification,
} from "../../utils/common/notifications";
import { generateRandomString } from "../../utils/common/commonUtils";

import { deleteExercise } from "../../api/exercises/exercises";

export const useDeleteExercise = () => {
  const randomId = generateRandomString(20);

  return useMutation((id: number) => deleteExercise(id), {
    onMutate: () => {
      startNotification(randomId);
    },
    onSuccess: () => {
      endNotification(
        randomId,
        i18n.t("Exercises:notifications.deleteSuccess"),
        true
      );
      queryClient.invalidateQueries(["exercises"]);
    },
    onError: (error: any) => {
      endNotification(
        randomId,
        error.response && error.response.data
          ? error.response.data.message
          : i18n.t("Exercises:notifications.deleteError"),
        false
      );
    },
  });
};
