import { Button, Group } from "@mantine/core";
import { AxiosError } from "axios";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Pagination } from "../../components/common";
import { ErrorCode } from "../../enums/api/ErrorCode";
import { Color } from "enums/common";
import { useExercises } from "../../hooks/exercises/useExercises";
import { ApiError } from "../../types/api/ApiError";
import { ExercisesObject } from "../../types/exercises/exercises";
import { generateRandomString } from "../../utils/common/commonUtils";
import {
  endNotification,
  warningNotification,
} from "../../utils/common/notifications";
import { AssignExercisesTable } from "./AssignExerciseTable";
import { useChapter } from "hooks/chapters/useChapter";

export interface AssignExerciseProps {
  mutation: any;
}

export const AssignExercise: React.FC<AssignExerciseProps> = ({ mutation }) => {
  const { t } = useTranslation();
  const { chapterId } = useParams();
  const [searchKey] = useState("");
  const [page, setPage] = useState(1);
  const [resultCount, setResultCount] = useState(15);
  const navigate = useNavigate();
  const { id } = useParams();

  const [selectedExercises, setSelectedExercises] = useState<ExercisesObject[]>(
    []
  );

  const { data: exerciseData } = useExercises(page, searchKey, resultCount, "");

  const chapter = useChapter(Number(chapterId));

  const handleExerciseSelection = (exercise: ExercisesObject) => {
    if (!exercise.checked) {
      setSelectedExercises(
        selectedExercises.filter(
          (selectedExercises) => selectedExercises.id !== exercise.id
        )
      );
    } else {
      setSelectedExercises((current) => [...current, exercise]);
    }
  };

  const handleSubmit = () => {
    if (selectedExercises.length === 0) {
      const randomId = generateRandomString(20);
      warningNotification(
        randomId,
        t("Exercises:notifications.assignExerciseToChapterWarning")
      );
    } else {
      mutation.mutate(
        {
          chapterId: Number(chapterId),
          exerciseIds: selectedExercises?.map((exercise) => exercise.id),
        },
        {
          onSuccess: () => {
            navigate(`/courses/${id}`);
          },
          onError: (error: AxiosError<ApiError>) => {
            if (
              error.response?.data.errorCode === ErrorCode.NAME_ALREADY_EXISTS
            ) {
              const randomId = generateRandomString(20);
              endNotification(
                randomId,
                t("Exercises:notifications.assignExerciseToChapterWarning"),
                false
              );
            }
          },
        }
      );
    }
  };

  console.log("CHAPTER_DATA", chapter.data?.exercises);

  return (
    <>
      {exerciseData && chapter?.data && (
        <>
          <AssignExercisesTable
            exercises={exerciseData?.elements?.map((exercise) => ({
              ...exercise,
              isAssigned: !!chapter?.data?.exercises?.find(
                (arr1Obj) => arr1Obj.id === exercise.id
              ),
            }))}
            selectedExercises={selectedExercises}
            setSelectedExercises={handleExerciseSelection}
          />
          <Pagination
            page={page}
            totalItems={exerciseData.total}
            itemsPerPage={resultCount}
            setItemsPerPage={setResultCount}
            setPage={setPage}
          />
        </>
      )}

      <Group position="right" mt="md">
        <Button
          data-testid="submit-button"
          type="submit"
          size="sm"
          onClick={handleSubmit}
          styles={(theme) => ({
            root: {
              fontSize: 16,
              fontFamily: "Montserrat",
              backgroundColor: Color.DARK,
              fontWeight: 600,

              "&:hover": {
                backgroundColor: theme.fn.lighten(Color.DARK, 0.1),
              },
            },
          })}
        >
          {t("Common:actions.submit")}
        </Button>
      </Group>
    </>
  );
};
