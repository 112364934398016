import { assignManagerToDepartment } from "api/departments/departments";
import { queryClient } from "App";
import i18n from "i18n";
import { useMutation } from "@tanstack/react-query";
import { AssignDepartmentManagerPayload } from "types/department/assignDepartment";
import {
  endNotification,
  generateRandomString,
  startNotification,
} from "utils/common";

export const useAssignDepartmentManager = () => {
  const randomId = generateRandomString(20);

  return useMutation(
    (payload: AssignDepartmentManagerPayload) =>
      assignManagerToDepartment(payload),
    {
      onMutate: () => {
        startNotification(randomId);
      },
      onSuccess: () => {
        endNotification(
          randomId,
          i18n.t("Department:notifications.assignManagerSuccess"),
          true
        );
        queryClient.invalidateQueries(["users-assigned-to-department"]);
      },
      onError: () => {
        endNotification(
          randomId,
          i18n.t("Department:notifications.assignManagerError"),
          false
        );
      },
    }
  );
};
