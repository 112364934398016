const envConfig = {
  env: process.env.REACT_APP_ENV,
  baseUrl: process.env.REACT_APP_BASE_API_URL,
  baseConversationsUrl: process.env.REACT_APP_BASE_CONVERSATIONS_URL,
  i18nDebug: process.env.REACT_APP_I18N_DEBUG,
};

export const configuration = () => {
  if (!envConfig.env) throw new Error('There is no "REACT_APP_ENV" setted up!');
  if (!envConfig.baseUrl)
    throw new Error('There is no "REACT_APP_BASE_API_URL" setted up!');
  if (!envConfig.baseConversationsUrl)
    throw new Error(
      'There is no "REACT_APP_VOICE_BOT_BASE_API_URL" setted up!'
    );
  if (!envConfig.i18nDebug)
    throw new Error('There is no "REACT_APP_I18N_DEBUG" setted up!');

  return envConfig;
};
