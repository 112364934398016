import { useEffect, useState } from "react";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useForm } from "@mantine/form";

// Components
import RichTextEditor from "@mantine/rte";
import {
  Box,
  Button,
  Group,
  MultiSelect,
  Space,
  Textarea,
  Text,
  TextInput,
} from "@mantine/core";

// Types
import { ErrorCode } from "enums/api/ErrorCode";
import { Color } from "enums/common";
import { ApiError } from "types/api/ApiError";
import { ExercisePages } from "types/exercises/exercisepages";

// Utils
import { generateRandomString } from "utils/common/commonUtils";
import { endNotification } from "utils/common/notifications";
import { removeAllHtmlTags } from "utils/common/htmlUtils";

export interface EditExercisePageProps {
  page: ExercisePages;
  mutation: any;
  exerciseId: number;
}

export const EditExercisePageForm: React.FC<EditExercisePageProps> = ({
  page,
  mutation,
  exerciseId,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [keywords, setKeywords] = useState([""]);

  useEffect(() => {
    setKeywords(page.keywords ? page.keywords : []);
  }, [page]);

  const form = useForm({
    initialValues: {
      exerciseId: page.exerciseId,
      slideText: page.slideText,
      title: page.title,
      scriptText: page.scriptText,
      customerText: page.customerText,
      negativeFeedback: page.negativeFeedback,
      objection: page.objection,
      positiveFeedback: page.positiveFeedback,
      proTip: page.proTip,
      techniques: page.techniques,
    },
  });

  const handleSubmit = () => {
    const slideText =
      removeAllHtmlTags(form.values?.slideText).length > 0
        ? form.values?.slideText
        : "";
    const techniques =
      removeAllHtmlTags(form.values?.techniques).length > 0
        ? form.values?.techniques
        : "";

    mutation.mutate(
      {
        id: page.id,
        payload: {
          ...form.values,
          keywordList: keywords.filter((e) => e),
          slideText,
          techniques,
        },
      },
      {
        onSuccess: () => {
          navigate(`/exercises/${exerciseId}`);
        },
        oneError: (error: AxiosError<ApiError>) => {
          if (
            error.response?.data.errorCode === ErrorCode.NAME_ALREADY_EXISTS
          ) {
            const randomId = generateRandomString(20);
            endNotification(
              randomId,
              t("Exercises:notifications.updatePageError"),
              false
            );
          }
        },
      }
    );
  };

  return (
    <Box sx={{ maxWidth: 800 }} mx="auto">
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <TextInput
          size="sm"
          required
          label={t("Exercises:createExercisePage.title")}
          placeholder={t("Exercises:createExercisePage.nameOfPage")}
          styles={() => ({
            label: {
              fontSize: 16,
              fontWeight: 600,
              color: Color.DARK,
            },
            input: {
              fontSize: 14,
              color: Color.DARK,
              fontFamily: "Montserrat",
              borderColor: Color.DARK,
              borderWidth: 1,
              backgroundColor: Color.WHITE,
              "&:focus": {
                borderColor: Color.DARK,
                borderWidth: 1,
              },
            },
          })}
          {...form.getInputProps("title")}
        />
        <Space h="md" />
        <Text
          style={{
            color: Color.DARK,
            fontSize: 16,
            fontWeight: 600,
            paddingBottom: ".2rem",
          }}
        >
          {t("Exercises:createExercisePage.slideText")}
        </Text>
        <RichTextEditor
          style={{
            borderColor: Color.DARK,
            color: Color.DARK,
            fontFamily: "Montserrat",
          }}
          {...form.getInputProps("slideText")}
        />
        <Space h="md" />
        <Textarea
          label={t("Exercises:createExercisePage.objection")}
          placeholder={t("Exercises:createExercisePage.objection")}
          mb={3}
          styles={() => ({
            label: {
              fontSize: 16,
              fontWeight: 600,
              color: Color.DARK,
            },
            input: {
              fontSize: 14,
              color: Color.DARK,
              fontFamily: "Montserrat",
              borderColor: Color.DARK,
              borderWidth: 1,
              backgroundColor: Color.WHITE,
              "&:focus": {
                borderColor: Color.DARK,
                borderWidth: 1,
              },
            },
          })}
          {...form.getInputProps("objection")}
        />
        <Space h="md" />
        <Textarea
          required
          label={t("Exercises:createExercisePage.scriptText")}
          placeholder={t("Exercises:createExercisePage.scriptText")}
          mb={3}
          styles={() => ({
            label: {
              fontSize: 16,
              fontWeight: 600,
              color: Color.DARK,
            },
            input: {
              fontSize: 14,
              color: Color.DARK,
              fontFamily: "Montserrat",
              borderColor: Color.DARK,
              borderWidth: 1,
              backgroundColor: Color.WHITE,
              "&:focus": {
                borderColor: Color.DARK,
                borderWidth: 1,
              },
            },
          })}
          {...form.getInputProps("scriptText")}
        />
        <Space h="md" />
        <Textarea
          label={t("Exercises:createExercisePage.customerText")}
          placeholder={t("Exercises:createExercisePage.customerText")}
          mb={3}
          styles={() => ({
            label: {
              fontSize: 16,
              fontWeight: 600,
              color: Color.DARK,
            },
            input: {
              fontSize: 14,
              color: Color.DARK,
              fontFamily: "Montserrat",
              borderColor: Color.DARK,
              borderWidth: 1,
              backgroundColor: Color.WHITE,
              "&:focus": {
                borderColor: Color.DARK,
                borderWidth: 1,
              },
            },
          })}
          {...form.getInputProps("customerText")}
        />
        <Space h="md" />
        <Textarea
          label={t("Exercises:createExercisePage.positiveFeedback")}
          placeholder={t("Exercises:createExercisePage.positiveFeedback")}
          mb={3}
          styles={() => ({
            label: {
              fontSize: 16,
              fontWeight: 600,
              color: Color.DARK,
            },
            input: {
              fontSize: 14,
              color: Color.DARK,
              fontFamily: "Montserrat",
              borderColor: Color.DARK,
              borderWidth: 1,
              backgroundColor: Color.WHITE,
              "&:focus": {
                borderColor: Color.DARK,
                borderWidth: 1,
              },
            },
          })}
          {...form.getInputProps("positiveFeedback")}
        />
        <Space h="md" />
        <Textarea
          label={t("Exercises:createExercisePage.negativeFeedback")}
          placeholder={t("Exercises:createExercisePage.negativeFeedback")}
          mb={3}
          styles={() => ({
            label: {
              fontSize: 16,
              fontWeight: 600,
              color: Color.DARK,
            },
            input: {
              fontSize: 14,
              color: Color.DARK,
              fontFamily: "Montserrat",
              borderColor: Color.DARK,
              borderWidth: 1,
              backgroundColor: Color.WHITE,
              "&:focus": {
                borderColor: Color.DARK,
                borderWidth: 1,
              },
            },
          })}
          {...form.getInputProps("negativeFeedback")}
        />
        <Space h="md" />
        <MultiSelect
          data={[]}
          label={t("Templates:table.keywords")}
          placeholder={t("Templates:table.keywords")}
          searchable
          creatable
          value={keywords.filter(Boolean)}
          onChange={setKeywords}
          getCreateLabel={(query) => `+ ${t("Templates:create")} ${query}`}
          styles={() => ({
            input: {
              fontSize: 14,
              color: Color.DARK,
              fontFamily: "Montserrat",
              borderColor: Color.DARK,
              borderWidth: 1,
              backgroundColor: Color.WHITE,
              "&:focus": {
                borderColor: Color.DARK,
                borderWidth: 1,
              },
            },
          })}
        />
        <Space h="md" />
        <Textarea
          label={t("Exercises:createExercisePage.proTip")}
          placeholder={t("Exercises:createExercisePage.proTip")}
          mb={3}
          styles={() => ({
            label: {
              fontSize: 16,
              fontWeight: 600,
              color: Color.DARK,
            },
            input: {
              fontSize: 14,
              color: Color.DARK,
              fontFamily: "Montserrat",
              borderColor: Color.DARK,
              borderWidth: 1,
              backgroundColor: Color.WHITE,
              "&:focus": {
                borderColor: Color.DARK,
                borderWidth: 1,
              },
            },
          })}
          {...form.getInputProps("proTip")}
        />
        <Space h="md" />
        <Text
          style={{
            color: Color.DARK,
            fontSize: 16,
            fontWeight: 600,
            paddingBottom: ".2rem",
          }}
        >
          {t("Exercises:createExercisePage.techniquesLabel")}
        </Text>
        <RichTextEditor
          style={{
            borderColor: Color.DARK,
            color: Color.DARK,
            fontFamily: "Montserrat",
          }}
          {...form.getInputProps("techniques")}
        />
        <Space h="md" />
        <Space h="md" />
        <Group position="right" mt="md">
          <Button
            data-testid="submit-button"
            type="submit"
            size="sm"
            styles={(theme) => ({
              root: {
                fontSize: 16,
                fontFamily: "Montserrat",
                backgroundColor: Color.DARK,
                fontWeight: 600,

                "&:hover": {
                  backgroundColor: theme.fn.lighten(Color.DARK, 0.1),
                },
              },
            })}
          >
            {t("Courses:createCourse.submitChapter")}
          </Button>
        </Group>
      </form>
    </Box>
  );
};
