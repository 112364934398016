import React from "react";
import styled from "styled-components";
import parseHtml from "html-react-parser";

// Styled components
const ContentWrapper = styled.div`
  font-family: "Montserrat", sans-serif;
  width: 100%;
`;

interface Props {
  htmlContent: string;
}

export const RichTextContent = ({ htmlContent = "" }: Props) => {
  if (typeof htmlContent !== "string") {
    return htmlContent;
  }
  return <ContentWrapper>{parseHtml(htmlContent)}</ContentWrapper>;
};
