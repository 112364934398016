import { Button, Group, InputWrapper, Space, Table } from "@mantine/core";
import { Color } from "enums/common";
import { useCreateVoicebot } from "hooks/voicebots/useVoiceBots";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Voicebot } from "types/voicebot/voicebot";

export interface VoicebotTableProps {
  voicebots: Voicebot[];
  selectedVoicebot: string;
  setSelectedVoicebot: (voicebot: string) => void;
}

export const VoicebotTable: React.FC<VoicebotTableProps> = ({
  voicebots,
  selectedVoicebot,
  setSelectedVoicebot,
}) => {
  const { t } = useTranslation();
  const { id } = useParams();

  const navigate = useNavigate();

  const createVoicebotMutation = useCreateVoicebot();

  const handleVoicebotSelection = (voicebotId: string) => {
    if (voicebotId) {
      setSelectedVoicebot(voicebotId);
    }
  };

  const handleSubmit = () => {
    createVoicebotMutation.mutate(
      {
        id: Number(id),
        payload: {
          rasaBotKey: selectedVoicebot,
        },
      },
      {
        onSuccess: () => {
          navigate(`/exercises/all`);
        },
      }
    );
  };

  return (
    <>
      <Table
        data-testid="templates-table"
        highlightOnHover
        verticalSpacing={6}
        style={{ marginTop: 20, marginBottom: 20 }}
        sx={() => ({
          backgroundColor: Color.WHITE,
          boxShadow: "box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.15)",
        })}
      >
        <thead>
          <tr>
            <th></th>
            <th>{t("Common:key")}</th>
            <th>{t("Common:description")}</th>
          </tr>
        </thead>
        <tbody>
          {voicebots.map((voicebot) => (
            <tr key={voicebot.key}>
              <td>
                <InputWrapper labelElement="div">
                  <input
                    type="radio"
                    name="radio"
                    id={voicebot.key}
                    onChange={(event) =>
                      handleVoicebotSelection(event.target.id)
                    }
                    checked={selectedVoicebot === voicebot.key}
                  />
                </InputWrapper>
              </td>
              <td>{voicebot.key}</td>
              <td>{voicebot.description}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Space h="md" />
      <Group position="right" mt="md">
        <Button
          onClick={handleSubmit}
          data-testid="submit-button"
          type="submit"
          styles={(theme) => ({
            root: {
              fontSize: 16,
              fontFamily: "Montserrat",
              backgroundColor: Color.DARK,
              fontWeight: 600,

              "&:hover": {
                backgroundColor: theme.fn.lighten(Color.DARK, 0.1),
              },
            },
          })}
        >
          {t("Common:actions.submit")}
        </Button>
      </Group>
    </>
  );
};
