import { Box, Container, Skeleton, Space, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { EditChapterForm } from "../../components/chapters/EditChapterForm/EditChapterForm";
import { Banner, BasePage } from "../../components/common";
import { Color } from "enums/common";
import { useChapter } from "../../hooks/chapters/useChapter";
import { useUpdateChapter } from "../../hooks/chapters/useUpdateChapter";

const StyledBanner = styled(Banner)`
  margin: 34px 0 40px 0;
`;

export const EditChapter: React.FC = () => {
  const { id } = useParams();
  const { chapterId } = useParams();

  const { t } = useTranslation();
  const { data, isLoading } = useChapter(Number(chapterId));

  const updateChapterMutation = useUpdateChapter();

  return (
    <BasePage>
      <Container>
        <Box sx={{ maxWidth: 800 }} mx="auto">
          <StyledBanner title="" text="">
            <Box sx={{ maxWidth: 800 }} mx="auto">
              <Space h="xs" />
              <Text
                styles={() => ({
                  root: {
                    fontSize: 28,
                    fontWeight: 600,
                    fontFamily: "Montserrat",
                    color: Color.DARK,
                  },
                })}
              >
                {t("Chapters:editChapter.title")}
              </Text>
              <Space h="xl" />
              {isLoading && (
                <Skeleton height={550} radius="sm" style={{ marginTop: 30 }} />
              )}
              {data && !isLoading && (
                <EditChapterForm
                  chapter={data}
                  mutation={updateChapterMutation}
                  courseId={Number(id)}
                />
              )}
            </Box>
            <Space h="xl" />
          </StyledBanner>
        </Box>
      </Container>
    </BasePage>
  );
};
