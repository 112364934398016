import React from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { Container, Tabs, TabsProps } from "@mantine/core";

import { Color } from "enums/common";
import { AllExercisesTab } from "../../components/exercises/ExercisesTabs/AllExercisesTab";
import { BasePage } from "../../components/common";
import { UserExercisesTab } from "../../components/exercises/ExercisesTabs/UserExercisesTab";
import { TemplatesTab } from "../../components/exercises/ExercisesTabs/TemplatesTab";

const TabContainer = styled.div`
  margin-top: 60px;
  margin-bottom: 10px;
`;

function StyledTabs(props: TabsProps) {
  return (
    <Tabs
      variant="unstyled"
      styles={(theme) => ({
        tabControl: {
          backgroundColor: Color.GRAY,
          color: Color.DARK,
          fontSize: "20px",
          lineHeight: "24px",
          padding: `${theme.spacing.lg}px ${theme.spacing.xl}px`,

          "&:not(:first-of-type)": {
            borderLeft: 0,
          },
        },

        tabActive: {
          borderColor: Color.DARK,
          borderBottom: `3px solid ${Color.DARK}`,
          fontWeight: 700,
        },
      })}
      {...props}
    />
  );
}

export const AllExercises: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <BasePage>
        <Container>
          <TabContainer>
            <StyledTabs>
              <Tabs.Tab label={t("Exercises:tab.allExercises")}>
                <AllExercisesTab />
              </Tabs.Tab>
              <Tabs.Tab label={t("Exercises:tab.userExercises")}>
                <UserExercisesTab />
              </Tabs.Tab>
              <Tabs.Tab label={t("Exercises:tab.templates")}>
                <TemplatesTab />
              </Tabs.Tab>
            </StyledTabs>
          </TabContainer>
        </Container>
      </BasePage>
    </>
  );
};
