import { getDepartmentManager } from "api/departments/departments";
import { useQuery } from "@tanstack/react-query";

export const useDepartmentManager = (
  id: number,
  page: number,
  searchKey: string,
  size: number
) => {
  return useQuery(
    ["manager-assigned-to-department", id, page, searchKey, size],
    () => getDepartmentManager(id, page, searchKey, size)
  );
};
