import { useMutation } from "@tanstack/react-query";
import { assignExerciseToUsers } from "api/userExercise/userExercise";
import { queryClient } from "App";
import i18n from "i18n";
import { AssignUserExercisePayload } from "types/userExercise/assignExercise";
import {
  endNotification,
  generateRandomString,
  startNotification,
} from "utils/common";

export const useAssignUserExercise = () => {
  const randomId = generateRandomString(20);

  return useMutation(
    (payload: AssignUserExercisePayload) => assignExerciseToUsers(payload),
    {
      onMutate: () => {
        startNotification(randomId);
      },
      onSuccess: () => {
        endNotification(
          randomId,
          i18n.t("Exercises:notifications.assignSuccess"),
          true
        );
        queryClient.invalidateQueries(["userExercises"]);
      },
      onError: () => {
        endNotification(
          randomId,
          i18n.t("Exercises:notifications.deletePageError"),
          false
        );
      },
    }
  );
};
