import React, { useState } from "react";

import { Skeleton } from "@mantine/core";

import { ManagersTable } from "./ManagersTable";
import { ManagersMenu } from "./ManagersMenu";

import { useOrganizationUsers } from "../../hooks/organizations/useOrganizationUsers";

import { Pagination } from "../../components/common/Pagination/Pagination";

import { Organization } from "../../types/organizations/Organization";
import { CreateUserModal } from "../users/CreateUserModal";

import { Roles } from "../../enums/users/Role";
import { useCreateUser } from "../../hooks/organizations/users/useCreateUser";
import { useDebouncedValue } from "@mantine/hooks";
import { User } from "types/user/User";
import { useNotifyUsers } from "hooks/organizations/users/useNotifyUsers";

export interface ManagersTabProps {
  organization: Organization;
}

export const ManagersTab: React.FC<ManagersTabProps> = ({ organization }) => {
  const organizationId = Number(organization.id);
  const [page, setPage] = useState(1);
  const [resultCount, setResultCount] = useState(20);
  const [searchKey, setSearchKey] = useState("");
  const [debouncedSearchKey] = useDebouncedValue(searchKey, 300);
  const departments = organization.departments;
  const [statusFilter, setStatusFilter] = useState<string>("");
  const [departmentFilter, setDepartmentFilter] = useState<string>("");
  const [selectedUsersToNotify, setSelectedUsersToNotify] = useState<User[]>();

  const notifyUsersMutation = useNotifyUsers();
  const [sendUserInviteSubmitted, setEnsetSendUserInviteSubmittedableInvite] =
    useState(false);

  const { data, isLoading } = useOrganizationUsers(
    organizationId,
    page,
    debouncedSearchKey,
    resultCount,
    Roles.MANAGER,
    departmentFilter,
    statusFilter
  );

  const [isCreateUserModalOpen, setIsCreateUserModalOpen] = useState(false);
  const createUserMutation = useCreateUser();

  return (
    <>
      <ManagersMenu
        searchKey={searchKey}
        setSearchKey={setSearchKey}
        setIsModalOpen={setIsCreateUserModalOpen}
        departments={departments}
        setStatusFilter={setStatusFilter}
        statusFilter={statusFilter}
        setDepartmentFilter={setDepartmentFilter}
        departmentFilter={departmentFilter}
        organization={organization}
        selectedUsersToNotify={selectedUsersToNotify}
        mutation={notifyUsersMutation}
        sendUserInviteSubmitted={setEnsetSendUserInviteSubmittedableInvite}
      />
      {isLoading && (
        <Skeleton height={550} radius="sm" style={{ marginTop: 30 }} />
      )}
      {data && (
        <>
          <ManagersTable
            setUsersToNotify={setSelectedUsersToNotify}
            managers={data.elements}
            status={statusFilter}
            sendUserInviteSubmitted={sendUserInviteSubmitted}
          />
          <Pagination
            page={page}
            totalItems={data.total}
            itemsPerPage={resultCount}
            setItemsPerPage={setResultCount}
            setPage={setPage}
          />
        </>
      )}
      <CreateUserModal
        organizationId={organization.id}
        mutation={createUserMutation}
        opened={isCreateUserModalOpen}
        onClose={() => setIsCreateUserModalOpen(false)}
        departments={departments ? departments : []}
      />
    </>
  );
};
