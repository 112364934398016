import { EXERCISEPAGES, Exercises } from "../../enums/exercises/urls";
import {
  CreateExercisePagesPayload,
  ExercisePages,
  UpdateExercisePagesObject,
  ContentPreview,
} from "../../types/exercises/exercisepages";
import { createAxiosInstance } from "api/config";

const axios = createAxiosInstance();

export const getExercisePages = async (
  exerciseId: number
): Promise<ExercisePages[]> => {
  const { data } = await axios.get(
    `/${EXERCISEPAGES.GET_EXERCISEPAGES}/${exerciseId}/all`,
    { headers: { "Content-Type": "application/json;" }, data: {} }
  );

  return data;
};

export const getExercisePage = async (id: number): Promise<ExercisePages> => {
  const { data } = await axios.get(`/${EXERCISEPAGES.EXERCISEPAGES}/${id}`);

  return data;
};

export const contentPreview = async (
  payload: ContentPreview
): Promise<ContentPreview> => {
  const { data } = await axios.post(`/${Exercises.CONTENT_OVERVIEW}`, payload);

  return data;
};

export const createExercisePage = async (
  payload: CreateExercisePagesPayload
): Promise<number> => {
  const { data } = await axios.post(
    `/${EXERCISEPAGES.CREATE_EXERCISEPAGES}`,
    payload
  );

  return data;
};

export const deleteExercisePage = async (id: number): Promise<void> => {
  const { data } = await axios.delete(`/${EXERCISEPAGES.EXERCISEPAGES}/${id}`);

  return data;
};

export const updateExercisePage = async (
  exercisepage: UpdateExercisePagesObject
): Promise<number> => {
  const { data } = await axios.put(
    `/${EXERCISEPAGES.EXERCISEPAGES}/${exercisepage.id}`,
    exercisepage.payload,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return data;
};
