import { Button, Group, Modal, Space, Text } from "@mantine/core";
import { ImageUpload } from "components/common";
import { Color } from "enums/common";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Resource } from "types/resources/Resource";
import { useResources } from "../../../hooks/resources/useResources";
import { Pagination } from "../../common/Pagination/Pagination";
import { ImageGrid } from "./ImageGrid";
import { warningNotification } from "../../../utils/common/notifications";
import { generateRandomString } from "../../../utils/common/commonUtils";

export interface ImageResourcesModalProps {
  opened: boolean;
  onClose: () => void;
  setImage: (resource: Resource | undefined) => void;
  image: Resource | undefined;
}

export const ImageResourcesModal: React.FC<ImageResourcesModalProps> = ({
  opened,
  image,
  setImage,
  onClose,
}) => {
  const [selectedImage, setSelectedImage] = useState<Resource>();
  const [page, setPage] = useState(1);
  const [resultCount, setResultCount] = useState(15);
  const [avatarUrl, setAvatarUrl] = useState<string | undefined>();
  const [images, setImages] = useState<Resource[]>([]);
  const { data } = useResources(page, resultCount, 0);

  useEffect(() => {
    if (image) setSelectedImage(image);
  }, [image]);

  useEffect(() => {
    if (data?.elements) {
      setImages(data?.elements);
      setSelectedImage(image);
    }
  }, [data?.elements, image]);

  useEffect(() => {
    var selectedVideo = images.find((x) => x.storageIdentifier === avatarUrl);
    setSelectedImage(selectedVideo);
  }, [avatarUrl, images]);

  const handleImageId = (fileId: string | undefined) => {
    if (!fileId) return;
    setAvatarUrl(fileId);
  };

  const { t } = useTranslation();

  const handleClose = () => {
    onClose();
  };

  const handleSelectedImage = () => {
    if (!selectedImage) {
      const randomId = generateRandomString(20);
      warningNotification(
        randomId,
        t("Chapters:notifications.createResourceImageError")
      );
    } else {
      setImage(selectedImage);
      onClose();
    }
  };

  useEffect(() => {
    setSelectedImage(image);
  }, [image]);

  return (
    <Modal
      centered
      data-testid="uploadvideo-modal"
      title="Add Image"
      opened={opened}
      onClose={handleClose}
      size="lg"
    >
      <ImageUpload setAvatarIdentifier={(id) => handleImageId(id)} />
      <Space h="xl" />
      <Text
        styles={() => ({
          root: {
            fontSize: 16,
            color: Color.DARK,
            fontWeight: 600,
          },
        })}
      >
        {t("Common:select")}
      </Text>
      <Space h="xs" />
      {data && (
        <>
          <ImageGrid
            selectedImage={selectedImage}
            setSelectedImage={setSelectedImage}
            resources={images}
          />
          <Space h="xl" />
          <Pagination
            page={page}
            totalItems={data.total}
            itemsPerPage={resultCount}
            setItemsPerPage={setResultCount}
            setPage={setPage}
          />
        </>
      )}
      <Space h="md" />
      <Group>
        <Button
          onClick={handleSelectedImage}
          styles={() => ({
            root: {
              fontSize: 14,
              fontFamily: "Montserrat",
              backgroundColor: Color.DARK,
              fontWeight: 600,

              "&:hover": {
                backgroundColor: Color.DARK,
              },
            },
          })}
        >
          {t("Common:save")}
        </Button>
        <Button
          onClick={handleClose}
          styles={() => ({
            root: {
              fontSize: 14,
              fontFamily: "Montserrat",
              fontWeight: 600,
              borderColor: Color.DARK,
              backgroundColor: Color.WHITE,
              color: Color.DARK,
              marginRight: 10,

              "&:hover": {
                backgroundColor: Color.WHITE,
              },
            },
          })}
        >
          {t("Common:cancel")}
        </Button>
      </Group>
    </Modal>
  );
};
