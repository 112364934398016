import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { NativeSelect, Table, Image, Group } from "@mantine/core";

import { LeaderBoardUser } from "types/dashboard";

import { LeaderBoardOption } from "enums/dashboard";
import { Color } from "enums/common";

const LeaderBoardContainer = styled.div`
  box-sizing: border-box;
  height: 360px;
  padding: 25px 45px;
  background-color: ${Color.PASTEL_BLUE};
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.05);
`;

const LeaderBoardHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const HeaderText = styled.div`
  font-size: 20px;
  font-weight: 600;
`;

const StyledTr = styled.tr`
  font-family: "Montserrat";
  color: ${Color.DARK};

  &:nth-child(odd) {
    background-color: ${Color.WHITE};
  }

  &:nth-child(even) {
    background-color: ${Color.PASTEL_GREEN};
  }
`;

const StyledTd = styled.td`
  font-size: 18px !important;
`;

const Name = styled.td`
  font-size: 18px !important;
  font-weight: 600;
`;

export interface LeaderBoardProps {
  data: LeaderBoardUser[];
}

export const LeaderBoard: React.FC<LeaderBoardProps> = ({ data }) => {
  const { t } = useTranslation("Dashboard");

  const rows = data.map((element) => (
    <StyledTr key={element.name}>
      <Name>{element.name}</Name>
      <StyledTd>{element.team}</StyledTd>
      <StyledTd>
        <Group spacing="xs">
          <Image src="../images/medal.png" width={34} height={34} />
          {element.score}
        </Group>
      </StyledTd>
    </StyledTr>
  ));

  return (
    <LeaderBoardContainer data-testid="leader-board">
      <LeaderBoardHeader>
        <HeaderText>{t("leaderBoard.local")}</HeaderText>
        <NativeSelect
          data={[
            {
              value: LeaderBoardOption.WEEK,
              label: t("leaderBoard.week"),
            },
            {
              value: LeaderBoardOption.MONTH,
              label: t("leaderBoard.month"),
            },
            {
              value: LeaderBoardOption.YEAR,
              label: t("leaderBoard.year"),
            },
          ]}
          styles={{
            wrapper: {
              marginBottom: 13,
            },
            input: {
              fontFamily: "Montserrat",
              fontWeight: 700,
              color: Color.DARK,
            },
          }}
        />
      </LeaderBoardHeader>
      <Table
        sx={() => ({
          boxShadow: "4px 4px 20px rgba(0, 0, 0, 0.15);",
        })}
      >
        <tbody>{rows}</tbody>
      </Table>
    </LeaderBoardContainer>
  );
};
