import { useMutation } from "@tanstack/react-query";

import { assignExercise } from "../../api/chapters/exercises";
import { queryClient } from "../../App";
import i18n from "../../i18n";
import { AssignExercisePayload } from "../../types/chapters/assignExercise";
import { generateRandomString } from "../../utils/common/commonUtils";
import {
  endNotification,
  startNotification,
} from "../../utils/common/notifications";

export const useAssignExercise = () => {
  const randomId = generateRandomString(20);

  return useMutation(
    (payload: AssignExercisePayload) => assignExercise(payload),
    {
      onMutate: () => {
        startNotification(randomId);
      },
      onSuccess: () => {
        endNotification(
          randomId,
          i18n.t("Exercises:notifications.assignSuccess"),
          true
        );
        queryClient.invalidateQueries(["chapter-exercises"]);
      },
      onError: () => {
        endNotification(
          randomId,
          i18n.t("Exercises:notifications.assignError"),
          false
        );
      },
    }
  );
};
