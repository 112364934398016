import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Checkbox, Table } from "@mantine/core";

import { Color } from "enums/common";
import { User } from "../../types/user/User";
import { useListState } from "@mantine/hooks";
import { StatusName } from "enums/common/statusName";

export interface ManagersTableProps {
  managers: User[];
  setUsersToNotify: (users: User[]) => void;
  status: string;
  sendUserInviteSubmitted: boolean;
}

export const ManagersTable: React.FC<ManagersTableProps> = ({
  managers,
  setUsersToNotify,
  status,
  sendUserInviteSubmitted,
}) => {
  const [values, handlers] = useListState(
    managers.map((x) => ({ ...x, checked: false }))
  );

  const allChecked = values.every((value) => value.checked);
  const indeterminate = values.some((value) => value.checked) && !allChecked;

  useEffect(() => {
    setUsersToNotify(values.filter((v) => v.checked));
  }, [setUsersToNotify, values]);

  useEffect(() => {
    handlers.setState(managers.map((x) => ({ ...x, checked: false })));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [managers]);

  useEffect(() => {
    managers.map((x) => ({ ...x, checked: false }));
    handlers.setState(managers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendUserInviteSubmitted]);

  const { t } = useTranslation();

  return (
    <Table
      data-testid="members-table"
      highlightOnHover
      verticalSpacing={6}
      style={{ marginTop: 20, marginBottom: 20 }}
      sx={() => ({
        backgroundColor: Color.WHITE,
        boxShadow: "box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.15)",
      })}
    >
      <thead>
        <tr>
          <>
            {status === StatusName.UNCONFIRMED.toString() &&
              values.findIndex(
                (m) => m.status.id === StatusName.UNCONFIRMED
              ) !== -1 && (
                <th>
                  <Checkbox
                    checked={allChecked}
                    indeterminate={indeterminate}
                    onChange={() => {
                      handlers.setState((current) =>
                        current.map((value) => ({
                          ...value,
                          checked: !allChecked,
                        }))
                      );
                    }}
                  />
                </th>
              )}
          </>
          <th>{t("Users:table.fullName")}</th>
          <th>{t("Users:table.nickName")}</th>
          <th>{t("Users:table.email")}</th>
          <th>{t("Users:table.avatarUrl")}</th>
          <th>{t("Users:table.state")}</th>
        </tr>
      </thead>
      <tbody>
        {values.map((manager, index) => (
          <tr key={manager.id}>
            {status === StatusName.UNCONFIRMED.toString() &&
              values.findIndex(
                (m) => m.status.id === StatusName.UNCONFIRMED
              ) !== -1 && (
                <td>
                  {manager.status.id === StatusName.UNCONFIRMED && (
                    <Checkbox
                      onChange={(event) => {
                        handlers.setItemProp(
                          index,
                          "checked",
                          event.currentTarget.checked
                        );
                      }}
                      checked={manager.checked}
                    />
                  )}
                </td>
              )}
            <td>{manager.fullName}</td>
            <td>{manager.nickName}</td>
            <td>{manager.email}</td>
            <td>
              {manager.avatar && (
                <img
                  width="48"
                  height="48"
                  src={manager.avatar}
                  alt={`${manager.nickName}-avatar`}
                />
              )}
            </td>
            <td>
              {manager.status
                ? t(`Common:${manager.status.name.toString().toLowerCase()}`)
                : ""}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
