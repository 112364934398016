import { useMutation } from "@tanstack/react-query";

import i18n from "../../i18n";
import { queryClient } from "../../App";

import { createTemplate } from "../../api/templates/templates";

import { generateRandomString } from "../../utils/common/commonUtils";
import { CreateTemplatePayload } from "../../types/template/template";

import {
  startNotification,
  endNotification,
} from "../../utils/common/notifications";

export const useCreateTemplate = () => {
  const randomId = generateRandomString(20);

  return useMutation(
    (payload: CreateTemplatePayload) => createTemplate(payload),
    {
      onMutate: () => {
        startNotification(randomId);
      },
      onSuccess: () => {
        endNotification(
          randomId,
          i18n.t("Templates:notifications.createSuccess"),
          true
        );
        queryClient.invalidateQueries(["templates"]);
      },
      onError: () => {
        endNotification(
          randomId,
          i18n.t("Templates:notifications.createError"),
          false
        );
      },
    }
  );
};
