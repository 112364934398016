import { useMutation, useQuery } from "@tanstack/react-query";

import { createResource, getResources } from "api/resources/resource";
import { queryClient } from "App";
import i18n from "../../i18n";
import { CreateResourcePayload } from "../../types/resource/resource";
import { generateRandomString } from "../../utils/common/commonUtils";
import {
  endNotification,
  startNotification,
} from "../../utils/common/notifications";

export const useResources = (page: number, size: number, type: number) => {
  return useQuery(["resources", page, size, type], () =>
    getResources(page, size, type)
  );
};

export const useCreateResource = () => {
  const randomId = generateRandomString(20);

  return useMutation(
    (payload: CreateResourcePayload) => createResource(payload),
    {
      onMutate: () => {
        startNotification(randomId);
      },
      onSuccess: () => {
        endNotification(
          randomId,
          i18n.t("Courses:notifications.createSuccess"),
          true
        );
        queryClient.invalidateQueries(["resources"]);
      },
      onError: () => {
        endNotification(
          randomId,
          i18n.t("Chapters:notifications.createError"),
          false
        );
      },
    }
  );
};
