import { Box, Button, Group, Modal, Skeleton, Space } from "@mantine/core";

import { useState } from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { Color } from "enums/common";
import { TemplateObject } from "../../types/template/template";

import { useTemplates } from "../../hooks/templates/useTemplates";

import { Pagination } from "../common/Pagination/Pagination";

import { TemplatesModalMenu } from "./TemplatesMenu/TemplatesModalMenu";
import { TemplatesModalTable } from "./TemplatesTable/TemplatesModalTable";
import { useDebouncedValue } from "@mantine/hooks";

export interface TemplateModalProps {
  opened: boolean;
  selectedTemplate: TemplateObject | null;
  onClose: () => void;
  setSelectedTemplate: (template: TemplateObject | null) => void;
}

const TemplatesModalTitle = styled.div`
  color: ${Color.DARK};
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 10px;
`;

export const TemplatesModal: React.FC<TemplateModalProps> = ({
  opened,
  selectedTemplate,
  onClose,
  setSelectedTemplate,
}) => {
  const { t } = useTranslation();
  const [searchKey, setSearchKey] = useState("");
  const [debouncedSearchKey] = useDebouncedValue(searchKey, 300);
  const [page, setPage] = useState(1);
  const [resultCount, setResultCount] = useState(15);
  const { data, isLoading } = useTemplates(
    page,
    debouncedSearchKey,
    resultCount
  );

  const handleClose = () => {
    onClose();
    setSearchKey("");
  };

  return (
    <Modal opened={opened} onClose={handleClose} size="80%">
      <Box>
        <TemplatesModalTitle>
          {t("Exercises:createExercise.template")}
        </TemplatesModalTitle>
        {isLoading && (
          <Skeleton height={550} radius="sm" style={{ marginTop: 30 }} />
        )}
        <TemplatesModalMenu setSearchKey={setSearchKey} searchKey={searchKey} />

        {data && (
          <>
            <TemplatesModalTable
              isLoading={isLoading}
              templates={data.elements}
              setSelectedTemplate={setSelectedTemplate}
              selectedTemplate={selectedTemplate}
            />
            <Pagination
              page={page}
              totalItems={data.total}
              itemsPerPage={resultCount}
              setItemsPerPage={setResultCount}
              setPage={setPage}
            />
            <Space h="md" />
            <Group position="right" mt="md">
              <Button
                data-testid="submit-button"
                type="submit"
                onClick={handleClose}
                styles={(theme) => ({
                  root: {
                    fontSize: 16,
                    fontFamily: "Montserrat",
                    backgroundColor: Color.DARK,
                    fontWeight: 600,

                    "&:hover": {
                      backgroundColor: theme.fn.lighten(Color.DARK, 0.1),
                    },
                  },
                })}
              >
                {t("Common:actions.ok")}
              </Button>
            </Group>
          </>
        )}
      </Box>
    </Modal>
  );
};
