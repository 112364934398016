import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Group, Anchor } from "@mantine/core";

import { ExerciseTile } from "components/courses";

import { Color } from "enums/common";

import { Exercise } from "types/exercises/exercises";

export interface ExercisesListProps {
  exercises: Exercise[];
}

const FlexContainer = styled.div`
  margin-top: 50px;
  color: ${Color.DARK};
`;

export const ExercisesList: React.FC<ExercisesListProps> = ({ exercises }) => {
  const { t } = useTranslation("Exercises");

  const navigate = useNavigate();

  return (
    <FlexContainer>
      <h3>{t("title")}</h3>
      <Group spacing="lg">
        {exercises.slice(0, 5).map((exercise) => (
          <ExerciseTile
            key={exercise.id}
            exercise={exercise}
            onClick={() => navigate(`/exercises/${exercise.id}`)}
          />
        ))}
      </Group>
      <Group position="right" mt="md">
        <Anchor
          component={Link}
          to="/exercises/all"
          styles={() => ({
            root: {
              marginTop: "10px",
              marginBottom: "20px",
              color: Color.DARK,
              fontWeight: "bold",
            },
          })}
        >
          {t("showMore", { ns: "Courses" })}
        </Anchor>
      </Group>
    </FlexContainer>
  );
};
