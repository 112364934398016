import React from "react";
import { useTranslation } from "react-i18next";

import { Group, Button, TextInput, Select } from "@mantine/core";
import styled from "styled-components";

import { Color } from "enums/common";
import { Department } from "../../types/department/Department";
import { StatusName } from "../../enums/common/statusName";
import { User } from "types/user/User";
import { Organization } from "types/organizations";

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export interface MembersMenuProps {
  searchKey: string;
  departments: Department[];
  statusFilter?: string;
  departmentFilter: string;
  setSearchKey: (searchKey: string) => void;
  setIsModalOpen: (isModalOpen: boolean) => void;
  setStatusFilter: (status: string) => void;
  setDepartmentFilter: (id: string) => void;
  organization?: Organization;
  selectedUsersToNotify: User[] | undefined;
  sendUserInviteSubmitted: (isSubmitted: boolean) => void;
  mutation: any;
}

export const MembersMenu: React.FC<MembersMenuProps> = ({
  searchKey,
  statusFilter,
  departmentFilter,
  sendUserInviteSubmitted,
  departments,
  setSearchKey,
  setIsModalOpen,
  setStatusFilter,
  setDepartmentFilter,
  organization,
  selectedUsersToNotify,
  mutation,
}) => {
  const organizationId = Number(organization?.id);
  const userIds =
    selectedUsersToNotify?.map((user) => user.id).toString() ?? "";

  const { t } = useTranslation();

  const departmentOptions = departments.map((department: Department) => ({
    value: department.id.toString(),
    label: department.name.toString(),
  }));
  departmentOptions.unshift({ value: "", label: t("Common:allDepartments") });

  const handleSubmit = () => {
    mutation.mutate({ organizationId: organizationId, userIds: userIds });
    sendUserInviteSubmitted(true);
  };

  return (
    <FlexContainer data-testid="members-menu">
      <Group spacing={0}>
        <TextInput
          value={searchKey}
          onChange={(event) => setSearchKey(event.currentTarget.value)}
          placeholder={t("Users:members.tab.searchPlaceholder")}
          styles={{
            root: { width: 640 },
            input: { borderRadius: 20 },
          }}
        />
        <Button
          variant="filled"
          onClick={() => setSearchKey(searchKey)}
          styles={(theme) => ({
            root: {
              fontSize: 14,
              textTransform: "uppercase",
              fontFamily: "Montserrat",
              fontWeight: 600,
              backgroundColor: Color.DARK,
              borderRadius: 20,
              left: -30,

              "&:hover": {
                backgroundColor: theme.fn.lighten(Color.DARK, 0.1),
              },
            },
          })}
        >
          {t("Common:search")}
        </Button>
      </Group>
      <Group>
        {departmentOptions.length && (
          <Select
            onChange={setDepartmentFilter}
            placeholder={t("Common:departments")}
            data={departmentOptions}
            value={
              departmentOptions.find((x) => x.value === departmentFilter)?.value
            }
            styles={{
              input: {
                fontFamily: "Montserrat",
                fontWeight: 600,
                color: Color.DARK,
                borderColor: Color.DARK,
                borderWidth: 1,
              },
            }}
          />
        )}

        <Select
          value={statusFilter}
          onChange={setStatusFilter}
          placeholder={t("Common:status")}
          data={[
            {
              value: "",
              label: t("Common:allStatus"),
            },
            {
              value: StatusName.ACTIVE.toString(),
              label: t("Common:active"),
            },
            {
              value: StatusName.INACTIVE.toString(),
              label: t("Common:inactive"),
            },
            {
              value: StatusName.UNCONFIRMED.toString(),
              label: t("Common:unconfirmed"),
            },
          ]}
          styles={{
            input: {
              fontFamily: "Montserrat",
              fontWeight: 600,
              color: Color.DARK,
              borderColor: Color.DARK,
              borderWidth: 1,
              width: 150,
            },
          }}
        />
        <Button
          onClick={() => {
            setIsModalOpen(true);
          }}
          variant="outline"
          styles={() => ({
            root: {
              fontSize: 14,
              textTransform: "uppercase",
              color: Color.DARK,
              fontFamily: "Montserrat",
              fontWeight: 600,
              borderColor: Color.DARK,
              borderWidth: 1,
              backgroundColor: Color.WHITE,
            },
          })}
        >
          {t("Common:addUser")}
        </Button>
        {selectedUsersToNotify && selectedUsersToNotify.length > 0 && (
          <Button
            onClick={handleSubmit}
            variant="outline"
            styles={() => ({
              root: {
                fontSize: 14,
                textTransform: "uppercase",
                color: Color.DARK,
                fontFamily: "Montserrat",
                fontWeight: 600,
                borderColor: Color.DARK,
                borderWidth: 1,
                backgroundColor: Color.WHITE,
              },
            })}
          >
            Send Invite
          </Button>
        )}
      </Group>
    </FlexContainer>
  );
};
