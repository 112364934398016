import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDeleteChapter } from "../../../hooks/chapters/useDeleteChapter";
import { DeleteChapterModal } from "../DeleteChapterModal/DeleteChapterModal";
import { AccordionDropdown } from "./Accordion/AccordionDropdown";
import { ChapterDetails } from "../../../types/chapters/chapterDetails";

export interface ChapterOverviewProps {
  chapters: ChapterDetails[];
  courseId: number;
}

export const ChapterOverview: React.FC<ChapterOverviewProps> = ({
  chapters,
  courseId,
}) => {
  const [selectedChapter, setSelectedChapter] = useState<ChapterDetails>();
  const [isDeleteExciseModalOpen, setIsDeleteExciseModalOpen] = useState(false);

  const deleteChapterMutation = useDeleteChapter();

  const handleEditChapter = (chapter: ChapterDetails) => {
    setSelectedChapter(chapter);
  };

  const handleDeleteChapter = (chapter: ChapterDetails) => {
    setSelectedChapter(chapter);
    setIsDeleteExciseModalOpen(true);
  };

  const { t } = useTranslation();

  return (
    <>
      <AccordionDropdown
        chapters={chapters}
        courseId={courseId}
        onDeleteChapter={handleDeleteChapter}
        onEditChapter={handleEditChapter}
      />
      {selectedChapter && (
        <DeleteChapterModal
          chapter={selectedChapter}
          title={t("Chapters:modals.deleteModalTitle")}
          text={t("Chapters:modals.deleteModalText")}
          onClose={() => setIsDeleteExciseModalOpen(false)}
          opened={isDeleteExciseModalOpen}
          mutation={deleteChapterMutation}
        />
      )}
    </>
  );
};
