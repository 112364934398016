import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";
import { Anchor, Breadcrumbs, Container, Skeleton } from "@mantine/core";

import { CreateModal } from "../../components/department/CreateModal/CreateModal";
import { EditModal } from "../../components/department/EditModal/EditModal";
import { useCreateDepartment } from "../../hooks/departments/useCreateDepartment";
import { useUpdateDepartment } from "../../hooks/departments/useUpdateDepartment";
import { useDeleteDepartment } from "../../hooks/departments/useDeleteDepartment";

import { DepartmentsMenu } from "../../components/department/DepartmentsMenu/DepartmentsMenu";
import { DepartmentsTable } from "../../components/department/DepartmentsTable/DepartmentsTable";

import { BasePage } from "../../components/common/BasePage/BasePage";
import { Pagination } from "../../components/common/Pagination/Pagination";

import { Color } from "enums/common";

import { Department } from "../../types/department/Department";
import { useParams } from "react-router-dom";
import { useDepartments } from "../../hooks/departments/useDepartments";
import { DeleteModal } from "../../components/common/Modal/DeleteModal";
import { useOrganization } from "../../hooks/organizations/useOrganization";
import { useDebouncedValue } from "@mantine/hooks";

const DepartmentsTitle = styled.div`
  color: ${Color.DARK};
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 10px;
`;

const BreadcrumbPannel = styled.div`
  margin: 34px 0 40px 0;
`;

export const Departments: React.FC = () => {
  const { organizationId } = useParams();

  const organizationQuery = useOrganization(Number(organizationId));

  const { t } = useTranslation();
  const [searchKey, setSearchKey] = useState("");
  const [debouncedSearchKey] = useDebouncedValue(searchKey, 300);
  const [page, setPage] = useState(1);
  const [resultCount, setResultCount] = useState(15);

  const [selectedDepartment, setSelectedDepartment] = useState<Department>();

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);

  const createDepartmentMutation = useCreateDepartment(Number(organizationId));
  const updateDepartmentMutation = useUpdateDepartment();
  const deleteDepartmentMutation = useDeleteDepartment();
  const { data, isLoading } = useDepartments(
    page,
    debouncedSearchKey,
    resultCount,
    Number(organizationId)
  );

  const organizationName = organizationQuery.data
    ? organizationQuery.data.name
    : "";

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleEditDepartment = (department: Department) => {
    setSelectedDepartment(department);
    setIsUpdateModalOpen(true);
  };

  const handleDeleteDepartment = (department: Department) => {
    setSelectedDepartment(department);
    setIsDeleteModalOpen(true);
  };

  const items = [
    { title: "Organizations", href: "/organizations" },
    {
      title: organizationName,
      href: `/organizations/${organizationId}`,
    },
    {
      title: t("Departments"),
      href: `/organizations/${organizationId}/departments`,
    },
  ].map((item, index) => (
    <Anchor href={item.href} key={index}>
      {item.title}
    </Anchor>
  ));

  return (
    <BasePage>
      <Container data-testid="departments-page">
        <BreadcrumbPannel>
          <Breadcrumbs
            styles={{
              breadcrumb: { color: Color.DARK, fontWeight: "bold" },
              separator: { color: Color.DARK, fontWeight: "bold" },
            }}
          >
            {items}
          </Breadcrumbs>
        </BreadcrumbPannel>
        <DepartmentsTitle>{t("Departments:banner.title")}</DepartmentsTitle>
        <DepartmentsMenu
          searchKey={searchKey}
          setSearchKey={setSearchKey}
          setIsModalOpen={setIsCreateModalOpen}
        />
        {isLoading && (
          <Skeleton height={550} radius="sm" style={{ marginTop: 30 }} />
        )}
        {data && (
          <>
            <DepartmentsTable
              departments={data.elements}
              onEditDepartment={handleEditDepartment}
              onDeleteDepartment={handleDeleteDepartment}
            />
            <Pagination
              page={page}
              totalItems={data.total}
              itemsPerPage={resultCount}
              setItemsPerPage={setResultCount}
              setPage={setPage}
            />
          </>
        )}
        <CreateModal
          mutation={createDepartmentMutation}
          opened={isCreateModalOpen}
          onClose={() => setIsCreateModalOpen(false)}
        />
        {selectedDepartment && (
          <EditModal
            department={selectedDepartment}
            mutation={updateDepartmentMutation}
            opened={isUpdateModalOpen}
            onClose={() => {
              setIsUpdateModalOpen(false);
              setSelectedDepartment(undefined);
            }}
          />
        )}
        {selectedDepartment && (
          <DeleteModal
            department={selectedDepartment}
            title={t("Departments:modals.deleteModalTitle")}
            text={t("Departments:modals.deleteModalText")}
            onClose={() => setIsDeleteModalOpen(false)}
            opened={isDeleteModalOpen}
            mutation={deleteDepartmentMutation}
          />
        )}
      </Container>
    </BasePage>
  );
};
