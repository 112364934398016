import { useMutation } from "@tanstack/react-query";
import { notifyUsers } from "api/organizations/users/notifyUsers";
import { queryClient } from "App";
import i18n from "i18n";
import { NotifyUsersPayload } from "types/user/NotifyPeople";
import {
  endNotification,
  generateRandomString,
  startNotification,
} from "utils/common";

export const useNotifyUsers = () => {
  const randomId = generateRandomString(20);

  return useMutation((payload: NotifyUsersPayload) => notifyUsers(payload), {
    onMutate: () => {
      startNotification(randomId);
    },
    onSuccess: () => {
      endNotification(
        randomId,
        i18n.t("Users:notifications.inviteSuccess"),
        true
      );
      queryClient.invalidateQueries(["organizationUsers"]);
    },
    onError: () => {
      endNotification(
        randomId,
        i18n.t("Users:notifications.inviteError"),
        false
      );
    },
  });
};
