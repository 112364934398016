import { UserCourses } from "../../enums/userCourses/userCourses";
import { ApiResponse } from "../../types/api/ApiResponse";
import { AssignUserCoursePayload } from "../../types/userCourse/assignCourse";
import { UserCourse } from "../../types/userCourse/userCourse";
import { createAxiosInstance } from "api/config";

// Utils
import { joinQueryParams } from "utils/queryParams";

const axios = createAxiosInstance();

export const getUserCourses = async (
  page: number,
  searchKey: string,
  size: number,
  sortKey: number | null,
  organizationId: number | null
): Promise<ApiResponse<UserCourse[]>> => {
  const params = joinQueryParams({
    page: page.toString(),
    "search-key": searchKey,
    size: size.toString(),
    "sort-key": sortKey?.toString(),
    "organization-id": organizationId?.toString(),
  });

  const { data } = await axios.get(
    `/${UserCourses.GET_USER_COURSES}?${params}`,
    { headers: { "Content-Type": "application/json;" }, data: {} }
  );

  return data;
};

export const deleteUserCourse = async (id: number): Promise<void> => {
  const { data } = await axios.delete(`/${UserCourses.USER_COURSES}/${id}`);

  return data;
};

export const assignCoursesToUsers = async (
  userCourse: AssignUserCoursePayload
): Promise<UserCourse[]> => {
  const { data } = await axios.post(
    `/${UserCourses.ASSIGN_COURSES}`,
    userCourse
  );

  return data;
};
