import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { World } from "tabler-icons-react";
import { Button, Menu } from "@mantine/core";

const LanguageChangeMenuContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px 20px;
`;

export const LanguageChangeMenu: React.FC = () => {
  const { t, i18n } = useTranslation("Auth");

  const changeLanguage = (lng: string | undefined) => {
    i18n.changeLanguage(lng);
  };

  return (
    <LanguageChangeMenuContainer>
      <Menu
        control={
          <Button
            variant="subtle"
            leftIcon={<World size={22} strokeWidth={2} color={"white"} />}
            styles={() => ({
              root: {
                "&:hover": {
                  backgroundColor: "transparent",
                },
              },
            })}
          />
        }
      >
        <Menu.Item onClick={() => changeLanguage("en")}>
          {t("loginPage.en")}
        </Menu.Item>
        <Menu.Item onClick={() => changeLanguage("no")}>
          {t("loginPage.no")}
        </Menu.Item>
      </Menu>
    </LanguageChangeMenuContainer>
  );
};
