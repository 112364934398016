import { createAxiosInstance } from "api/config";
import { USER } from "enums/users/urls";
import { ApiResponse } from "types/api";
import { NotifyUsersPayload } from "types/user/NotifyPeople";
import { User } from "types/user/User";

const axios = createAxiosInstance();

export const notifyUsers = async (
  payload: NotifyUsersPayload
): Promise<ApiResponse<User[]>> => {
  const { data } = await axios.get(
    `/${USER.NOTIFY_USERS}${payload.organizationId}/notify?userIds=${payload.userIds}`,
    { headers: { "Content-Type": "application/json;" }, data: {} }
  );
  return data;
};
