import {
  Accordion,
  AccordionItem,
  Button,
  Group,
  Space,
  Text,
} from "@mantine/core";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Color } from "enums/common";
import { ExercisePages } from "../../../../../types/exercises/exercisepages";
import { AccordionPageLabel } from "./AccordionPageLabel";

const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const RightContainer = styled.div`
  flex: 30%;
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
`;

const LeftContainer = styled.div`
  flex: 70%;
  display: flex;
  flex-direction: column;
`;

export interface ExercisePageDropdownProps {
  pages: ExercisePages[];
  exerciseId: number;
  onDeleteExercisePage: (exercisepage: ExercisePages) => void;
}

export function AccordionPageDropdown({
  exerciseId,
  pages,
  onDeleteExercisePage,
}: ExercisePageDropdownProps) {
  const { t } = useTranslation();

  const pageitems =
    pages && pages.length
      ? pages?.map((page) => {
          return (
            <AccordionItem
              key={page.id}
              label={<AccordionPageLabel page={page} />}
            >
              <Space h="xl" />
              <Group>
                <ButtonsContainer>
                  <LeftContainer>
                    <Text
                      styles={() => ({
                        root: {
                          fontSize: 16,
                          color: Color.DARK,
                          fontWeight: 700,
                        },
                      })}
                    >
                      {t("Exercises:createExercisePage.scriptText")}
                      {":"}
                    </Text>
                    <Text
                      styles={() => ({
                        root: {
                          fontSize: 16,
                          color: Color.DARK,
                          fontWeight: 500,
                        },
                      })}
                    >
                      {page.scriptText}
                    </Text>
                    <Text
                      styles={() => ({
                        root: {
                          fontSize: 16,
                          color: Color.DARK,
                          fontWeight: 700,
                        },
                      })}
                    >
                      {t("Exercises:createExercisePage.customerText")}
                      {":"}
                    </Text>
                    <Text
                      styles={() => ({
                        root: {
                          fontSize: 16,
                          color: Color.DARK,
                          fontWeight: 500,
                        },
                      })}
                    >
                      {page.customerText}
                    </Text>
                  </LeftContainer>
                  <RightContainer>
                    <Button
                      component={Link}
                      to={`/exercises/${exerciseId}/pages/${page.id}/edit`}
                      styles={() => ({
                        root: {
                          fontSize: 14,
                          fontFamily: "Montserrat",
                          fontWeight: 600,
                          borderColor: Color.DARK,
                          backgroundColor: Color.WHITE,
                          color: Color.DARK,
                          marginRight: 10,

                          "&:hover": {
                            backgroundColor: Color.WHITE,
                          },
                        },
                      })}
                    >
                      {t("Exercises:createExercisePage.edit")}
                    </Button>
                    <Button
                      styles={() => ({
                        root: {
                          fontSize: 14,
                          fontFamily: "Montserrat",
                          fontWeight: 600,
                          backgroundColor: Color.DARK,

                          "&:hover": {
                            backgroundColor: Color.DARK,
                          },
                        },
                      })}
                      onClick={() => onDeleteExercisePage(page)}
                    >
                      {t("Exercises:createExercisePage.delete")}
                    </Button>
                  </RightContainer>
                </ButtonsContainer>
              </Group>
              <Space h="lg" />
            </AccordionItem>
          );
        })
      : "";

  return (
    <>
      <Accordion
        sx={() => ({
          backgroundColor: Color.WHITE,
          "&:hover": {
            backgroundColor: Color.WHITE,
          },
        })}
        initialItem={-1}
        iconPosition="right"
      >
        {pageitems}
      </Accordion>
    </>
  );
}
