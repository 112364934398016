import { Suspense, useContext, useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import { ProtectedRoute } from "./ProtectedRoute";
import { ErrorPage } from "./ErrorPage";

import { Dashboard } from "../pages/Dashboard/Dashboard";

import { MyProfile } from "pages/MyProfile";

import { AllCourses } from "../pages/Courses/AllCourses";
import { Courses } from "../pages/Courses/Courses";
import { CreateCourse } from "../pages/Courses/CreateCourse";

import { AllExercises } from "../pages/Exercises/AllExercises";
import { CreateExercise } from "../pages/Exercises/CreateExercise";
import { EditExercise } from "../pages/Exercises/EditExercise";
import { ExerciseDetails } from "../pages/Exercises/ExerciseDetails";

import { Departments } from "../pages/Departments/Departments";

import { Organization } from "../pages/Organizations/Organization";
import { Organizations } from "../pages/Organizations/Organizations";

import { CourseDetails } from "../pages/Courses/CourseDetails";
import { UsersCoursesTab } from "../components/courses/CoursesTabs/UsersCoursesTab";

import { CreateChapter } from "../pages/Chapters/CreateChapter";
import { EditCoursePage } from "../pages/Courses/EditCoursePage";
import { Templates } from "../pages/Templates/Templates";
import { CreateTemplate } from "../pages/Templates/CreateTemplate";
import { UpdateTemplate } from "../pages/Templates/UpdateTemplate";
import { CreateChapterPage } from "../pages/Chapters/ChapterPages/CreateChapterPage";
import { CreateQuiz } from "../pages/Chapters/ChapterPages/CreateQuiz";
import { AddScriptPage } from "../pages/Chapters/ChapterPages/ChapterMedia/AddScriptPage";
import { EditChapter } from "../pages/Chapters/EditChapter";
import { EditChapterPage } from "../pages/Chapters/ChapterPages/EditChapterPage";
import { EditQuiz } from "../pages/Chapters/ChapterPages/EditQuiz";
import { UserManagement } from "../pages/UserManagement/UserManagement";
import { UserManagementDetails } from "../pages/UserManagement/UserManagementDetails";

import { Login, ResetPassword } from "../pages/auth";
import { CreatePage } from "../pages/Exercises/CreatePage";
import { EditPage } from "../pages/Exercises/EditPage";
import { DepartmentDetails } from "pages/Departments/DepartmentDetails";
import { AssignExerciseToUserPage } from "pages/Exercises/AssignExerciseToUserPage";
import { AssignUsersToCourses } from "pages/Courses/AssignUsersToCourses";

import { useToken } from "hooks/auth";
import { UserContext } from "contexes";
import { Loader } from "@mantine/core";
import styled from "styled-components";

const StyledLoader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 22em;
`;

const Loading = () => (
  <StyledLoader>
    <Loader size="lg" />
  </StyledLoader>
);

export const Router = () => {
  const [user] = useContext(UserContext);

  const { data: response, error } = useToken(localStorage.getItem("authToken"));

  const [userLoaded, setUserLoaded] = useState(false);

  useEffect(() => {
    if (response && response.data) {
      setUserLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  useEffect(() => {
    if (error) {
      setUserLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  if (!userLoaded) {
    return <Loading />;
  }

  return (
    <BrowserRouter>
      <Suspense fallback={<Loading />}>
        {/*Public routes*/}
        {!user.access_token && (
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            {/*Error page route*/}
            <Route path="/denied" element={<ErrorPage />} />
            <Route path="*" element={<Navigate to="/login" replace />} />
          </Routes>
        )}
        {user.access_token && (
          <Routes>
            {/*Protected routes*/}
            <Route element={<ProtectedRoute />}>
              <Route path="/" element={<Dashboard />} />

              <Route path="/my-profile" element={<MyProfile />} />

              <Route path="/organizations" element={<Organizations />} />
              <Route
                path="/organizations/:organizationId/"
                element={<Organization />}
              />
              <Route
                path="/organizations/:organizationId/departments"
                element={<Departments />}
              />
              <Route
                path="/organizations/:organizationId/departments/:departmentId/details"
                element={<DepartmentDetails />}
              />
              <Route path="/userManagement" element={<UserManagement />} />
              <Route
                path="/users/:id/details"
                element={<UserManagementDetails />}
              />
              <Route
                path="/users-courses/:id/users"
                element={<UsersCoursesTab />}
              />
              <Route path="/courses/create" element={<CreateCourse />} />
              <Route path="/courses/:id/edit" element={<EditCoursePage />} />
              <Route path="/courses" element={<Courses />} />
              <Route path="/courses/all" element={<AllCourses />} />
              <Route path="/courses/users" element={<AllCourses />} />
              <Route
                path="/users-courses/:id/users"
                element={<UsersCoursesTab />}
              />
              <Route path="/courses/:id/edit" element={<EditCoursePage />} />
              <Route path="/courses/:id" element={<CourseDetails />} />
              <Route path="/practice-templates/all" element={<Templates />} />
              <Route
                path="/courses/:id/chapters/create"
                element={<CreateChapter />}
              />
              <Route path="/practices/all" element={<AllExercises />} />
              <Route
                path="/exercises/createExercise"
                element={<CreateExercise />}
              />
              <Route path="/exercises/:id/edit" element={<EditExercise />} />
              <Route path="/exercises/:id" element={<ExerciseDetails />} />
              <Route
                path="/exercises/:id/pages/create"
                element={<CreatePage />}
              />
              <Route
                path="/exercises/:id/pages/:pageId/edit"
                element={<EditPage />}
              />

              <Route
                path="/courses/:id/chapters/create"
                element={<CreateChapter />}
              />
              <Route path="/exercises/all" element={<AllExercises />} />
              <Route
                path="/exercises/createExercise"
                element={<CreateExercise />}
              />
              <Route path="/exercises/:id/edit" element={<EditExercise />} />
              <Route path="/exercises/:id" element={<ExerciseDetails />} />
              <Route
                path="/exercises/createTemplate"
                element={<CreateTemplate />}
              />
              <Route path="/templates/:id/edit" element={<UpdateTemplate />} />

              <Route
                path="/courses/:id/chapters/create"
                element={<CreateChapter />}
              />
              <Route
                path="/courses/:id/chapters/:chapterId/edit"
                element={<EditChapter />}
              />
              <Route
                path="/courses/:id/chapters/:chapterId/newpage"
                element={<CreateChapterPage />}
              />
              <Route
                path="/courses/:id/chapters/:chapterId/editpage/:pageId"
                element={<EditChapterPage />}
              />
              <Route
                path="/courses/:id/chapters/:chapterId/addquiz"
                element={<CreateQuiz />}
              />
              <Route
                path="/courses/:id/chapters/:chapterId/quiz/:quizId"
                element={<EditQuiz />}
              />
              <Route
                path="/courses/:id/chapters/:chapterId/addscript"
                element={<AddScriptPage />}
              />

              <Route
                path="course-assignement"
                element={<AssignUsersToCourses />}
              />

              <Route path="/exercises/all" element={<AllExercises />} />
              <Route
                path="/exercises/createExercise"
                element={<CreateExercise />}
              />
              <Route path="/exercises/:id/edit" element={<EditExercise />} />
              <Route path="/exercises/:id" element={<ExerciseDetails />} />
              <Route
                path="/exercises-assignment"
                element={<AssignExerciseToUserPage />}
              />
              <Route
                path="/exercises/createTemplate"
                element={<CreateTemplate />}
              />
              <Route path="/templates/:id/edit" element={<UpdateTemplate />} />
            </Route>
            {/*Error page route*/}
            <Route path="/denied" element={<ErrorPage />} />
            <Route path="*" element={<Navigate to="/denied" replace />} />
          </Routes>
        )}
      </Suspense>
    </BrowserRouter>
  );
};
