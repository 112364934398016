import { Text, Checkbox } from "@mantine/core";
import { Color } from "enums/common";
import { Fragment, useEffect, useState } from "react";
import styled from "styled-components";
import { Resource } from "types/resources/Resource";

const StyledAudios = styled.div`
  display: flex;
  flex-direction: row;
`;

export interface AudioListProps {
  resources: Resource[];
  selectedAudio: Resource | undefined;
  setSelectedAudio: (resource: Resource) => void;
}

export function AudioList({
  resources,
  selectedAudio,
  setSelectedAudio,
}: AudioListProps) {
  const [audio, setAudio] = useState<Resource>();

  useEffect(() => {
    if (selectedAudio) setAudio(selectedAudio);
  }, [selectedAudio]);

  return (
    <div>
      {resources.map((resource, index) => (
        <Fragment key={index}>
          <StyledAudios>
            <Checkbox
              onChange={() =>
                setSelectedAudio({ ...resource, resourceId: resource.id })
              }
              checked={audio?.id === resource.id}
            />
            <Text
              onClick={() => setSelectedAudio(resource)}
              styles={() => ({
                root: {
                  fontSize: 16,
                  color: Color.DARK,
                  marginLeft: 10,
                  marginTop: 2,
                },
              })}
            >
              {resource.originalName}
            </Text>
          </StyledAudios>
        </Fragment>
      ))}
    </div>
  );
}
