import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { Container, Tabs, TabsProps } from "@mantine/core";

// Types
import { Color } from "enums/common";

// Components
import styled from "styled-components";
import { AllCoursesTab } from "components/courses/CoursesTabs/AllCoursesTab";
import { UsersCoursesTab } from "components/courses/CoursesTabs/UsersCoursesTab";
import { BasePage } from "components/common";

const TabContainer = styled.div`
  margin-top: 60px;
  margin-bottom: 10px;
`;

function StyledTabs(props: TabsProps) {
  return (
    <Tabs
      variant="unstyled"
      styles={(theme) => ({
        tabControl: {
          backgroundColor: Color.GRAY,
          color: Color.DARK,
          fontSize: "20px",
          lineHeight: "24px",
          padding: `${theme.spacing.lg}px ${theme.spacing.xl}px`,

          "&:not(:first-of-type)": {
            borderLeft: 0,
          },
        },

        tabActive: {
          borderColor: Color.DARK,
          borderBottom: `3px solid ${Color.DARK}`,
          fontWeight: 700,
        },
      })}
      {...props}
    />
  );
}

export const AllCourses: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  let activeTab = 0;

  if (location.pathname === "/courses/users") {
    activeTab = 1;
  }

  const onTabChange = (tabIndex: number) => {
    if (tabIndex === 0) navigate("/courses/all");
    if (tabIndex === 1) navigate("/courses/users");
  };

  return (
    <div>
      <BasePage>
        <Container>
          <TabContainer>
            <StyledTabs active={activeTab} onTabChange={onTabChange}>
              <Tabs.Tab label={t("Courses:tab.allCourses")}>
                <AllCoursesTab />
              </Tabs.Tab>
              <Tabs.Tab label={t("Courses:tab.userCourses")}>
                <UsersCoursesTab />
              </Tabs.Tab>
            </StyledTabs>
          </TabContainer>
        </Container>
      </BasePage>
    </div>
  );
};
