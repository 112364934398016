import axios from "axios";
import { configuration } from "config";

export const createAxiosInstance = (baseURL = configuration().baseUrl) => {
  const _axios = axios.create({ baseURL });

  _axios.interceptors.request.use((request) => {
    if (!request.headers) {
      request.headers = {};
    }
    request.headers.Accept = "application/json";

    // Attach token to request if available
    const jwtToken = localStorage.getItem("authToken");
    if (jwtToken) {
      request.headers.Authorization = `Bearer ${jwtToken}`;
    }

    // Send content-type with POST/PUT requests
    if (request.method === "POST" || request.method === "PUT") {
      request.headers["Content-Type"] = "application/json";
    }

    return request;
  });

  // if request succeeds with status code 1001 throw error
  _axios.interceptors.response.use((response) => {
    if (response.data.code === 1001) {
      localStorage.removeItem("authToken");
      window.location.reload();
    }

    return response;
  });

  return _axios;
};
