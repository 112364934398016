import React, { useState } from "react";

import { QueryClientProvider, QueryClient } from "@tanstack/react-query";

import { MantineProvider } from "@mantine/core";
import { NotificationsProvider } from "@mantine/notifications";

import { UserContext, defaultLoginResponse } from "contexes";

import { Router } from "routes";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App: React.FC = () => {
  const [user, setUser] = useState(defaultLoginResponse);

  return (
    <MantineProvider>
      <NotificationsProvider>
        <QueryClientProvider client={queryClient}>
          <UserContext.Provider value={[user, setUser]}>
            <Router />
          </UserContext.Provider>
        </QueryClientProvider>
      </NotificationsProvider>
    </MantineProvider>
  );
};

export default App;
