import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { AxiosError } from "axios";
import styled from "styled-components";
import { TimeInput } from "@mantine/dates";
import { Clock } from "tabler-icons-react";

import { Modal, TextInput, Textarea, Group, Button } from "@mantine/core";
import { useForm } from "@mantine/form";

import { ImageUpload } from "../../common";
import { ApiError } from "../../../types/api/ApiError";
import { ErrorCode } from "../../../enums/api/ErrorCode";

export interface CreateModalProps {
  opened: boolean;
  mutation: any;
  onClose: () => void;
}

const TimeInputsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
`;

const TrainingTimeTitle = styled.div`
  font-size: 14px;
  line-height: 21.7px;
  padding: 1rem 0; ;
`;

export const CreateModal: React.FC<CreateModalProps> = ({
  opened,
  mutation,
  onClose,
}) => {
  const TimeInputStyle = {
    width: "100%",
    label: {
      lineHeight: "18.6px",
      fontSize: "14px",
      width: "100%",
    },
    input: {
      color: "#495057",
    },
  };
  const { t } = useTranslation();

  const defaultTime = new Date();
  defaultTime.setHours(0);
  defaultTime.setMinutes(0);

  const [avatarUrl, setAvatarUrl] = useState<string | undefined>();

  const form = useForm({
    initialValues: {
      name: "",
      summary: "",
      avatar: "",
      beginnerLearningTime: defaultTime,
      expertLearningTime: defaultTime,
      intermediateLearningTime: defaultTime,
    },
  });

  const handleClose = () => {
    setAvatarUrl(undefined);
    form.reset();
    onClose();
  };

  const handleSubmit = () => {
    mutation.mutate(
      {
        ...form.values,
        avatar: avatarUrl,
        beginnerLearningTime:
          +form.values.beginnerLearningTime.getHours() * 60 +
          +form.values.beginnerLearningTime.getMinutes(),
        intermediateLearningTime:
          +form.values.intermediateLearningTime.getHours() * 60 +
          +form.values.intermediateLearningTime.getMinutes(),
        expertLearningTime:
          +form.values.expertLearningTime.getHours() * 60 +
          +form.values.expertLearningTime.getMinutes(),
      },
      {
        onSuccess: () => {
          handleClose();
        },
        onError: (error: AxiosError<ApiError>) => {
          if (
            error.response?.data.errorCode === ErrorCode.NAME_ALREADY_EXISTS
          ) {
            form.setFieldError("name", t("Departments:errors.nameExists"));
          }
        },
      }
    );
  };

  return (
    <Modal
      centered
      data-testid="create-modal"
      size="lg"
      title={t("Departments:modals.create")}
      opened={opened}
      onClose={handleClose}
    >
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <TextInput
          required
          label={t("Departments:modals.name")}
          placeholder={t("Departments:modals.name")}
          {...form.getInputProps("name")}
        />
        <Textarea
          required
          label={t("Departments:modals.summary")}
          placeholder={t("Departments:modals.summary")}
          mb={3}
          {...form.getInputProps("summary")}
        />
        <ImageUpload setAvatarIdentifier={setAvatarUrl} />

        <TrainingTimeTitle>
          Add weekly recommended training time
        </TrainingTimeTitle>

        <TimeInputsWrapper>
          <TimeInput
            styles={TimeInputStyle}
            style={{ width: "100%" }}
            label="Beginner"
            required
            icon={<Clock size={18} />}
            defaultValue={defaultTime}
            {...form.getInputProps("beginnerLearningTime")}
          />
          <TimeInput
            styles={TimeInputStyle}
            style={{ width: "100%" }}
            label="Intermediate"
            required
            icon={<Clock size={18} />}
            defaultValue={defaultTime}
            {...form.getInputProps("intermediateLearningTime")}
          />
          <TimeInput
            styles={TimeInputStyle}
            style={{ width: "100%" }}
            label="Experienced"
            required
            icon={<Clock size={18} />}
            defaultValue={defaultTime}
            {...form.getInputProps("expertLearningTime")}
          />
        </TimeInputsWrapper>

        <Group position="right" mt="md">
          <Button data-testid="submit-button" type="submit">
            {t("Common:actions.submit")}
          </Button>
        </Group>
      </form>
    </Modal>
  );
};
