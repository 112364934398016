export enum CHAPTERS {
  EDIT_CHAPTER = "chapters/",
  CREATE_CHAPTER = "chapters/create",
  CHAPTERS = "chapters",
  GET_CHAPTERS = "chapters/all",
}

export enum CHAPTERPAGES {
  GET_CHAPTERSPAGES = "chapter-pages",
  EDIT_CHAPTERPAGES = "chapter-pages/",
  CREATE_CHAPTERPAGES = "chapter-pages/create",
  CHAPTERPAGES = "chapter-pages",
}

export enum CHAPTER_EXERCISES {
  CHAPTER_EXERCISES = "chapters/exercises",
}

export enum QUIZZES {
  GET_QUIZZES = "quizzes",
  EDIT_QUIZZES = "quizzes/",
  CREATE_QUIZZES = "quizzes/create",
  QUIZZES = "quizzes",
}
