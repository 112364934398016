import { CATEGORIES } from "../../enums/categories/urls";

import {
  Category,
  CreateCategoryPayload,
} from "../../types/category/categories";

import { createAxiosInstance } from "api/config";

const axios = createAxiosInstance();

export const getCategories = async (): Promise<Category[]> => {
  const { data } = await axios.get(`/${CATEGORIES.GET_CATEGORIES}`);

  return data;
};

export const createCategory = async (
  payload: CreateCategoryPayload
): Promise<number> => {
  const { data } = await axios.post(`/${CATEGORIES.CREATE_CATEGORY}`, payload);

  return data;
};
