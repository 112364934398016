import React from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";
import { Group, Table, Menu } from "@mantine/core";
import { Pencil, Trash } from "tabler-icons-react";

import { Template } from "../../../types/template/template";

import { Color } from "enums/common";
import { Link } from "react-router-dom";

interface CircleProps {
  color: string;
}

const SmallCircle = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ color }: CircleProps) => color};
`;

const Short = styled.div`
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: center;
`;

export interface TemplatesTableProps {
  templates: Template[];
  deleteMutation: any;
}

export const TemplatesTable: React.FC<TemplatesTableProps> = ({
  templates,
  deleteMutation,
}) => {
  const { t } = useTranslation();

  return (
    <Table
      data-testid="templates-table"
      highlightOnHover
      verticalSpacing={6}
      style={{ marginTop: 20, marginBottom: 20, textAlign: "center" }}
      sx={() => ({
        backgroundColor: Color.WHITE,
        boxShadow: "box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.15)",
      })}
    >
      <thead>
        <tr>
          <th>{t("Templates:table.name")}</th>
          <th>{t("Templates:table.type")}</th>
          <th>{t("Templates:table.image")}</th>
          <th>{t("Templates:table.keywords")}</th>
          <th>{t("Templates:table.skills")}</th>
          <th>{t("Templates:table.summary")}</th>
          <th>{t("Templates:table.actions")}</th>
        </tr>
      </thead>
      <tbody>
        {templates.map((template, index) => (
          <tr key={template.id}>
            <td>{template.title}</td>
            <td>{template.type.name}</td>
            <td>
              {template.coverImage && (
                <img
                  alt={`${template.title}-coverImage}`}
                  src={template.coverImage}
                  width="48"
                  height="48"
                />
              )}
            </td>
            <td>
              <Short>
                {template.keywordList && template.keywordList.length
                  ? template.keywordList.join(", ")
                  : ""}
              </Short>
            </td>
            <td>
              <Short>
                {template.skillList && template.skillList.length
                  ? template.skillList.join(", ")
                  : ""}
              </Short>
            </td>
            <td>{template.description}</td>
            <td>
              <Group spacing="xl">
                <Menu
                  control={
                    <Group
                      data-testid="menu-control"
                      spacing={3}
                      style={{ cursor: "pointer" }}
                    >
                      <SmallCircle color={Color.DARK} />
                      <SmallCircle color={Color.DARK} />
                      <SmallCircle color={Color.DARK} />
                    </Group>
                  }
                >
                  <Menu.Item
                    icon={<Pencil size={14} />}
                    component={Link}
                    to={"/templates/" + template.id.toString() + "/edit"}
                  >
                    {t("Common:edit")}
                  </Menu.Item>
                  <Menu.Item
                    icon={<Trash size={14} />}
                    onClick={() => deleteMutation.mutate(template.id)}
                  >
                    {t("Common:delete")}
                  </Menu.Item>
                </Menu>
              </Group>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
