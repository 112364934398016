import React from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";
import { Group, Table, Menu } from "@mantine/core";
import { Trash } from "tabler-icons-react";

import { UserExercise } from "../../../types/exercises/exercises";

import { Color } from "enums/common";

interface CircleProps {
  color: string;
}

const SmallCircle = styled.div`
  text-align: center;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ color }: CircleProps) => color};
`;

export interface UserExercisesTableProps {
  userExercises: UserExercise[];
  onDeleteUserExercise: (exercise: UserExercise) => void;
}

export const UserExercisesTable: React.FC<UserExercisesTableProps> = ({
  userExercises,
  onDeleteUserExercise,
}) => {
  const { t } = useTranslation();

  return (
    <Table
      data-testid="exercises-table"
      highlightOnHover
      verticalSpacing={6}
      style={{ marginTop: 20, marginBottom: 20, textAlign: "center" }}
      sx={() => ({
        backgroundColor: Color.WHITE,
        boxShadow: "box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.15)",
      })}
    >
      <thead>
        <tr>
          <th>{t("UserExercises:table.userEmail")}</th>
          <th>{t("UserExercises:table.userOrganisation")}</th>
          <th>{t("UserExercises:table.userName")}</th>
          <th>{t("UserExercises:table.practice")}</th>
          <th>{t("UserExercises:table.actions")}</th>
        </tr>
      </thead>
      <tbody>
        {userExercises.map((exercise, index) => (
          <tr key={index}>
            <td>{exercise.email}</td>
            <td>{exercise.userOrganization}</td>
            <td>{exercise.userName}</td>
            <td>{exercise.exerciseName}</td>
            <td>
              <Group spacing="xl">
                <Menu
                  control={
                    <Group
                      data-testid="menu-control"
                      spacing={3}
                      style={{ cursor: "pointer" }}
                    >
                      <SmallCircle color={Color.DARK} />
                      <SmallCircle color={Color.DARK} />
                      <SmallCircle color={Color.DARK} />
                    </Group>
                  }
                >
                  <Menu.Item
                    icon={<Trash size={14} />}
                    onClick={() => onDeleteUserExercise(exercise)}
                  >
                    {t("Common:delete")}
                  </Menu.Item>
                </Menu>
              </Group>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
