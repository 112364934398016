import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useForm } from "@mantine/form";
import { AxiosError } from "axios";

// Components
import { ImageUpload } from "components/common";
import { CreateCategoryModal } from "components/categories/CreateCategory/CreateCategoryModal";
import { LockOff, LockOpen } from "tabler-icons-react";
import {
  TextInput,
  Textarea,
  Text,
  Group,
  Button,
  RadioGroup,
  Radio,
  Space,
  Select,
  Box,
  MultiSelect,
} from "@mantine/core";

// Types
import { Category } from "types/category/categories";
import { Organization } from "types/organizations/Organization";
import { ApiError } from "types/api/ApiError";
import { ErrorCode } from "enums/api/ErrorCode";
import { Color } from "enums/common";

// Queries
import { useCategories } from "../../hooks/categories/useCategories";
import { useCreateCategory } from "../../hooks/categories/useCreateCategory";
import { useAllOrganizations } from "../../hooks/organizations/useAllOrganizations";

// Utils
import { requiredNumber, requiredString } from "utils/form/validatorsUtils";

// Interfaces
export interface CreateModalProps {
  mutation: any;
}

export const CreateCoursePage: React.FC<CreateModalProps> = ({ mutation }) => {
  // Destructions
  const { t } = useTranslation();
  const navigate = useNavigate();

  // State
  const [avatarUrl, setAvatarUrl] = useState<string | undefined>();
  const [skills, setSkills] = useState([""]);
  const [isCreateCategoryModalOpen, setIsCreateCategoryModalOpen] =
    useState(false);

  // Validations
  const schema = {
    level: (value: number) =>
      requiredNumber(value, t("Exercises:notifications.requiredField")),
    categoryId: (value: number) =>
      requiredNumber(value, t("Exercises:notifications.requiredField")),
    isPublic: (value: string) =>
      requiredString(value, t("Exercises:notifications.requiredField")),
    isLeadership: (value: string) =>
      requiredString(value, t("Exercises:notifications.requiredField")),
    name: (value: string) =>
      requiredString(value, t("Exercises:notifications.requiredField")),
    summary: (value: string) =>
      requiredString(value, t("Exercises:notifications.requiredField")),
  };

  const form = useForm({
    initialValues: {
      avatar: "",
      isLeadership: "",
      level: 0,
      name: "",
      organizationId: 0,
      skills: "",
      status: 0,
      summary: "",
      subtitle: "",
      categoryId: 0,
      isPublic: "",
    },
    validate: schema,
  });

  // Queries
  const createCategoryMutation = useCreateCategory();
  const { data } = useCategories();
  const organizationQuery = useAllOrganizations();

  // Handlers
  const handleSubmit = () => {
    mutation.mutate(
      {
        ...form.values,
        isPublic: form.values.isPublic === "true",
        isLeadership: form.values.isLeadership === "true",
        organizationId:
          form.values.isPublic !== "true"
            ? Number(form.values.organizationId)
            : null,
        skills: skills.filter((e) => e).toString(),
        avatar: avatarUrl,
      },
      {
        onSuccess: (id: any) => {
          navigate(`/courses/${id}`);
        },
        onError: (error: AxiosError<ApiError>) => {
          if (
            error.response?.data.errorCode === ErrorCode.NAME_ALREADY_EXISTS
          ) {
            form.setFieldError("name", t("Courses:errors.nameExists"));
          }
        },
      }
    );
  };

  // Prepare data
  const categoryOptions = data
    ? data.map((category: Category) => ({
        value: category.id,
        label: category.name,
      }))
    : [];

  const organizationOptions = organizationQuery.data
    ? organizationQuery.data.map((organization: Organization) => ({
        value: organization.id,
        label: organization.name,
      }))
    : [];

  const difficultyLevelOptions = [
    {
      value: 1,
      label: t("Courses:createCourse.beginner"),
    },
    {
      value: 2,
      label: t("Courses:createCourse.intermediate"),
    },
    {
      value: 3,
      label: t("Courses:createCourse.expert"),
    },
  ];
  // Effects
  useEffect(() => {
    if (form.values.isPublic === "false" && organizationOptions.length) {
      form.setFieldValue("organizationId", organizationOptions[0].value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.values.isPublic]);

  return (
    <Box sx={{ maxWidth: 800 }} mx="auto">
      <form onSubmit={form.onSubmit(handleSubmit)} noValidate>
        <TextInput
          required
          label={t("Courses:createCourse.title")}
          styles={() => ({
            input: {
              fontSize: 14,
              color: Color.DARK,
              fontFamily: "Montserrat",
              borderColor: Color.DARK,
              borderWidth: 1,
              backgroundColor: Color.WHITE,
              "&:focus": {
                borderColor: Color.DARK,
                borderWidth: 1,
              },
            },
          })}
          {...form.getInputProps("name")}
        />
        <Space h="md" />
        <TextInput
          label={t("Courses:createCourse.subtitle")}
          styles={() => ({
            input: {
              fontSize: 14,
              color: Color.DARK,
              fontFamily: "Montserrat",
              borderColor: Color.DARK,
              borderWidth: 1,
              backgroundColor: Color.WHITE,
              "&:focus": {
                borderColor: Color.DARK,
                borderWidth: 1,
              },
            },
          })}
          {...form.getInputProps("subtitle")}
        />
        <Space h="md" />
        <Textarea
          required
          label={t("Courses:createCourse.description")}
          mb={3}
          styles={() => ({
            input: {
              fontSize: 14,
              color: Color.DARK,
              fontFamily: "Montserrat",
              borderColor: Color.DARK,
              borderWidth: 1,
              backgroundColor: Color.WHITE,
              "&:focus": {
                borderColor: Color.DARK,
                borderWidth: 1,
              },
            },
          })}
          {...form.getInputProps("summary")}
        />
        <Space h="md" />
        <Group>
          <Select
            label={t("Courses:createCourse.category")}
            placeholder={t("Courses:createCourse.category")}
            required
            data={categoryOptions}
            styles={{
              input: {
                width: 200,
                fontFamily: "Montserrat",
                color: Color.DARK,
                borderColor: Color.DARK,
                borderWidth: 1,
                "&:focus": {
                  borderColor: Color.DARK,
                  borderWidth: 1,
                },
              },
            }}
            {...form.getInputProps("categoryId")}
          />
          <Button
            onClick={() => {
              setIsCreateCategoryModalOpen(true);
            }}
            variant="outline"
            styles={() => ({
              root: {
                fontSize: 14,
                color: Color.DARK,
                fontFamily: "Montserrat",
                borderColor: Color.DARK,
                borderWidth: 1,
                backgroundColor: Color.WHITE,
                marginTop: 27,
                alignSelf: "flex-start",
              },
            })}
          >
            {t("Courses:categoryModal.button")}
          </Button>
        </Group>
        <Space h="md" />
        <Select
          required
          label={t("Courses:createCourse.difficulty")}
          placeholder={t("Courses:createCourse.difficulty")}
          data={difficultyLevelOptions}
          styles={{
            input: {
              fontFamily: "Montserrat",
              color: Color.DARK,
              borderColor: Color.DARK,
              borderWidth: 1,
              fontSize: 14,
              "&:focus": {
                borderColor: Color.DARK,
                borderWidth: 1,
              },
            },
          }}
          {...form.getInputProps("level")}
        />
        <Space h="md" />
        <MultiSelect
          data={[]}
          label={t("Courses:createCourse.skills")}
          placeholder={t("Courses:createCourse.skillsSelect")}
          searchable
          creatable
          getCreateLabel={(query) => `+ ${t("Courses:create")} ${query}`}
          onCreate={(query) => setSkills((current) => [...current, query])}
          styles={() => ({
            input: {
              fontSize: 14,
              color: Color.DARK,
              fontFamily: "Montserrat",
              borderColor: Color.DARK,
              borderWidth: 1,
              backgroundColor: Color.WHITE,
              "&:focus": {
                borderColor: Color.DARK,
                borderWidth: 1,
              },
            },
          })}
        />
        <Space h="md" />
        <RadioGroup
          label={t("Courses:createCourse.privacy")}
          orientation="vertical"
          required
          styles={{
            label: { fontSize: 14, color: Color.DARK },
            radio: {
              fontSize: 14,
              color: Color.DARK,
            },
            inner: { fontSize: 14, color: Color.DARK },
            icon: { fontSize: 14, color: Color.DARK },
            radioWrapper: { fontSize: 14, color: Color.DARK },
          }}
          {...form.getInputProps("isPublic")}
        >
          <Radio
            value="true"
            styles={{
              icon: {},
            }}
            label={
              <>
                <Text style={{ fontFamily: "Montserrat", fontSize: 14 }}>
                  <LockOpen size={14} />
                  {t("Courses:createCourse.public")}
                </Text>
              </>
            }
          />
          <Radio
            value="false"
            label={
              <>
                <Text style={{ fontFamily: "Montserrat", fontSize: 14 }}>
                  <LockOff size={14} />
                  {t("Courses:createCourse.private")}
                </Text>
              </>
            }
          />
        </RadioGroup>
        <Space h="md" />
        {form.values.isPublic === "false" ? (
          <Select
            label={t("Courses:createCourse.organization")}
            placeholder={t("Courses:createCourse.organization")}
            data={organizationOptions}
            styles={() => ({
              input: {
                fontSize: 14,
                color: Color.DARK,
                fontFamily: "Montserrat",
                borderColor: Color.DARK,
                borderWidth: 1,
                backgroundColor: Color.WHITE,
                "&:focus": {
                  borderColor: Color.DARK,
                  borderWidth: 1,
                },
              },
            })}
            {...form.getInputProps("organizationId")}
          />
        ) : null}
        <Space h="md" />
        <RadioGroup
          label={t("Courses:createCourse.isLeadership")}
          orientation="vertical"
          required
          styles={{
            label: { fontSize: 14, color: Color.DARK },
            radio: {
              fontSize: 14,
              color: Color.DARK,
            },
            inner: { fontSize: 14, color: Color.DARK },
            icon: { fontSize: 14, color: Color.DARK },
            radioWrapper: { fontSize: 14, color: Color.DARK },
          }}
          {...form.getInputProps("isLeadership")}
        >
          <Radio value="true" label={t("Courses:createCourse.yes")} />
          <Radio value="false" label={t("Courses:createCourse.no")} />
        </RadioGroup>
        <Space h="md" />
        <ImageUpload setAvatarIdentifier={setAvatarUrl} />
        <Space h="md" />
        <Group position="right" mt="md">
          <Button
            data-testid="submit-button"
            type="submit"
            size="sm"
            styles={(theme) => ({
              root: {
                fontSize: 16,
                fontFamily: "Montserrat",
                backgroundColor: Color.DARK,
                fontWeight: 600,

                "&:hover": {
                  backgroundColor: theme.fn.lighten(Color.DARK, 0.1),
                },
              },
            })}
          >
            {t("Common:actions.next")}
          </Button>
        </Group>
      </form>
      <CreateCategoryModal
        setCreatedCategorySelected={(id) =>
          form.setFieldValue("categoryId", id)
        }
        mutation={createCategoryMutation}
        opened={isCreateCategoryModalOpen}
        onClose={() => setIsCreateCategoryModalOpen(false)}
      ></CreateCategoryModal>
    </Box>
  );
};
