import { Space, Table } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { LockOpen, Lock } from "tabler-icons-react";
import { Color } from "enums/common";
import { useUserManagement } from "../../../hooks/UserManagement/useUseManagement";
import { Fragment, useState } from "react";
import { Pagination } from "components/common";

export const PracticesDetailsTable: React.FC = () => {
  const [usersPage, setUsersPage] = useState(1);
  const [usersResultCount, setUsersResultCount] = useState(15);

  const { t } = useTranslation();
  const { id } = useParams();

  const { data } = useUserManagement(Number(id), usersPage, usersResultCount);

  const getPracticePublicIcon = (isPublic: Boolean) => {
    return isPublic ? (
      <LockOpen size={28} strokeWidth={2} color={"green"} />
    ) : (
      <Lock size={28} strokeWidth={2} color={"gray"} />
    );
  };

  console.log("exercises", data?.exercises.elements);

  const exercisesDetails =
    data &&
    data.exercises.total &&
    data.exercises &&
    data.exercises.elements &&
    data.exercises.elements.map((exercise, index) => (
      <Fragment key={index}>
        <tr key={exercise.id}>
          <td>{exercise.title}</td>
          <td>{exercise.description}</td>
          <td>{exercise.duration}</td>
          <td>{exercise.points}</td>
          <td>{getPracticePublicIcon(exercise.isPublic)}</td>
        </tr>
      </Fragment>
    ));

  return (
    <>
      <Table
        data-testid="practicesDetails-table"
        highlightOnHover
        verticalSpacing={6}
        style={{ marginTop: 20, marginBottom: 20, textAlign: "center" }}
        sx={() => ({
          backgroundColor: Color.WHITE,
          boxShadow: "box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.15)",
        })}
      >
        <thead>
          <tr>
            <th>{t("UserManagement:practiceDetails.name")}</th>
            <th>{t("UserManagement:practiceDetails.summary")}</th>
            <th>{t("UserManagement:practiceDetails.time")}</th>
            <th>{t("UserManagement:practiceDetails.score")}</th>
            <th>{t("UserManagement:practiceDetails.public")}</th>
          </tr>
        </thead>
        <tbody>{exercisesDetails}</tbody>
      </Table>
      <Space h="xl" />
      {data && (
        <Pagination
          page={usersPage}
          totalItems={data.exercises.total}
          itemsPerPage={usersResultCount}
          setItemsPerPage={setUsersResultCount}
          setPage={setUsersPage}
        />
      )}
    </>
  );
};
