import { Group, Menu, Table } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Eye, Trash } from "tabler-icons-react";
import { Color } from "enums/common";
import { User } from "../../../types/user/User";
import useDeleteUser from "hooks/organizations/users/useDeleteUser";

export interface AdminsTableProps {
  admins: User[];
}

export const UserManagementAdminsTable: React.FC<AdminsTableProps> = ({
  admins,
}) => {
  const { t } = useTranslation();

  interface CircleProps {
    color: string;
  }

  const SmallCircle = styled.div`
    text-align: center;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${({ color }: CircleProps) => color};
  `;

  const { mutate } = useDeleteUser();

  return (
    <Table
      data-testid="admins-table"
      highlightOnHover
      verticalSpacing={6}
      style={{ marginTop: 20, marginBottom: 20 }}
      sx={() => ({
        backgroundColor: Color.WHITE,
        boxShadow: "box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.15)",
      })}
    >
      <thead>
        <tr>
          <th>{t("Users:table.fullName")}</th>
          <th>{t("Users:table.nickName")}</th>
          <th>{t("Users:table.gender")}</th>
          <th>{t("Users:table.email")}</th>
          <th>{t("Users:table.avatarUrl")}</th>
          <th>{t("Users:table.state")}</th>
          <th>{t("Users:table.actions")}</th>
        </tr>
      </thead>
      <tbody>
        {admins.map((admin) => (
          <tr key={admin.id}>
            <td>{admin.fullName}</td>
            <td>{admin.nickName}</td>
            <td>
              {admin.gender
                ? t(`Common:${admin.gender.name.toString().toLowerCase()}`)
                : ""}
            </td>
            <td>{admin.email}</td>
            <td>
              {admin.avatar && (
                <img
                  width="48"
                  height="48"
                  src={admin.avatar}
                  alt={`${admin.nickName}-avatar`}
                />
              )}
            </td>
            <td>
              {admin.status
                ? t(`Common:${admin.status.name.toString().toLowerCase()}`)
                : ""}
            </td>
            <td>
              <Group spacing="xl">
                <Menu
                  control={
                    <Group
                      data-testid="menu-control"
                      spacing={3}
                      style={{ cursor: "pointer" }}
                    >
                      <SmallCircle color={Color.DARK} />
                      <SmallCircle color={Color.DARK} />
                      <SmallCircle color={Color.DARK} />
                    </Group>
                  }
                >
                  <Menu.Item
                    icon={<Eye size={14} />}
                    component={Link}
                    to={`/users/${admin.id?.toString()}/details`}
                  >
                    {t("Common:view")}
                  </Menu.Item>
                  <Menu.Item
                    icon={<Trash size={14} />}
                    onClick={() => mutate(admin.id)}
                  >
                    {t("Common:delete")}
                  </Menu.Item>
                </Menu>
              </Group>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
