import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import styled from "styled-components";

import { Banner, BasePage } from "../../components/common";
import { CreateChapterForm } from "../../components/chapters/CreateChapterForm/CreateChapterForm";

import { Color } from "enums/common";

import { Container, Text, Space, Box } from "@mantine/core";

import { useCreateChapter } from "../../hooks/chapters/useChapters";

const StyledBanner = styled(Banner)`
  margin: 34px 0 40px 0;
`;

export const CreateChapter: React.FC = () => {
  const { id } = useParams();
  const { t } = useTranslation();

  const createChapterMutation = useCreateChapter();

  return (
    <BasePage>
      <Container>
        <Box sx={{ maxWidth: 800 }} mx="auto">
          <StyledBanner title="" text="">
            <Box sx={{ maxWidth: 800 }} mx="auto">
              <Text
                styles={() => ({
                  root: {
                    fontSize: 28,
                    fontWeight: 600,
                    fontFamily: "Montserrat",
                    color: Color.DARK,
                  },
                })}
              >
                {t("Courses:createCourse.newChapter")}
              </Text>
              <Space h="lg" />
              <CreateChapterForm
                mutation={createChapterMutation}
                id={Number(id)}
              />
            </Box>
            <Space h="xl" />
          </StyledBanner>
        </Box>
      </Container>
    </BasePage>
  );
};
