import { useMutation } from "@tanstack/react-query";
import { cloneExercise } from "api/exercises/exercises";
import i18n from "i18n";
import { queryClient } from "App";

// Utils
import {
  endNotification,
  generateRandomString,
  startNotification,
} from "utils/common";

export const useCloneExercise = () => {
  const randomId = generateRandomString(20);

  return useMutation((id: number) => cloneExercise(id), {
    onMutate: () => {
      startNotification(randomId);
    },
    onSuccess: () => {
      endNotification(
        randomId,
        i18n.t("Exercise:notifications.createSuccess"),
        true
      );
      queryClient.invalidateQueries(["exercises"]);
    },
    onError: () => {
      endNotification(
        randomId,
        i18n.t("Exercise:notifications.createError"),
        false
      );
    },
  });
};
