import React from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { Group, Button, TextInput, Select } from "@mantine/core";

import { Color } from "enums/common";
import { useCategories } from "hooks/categories/useCategories";
import { Category } from "types/category/categories";

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export interface AssignCoursesMenuProps {
  searchKey: string;
  categoryFilter: string;
  setSearchKey: (searchKey: string) => void;
  setCategoryFilter: (id: string) => void;
}

export const AssignCoursesMenu: React.FC<AssignCoursesMenuProps> = ({
  searchKey,
  categoryFilter,
  setSearchKey,
  setCategoryFilter,
}) => {
  const { t } = useTranslation();

  const { data } = useCategories();

  const categoryOptions = data
    ? data.map((category: Category) => ({
        value: category.id.toString(),
        label: category.name,
      }))
    : [];

  return (
    <FlexContainer data-testid="assignCourses-menu">
      <Group spacing={0}>
        <TextInput
          value={searchKey}
          onChange={(event) => setSearchKey(event.currentTarget.value)}
          placeholder={t("AssignCourse:menu.coursesSearchPlaceholder")}
          styles={{
            root: { width: 640 },
            input: { borderRadius: 20 },
          }}
        />
        <Button
          variant="filled"
          onClick={() => setSearchKey(searchKey)}
          styles={(theme) => ({
            root: {
              fontSize: 14,
              textTransform: "uppercase",
              fontFamily: "Montserrat",
              fontWeight: 600,
              backgroundColor: Color.DARK,
              borderRadius: 20,
              left: -30,

              "&:hover": {
                backgroundColor: theme.fn.lighten(Color.DARK, 0.1),
              },
            },
          })}
        >
          {t("Common:search")}
        </Button>
      </Group>
      <Group>
        {categoryOptions.length && (
          <Select
            onChange={setCategoryFilter}
            placeholder={t("AssignCourse:menu.category")}
            data={categoryOptions}
            value={
              categoryOptions.find((x) => x.value === categoryFilter)?.value
            }
            styles={{
              input: {
                fontFamily: "Montserrat",
                fontWeight: 600,
                color: Color.DARK,
                borderColor: Color.DARK,
                borderWidth: 1,
                width: 150,
                "&:focus": {
                  borderColor: Color.DARK,
                  borderWidth: 1,
                },
              },
            }}
          />
        )}
      </Group>
    </FlexContainer>
  );
};
