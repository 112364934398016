import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Components
import styled from "styled-components";
import { Group, Table, Menu } from "@mantine/core";
import { Pencil, Trash, Lock, LockOpen, Eye, Copy } from "tabler-icons-react";
import { RichTextContent } from "components/RichText";

// Handlers
import { Exercise } from "types/exercises/exercises";
import { Color } from "enums/common";
import { UseMutationResult } from "@tanstack/react-query";

interface CircleProps {
  color: string;
}

const SmallCircle = styled.div`
  text-align: center;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ color }: CircleProps) => color};
`;

export interface CoursesTableProps {
  exercises: Exercise[];
  onEditExercise: (exercise: Exercise) => void;
  onDeleteExercise: (exercise: Exercise) => void;
  cloneMutation: UseMutationResult<void, unknown, number>;
}

export const ExercisesTable: React.FC<CoursesTableProps> = ({
  exercises,
  onDeleteExercise,
  cloneMutation,
}) => {
  const { t } = useTranslation();

  // Handlers
  const getCoursePublicIcon = (isPublic: Boolean) => {
    return isPublic ? (
      <LockOpen size={28} strokeWidth={2} color={"green"} />
    ) : (
      <Lock size={28} strokeWidth={2} color={"gray"} />
    );
  };

  const handleCloneExercise = (id: number) => {
    cloneMutation.mutate(id);
  };

  return (
    <Table
      data-testid="courses-table"
      highlightOnHover
      verticalSpacing={6}
      style={{ marginTop: 20, marginBottom: 20, textAlign: "center" }}
      sx={() => ({
        backgroundColor: Color.WHITE,
        boxShadow: "box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.15)",
      })}
    >
      <thead>
        <tr>
          <th>{t("Exercises:table.name")}</th>
          <th>{t("Exercises:table.learningTime")}</th>
          <th>{t("Exercises:table.learningScore")}</th>
          <th>{t("Exercises:table.summary")}</th>
          <th>{t("Exercises:table.privacy")}</th>
          <th>{t("Exercises:table.actions")}</th>
        </tr>
      </thead>
      <tbody>
        {exercises.map((exercise, index) => (
          <tr key={exercise.id}>
            <td>{exercise.title}</td>
            <td>{exercise.duration}</td>
            <td>{exercise.points}</td>
            <td>
              {" "}
              <RichTextContent htmlContent={exercise.description} />{" "}
            </td>
            <td>{getCoursePublicIcon(exercise.isPublic)}</td>
            <td>
              <Group spacing="xl">
                <Menu
                  control={
                    <Group
                      data-testid="menu-control"
                      spacing={3}
                      style={{ cursor: "pointer" }}
                    >
                      <SmallCircle color={Color.DARK} />
                      <SmallCircle color={Color.DARK} />
                      <SmallCircle color={Color.DARK} />
                    </Group>
                  }
                >
                  <Menu.Item
                    icon={<Eye size={14} />}
                    component={Link}
                    to={`/exercises/${exercise.id.toString()}`}
                  >
                    View
                  </Menu.Item>
                  <Menu.Item
                    icon={<Pencil size={14} />}
                    component={Link}
                    to={"/exercises/" + exercise.id.toString() + "/edit"}
                  >
                    {t("Common:edit")}
                  </Menu.Item>
                  <Menu.Item
                    icon={<Trash size={14} />}
                    onClick={() => onDeleteExercise(exercise)}
                  >
                    {t("Common:delete")}
                  </Menu.Item>
                  <Menu.Item
                    icon={<Copy size={14} />}
                    onClick={() => handleCloneExercise(exercise.id)}
                  >
                    {t("Common:clone")}
                  </Menu.Item>
                </Menu>
              </Group>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
