import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Group, Button, TextInput, Select } from "@mantine/core";
import { Link, useSearchParams } from "react-router-dom";
import styled from "styled-components";

// Hooks
import { useAllOrganizations } from "hooks/organizations/useAllOrganizations";

// Types
import { SortParams } from "../CoursesTabs/UsersCoursesTab";
import { Color } from "enums/common";

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export interface CoursesMenuProps {
  searchKey: string;
  setSearchKey: (searchKey: string) => void;
  setIsModalOpen: (isModalOpen: boolean) => void;
  setSortByOrganisationId: (value: number | null) => void;
  handleOnChangeParams: (value: SortParams) => void;
}

export const UserCoursesMenu: React.FC<CoursesMenuProps> = ({
  searchKey,
  setSearchKey,
  setIsModalOpen,
  setSortByOrganisationId,
  handleOnChangeParams,
}) => {
  // Queries
  const organizationQuery = useAllOrganizations();

  // Params
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const organizationId = searchParams.get("organizationId");

  // Handlers
  const handleOnChange = (value: string | null) => {
    handleOnChangeParams({ organizationId: Number(value), sortBy: "" });
  };

  // Prepare data
  const organizationOptions: {
    value: string;
    label: string;
  }[] = organizationQuery.data
    ? organizationQuery.data.map(({ id, name }) => ({
        value: id.toString(),
        label: name,
      }))
    : [];

  organizationOptions.unshift({
    value: "0",
    label: "All organizations (Default)",
  });

  // Effects
  useEffect(() => {
    !organizationId
      ? setSortByOrganisationId(null)
      : setSortByOrganisationId(Number(organizationId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId]);

  return (
    <FlexContainer data-testid="courses-menu">
      <Group spacing={0}>
        <TextInput
          value={searchKey}
          onChange={(event) => setSearchKey(event.currentTarget.value)}
          placeholder={t("Courses:menu.searchPlaceholder")}
          styles={{
            root: { width: 640 },
            input: { borderRadius: 20 },
          }}
        />
        <Button
          variant="filled"
          onClick={() => setSearchKey(searchKey)}
          styles={(theme) => ({
            root: {
              fontSize: 14,
              textTransform: "uppercase",
              fontFamily: "Montserrat",
              fontWeight: 600,
              backgroundColor: Color.DARK,
              borderRadius: 20,
              left: -30,

              "&:hover": {
                backgroundColor: theme.fn.lighten(Color.DARK, 0.1),
              },
            },
          })}
        >
          {t("Common:search")}
        </Button>
      </Group>
      <Group>
        <Select
          defaultValue={organizationId ? organizationId.toString() : "0"}
          placeholder={t("Courses:createCourse.organization")}
          data={organizationOptions}
          onChange={(value) => handleOnChange(value)}
          styles={() => ({
            input: {
              fontSize: 14,
              color: Color.DARK,
              fontFamily: "Montserrat",
              borderColor: Color.DARK,
              borderWidth: 1,
              backgroundColor: Color.WHITE,
              "&:focus": {
                borderColor: Color.DARK,
                borderWidth: 1,
              },
            },
          })}
        />
        <Button
          component={Link}
          to="/course-assignement"
          variant="outline"
          styles={() => ({
            root: {
              fontSize: 14,
              textTransform: "uppercase",
              color: Color.DARK,
              fontFamily: "Montserrat",
              fontWeight: 600,
              borderColor: Color.DARK,
              borderWidth: 3,
            },
          })}
        >
          {t("Courses:assignCourse")}
        </Button>
      </Group>
    </FlexContainer>
  );
};
