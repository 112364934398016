import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Button, Container, Grid, Space, Group } from "@mantine/core";

import { Banner, BasePage } from "components/common";
import { LeaderBoard, ProgressBanner } from "components/dashboard";

import { Color } from "enums/common/Color";
import { Organizations, Courses } from "enums/api/urls";

const StyledBanner = styled(Banner)`
  margin: 34px 0 108px 0;
`;

export const Dashboard: React.FC = () => {
  const { t } = useTranslation("Dashboard");

  return (
    <BasePage>
      <Container data-testid="dashboard-page">
        <StyledBanner title={t("banner.title")} text={t("banner.text")}>
          <Group spacing="xs">
            <Button
              size="xl"
              component={Link}
              to={Courses.CREATE_COURSE}
              styles={(theme) => ({
                root: {
                  fontSize: 18,
                  fontFamily: "Montserrat",
                  backgroundColor: Color.DARK,
                  fontWeight: 600,
                  "&:hover": {
                    backgroundColor: theme.fn.lighten(Color.DARK, 0.1),
                  },
                },
              })}
            >
              {t("banner.addCourse")}
            </Button>
            <Space w="md" />
            <Button
              size="xl"
              component={Link}
              to={Organizations.ORGANIZATIONS}
              variant="outline"
              styles={() => ({
                root: {
                  fontSize: 18,
                  color: Color.DARK,
                  fontFamily: "Montserrat",
                  fontWeight: 700,
                  borderColor: Color.DARK,
                  borderWidth: 3,
                },
              })}
            >
              {t("banner.manageOrganization")}
            </Button>
          </Group>
        </StyledBanner>
        <Grid gutter={40}>
          <Grid.Col md={12} lg={6}>
            <LeaderBoard
              data={[
                { name: "Henning Adolfsen", team: "Team A", score: 7520 },
                { name: "Olaug Nordmann", team: "Syscom Team", score: 7190 },
                {
                  name: "Kjersti Holm",
                  team: "Det første team",
                  score: 6850,
                },
                { name: "Anders Bretvedt", team: "Team B", score: 6610 },
                { name: "Karl Rommen", team: "Avdeling Oslo", score: 1250 },
              ]}
            />
          </Grid.Col>
          <Grid.Col md={12} lg={6}>
            <ProgressBanner
              organizationScore={40}
              averageScore={79}
              efficientScore={70}
            />
          </Grid.Col>
        </Grid>
      </Container>
    </BasePage>
  );
};
