import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { VictoryBar, VictoryLabel } from "victory";

import { Color } from "enums/common";

const ProgressBannerContainer = styled.div`
  box-sizing: border-box;
  min-height: 360px;
  padding: 31px 45px;
  background-color: ${Color.WHITE};
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.05);
`;

const StyledVictoryLabel = styled(VictoryLabel)`
  font-size: 16px;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: ${Color.DARK};
  margin-bottom: 15px;
`;

const InfoText = styled.div`
  font-size: 16px;
  color: ${Color.DARK};
  font-weight: 400;
`;

const Legend = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const LegendTitle = styled.div`
  color: ${Color.DARK};
  margin-bottom: 19px;
  font-size: 32px;
  font-weight: 700;
`;

const LegendItem = styled.div`
  display: flex;
  margin-bottom: 4px;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  color: ${Color.DARK};
`;

interface LegendItemRectangleProps {
  color: string;
}

const LegendItemRectangle = styled.div`
  width: 15px;
  height: 15px;
  margin-right: 7px;
  background-color: ${(props: LegendItemRectangleProps) => props.color};
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const VictoryContainer = styled.div`
  width: 50%;
  height: 100%;
`;

class ProgressBannerScore {
  public chart: number = 0;
  public score: number = 0;
  public label?: string;

  constructor(chart: number, score: number) {
    this.chart = chart;
    this.score = score;
    this.label = this.score + "%";
  }
}

export interface ProgressBannerProps {
  organizationScore: number;
  efficientScore: number;
  averageScore: number;
}

export const ProgressBanner: React.FC<ProgressBannerProps> = ({
  organizationScore,
  efficientScore,
  averageScore,
}) => {
  const { t } = useTranslation("Dashboard");

  const dataArray = [
    new ProgressBannerScore(1, organizationScore),
    new ProgressBannerScore(2, efficientScore),
    new ProgressBannerScore(3, averageScore),
  ];

  return (
    <ProgressBannerContainer data-testid="progress-banner">
      <Title>{t("progressBanner.title")}</Title>
      <InfoText>{t("progressBanner.info")}</InfoText>
      <FlexContainer>
        <Legend>
          <LegendTitle>{dataArray[0].label}</LegendTitle>
          <LegendItem>
            <LegendItemRectangle color={Color.BITTER_SWEET} />
            {t("progressBanner.organization")}
          </LegendItem>
          <LegendItem>
            <LegendItemRectangle color={Color.MONTE_CARLO} />
            {t("progressBanner.average")}
          </LegendItem>
          <LegendItem>
            <LegendItemRectangle color={Color.DARK} />
            {t("progressBanner.efficient")}
          </LegendItem>
        </Legend>
        <VictoryContainer>
          <VictoryBar
            data={dataArray}
            x="chart"
            y="score"
            style={{
              data: {
                fill: ({ datum }) => {
                  switch (datum.chart) {
                    case 1:
                      return "#F2675A";
                    case 2:
                      return "#75C3B8";
                    case 3:
                      return "#26145C";
                    default:
                      return "#000000";
                  }
                },
              },
            }}
            barRatio={0.8}
            padding={{ bottom: 0 }}
            domainPadding={100}
            labelComponent={
              <StyledVictoryLabel
                angle={0}
                textAnchor="middle"
                className="score-label"
              />
            }
          />
        </VictoryContainer>
      </FlexContainer>
    </ProgressBannerContainer>
  );
};
