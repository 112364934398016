import { useMutation } from "@tanstack/react-query";

import i18n from "../../../i18n";

import { queryClient } from "../../../App";

import { CreateUserPayload } from "../../../types/user/User";

import {
  startNotification,
  endNotification,
} from "../../../utils/common/notifications";
import { generateRandomString } from "../../../utils/common/commonUtils";

import { createUser } from "../../../api/organizations/users/users";

export const useCreateUser = () => {
  const randomId = generateRandomString(20);

  return useMutation((payload: CreateUserPayload) => createUser(payload), {
    onMutate: () => {
      startNotification(randomId);
    },
    onSuccess: () => {
      endNotification(
        randomId,
        i18n.t("Users:notifications.createSuccess"),
        true
      );
      queryClient.invalidateQueries(["organizationUsers"]);
    },
    onError: () => {
      endNotification(
        randomId,
        i18n.t("Users:notifications.createError"),
        false
      );
    },
  });
};
