export const joinQueryParams = (params: {
  [key: string]: string | null | undefined;
}) => {
  const paramsKeys = Object.keys(params) as string[];
  const filteredParamKeys = paramsKeys.filter(
    (key) =>
      params[key] !== undefined && params[key] !== "" && params[key] !== null
  );
  console.log("FILTERED_PARAMS", { params, filteredParamKeys });
  const paramsArr = filteredParamKeys.map(
    (key) => `${key}=${params[key.trim()]}`
  );
  return paramsArr.join("&");
};
