import React, { useEffect, useState } from "react";
import { useForm } from "@mantine/form";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

// Components
import {
  Box,
  Button,
  Group,
  MultiSelect,
  Radio,
  RadioGroup,
  Select,
  Space,
  Textarea,
  TextInput,
} from "@mantine/core";
import { CreateCategoryModal } from "components/categories/CreateCategory/CreateCategoryModal";
import { ImageUpload } from "components/common";

// Types
import { ApiError } from "types/api/ApiError";
import { Category } from "types/category/categories";
import { Course } from "types/courses/courses";
import { Organization } from "types/organizations/Organization";
import { ErrorCode } from "enums/api/ErrorCode";
import { Color } from "enums/common";

//Queries
import { useCategories } from "hooks/categories/useCategories";
import { useCreateCategory } from "hooks/categories/useCreateCategory";
import { useAllOrganizations } from "hooks/organizations/useAllOrganizations";

// Utils
import { generateRandomString, warningNotification } from "utils/common";
import { requiredString } from "utils/form/validatorsUtils";

export interface CoursesListProps {
  course: Course;
  mutation: any;
}

export const CoursesEditForm: React.FC<CoursesListProps> = ({
  course,
  mutation,
}) => {
  // State
  const [isPublic, setIsPublic] = useState("");
  const [showOrgInput, setShowOrgInput] = useState<Boolean>();
  const [skills, setSkills] = useState([""]);
  const [avatarUrl, setAvatarUrl] = useState<string | undefined>();
  const [categoryId, setCategoryId] = useState<string | null>("");
  const [isCreateCategoryModalOpen, setIsCreateCategoryModalOpen] =
    useState(false);

  // Hooks
  const createCategoryMutation = useCreateCategory();
  const categoryData = useCategories();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const organizationQuery = useAllOrganizations();

  // Schema
  const schema = {
    level: (value: string) =>
      requiredString(value, t("Exercises:notifications.requiredField")),
    name: (value: string) =>
      requiredString(value, t("Exercises:notifications.requiredField")),
    summary: (value: string) =>
      requiredString(value, t("Exercises:notifications.requiredField")),
  };

  const form = useForm({
    initialValues: {
      id: course.id,
      avatar: "",
      isLeadership: course.isLeadership ? "true" : "false",
      level: course.level?.id.toString(),
      name: course.name,
      organizationId: course.organization
        ? course.organization.id.toString()
        : undefined,
      skills: skills,
      status: course.activationStatus
        ? course.activationStatus.id.toString()
        : undefined,
      summary: course.summary,
      subtitle: course.subtitle,
    },
    validate: schema,
  });

  // Handlers
  const handleSubmit = () => {
    if (
      categoryId === "" ||
      isPublic === "" ||
      form.values.isLeadership === ""
    ) {
      const randomId = generateRandomString(20);
      warningNotification(randomId, t("Exercises:notifications.fillRequired"));
    } else {
      mutation.mutate(
        {
          ...form.values,
          isPublic: isPublic === "true" ? true : false,
          categoryId: categoryId ? Number(categoryId) : 0,
          organizationId:
            isPublic !== "true" ? Number(form.values.organizationId) : null,
          isLeadership: form.values.isLeadership === "true" ? true : false,
          level: Number(form.values.level),
          skills: skills.filter((e) => e).toString(),
          avatar: avatarUrl,
        },
        {
          onSuccess: () => {
            navigate(`/courses/${course.id}`);
          },
          onError: (error: AxiosError<ApiError>) => {
            if (
              error.response?.data.errorCode === ErrorCode.NAME_ALREADY_EXISTS
            ) {
              form.setFieldError("name", t("Courses:errors.nameExists"));
            }
          },
        }
      );
    }
  };

  const categoryOptions = categoryData.data
    ? categoryData.data.map((category: Category) => ({
        value: category.id.toString(),
        label: category.name,
      }))
    : [];

  const organizationOptions = organizationQuery.data
    ? organizationQuery.data.map((organization: Organization) => ({
        value: organization.id.toString(),
        label: organization.name,
      }))
    : [];

  const difficultyLevelOptions = [
    {
      value: "1",
      label: t("Courses:createCourse.beginner"),
    },
    {
      value: "2",
      label: t("Courses:createCourse.intermediate"),
    },
    {
      value: "3",
      label: t("Courses:createCourse.expert"),
    },
  ];

  // Effects
  useEffect(() => {
    setShowOrgInput(false);
  }, []);

  useEffect(() => {
    isPublic && isPublic === "true"
      ? setShowOrgInput(false)
      : setShowOrgInput(true);

    if (isPublic === "false" && organizationOptions.length) {
      form.setFieldValue("organizationId", organizationOptions[0].value);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPublic]);

  useEffect(() => {
    setSkills(course.skills ? course.skills.split(",") : []);
    setIsPublic(course.isPublic ? "true" : "false");
  }, [course.isPublic, course.skills]);

  useEffect(() => {
    setCategoryId(course.category ? course.category.id.toString() : "");
  }, [course]);

  return (
    <Box sx={{ maxWidth: 800 }} mx="auto">
      <form onSubmit={form.onSubmit(handleSubmit)} noValidate>
        <TextInput
          required
          label={t("Courses:createCourse.title")}
          styles={() => ({
            input: {
              fontSize: 14,
              color: Color.DARK,
              fontFamily: "Montserrat",
              borderColor: Color.DARK,
              borderWidth: 1,
              backgroundColor: Color.WHITE,
              "&:focus": {
                borderColor: Color.DARK,
                borderWidth: 1,
              },
            },
          })}
          {...form.getInputProps("name")}
        />
        <Space h="md" />
        <TextInput
          label={t("Courses:createCourse.subtitle")}
          styles={() => ({
            input: {
              fontSize: 14,
              color: Color.DARK,
              fontFamily: "Montserrat",
              borderColor: Color.DARK,
              borderWidth: 1,
              backgroundColor: Color.WHITE,
              "&:focus": {
                borderColor: Color.DARK,
                borderWidth: 1,
              },
            },
          })}
          {...form.getInputProps("subtitle")}
        />
        <Space h="md" />
        <Textarea
          required
          label={t("Courses:createCourse.description")}
          mb={3}
          styles={() => ({
            input: {
              fontSize: 14,
              color: Color.DARK,
              fontFamily: "Montserrat",
              borderColor: Color.DARK,
              borderWidth: 1,
              backgroundColor: Color.WHITE,
              "&:focus": {
                borderColor: Color.DARK,
                borderWidth: 1,
              },
            },
          })}
          {...form.getInputProps("summary")}
        />
        <Space h="md" />
        <Group>
          <Select
            label={t("Courses:createCourse.category")}
            placeholder={t("Courses:createCourse.category")}
            data={categoryOptions}
            styles={{
              input: {
                width: 200,
                fontFamily: "Montserrat",
                color: Color.DARK,
                borderColor: Color.DARK,
                borderWidth: 1,
                "&:focus": {
                  borderColor: Color.DARK,
                  borderWidth: 1,
                },
              },
            }}
            value={categoryId}
            onChange={(id) => setCategoryId(id)}
          />
          <Button
            onClick={() => {
              setIsCreateCategoryModalOpen(true);
            }}
            variant="outline"
            styles={() => ({
              root: {
                fontSize: 14,
                color: Color.DARK,
                fontFamily: "Montserrat",
                borderColor: Color.DARK,
                borderWidth: 1,
                backgroundColor: Color.WHITE,
                marginTop: 25,
              },
            })}
          >
            {t("Courses:categoryModal.button")}
          </Button>
        </Group>
        <Space h="md" />
        <Select
          required
          label={t("Courses:createCourse.difficulty")}
          placeholder={t("Courses:createCourse.difficulty")}
          data={difficultyLevelOptions}
          styles={{
            input: {
              fontFamily: "Montserrat",
              color: Color.DARK,
              borderColor: Color.DARK,
              borderWidth: 1,
              fontSize: 14,
              "&:focus": {
                borderColor: Color.DARK,
                borderWidth: 1,
              },
            },
          }}
          {...form.getInputProps("level")}
          value={form.values.level?.toString()}
          onChange={(value) => form.setFieldValue("level", value || "")}
        />
        <Space h="md" />
        <MultiSelect
          data={[]}
          value={skills.filter(Boolean)}
          onChange={setSkills}
          label={t("Courses:createCourse.skills")}
          placeholder={t("Courses:createCourse.skillsSelect")}
          searchable
          creatable
          getCreateLabel={(query) => `+ ${t("Courses:create")} ${query}`}
          styles={() => ({
            input: {
              fontSize: 14,
              color: Color.DARK,
              fontFamily: "Montserrat",
              borderColor: Color.DARK,
              borderWidth: 1,
              backgroundColor: Color.WHITE,
              "&:focus": {
                borderColor: Color.DARK,
                borderWidth: 1,
              },
            },
          })}
        />
        <Space h="md" />
        <RadioGroup
          value={isPublic}
          onChange={setIsPublic}
          label={t("Courses:createCourse.privacy")}
          orientation="vertical"
          required
          styles={{
            label: { fontSize: 14, color: Color.DARK },
            radio: {
              fontSize: 14,
              color: Color.DARK,
            },
            inner: { fontSize: 14, color: Color.DARK },
            icon: { fontSize: 14, color: Color.DARK },
            radioWrapper: { fontSize: 14, color: Color.DARK },
          }}
        >
          <Radio value="true" label={t("Courses:createCourse.public")} />
          <Radio value="false" label={t("Courses:createCourse.private")} />
        </RadioGroup>
        <Space h="md" />
        {showOrgInput === true ? (
          <Select
            label={t("Courses:createCourse.organization")}
            placeholder={t("Courses:createCourse.organization")}
            data={organizationOptions}
            styles={() => ({
              input: {
                fontSize: 14,
                color: Color.DARK,
                fontFamily: "Montserrat",
                borderColor: Color.DARK,
                borderWidth: 1,
                backgroundColor: Color.WHITE,
                "&:focus": {
                  borderColor: Color.DARK,
                  borderWidth: 1,
                },
              },
            })}
            {...form.getInputProps("organizationId")}
          />
        ) : null}
        <Space h="md" />
        <RadioGroup
          label={t("Courses:createCourse.isLeadership")}
          orientation="vertical"
          required
          styles={{
            label: { fontSize: 14, color: Color.DARK },
            radio: {
              fontSize: 14,
              color: Color.DARK,
            },
            inner: { fontSize: 14, color: Color.DARK },
            icon: { fontSize: 14, color: Color.DARK },
            radioWrapper: { fontSize: 14, color: Color.DARK },
          }}
          {...form.getInputProps("isLeadership")}
        >
          <Radio value="true" label={t("Courses:createCourse.yes")} />
          <Radio value="false" label={t("Courses:createCourse.no")} />
        </RadioGroup>
        <Space h="md" />
        <ImageUpload setAvatarIdentifier={setAvatarUrl} />
        <Space h="md" />
        <Group position="right" mt="md">
          <Button
            data-testid="submit-button"
            type="submit"
            styles={(theme) => ({
              root: {
                fontSize: 16,
                fontFamily: "Montserrat",
                backgroundColor: Color.DARK,
                fontWeight: 600,

                "&:hover": {
                  backgroundColor: theme.fn.lighten(Color.DARK, 0.1),
                },
              },
            })}
          >
            {t("Common:actions.submit")}
          </Button>
        </Group>
      </form>
      <CreateCategoryModal
        setCreatedCategorySelected={(id) => setCategoryId(id.toString())}
        mutation={createCategoryMutation}
        opened={isCreateCategoryModalOpen}
        onClose={() => setIsCreateCategoryModalOpen(false)}
      ></CreateCategoryModal>
    </Box>
  );
};
