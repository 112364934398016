import { useMutation } from "@tanstack/react-query";

import i18n from "../../../i18n";

import { queryClient } from "../../../App";

import { InvitePeoplePayload } from "../../../types/user/InvitePeople";

import {
  startNotification,
  endNotification,
} from "../../../utils/common/notifications";
import { generateRandomString } from "../../../utils/common/commonUtils";

import { invitePeople } from "../../../api/organizations/users/invitePeople";

export const useInvitePeople = () => {
  const randomId = generateRandomString(20);

  return useMutation((payload: InvitePeoplePayload) => invitePeople(payload), {
    onMutate: () => {
      startNotification(randomId);
    },
    onSuccess: () => {
      endNotification(
        randomId,
        i18n.t("Users:notifications.inviteSuccess"),
        true
      );
      queryClient.invalidateQueries(["organizationUsers"]);
    },
    onError: () => {
      endNotification(
        randomId,
        i18n.t("Users:notifications.inviteError"),
        false
      );
    },
  });
};
