import { Resource } from "types/resources/Resource";
import { RESOURCES } from "../../enums/resource/urls";
import { ApiResponse } from "../../types/api/ApiResponse";
import { CreateResourcePayload } from "../../types/resource/resource";
import { createAxiosInstance } from "api/config";

const axios = createAxiosInstance();

export const getResources = async (
  page: number,
  size: number,
  type: number
): Promise<ApiResponse<Resource[]>> => {
  const { data } = await axios.get(
    `/${RESOURCES.GET_RESOURCES}/?page=${page}&size=${size}&type=${type}`
  );

  return data;
};

export const getResource = async (resourceId: number): Promise<Resource> => {
  const { data } = await axios.get(`/${RESOURCES.RESOURCES}/${resourceId}`);

  return data;
};

export const createResource = async (
  payload: CreateResourcePayload
): Promise<number> => {
  const { data } = await axios.post(`/${RESOURCES.CREATE_RESOURCES}`, payload);

  return data;
};
