import { USER } from "../../../enums/users/urls";
import { InvitePeoplePayload } from "../../../types/user/InvitePeople";

import { createAxiosInstance } from "api/config";

const axios = createAxiosInstance();

export const invitePeople = async (
  payload: InvitePeoplePayload
): Promise<number> => {
  const { data } = await axios.post(`/${USER.REGISTER_USER}`, payload);
  return data;
};
