import React from "react";

import { useTranslation } from "react-i18next";

import { Container, Space, Text } from "@mantine/core";
import styled from "styled-components";

import { Banner, BasePage } from "../../../components/common";
import { Color } from "enums/common";
import { useUpdateChapterQuiz } from "../../../hooks/chapters/chapterquizes/useUpdateChapterQuiz";
import { useParams } from "react-router-dom";
import { useChapterQuiz } from "../../../hooks/chapters/chapterquizes/useChapterQuiz";
import { EditQuizFrom } from "../../../components/chapters/EditQuizFrom/EditQuizFrom";

const StyledBanner = styled(Banner)`
  margin: 34px 0 40px 0;
`;

export const EditQuiz: React.FC = () => {
  const { t } = useTranslation();
  const { quizId } = useParams();

  const { data } = useChapterQuiz(Number(quizId));

  const updateQuizMutation = useUpdateChapterQuiz();

  return (
    <BasePage>
      <Space h="xl" />
      <Container>
        <StyledBanner title="" text="">
          <Text
            styles={() => ({
              root: {
                fontSize: 28,
                fontWeight: 600,
                fontFamily: "Montserrat",
                color: Color.DARK,
              },
            })}
          >
            {t("Chapters:quizPage.addQuiz")}
          </Text>
          <Space h="xl" />

          {data && <EditQuizFrom mutation={updateQuizMutation} quiz={data} />}
        </StyledBanner>
      </Container>
    </BasePage>
  );
};
