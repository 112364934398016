import { Container, Skeleton, Text } from "@mantine/core";
import { Banner } from "components/common";
import { Color } from "enums/common";
import { useVoiceBots } from "hooks/voicebots/useVoiceBots";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { VoicebotTable } from "./VoicebotTable";

const StyledBanner = styled(Banner)`
  margin: 34px 0 40px 0;
`;

export interface VoicebotPageProps {
  selectedVoicebot: string;
  setSelectedVoicebot: (voicebot: string) => void;
}

export const VoicebotPage: React.FC<VoicebotPageProps> = ({
  selectedVoicebot,
  setSelectedVoicebot,
}) => {
  const { t } = useTranslation();
  const { data, isLoading } = useVoiceBots();

  return (
    <>
      <Container>
        <StyledBanner title="" text="">
          <Text weight={600} size="xl" color={Color.DARK}>
            {t("Common:voicebot")}
          </Text>
          {isLoading && (
            <Skeleton height={550} radius="sm" style={{ marginTop: 30 }} />
          )}
          {data && (
            <VoicebotTable
              voicebots={data}
              selectedVoicebot={selectedVoicebot}
              setSelectedVoicebot={setSelectedVoicebot}
            />
          )}
        </StyledBanner>
      </Container>
    </>
  );
};
