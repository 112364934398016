import { Table } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { LockOpen, Lock } from "tabler-icons-react";
import { Color } from "enums/common";
import { Exercise, ExercisesObject } from "../../types/exercises/exercises";

interface _Exercise extends Exercise {
  isAssigned?: boolean;
}

export interface AssignExercisesTableProps {
  exercises: _Exercise[];
  selectedExercises: ExercisesObject[];
  setSelectedExercises: (exercise: ExercisesObject) => void;
}

export const AssignExercisesTable: React.FC<AssignExercisesTableProps> = ({
  exercises,
  setSelectedExercises,
}) => {
  const { t } = useTranslation();

  const handleExerciseSelection = (exerciseId: string, checked: boolean) => {
    const exercise = exercises.find((x) => x.id.toString() === exerciseId);

    if (exercise) {
      const customExercise = {
        ...exercise,
        checked: checked,
      };
      setSelectedExercises(customExercise);
    }
  };

  const getExercisePublicIcon = (isPublic: Boolean) => {
    return isPublic ? (
      <LockOpen size={28} strokeWidth={2} color={"green"} />
    ) : (
      <Lock size={28} strokeWidth={2} color={"gray"} />
    );
  };

  return (
    <Table
      data-testid="courses-table"
      highlightOnHover
      verticalSpacing={6}
      style={{ marginTop: 20, marginBottom: 20, textAlign: "center" }}
      sx={() => ({
        backgroundColor: Color.WHITE,
        boxShadow: "box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.15)",
      })}
    >
      <thead>
        <tr>
          <th></th>
          <th>{t("Exercises:table.name")}</th>
          <th>{t("Exercises:table.learningTime")}</th>
          <th>{t("Exercises:table.learningScore")}</th>
          <th>{t("Exercises:table.summary")}</th>
          <th>{t("Exercises:table.privacy")}</th>
        </tr>
      </thead>
      <tbody>
        {exercises.map((exercise, index) => (
          <tr
            key={exercise.id}
            style={{ opacity: exercise.isAssigned ? 0.6 : 1 }}
          >
            <td>
              <input
                disabled={exercise.isAssigned}
                type="checkbox"
                name="checkbox"
                id={exercise.id.toString()}
                onChange={(event) =>
                  handleExerciseSelection(event.target.id, event.target.checked)
                }
              ></input>
            </td>
            <td>{exercise.title}</td>
            <td>{exercise.duration}</td>
            <td>{exercise.points}</td>
            <td>{exercise.description}</td>
            <td>{getExercisePublicIcon(exercise.isPublic)}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
