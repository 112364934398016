import {
  Card,
  Group,
  Image,
  SimpleGrid,
  UnstyledButton,
  createStyles,
} from "@mantine/core";
import { Color } from "enums/common";
import { useEffect, useState } from "react";
import { Resource } from "types/resources/Resource";

export interface ImageGridProps {
  resources: Resource[];
  selectedImage: Resource | undefined;
  setSelectedImage: (resource: Resource) => void;
}

const useStyles = createStyles((theme) => ({
  borderImage: {
    width: 150,
    height: 90,
    borderWidth: "2px",
    borderColor: Color.DARK,
    borderStyle: "solid",
  },
}));

export function ImageGrid({
  resources,
  selectedImage,
  setSelectedImage,
}: ImageGridProps) {
  const { classes } = useStyles();

  const [image, setImage] = useState<Resource>();

  useEffect(() => {
    if (selectedImage) setImage(selectedImage);
  }, [selectedImage]);

  const images = resources.map((resource, index) => (
    <UnstyledButton onClick={() => setSelectedImage(resource)} key={index}>
      <Image
        width={150}
        height={90}
        src={resource.resourceUrl}
        mt="md"
        className={image?.id === resource.id ? classes.borderImage : ""}
      />
    </UnstyledButton>
  ));

  return (
    <Card withBorder radius="md">
      <Group position="apart"></Group>
      <SimpleGrid cols={3} mt="md">
        {images}
      </SimpleGrid>
    </Card>
  );
}
