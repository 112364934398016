import React from "react";
import { useParams } from "react-router-dom";

import { Box, Container, Skeleton, Space } from "@mantine/core";

import { useCourse } from "../../hooks/courses/useCourse";
import { CoursesEditForm } from "../../components/courses/CourseEdit/CourseEditForm";
import { useUpdateCourse } from "../../hooks/courses/useUpdateCourse";
import { Banner, BasePage } from "../../components/common";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const StyledBanner = styled(Banner)`
  margin: 34px 0 40px 0;
`;

export const EditCoursePage: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { data, isLoading } = useCourse(Number(id));
  const updateCourseMutation = useUpdateCourse();

  return (
    <BasePage>
      <Container data-testid="courses-page">
        <Box sx={{ maxWidth: 800 }} mx="auto">
          <StyledBanner title={t("Courses:banner.editCourse")} text={""}>
            <Space h="lg" />
            <Box sx={{ maxWidth: 800 }} mx="auto">
              {isLoading && (
                <Skeleton height={550} radius="sm" style={{ marginTop: 30 }} />
              )}
              {data && (
                <CoursesEditForm
                  course={data}
                  mutation={updateCourseMutation}
                ></CoursesEditForm>
              )}
            </Box>
          </StyledBanner>
        </Box>
      </Container>
    </BasePage>
  );
};
