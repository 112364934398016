import { useMutation } from "@tanstack/react-query";
import { cloneCourse } from "api/courses/courses";
import { queryClient } from "App";
import i18n from "i18n";

// Utils
import {
  endNotification,
  generateRandomString,
  startNotification,
} from "utils/common";

export const useCloneCourse = () => {
  const randomId = generateRandomString(20);

  return useMutation((id: number) => cloneCourse(id), {
    onMutate: () => {
      startNotification(randomId);
    },
    onSuccess: () => {
      endNotification(
        randomId,
        i18n.t("Courses:notifications.createSuccess"),
        true
      );
      queryClient.invalidateQueries(["courses"]);
    },
    onError: () => {
      endNotification(
        randomId,
        i18n.t("Courses:notifications.createError"),
        false
      );
    },
  });
};
