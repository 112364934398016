import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";

// Components
import {
  Modal,
  TextInput,
  Group,
  Button,
  Select,
  CheckboxGroup,
  Checkbox,
  Space,
  Box,
  MultiSelect,
} from "@mantine/core";
import { useForm } from "@mantine/form";

// types
import { ApiError } from "types/api/ApiError";
import { Department } from "types/department/Department";
import { ErrorCode } from "enums/api/ErrorCode";
import { Color } from "enums/common";

// Utils
import { requiredNumber, requiredString } from "utils/form/validatorsUtils";

export interface CreateUserModalProps {
  opened: boolean;
  mutation: any;
  departments: Department[];
  organizationId: number;
  onClose: () => void;
}

export const CreateUserModal: React.FC<CreateUserModalProps> = ({
  opened,
  mutation,
  departments,
  organizationId,
  onClose,
}) => {
  // Hooks
  const { t } = useTranslation();

  // State
  const form = useForm({
    initialValues: {
      fullName: "",
      nickName: "",
      mobile: "",
      email: "",
      genderId: 0,
      expertLevelId: 0,
      language: 0,
      roleIds: [],
      organizationId: organizationId,
      departmentIds: [],
    },
    validate: {
      email: (value) =>
        /^\S+@\S+$/.test(value)
          ? null
          : t("Exercises:notifications.requiredField"),
      expertLevelId: (value) =>
        requiredNumber(value, t("Exercises:notifications.requiredField")),
      fullName: (value) =>
        requiredString(value, t("Exercises:notifications.requiredField")),
      nickName: (value) =>
        requiredString(value, t("Exercises:notifications.requiredField")),
      mobile: (value) =>
        requiredString(value, t("Exercises:notifications.requiredField")),
      roleIds: (value) =>
        value.length ? null : t("Exercises:notifications.requiredField"),
    },
  });

  // Handlers
  const handleClose = () => {
    form.reset();
    onClose();
  };

  const handleSubmit = () => {
    mutation.mutate(
      {
        ...form.values,
      },
      {
        onSuccess: () => {
          handleClose();
        },
        onError: (error: AxiosError<ApiError>) => {
          if (
            error.response?.data.errorCode === ErrorCode.NAME_ALREADY_EXISTS
          ) {
            form.setFieldError(
              "fullName",
              t("Organizations:errors.nameExists")
            );
          }
        },
      }
    );
  };

  // Select options data
  const departmentOptions = departments.map((department: Department) => ({
    value: department.id.toString(),
    label: department.name.toString(),
  }));

  const genderOptions = [
    {
      value: "1",
      label: t("Common:male"),
    },
    {
      value: "2",
      label: t("Common:female"),
    },
  ];

  const languageOptions = [
    {
      value: "1",
      label: t("Common:languages.english"),
    },
    {
      value: "2",
      label: t("Common:languages.norwegian"),
    },
  ];

  const expertLevelOptions = [
    {
      value: 1,
      label: t("Common:expertLevel.beginner"),
    },
    {
      value: 2,
      label: t("Common:expertLevel.intermediate"),
    },
    {
      value: 3,
      label: t("Common:expertLevel.experienced"),
    },
  ];

  return (
    <Modal
      centered
      data-testid="create-modal"
      size="lg"
      title={t("Users:modals.create")}
      opened={opened}
      onClose={handleClose}
    >
      <Box>
        <form onSubmit={form.onSubmit(handleSubmit)} noValidate>
          <TextInput
            required
            label={t("Users:form.fullName.label")}
            placeholder={t("Users:form.fullName.placeholder")}
            {...form.getInputProps("fullName")}
          />
          <Space h="md" />
          <TextInput
            required
            label={t("Users:form.nickName.label")}
            placeholder={t("Users:form.nickName.placeholder")}
            {...form.getInputProps("nickName")}
          />
          <Space h="md" />
          <TextInput
            required
            label={t("Users:form.mobile.label")}
            placeholder={t("Users:form.mobile.placeholder")}
            {...form.getInputProps("mobile")}
          />
          <Space h="md" />
          <TextInput
            required
            label={t("Users:form.email.label")}
            placeholder={t("Users:form.email.placeholder")}
            {...form.getInputProps("email")}
          />
          <Space h="md" />
          <Select
            label={t("Users:form.gender.label")}
            placeholder={t("Users:form.gender.placeholder")}
            data={genderOptions}
            {...form.getInputProps("genderId")}
            styles={{
              input: {
                fontFamily: "Montserrat",
                fontWeight: 600,
                color: Color.DARK,
                borderColor: Color.DARK,
                borderWidth: 1,
              },
            }}
          />
          <Space h="md" />
          {departmentOptions && (
            <MultiSelect
              label={t("Users:form.department.label")}
              placeholder={t("Users:form.department.placeholder")}
              data={departmentOptions}
              {...form.getInputProps("departmentIds")}
              styles={{
                input: {
                  fontFamily: "Montserrat",
                  fontWeight: 600,
                  color: Color.DARK,
                  borderColor: Color.DARK,
                  borderWidth: 1,
                },
              }}
            />
          )}
          <Space h="md" />
          <Select
            label={t("Users:form.language.label")}
            placeholder={t("Users:form.language.placeholder")}
            data={languageOptions}
            {...form.getInputProps("language")}
            styles={{
              input: {
                fontFamily: "Montserrat",
                fontWeight: 600,
                color: Color.DARK,
                borderColor: Color.DARK,
                borderWidth: 1,
              },
            }}
          />

          <Space h="md" />
          <Select
            required
            label={t("Users:form.expertLevel.label")}
            placeholder={t("Users:form.expertLevel.placeholder")}
            data={expertLevelOptions}
            {...form.getInputProps("expertLevelId")}
            styles={{
              input: {
                fontFamily: "Montserrat",
                fontWeight: 600,
                color: Color.DARK,
                borderColor: Color.DARK,
                borderWidth: 1,
              },
            }}
          />
          <Space h="md" />

          <CheckboxGroup
            defaultValue={["react"]}
            label={t("Users:roles.createUser.selectRoleTitle")}
            {...form.getInputProps("roleIds")}
            required
          >
            <Checkbox
              label={t("Users:roles.createUser.memberRoleLabel")}
              value="3"
            />
            <Checkbox
              label={t("Users:roles.createUser.managerRoleLabel")}
              value="4"
            />
            <Checkbox
              label={t("Users:roles.createUser.administratorRoleLabel")}
              value="2"
            />
          </CheckboxGroup>
          <Space h="md" />
          {/* <ImageUpload setAvatarIdentifier={setAvatarUrl} /> */}
          <Group position="right" mt="md">
            <Button data-testid="submit-button" type="submit">
              {t("Common:actions.submit")}
            </Button>
          </Group>
        </form>
      </Box>
    </Modal>
  );
};
