import React from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";
import { TabsProps, Tabs } from "@mantine/core";

import { Color } from "enums/common";
import { Organization } from "../../../types/organizations/Organization";

import { MembersTab } from "../../members/MembersTab";
import { ManagersTab } from "../../managers/ManagersTab";
import { AdministratorsTab } from "../../administrators/AdministratosTab";

const OrganizationTabMenuContainer = styled.div`
  height: 115px;
  width: 100%;
  float: left;
`;

export interface OrganizationTabMenuProps {
  className?: string;
  organization: Organization;
  children?: React.ReactNode;
}

function StyledTabs(props: TabsProps) {
  return (
    <Tabs
      variant="unstyled"
      styles={(theme) => ({
        tabControl: {
          backgroundColor: Color.GRAY,
          color: Color.DARK,
          fontSize: "20px",
          lineHeight: "24px",
          padding: `${theme.spacing.lg}px ${theme.spacing.xl}px`,

          "&:not(:first-of-type)": {
            borderLeft: 0,
          },
        },

        tabActive: {
          borderColor: Color.DARK,
          borderBottom: `3px solid ${Color.DARK}`,
          fontWeight: 700,
        },
      })}
      {...props}
    />
  );
}

export const OrganizationTabMenu: React.FC<OrganizationTabMenuProps> = ({
  className,
  children,
  organization,
}) => {
  const { t } = useTranslation();
  return (
    <OrganizationTabMenuContainer
      data-testid="organizationDetailsPreview"
      className={className}
    >
      <StyledTabs color={Color.DARK}>
        <Tabs.Tab label={t("Users:members.tab.title")}>
          <MembersTab organization={organization} />
        </Tabs.Tab>
        <Tabs.Tab label={t("Users:administrators.tab.title")}>
          <AdministratorsTab organization={organization} />
        </Tabs.Tab>
        <Tabs.Tab label={t("Users:managers.tab.title")}>
          <ManagersTab organization={organization} />
        </Tabs.Tab>
      </StyledTabs>
      {children}
    </OrganizationTabMenuContainer>
  );
};
