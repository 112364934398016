import { createAxiosInstance } from "api/config";
import { Organizations as OrganizationUrls } from "enums/api/urls";
import { ApiResponse } from "types/api";

import {
  Organization,
  GetOrganizationsParams,
  CreateOrganizationPayload,
  UpdateOrganizationPayload,
} from "types/organizations";
import { User } from "types/user/User";
import { USER } from "enums/users/urls";

const axios = createAxiosInstance();

export const getOrganizations = async (
  params: GetOrganizationsParams
): Promise<ApiResponse<Organization[]>> => {
  const { data } = await axios.get(`/${OrganizationUrls.GET_ORGANIZATIONS}`, {
    params,
  });

  return data;
};

export const createOrganization = async (
  payload: CreateOrganizationPayload
): Promise<number> => {
  const { data } = await axios.post(
    `/${OrganizationUrls.CREATE_ORGANIZATION}`,
    payload
  );

  return data;
};

export const updateOrganization = async (
  id: number,
  payload: UpdateOrganizationPayload
): Promise<number> => {
  const { data } = await axios.put(
    `/${OrganizationUrls.ORGANIZATIONS}/${id}`,
    payload
  );

  return data;
};

export const deleteOrganization = async (id: number): Promise<void> => {
  const { data } = await axios.delete(
    `/${OrganizationUrls.ORGANIZATIONS}/${id}`
  );

  return data;
};

export const getOrganization = async (
  organizationId: number
): Promise<Organization> => {
  const { data } = await axios.get(
    `/${OrganizationUrls.ORGANIZATIONS}/${organizationId}`
  );

  return data;
};

export const getAllOrganizations = async (): Promise<Organization[]> => {
  const { data } = await axios.get(`/${OrganizationUrls.ORGANIZATIONS}/names`);

  return data;
};

export const getAllOrganizationsUsers = async (
  organizationId: number,
  page: number,
  searchKey: string,
  size: number
): Promise<ApiResponse<User[]>> => {
  const { data } = await axios.get(
    `/${USER.USERS}/${organizationId}/members?page=${page}&search-key=${searchKey}&size=${size}`
  );

  return data;
};
