import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Card, Container, Group, Text, Title } from "@mantine/core";

import { UserContext } from "contexes";

import { BasePage } from "components/common";
import { ChangePasswordModal } from "./ChangePasswordModal";

export const MyProfile: React.FC = () => {
  const { t } = useTranslation("Auth");

  const [userContext] = useContext(UserContext);
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <BasePage>
      <Container>
        <Card withBorder shadow="sm" p="lg" radius="md" mt="xl">
          <Title order={3} mb="xl">
            {t("changePassword.title")}
          </Title>
          <Group>
            <Text>{t("changePassword.email")}</Text>
            <Text>{userContext.nickName}</Text>
          </Group>
          <Group mt={5}>
            <Text>{t("changePassword.roles")}</Text>
            <Text>{userContext.authority.join(", ")}</Text>
          </Group>
          <Button mt="xl" onClick={() => setIsModalOpen(true)}>
            {t("changePassword.title")}
          </Button>
        </Card>
      </Container>
      <ChangePasswordModal
        opened={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </BasePage>
  );
};
