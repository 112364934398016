import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { AxiosError } from "axios";

import { Modal, TextInput, Textarea, Group, Button } from "@mantine/core";
import { useForm } from "@mantine/form";
import { TimeInput } from "@mantine/dates";
import { Clock } from "tabler-icons-react";
import { ImageUpload } from "../../common";

import { ApiError } from "../../../types/api/ApiError";

import { Department } from "../../../types/department/Department";
import { ErrorCode } from "../../../enums/api/ErrorCode";

export interface EditModalProps {
  department: Department;
  opened: boolean;
  mutation: any;
  onClose: () => void;
}

const TimeInputsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
`;

const TrainingTimeTitle = styled.div`
  font-size: 14px;
  line-height: 21.7px;
  padding: 1rem 0; ;
`;

export const EditModal: React.FC<EditModalProps> = ({
  department,
  opened,
  mutation,
  onClose,
}) => {
  const TimeInputStyle = {
    width: "100%",
    label: {
      lineHeight: "18.6px",
      fontSize: "14px",
      width: "100%",
    },
    input: {
      color: "#495057",
    },
  };

  const { t } = useTranslation();
  const [avatarUrl, setAvatarUrl] = useState<string | undefined>();

  const { beginnerLearningTime, expertLearningTime, intermediateLearningTime } =
    department;

  const _beginnerLearningTime = new Date(
    2024,
    3,
    19,
    Math.floor(beginnerLearningTime / 60),
    beginnerLearningTime % 60,
    0,
    0
  );
  const _intermediateLearningTime = new Date(
    2024,
    3,
    19,
    Math.floor(intermediateLearningTime / 60),
    intermediateLearningTime % 60,
    0,
    0
  );
  const _expertLearningTime = new Date(
    2024,
    3,
    19,
    Math.floor(expertLearningTime / 60),
    expertLearningTime % 60,
    0,
    0
  );

  const form = useForm({
    initialValues: {
      name: department.name,
      summary: department.summary,
      avatar: department.avatar,
      beginnerLearningTime: _beginnerLearningTime,
      expertLearningTime: _expertLearningTime,
      intermediateLearningTime: _intermediateLearningTime,
    },
  });

  const handleClose = () => {
    setAvatarUrl(undefined);
    form.reset();
    onClose();
  };

  const handleSubmit = () => {
    console.log("form.values", form.values);
    mutation.mutate(
      {
        id: department.id,
        payload: {
          ...form.values,
          beginnerLearningTime:
            +form.values.beginnerLearningTime.getHours() * 60 +
            +form.values.beginnerLearningTime.getMinutes(),
          intermediateLearningTime:
            +form.values.intermediateLearningTime.getHours() * 60 +
            +form.values.intermediateLearningTime.getMinutes(),
          expertLearningTime:
            +form.values.expertLearningTime.getHours() * 60 +
            +form.values.expertLearningTime.getMinutes(),
        },
        avatar: avatarUrl,
      },
      {
        onSuccess: () => {
          handleClose();
        },
        onError: (error: AxiosError<ApiError>) => {
          if (
            error.response?.data.errorCode === ErrorCode.NAME_ALREADY_EXISTS
          ) {
            form.setFieldError("name", t("Departments:errors.nameExists"));
          }
        },
      }
    );
  };

  return (
    <Modal
      centered
      data-testid="edit-modal"
      size="lg"
      title={t("Departments:modals.update")}
      opened={opened}
      onClose={handleClose}
    >
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <TextInput
          required
          label={t("Common:words.name")}
          placeholder={t("Common:words.name")}
          {...form.getInputProps("name")}
        />
        <Textarea
          required
          label={t("Departments:modals.summary")}
          placeholder={t("Departments:modals.summary")}
          mb={3}
          {...form.getInputProps("summary")}
        />
        <ImageUpload setAvatarIdentifier={setAvatarUrl} />

        <TrainingTimeTitle>
          Add weekly recommended training time
        </TrainingTimeTitle>

        <TimeInputsWrapper>
          <TimeInput
            styles={TimeInputStyle}
            style={{ width: "100%" }}
            label="Beginner"
            required
            icon={<Clock size={18} />}
            {...form.getInputProps("beginnerLearningTime")}
          />
          <TimeInput
            styles={TimeInputStyle}
            style={{ width: "100%" }}
            label="Intermediate"
            required
            icon={<Clock size={18} />}
            {...form.getInputProps("intermediateLearningTime")}
          />
          <TimeInput
            styles={TimeInputStyle}
            style={{ width: "100%" }}
            label="Experienced"
            required
            icon={<Clock size={18} />}
            {...form.getInputProps("expertLearningTime")}
          />
        </TimeInputsWrapper>
        <Group position="right" mt="md">
          <Button data-testid="submit-button" type="submit">
            {t("Common:actions.submit")}
          </Button>
        </Group>
      </form>
    </Modal>
  );
};
