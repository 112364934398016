import React, { useState } from "react";
import { Skeleton } from "@mantine/core";
import { Pagination } from "../../common";
import { useUserExercises } from "../../../hooks/exercises/useUserExercise";
import { UserExercise } from "../../../types/exercises/exercises";
import { useTranslation } from "react-i18next";
import { UserExercisesMenu } from "../ExercisesMenu/UserExercisesMenu";
import { UserExercisesTable } from "../ExercisesTable/UserExercisesTable";
import { DeleteUserExerciseModal } from "../DeleteModal/DeleteUserExerciseModal";
import { useDebouncedValue } from "@mantine/hooks";
import { useDeleteUserExercise } from "hooks/userExercises/useDeleteUserExercise";

export const UserExercisesTab: React.FC = () => {
  const { t } = useTranslation();
  const [searchKey, setSearchKey] = useState("");
  const [debouncedSearchKey] = useDebouncedValue(searchKey, 300);
  const [page, setPage] = useState(1);
  const [resultCount, setResultCount] = useState(15);
  const [isDeleteExciseModalOpen, setIsDeleteExciseModalOpen] = useState(false);

  const deleteUserExerciseMutation = useDeleteUserExercise();

  const [selectedExercise, setSelectedExercise] = useState<UserExercise>();

  const handleDeleteExercise = (exercise: UserExercise) => {
    setSelectedExercise(exercise);
    setIsDeleteExciseModalOpen(true);
  };

  const { data, isLoading } = useUserExercises(
    page,
    debouncedSearchKey,
    resultCount
  );

  return (
    <>
      {isLoading && (
        <Skeleton height={550} radius="sm" style={{ marginTop: 30 }} />
      )}
      <UserExercisesMenu setSearchKey={setSearchKey} searchKey={searchKey} />
      {data && (
        <>
          <UserExercisesTable
            userExercises={data.elements}
            onDeleteUserExercise={handleDeleteExercise}
          />
          <Pagination
            page={page}
            totalItems={data.total}
            itemsPerPage={resultCount}
            setItemsPerPage={setResultCount}
            setPage={setPage}
          />
        </>
      )}
      {selectedExercise && (
        <DeleteUserExerciseModal
          exercise={selectedExercise}
          title={t("Exercises:modals.deleteModalTitle")}
          text={t("Exercises:modals.deleteModalText")}
          onClose={() => setIsDeleteExciseModalOpen(false)}
          opened={isDeleteExciseModalOpen}
          mutation={deleteUserExerciseMutation}
        />
      )}
    </>
  );
};
