import React from "react";

export const Logo: React.FC = () => {
  return (
    <svg data-testid="logo" width="80" height="80" viewBox="0 0 80 80">
      <path
        d="M60.2437 56.1825C64.6235 53.3653 67.5335 48.4592 67.5335 42.8637C67.5335 34.1158 60.4429 27.0252 51.6959 27.0252V34.8485C56.1153 34.8485 59.7102 38.4434 59.7102 42.8637C59.7102 47.2823 56.1153 50.8771 51.6959 50.8771C47.4612 50.8771 43.9934 47.5752 43.7086 43.4125V42.9007H43.6833C43.6824 42.8871 43.6815 42.8745 43.6815 42.8637H35.8574C35.8574 42.8745 35.8592 42.8871 35.8592 42.9007H35.8574V69.3201H43.7086V56.524C46.0553 57.8984 48.7787 58.7004 51.6959 58.7004C52.0771 58.7004 52.452 58.6707 52.8278 58.6427C54.9681 64.852 60.8376 69.3201 67.7732 69.3201V61.4968C64.3036 61.4968 61.3531 59.2754 60.2437 56.1825Z"
        fill="#F2675A"
      />
      <path
        d="M33.8807 8.7959C25.1337 8.7959 18.0431 15.8865 18.0431 24.6326V51.5774H25.8943V38.2929C28.2401 39.669 30.9645 40.4702 33.8807 40.4702V32.6487C29.4622 32.6487 25.8664 29.053 25.8664 24.6326C25.8664 20.214 29.4622 16.6192 33.8807 16.6192C38.2993 16.6192 41.896 20.214 41.896 24.6326H49.7183C49.7183 15.8865 42.6277 8.7959 33.8807 8.7959Z"
        fill="#F2675A"
      />
      <mask width="80" height="80">
        <path d="M0 80H79.9982V0H0V80Z" fill="white" />
      </mask>
      <path
        d="M9.87744 40.474H33.8826V32.6498H9.87744V40.474Z"
        fill="#F9C3CE"
      />
    </svg>
  );
};
