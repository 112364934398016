import { Button, Group, TextInput } from "@mantine/core";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Color } from "enums/common";
import { useParams } from "react-router-dom";
import { useDepartment } from "hooks/departments/useDepartment";

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export interface DepartmentUsersMenuProps {
  searchKey: string;
  isManagerSelected: boolean;
  setSearchKey: (searchKey: string) => void;
  setIsModalOpen: (isModalOpen: boolean) => void;
  setIsAssignManagerModalOpen: (isModalOpen: boolean) => void;
}

export const DepartmentMenu: React.FC<DepartmentUsersMenuProps> = ({
  searchKey,
  setSearchKey,
  setIsModalOpen,
  setIsAssignManagerModalOpen,
  isManagerSelected,
}) => {
  const { t } = useTranslation();
  const { departmentId } = useParams();
  const { data } = useDepartment(Number(departmentId));

  return (
    <FlexContainer data-testid="members-menu">
      <>
        <Group spacing={0} sx={{ marginBottom: 10 }}>
          <TextInput
            value={searchKey}
            onChange={(event) => setSearchKey(event.currentTarget.value)}
            placeholder={t("Department:searchUser")}
            styles={{
              root: { width: 640 },
              input: { borderRadius: 20 },
            }}
          />
          <Button
            variant="filled"
            onClick={() => setSearchKey(searchKey)}
            styles={(theme) => ({
              root: {
                fontSize: 14,
                textTransform: "uppercase",
                fontFamily: "Montserrat",
                fontWeight: 600,
                backgroundColor: Color.DARK,
                borderRadius: 20,
                left: -30,

                "&:hover": {
                  backgroundColor: theme.fn.lighten(Color.DARK, 0.1),
                },
              },
            })}
          >
            {t("Common:search")}
          </Button>
        </Group>
        <Group>
          {data && !data.manager && !isManagerSelected && (
            <Button
              onClick={() => {
                setIsAssignManagerModalOpen(true);
              }}
              variant="outline"
              styles={() => ({
                root: {
                  fontSize: 14,
                  textTransform: "uppercase",
                  color: Color.DARK,
                  fontFamily: "Montserrat",
                  fontWeight: 600,
                  borderColor: Color.DARK,
                  borderWidth: 3,
                },
              })}
            >
              {t("Department:actions.assignManager")}
            </Button>
          )}
          <Button
            onClick={() => {
              setIsModalOpen(true);
            }}
            variant="outline"
            styles={() => ({
              root: {
                fontSize: 14,
                textTransform: "uppercase",
                color: Color.DARK,
                fontFamily: "Montserrat",
                fontWeight: 600,
                borderColor: Color.DARK,
                borderWidth: 3,
              },
            })}
          >
            {t("Department:actions.assignUsers")}
          </Button>
        </Group>
      </>
    </FlexContainer>
  );
};
