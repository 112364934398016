import { useMutation } from "@tanstack/react-query";

import i18n from "../../i18n";
import { queryClient } from "../../App";

import { createDepartment } from "../../api/departments/departments";

import { CreateDepartmentPayload } from "../../types/department/Department";

import {
  startNotification,
  endNotification,
} from "../../utils/common/notifications";
import { generateRandomString } from "../../utils/common/commonUtils";

export const useCreateDepartment = (organizationId: number) => {
  const randomId = generateRandomString(20);

  return useMutation(
    (payload: CreateDepartmentPayload) =>
      createDepartment(payload, organizationId),
    {
      onMutate: () => {
        startNotification(randomId);
      },
      onSuccess: () => {
        endNotification(
          randomId,
          i18n.t("Departments:notifications.createSuccess"),
          true
        );
        queryClient.invalidateQueries(["departments"]);
      },
      onError: () => {
        endNotification(
          randomId,
          i18n.t("Departments:notifications.createError"),
          false
        );
      },
    }
  );
};
