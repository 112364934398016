import {
  AssignDepartmentManagerPayload,
  AssignUserDepartmentPayload,
} from "types/department/assignDepartment";
import { DepartmentUser, User } from "types/user/User";
import { DEPARTMENTS } from "../../enums/departments/urls";
import { ApiResponse } from "../../types/api/ApiResponse";
import {
  CreateDepartmentPayload,
  Department,
  UpdateDepartmentObject,
} from "../../types/department/Department";
import { createAxiosInstance } from "api/config";

const axios = createAxiosInstance();

export const getDepartments = async (
  page: number,
  searchKey: string,
  size: number,
  organizationId: number
): Promise<ApiResponse<Department[]>> => {
  const { data } = await axios.get(
    `/${DEPARTMENTS.GET_DEPARTMENTS}/${organizationId}?page=${page}&search-key=${searchKey}&size=${size}`
  );

  return data;
};

export const getDepartment = async (id: number): Promise<Department> => {
  const { data } = await axios.get(`/${DEPARTMENTS.DEPARTMENT}/${id}`);

  return data;
};

export const createDepartment = async (
  payload: CreateDepartmentPayload,
  organizationId: number
): Promise<number> => {
  payload.organizationId = organizationId;
  const { data } = await axios.post(
    `/${DEPARTMENTS.CREATE_DEPARTMENT}`,
    payload
  );

  return data;
};

export const deleteDepartment = async (id: number): Promise<void> => {
  const { data } = await axios.delete(`/${DEPARTMENTS.DEPARTMENT}/${id}`);

  return data;
};

export const updateDepartment = async (
  department: UpdateDepartmentObject
): Promise<number> => {
  const { data } = await axios.put(
    `/${DEPARTMENTS.DEPARTMENT}/${department.id}`,
    department.payload
  );

  return data;
};

export const assignManagerToDepartment = async (
  managerDepartment: AssignDepartmentManagerPayload
) => {
  const { data } = await axios.post(
    `/${DEPARTMENTS.ADD_MANAGER}`,
    managerDepartment
  );

  return data;
};

export const getDepartmentManager = async (
  id: number,
  page: number,
  searchKey: string,
  size: number
): Promise<ApiResponse<User[]>> => {
  const { data } = await axios.get(
    `/${DEPARTMENTS.DEPARTMENT}/${id}/users?page=${page}&search-key=${searchKey}&size=${size}`
  );

  return data;
};

export const assignDepartmentToUsers = async (
  userDepartment: AssignUserDepartmentPayload
) => {
  const { data } = await axios.post(
    `/${DEPARTMENTS.ADD_USERS}`,
    userDepartment
  );

  return data;
};

export const getDepartmentUsers = async (
  id: number,
  page: number,
  searchKey: string,
  size: number
): Promise<ApiResponse<DepartmentUser[]>> => {
  const { data } = await axios.get(
    `/${DEPARTMENTS.DEPARTMENT}/${id}/users?page=${page}&search-key=${searchKey}&size=${size}`
  );

  return data;
};

export const deleteUserDepartmentAssignment = async (id: number) => {
  const { data } = await axios.delete(
    `/${DEPARTMENTS.DEPARTMENT}/${id}/delete`
  );

  return data;
};
