import { useQuery } from "@tanstack/react-query";

import { getAllUsersOrganization } from "../../api/organizations/users";

export const useAllUsersOrganization = (
  organizationId: number,
  page: number,
  searchKey: string,
  size: number
) => {
  return useQuery(
    ["organizationUsers", organizationId, page, searchKey, size],
    () => getAllUsersOrganization(organizationId, page, searchKey, size)
  );
};
