import { IMAGE_MIME_TYPES, AUDIO_MIME_TYPES, VIDEO_MIME_TYPES } from "consts";

export const generateRandomString = (length: number) => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
};

export const convertEmailToUsername = (email: string) => {
  return email.split("@")[0];
};

export const mapMimeTypeToFileType = (type: "image" | "video" | "audio") => {
  switch (type) {
    case "image":
      return IMAGE_MIME_TYPES;
    case "video":
      return VIDEO_MIME_TYPES;
    case "audio":
      return AUDIO_MIME_TYPES;
    default:
      throw new Error("Invalid file type");
  }
};
