import {
  Box,
  Button,
  Group,
  MultiSelect,
  Select,
  Space,
  Textarea,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/hooks";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ErrorCode } from "../../enums/api/ErrorCode";
import { Color } from "enums/common";
import { TemplateTypes } from "../../enums/templates/templateTypes";
import { ApiError } from "../../types/api/ApiError";
import { Template } from "../../types/template/template";

export interface UpdateTemplateProps {
  template: Template;
  mutation: any;
}

export const UpdateTemplatePage: React.FC<UpdateTemplateProps> = ({
  template,
  mutation,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  // const [logo, setLogo] = useState<File | undefined>();
  const [keywords, setKeywords] = useState([""]);
  const [skills, setSkills] = useState([""]);

  const typesOptions = [
    {
      value: TemplateTypes.CHATBOT.toString(),
      label: "Chatbot",
    },
    {
      value: TemplateTypes.CHATBOT_V2.toString(),
      label: "Chatbot v2.0",
    },
  ];

  const form = useForm({
    initialValues: {
      title: template.title,
      description: template.description,
      type: template.type ? template.type.id.toString() : undefined,
      keywordList: keywords,
      skillList: skills,
      organizationId: template.organizationId
        ? template.organizationId.toString()
        : undefined,
    },
  });

  useEffect(() => {
    setSkills(template.skillList ? template.skillList : []);
    setKeywords(template.keywordList ? template.keywordList : []);
  }, [template.keywordList, template.skillList]);

  const handleClose = () => {
    // setLogo(undefined);
    form.reset();
  };

  const handleSubmit = () => {
    mutation.mutate(
      {
        ...form.values,
        id: template.id,
        coverImage: "",
        type: Number(form.values.type),
        keywordList: keywords.filter((e) => e),
        skillList: skills.filter((e) => e),
      },
      {
        onSuccess: () => {
          handleClose();
          navigate("/exercises/all");
        },
        onError: (error: AxiosError<ApiError>) => {
          if (
            error.response?.data.errorCode === ErrorCode.NAME_ALREADY_EXISTS
          ) {
            form.setFieldError("title", t("Templates:errors.nameExists"));
          }
        },
      }
    );
  };

  return (
    <Box sx={{ maxWidth: 800 }} mx="auto">
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <TextInput
          required
          label={t("Templates:table.name")}
          placeholder={t("Templates:table.name")}
          {...form.getInputProps("title")}
        />
        <Textarea
          required
          label={t("Templates:table.summary")}
          placeholder={t("Templates:table.summary")}
          mb={3}
          {...form.getInputProps("description")}
        />
        <Space h="md" />
        <Select
          label={t("Templates:table.type")}
          placeholder={t("Templates:table.type")}
          data={typesOptions}
          styles={{
            input: {
              fontFamily: "Montserrat",
              color: Color.DARK,
              borderColor: Color.DARK,
              borderWidth: 1,
              fontSize: 14,
              "&:focus": {
                borderColor: Color.DARK,
                borderWidth: 1,
              },
            },
          }}
          {...form.getInputProps("type")}
        />
        <Space h="md" />
        <MultiSelect
          data={[]}
          value={keywords.filter(Boolean)}
          onChange={setKeywords}
          label={t("Templates:table.keywords")}
          placeholder={t("Templates:table.keywords")}
          searchable
          creatable
          getCreateLabel={(query) => `+ ${t("Templates:create")} ${query}`}
          styles={() => ({
            input: {
              fontSize: 14,
              color: Color.DARK,
              fontFamily: "Montserrat",
              borderColor: Color.DARK,
              borderWidth: 1,
              backgroundColor: Color.WHITE,
              "&:focus": {
                borderColor: Color.DARK,
                borderWidth: 1,
              },
            },
          })}
        />
        <Space h="md" />
        <MultiSelect
          data={[]}
          value={skills.filter(Boolean)}
          onChange={setSkills}
          label={t("Templates:table.skills")}
          placeholder={t("Templates:table.skills")}
          searchable
          creatable
          getCreateLabel={(query) => `+ ${t("Templates:create")} ${query}`}
          styles={() => ({
            input: {
              fontSize: 14,
              color: Color.DARK,
              fontFamily: "Montserrat",
              borderColor: Color.DARK,
              borderWidth: 1,
              backgroundColor: Color.WHITE,
              "&:focus": {
                borderColor: Color.DARK,
                borderWidth: 1,
              },
            },
          })}
        />
        <Space h="md" />
        {/* <ImageUpload logo={logo} setLogo={setLogo} /> */}
        <Group position="right" mt="md">
          <Button
            data-testid="submit-button"
            type="submit"
            styles={(theme) => ({
              root: {
                fontSize: 16,
                fontFamily: "Montserrat",
                backgroundColor: Color.DARK,
                fontWeight: 600,

                "&:hover": {
                  backgroundColor: theme.fn.lighten(Color.DARK, 0.1),
                },
              },
            })}
          >
            {t("Common:actions.submit")}
          </Button>
        </Group>
      </form>
    </Box>
  );
};
