import { Space } from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";
import { useState } from "react";
import { Roles } from "../../../enums/users/Role";
import { useUserManagements } from "../../../hooks/UserManagement/useUserManagements";
import { Pagination } from "../../common";
import { UserManagementMembersMenu } from "./UserManagementMembersMenu";
import { UserManagementMembersTable } from "./UserManagementMembersTable";

export const UserManagerMembersTab: React.FC = () => {
  const [page, setPage] = useState(1);
  const [searchKey, setSearchKey] = useState("");
  const [debouncedSearchKey] = useDebouncedValue(searchKey, 300);
  const [resultCount, setResultCount] = useState(20);

  const { data } = useUserManagements(
    page,
    debouncedSearchKey,
    resultCount,
    Number(Roles.MEMBER)
  );

  return (
    <>
      <Space h="lg" />
      <UserManagementMembersMenu
        setSearchKey={setSearchKey}
        searchKey={searchKey}
      />
      <Space h="md" />
      {data && (
        <>
          <UserManagementMembersTable members={data.elements} />
          <Pagination
            page={page}
            totalItems={data.total}
            itemsPerPage={resultCount}
            setItemsPerPage={setResultCount}
            setPage={setPage}
          />
        </>
      )}
    </>
  );
};
