import { Button, Group, Modal, Select, Space } from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";
import { AxiosError } from "axios";
import { Pagination } from "components/common";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ErrorCode } from "../../../enums/api/ErrorCode";
import { Color } from "../../../enums/common";
import { useOrganizationCourses } from "../../../hooks/courses/useCourses";
import { useAllOrganizations } from "../../../hooks/organizations/useAllOrganizations";
import { useAllUsersOrganization } from "../../../hooks/organizations/useAllUsersOrganization";
import { ApiError } from "../../../types/api/ApiError";
import { CoursesObject } from "../../../types/courses/courses";
import { Organization } from "../../../types/organizations/Organization";
import { UsersObject } from "../../../types/user/User";
import { AssignCoursesMenu } from "./assignCoursesMenu";
import { AssignCoursesTable } from "./AssignCoursesTable";
import { AssignUsersMenu } from "./assignUsersMenu";
import { AssignUsersTable } from "./AssignUsersTable";

export interface AssignCourseToUserModalProps {
  mutation: any;
  opened: boolean;
  onClose: () => void;
}

export const AssignCourseToUserModal: React.FC<
  AssignCourseToUserModalProps
> = ({ opened, mutation, onClose }) => {
  const { t } = useTranslation();

  const [selectedUsers, setSelectedUsers] = useState<UsersObject[]>([]);
  const [selectedCourses, setSelectedCourses] = useState<CoursesObject[]>([]);
  const [usersPage, setUsersPage] = useState(1);
  const [usersResultCount, setUsersResultCount] = useState(15);
  const [coursesPage, setCoursesPage] = useState(1);
  const [coursesResultCount, setCoursesResultCount] = useState(15);
  const [usersSearchKey, setUsersSearchKey] = useState("");
  const [debouncedUsersSearchKey] = useDebouncedValue(usersSearchKey, 300);
  const [coursesSearchKey, setCoursesSearchKey] = useState("");
  const [debouncedCoursesSearchKey] = useDebouncedValue(coursesSearchKey, 300);
  const [categoryFilter, setCategoryFilter] = useState<string>("");

  const [organizationFilter, setOrganizationFilter] = useState<string>("");

  const organizationQuery = useAllOrganizations();

  const organizationOptions = organizationQuery.data
    ? organizationQuery.data.map((organization: Organization) => ({
        value: organization.id.toString(),
        label: organization.name,
      }))
    : [];

  const organizationId = Number(organizationFilter);

  const { data } = useOrganizationCourses(
    organizationId,
    coursesPage,
    debouncedCoursesSearchKey,
    coursesResultCount,
    categoryFilter
  );

  const AssignCourseTitle = styled.div`
    color: ${Color.DARK};
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 10px;
  `;

  const Label = styled.label`
    color: ${Color.DARK};
    font-size: 14px;
    font-weight: 500;
    margin-bottom: -30 !important;
  `;

  const handleOrganizationChange = (organizationId: string | null) => {
    if (organizationId) setOrganizationFilter(organizationId);
  };

  const organizationUsers = useAllUsersOrganization(
    organizationId,
    usersPage,
    debouncedUsersSearchKey,
    usersResultCount
  );

  const handleClose = () => {
    onClose();
  };

  const handleUserSelection = (user: UsersObject) => {
    if (!user.checked) {
      setSelectedUsers(
        selectedUsers.filter((selectedUser) => selectedUser.id !== user.id)
      );
    } else {
      setSelectedUsers((current) => [...current, user]);
    }
  };

  const handleCourseSelection = (course: CoursesObject) => {
    if (!course.checked) {
      setSelectedCourses(
        selectedCourses.filter(
          (selectedCourse) => selectedCourse.id !== course.id
        )
      );
    } else {
      setSelectedCourses((current) => [...current, course]);
    }
  };

  const handleSubmit = () => {
    mutation.mutate(
      {
        userIds: selectedUsers?.map((user) => user.id),
        itemIds: selectedCourses?.map((course) => course.id),
      },
      {
        onSuccess: () => {
          handleClose();
          setOrganizationFilter("");
          setSelectedCourses([]);
          setSelectedUsers([]);
        },
        onError: (error: AxiosError<ApiError>) => {
          if (
            error.response?.data.errorCode === ErrorCode.NAME_ALREADY_EXISTS
          ) {
            console.log("Something went wrong!");
          }
        },
      }
    );
  };

  return (
    <Modal
      centered
      data-testid="create-modal"
      size={1200}
      opened={opened}
      onClose={handleClose}
    >
      <>
        <AssignCourseTitle>{t("AssignCourse:title")}</AssignCourseTitle>
        <Space h="md" />
        <Label>{t("AssignCourse:organization")}</Label>
        {organizationOptions.length && (
          <Select
            placeholder={t("AssignCourse:organization")}
            data={organizationOptions}
            onChange={(organizationId) =>
              handleOrganizationChange(organizationId)
            }
            styles={{
              input: {
                fontFamily: "Montserrat",
                color: Color.DARK,
                borderColor: Color.DARK,
                borderWidth: 1,
                marginTop: 5,
                fontSize: 14,
                "&:focus": {
                  borderColor: Color.DARK,
                  borderWidth: 1,
                },
              },
            }}
          />
        )}
        <Space h="md" />
        <Label>{t("AssignCourse:selectUsers")}</Label>
        <Space h="md" />
        <AssignUsersMenu
          searchKey={usersSearchKey}
          setSearchKey={setUsersSearchKey}
        />
        {organizationUsers.data && (
          <>
            <AssignUsersTable
              organizationUsers={organizationUsers.data.elements}
              selectedOrganization={Number(organizationFilter)}
              setSelectedUsers={handleUserSelection}
            />
            <Space h="xl" />
            <Pagination
              page={usersPage}
              totalItems={organizationUsers.data.total}
              itemsPerPage={usersResultCount}
              setItemsPerPage={setUsersResultCount}
              setPage={setUsersPage}
            />
          </>
        )}
        <Space h="md" />
        <Label>{t("AssignCourse:selectCourses")}</Label>
        <Space h="md" />
        <AssignCoursesMenu
          searchKey={coursesSearchKey}
          categoryFilter={categoryFilter}
          setSearchKey={setCoursesSearchKey}
          setCategoryFilter={setCategoryFilter}
        />
        {data && (
          <>
            <AssignCoursesTable
              courses={data.elements}
              selectedCourses={selectedCourses}
              setSelectedCourses={handleCourseSelection}
            />

            <Space h="xl" />
            <Pagination
              page={coursesPage}
              totalItems={data.total}
              itemsPerPage={coursesResultCount}
              setItemsPerPage={setCoursesResultCount}
              setPage={setCoursesPage}
            />
          </>
        )}
        <Space h="xl" />
        <Group position="right" mt="md">
          <Button
            data-testid="submit-button"
            type="submit"
            onClick={handleSubmit}
            styles={(theme) => ({
              root: {
                fontSize: 16,
                fontFamily: "Montserrat",
                backgroundColor: Color.DARK,
                fontWeight: 600,

                "&:hover": {
                  backgroundColor: theme.fn.lighten(Color.DARK, 0.1),
                },
              },
            })}
          >
            {t("Common:actions.submit")}
          </Button>
        </Group>
      </>
    </Modal>
  );
};
