import { Avatar, Group, Menu, Space, Text } from "@mantine/core";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import { Pencil, Trash } from "tabler-icons-react";
import { Color } from "enums/common";
import { Quiz } from "../../../../types/chapters/chapterDetails";
import { Page } from "../../../../types/chapters/chapterpages";
import { Exercise } from "../../../../types/exercises/exercises";
import { useChapterPages } from "hooks/chapters/chapterpages/useChapterPages";

const SmallCircle = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: ${({ color }: CircleProps) => color};
`;

const AccordionContainer = styled.div`
  flex: 1;
`;

interface CircleProps {
  color: string;
}

export interface AccordionPagesProps {
  chapterId: number;
  quiz?: Quiz;
  exercises: Exercise[];
  onDeleteChapterPage: (page: Page) => void;
  onDeleteQuiz: (quiz: Quiz) => void;
  onDeleteChapterExercise: (exercise: Exercise) => void;
}

export const AccordionPages: React.FC<AccordionPagesProps> = ({
  chapterId,
  quiz,
  exercises,
  onDeleteChapterPage,
  onDeleteQuiz,
  onDeleteChapterExercise,
}) => {
  const { t } = useTranslation();
  const { id } = useParams();

  const { data } = useChapterPages(chapterId);

  return (
    <>
      <Text weight={500} size="xl" color="#2B1467;">
        Pages
      </Text>
      <Space h="sm" />
      {data &&
        data &&
        data.map((page, index) => (
          <Fragment key={index}>
            <Group>
              <Menu
                control={
                  <Group
                    data-testid="menu-control"
                    spacing={3}
                    style={{ cursor: "pointer" }}
                  >
                    <SmallCircle color={Color.DARK} />
                    <SmallCircle color={Color.DARK} />
                    <SmallCircle color={Color.DARK} />
                  </Group>
                }
              >
                <Menu.Item
                  component={Link}
                  to={`/courses/${id}/chapters/${chapterId}/editpage/${page.pageId}`}
                  icon={<Pencil size={14} />}
                >
                  {t("Common:edit")}
                </Menu.Item>
                <Menu.Item
                  icon={<Trash size={14} />}
                  onClick={() => onDeleteChapterPage(page)}
                >
                  {t("Common:delete")}
                </Menu.Item>
              </Menu>
              <Avatar
                src="../images/defavatar.png"
                size="lg"
                sx={{ marginBottom: 10 }}
              />
              <AccordionContainer>
                <Text
                  styles={() => ({
                    root: {
                      fontSize: 16,
                      fontWeight: 500,
                      color: Color.DARK,
                      marginTop: -8,
                    },
                  })}
                >
                  {page.pageTitle}
                </Text>
                <Text size="sm" color="#2B1467;" weight={400}>
                  {page.pageSummary}
                </Text>
              </AccordionContainer>
            </Group>
          </Fragment>
        ))}
      {quiz && (
        <>
          <Group>
            <Menu
              control={
                <Group
                  data-testid="menu-control"
                  spacing={3}
                  style={{ cursor: "pointer" }}
                >
                  <SmallCircle color={Color.DARK} />
                  <SmallCircle color={Color.DARK} />
                  <SmallCircle color={Color.DARK} />
                </Group>
              }
            >
              <Menu.Item
                component={Link}
                to={`/courses/${id}/chapters/${chapterId}/quiz/${quiz.quizId}`}
                icon={<Pencil size={14} />}
              >
                {t("Common:edit")}
              </Menu.Item>
              <Menu.Item
                icon={<Trash size={14} />}
                onClick={() => onDeleteQuiz(quiz)}
              >
                {t("Common:delete")}
              </Menu.Item>
            </Menu>
            <Avatar
              src="../images/defavatar.png"
              size="lg"
              sx={{ marginBottom: 10 }}
            />
            <AccordionContainer>
              <Text
                styles={() => ({
                  root: {
                    fontSize: 16,
                    fontWeight: 500,
                    color: Color.DARK,
                    marginTop: -8,
                  },
                })}
              >
                {t("Chapters:quiz.title")}
              </Text>
            </AccordionContainer>
          </Group>
        </>
      )}
      {exercises &&
        exercises.map((exercise, index) => {
          return (
            <Fragment key={index}>
              <Group>
                <Menu
                  control={
                    <Group
                      data-testid="menu-control"
                      spacing={3}
                      style={{ cursor: "pointer" }}
                    >
                      <SmallCircle color={Color.DARK} />
                      <SmallCircle color={Color.DARK} />
                      <SmallCircle color={Color.DARK} />
                    </Group>
                  }
                >
                  <Menu.Item
                    icon={<Trash size={14} />}
                    onClick={() => onDeleteChapterExercise(exercise)}
                  >
                    {t("Common:delete")}
                  </Menu.Item>
                </Menu>
                <Avatar
                  src="../images/defavatar.png"
                  size="lg"
                  sx={{ marginBottom: 10 }}
                />
                <AccordionContainer>
                  <Text
                    styles={() => ({
                      root: {
                        fontSize: 16,
                        fontWeight: 500,
                        color: Color.DARK,
                        marginTop: -8,
                      },
                    })}
                  >
                    {exercise.title}
                  </Text>
                  <Text size="sm" color="#2B1467;" weight={400}>
                    {exercise.description}
                  </Text>
                </AccordionContainer>
              </Group>
            </Fragment>
          );
        })}
    </>
  );
};
