import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteUser } from "api/organizations/users/users";
import i18n from "i18n";
import {
  endNotification,
  generateRandomString,
  startNotification,
} from "utils/common";

const useDeleteUser = () => {
  const queryClient = useQueryClient();
  const randomId = generateRandomString(20);

  return useMutation((id: number) => deleteUser(id), {
    onMutate: () => {
      startNotification(randomId);
    },
    onSuccess: () => {
      endNotification(
        randomId,
        i18n.t("notifications.deleteSuccess", { ns: "Users" }),
        true
      );
      queryClient.invalidateQueries(["userDetails"]);

      queryClient.invalidateQueries(["users"]);
    },
    onError: (error) => {
      console.error("Error deleting user:", error);
    },
  });
};

export default useDeleteUser;
