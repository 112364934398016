import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Pagination as MantinePagination, NativeSelect } from "@mantine/core";

import { Color } from "enums/common";

const PaginationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
`;

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${Color.DARK};
  font-size: 16px;
  font-weight: 400;
  margin-right: 10px;
`;

export interface PaginationProps {
  totalItems: number;
  itemsPerPage: number;
  page: number;
  setPage: (page: number) => void;
  setItemsPerPage: (itemsPerPage: number) => void;
}

export const Pagination: React.FC<PaginationProps> = ({
  totalItems,
  itemsPerPage,
  page,
  setPage,
  setItemsPerPage,
}) => {
  const { t } = useTranslation("Common");

  const start = (page - 1) * itemsPerPage + 1;
  const currentMaximum = page * itemsPerPage;
  const end = currentMaximum < totalItems ? currentMaximum : totalItems;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handleItemsPerPageChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setPage(1);

    setItemsPerPage(Number(event.currentTarget.value));
  };

  return (
    <PaginationContainer data-testid="pagination">
      <TextContainer>
        {t("pagination", { start: start, end: end, total: totalItems })}
      </TextContainer>
      <MantinePagination
        page={page}
        total={totalPages || 1}
        onChange={setPage}
      />
      <NativeSelect
        value={itemsPerPage}
        onChange={(event) => handleItemsPerPageChange(event)}
        data={[
          {
            value: "15",
            label: `15 / ${t("page").toLowerCase()}`,
          },
          {
            value: "30",
            label: `30 / ${t("page").toLowerCase()}`,
          },
          {
            value: "50",
            label: `50 / ${t("page").toLowerCase()}`,
          },
        ]}
        styles={{
          wrapper: {
            marginLeft: 10,
          },
          input: {
            fontFamily: "Montserrat",
            fontWeight: 700,
            color: Color.DARK,
          },
        }}
      />
    </PaginationContainer>
  );
};
