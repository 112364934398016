import {
  Box,
  Space,
  Textarea,
  TextInput,
  Group,
  Button,
  Text,
  Image,
  Menu,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { Trash } from "tabler-icons-react";
import { Color } from "enums/common";
import { ApiError } from "../../../types/api/ApiError";
import { ErrorCode } from "enums/api";
import { Resource } from "types/resources/Resource";

const SmallCircle = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ color }: CircleProps) => color};
`;

const StyledDots = styled.div`
  padding: 18px;
`;

const StyledContainer = styled.div`
  display: flex;
  width: 600px;
`;

const StyledRow = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const StyledCol = styled.div`
  padding: 16px;
  background-color: #e3e1ea;
  flex: 0 0 auto;
`;

interface CircleProps {
  color: string;
}

export interface CreateChapterPageFormProps {
  mutation: any;
  id: number;
  setIsVideoResourcesModalOpen: (isModalOpen: boolean) => void;
  setIsImageResourcesModalOpen: (isModalOpen: boolean) => void;
  setIsAudioResourcesModalOpen: (isModalOpen: boolean) => void;
  selectedImage: Resource | undefined;
  selectedVideo: Resource | undefined;
  selectedAudio: Resource | undefined;
  setSelectedVideo: React.Dispatch<React.SetStateAction<Resource | undefined>>;
  setSelectedImage: React.Dispatch<React.SetStateAction<Resource | undefined>>;
  setSelectedAudio: React.Dispatch<React.SetStateAction<Resource | undefined>>;
}

export const CreateChapterPageForm: React.FC<CreateChapterPageFormProps> = ({
  mutation,
  id,
  selectedImage,
  selectedVideo,
  selectedAudio,
  setSelectedVideo,
  setIsVideoResourcesModalOpen,
  setIsImageResourcesModalOpen,
  setIsAudioResourcesModalOpen,
  setSelectedImage,
  setSelectedAudio,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { chapterId } = useParams();

  const form = useForm({
    initialValues: {
      chapterId: chapterId,
      text: "",
      title: "",
    },
  });

  const handleSubmit = () => {
    mutation.mutate(
      {
        ...form.values,
        resourceIds: [
          selectedImage?.id,
          selectedAudio?.id,
          selectedVideo?.id,
        ].filter(Number),
      },
      {
        onSuccess: () => {
          navigate(`/courses/${id}`);
        },
        onError: (error: AxiosError<ApiError>) => {
          if (
            error.response?.data.errorCode === ErrorCode.NAME_ALREADY_EXISTS
          ) {
            form.setFieldError("title", t("Courses:errors.nameExists"));
          }
        },
      }
    );
  };

  return (
    <Box sx={{ maxWidth: 800 }} mx="auto">
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <TextInput
          size="sm"
          required
          label={t("Chapters:createChapter.pageName")}
          placeholder={t("Chapters:createChapter.nameOfPage")}
          styles={() => ({
            label: {
              fontSize: 16,
              fontWeight: 600,
              color: Color.DARK,
            },
            input: {
              fontSize: 14,
              color: Color.DARK,
              fontFamily: "Montserrat",
              borderColor: Color.DARK,
              borderWidth: 1,
              backgroundColor: Color.WHITE,
              "&:focus": {
                borderColor: Color.DARK,
                borderWidth: 1,
              },
            },
          })}
          {...form.getInputProps("title")}
        />
        <Space h="md" />
        <Textarea
          required
          label={t("Chapters:createChapter.pageText")}
          placeholder={t("Chapters:createChapter.pageSummary")}
          mb={3}
          styles={() => ({
            label: {
              fontSize: 16,
              fontWeight: 600,
              color: Color.DARK,
            },
            input: {
              fontSize: 14,
              color: Color.DARK,
              fontFamily: "Montserrat",
              borderColor: Color.DARK,
              borderWidth: 1,
              backgroundColor: Color.WHITE,
              "&:focus": {
                borderColor: Color.DARK,
                borderWidth: 1,
              },
            },
          })}
          {...form.getInputProps("text")}
        />
        <Space h="xl" />
        {selectedImage?.originalName && (
          <>
            <StyledContainer>
              <StyledRow>
                <Image src={selectedImage.resourceUrl} width={60} height={60} />
                <StyledCol>
                  <Text>{selectedImage?.originalName}</Text>
                </StyledCol>
              </StyledRow>
              <StyledDots>
                <Menu
                  control={
                    <Group
                      data-testid="menu-control"
                      spacing={3}
                      style={{ cursor: "pointer" }}
                    >
                      <SmallCircle color={Color.DARK} />
                      <SmallCircle color={Color.DARK} />
                      <SmallCircle color={Color.DARK} />
                    </Group>
                  }
                >
                  <Menu.Item
                    onClick={() => setSelectedImage(undefined)}
                    icon={<Trash size={14} />}
                  >
                    {t("Common:delete")}
                  </Menu.Item>
                </Menu>
              </StyledDots>
            </StyledContainer>
          </>
        )}
        <Space h="xs" />
        {selectedVideo?.originalName && (
          <>
            <StyledContainer>
              <StyledRow>
                <Image src="../../../../images/videoavatar.png" width={60} />
                <StyledCol>
                  <Text>{selectedVideo?.originalName}</Text>
                </StyledCol>
              </StyledRow>
              <StyledDots>
                <Menu
                  control={
                    <Group
                      data-testid="menu-control"
                      spacing={3}
                      style={{ cursor: "pointer" }}
                    >
                      <SmallCircle color={Color.DARK} />
                      <SmallCircle color={Color.DARK} />
                      <SmallCircle color={Color.DARK} />
                    </Group>
                  }
                >
                  <Menu.Item
                    onClick={() => setSelectedVideo(undefined)}
                    icon={<Trash size={14} />}
                  >
                    {t("Common:delete")}
                  </Menu.Item>
                </Menu>
              </StyledDots>
            </StyledContainer>
          </>
        )}
        <Space h="xs" />
        {selectedAudio?.originalName && (
          <>
            <StyledContainer>
              <StyledRow>
                <Image src="../../../../images/quiz-avatar.png" width={60} />
                <StyledCol>
                  <Text>{selectedAudio?.originalName}</Text>
                </StyledCol>
              </StyledRow>
              <StyledDots>
                <Menu
                  control={
                    <Group
                      data-testid="menu-control"
                      spacing={3}
                      style={{ cursor: "pointer" }}
                    >
                      <SmallCircle color={Color.DARK} />
                      <SmallCircle color={Color.DARK} />
                      <SmallCircle color={Color.DARK} />
                    </Group>
                  }
                >
                  <Menu.Item
                    onClick={() => setSelectedAudio(undefined)}
                    icon={<Trash size={14} />}
                  >
                    {t("Common:delete")}
                  </Menu.Item>
                </Menu>
              </StyledDots>
            </StyledContainer>
          </>
        )}
        <Space h="xl" />
        <Group spacing="xl" grow>
          <Button
            onClick={() => {
              setIsVideoResourcesModalOpen(true);
            }}
            variant="outline"
            styles={() => ({
              root: {
                borderColor: Color.DARK,
                color: Color.DARK,
              },
            })}
          >
            {t("Chapters:createChapter.video")}
          </Button>
          <Button
            onClick={() => {
              setIsImageResourcesModalOpen(true);
            }}
            variant="outline"
            styles={() => ({
              root: {
                borderColor: Color.DARK,
                color: Color.DARK,
              },
            })}
          >
            {t("Chapters:createChapter.image")}
          </Button>
          <Button
            onClick={() => {
              setIsAudioResourcesModalOpen(true);
            }}
            variant="outline"
            styles={() => ({
              root: {
                borderColor: Color.DARK,
                color: Color.DARK,
              },
            })}
          >
            {t("Chapters:createChapter.audio")}
          </Button>
        </Group>
        <Space h="xl" />
        <Group position="right" mt="md">
          <Button
            data-testid="submit-button"
            type="submit"
            size="sm"
            styles={(theme) => ({
              root: {
                fontSize: 16,
                fontFamily: "Montserrat",
                backgroundColor: Color.DARK,
                fontWeight: 600,

                "&:hover": {
                  backgroundColor: theme.fn.lighten(Color.DARK, 0.1),
                },
              },
            })}
          >
            {t("Courses:createCourse.submitChapter")}
          </Button>
        </Group>
      </form>
    </Box>
  );
};
