import React from "react";

export const ExpandMenu: React.FC = () => {
  return (
    <svg
      data-testid="expand-menu"
      width="24"
      height="14"
      viewBox="0 0 24 14"
      fill="none"
    >
      <path d="M2 2L12 11L22 2" stroke="white" strokeWidth="3" />
    </svg>
  );
};
