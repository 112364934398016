import { useTranslation } from "react-i18next";
import { AxiosError } from "axios";
import {
  Modal,
  Group,
  Button,
  Box,
  Textarea,
  Select,
  Space,
  Text,
  Radio,
  RadioGroup,
} from "@mantine/core";
import { useForm } from "@mantine/form";

import { ApiError } from "../../types/api/ApiError";

import { ErrorCode } from "../../enums/api/ErrorCode";
import { Department } from "../../types/department/Department";
import { Color } from "enums/common";

export interface InviteUsersModalProps {
  departments: Department[];
  organizationId: number;
  opened: boolean;
  mutation: any;
  onClose: () => void;
}

export const InviteUsersModal: React.FC<InviteUsersModalProps> = ({
  departments,
  organizationId,
  opened,
  mutation,
  onClose,
}) => {
  const { t } = useTranslation();

  const form = useForm({
    initialValues: {
      departmentId: "",
      emails: "",
      organizationId: organizationId,
      level: "1",
    },
  });

  const handleClose = () => {
    form.reset();
    onClose();
  };

  const handleSubmit = () => {
    // Prepare emails data
    const emails: Array<string> = form.values.emails
      .replace(/\s|\r?\n|\r/g, "")
      .toString()
      .split(",");

    mutation.mutate(
      {
        ...form.values,
        emails,
        level: +form.values.level,
      },
      {
        onSuccess: () => {
          handleClose();
        },
        onError: (error: AxiosError<ApiError>) => {
          if (
            error.response?.data.errorCode === ErrorCode.EMAIL_ALREADY_EXISTS
          ) {
            form.setFieldError("emails", t("Users:modals.existingEmail"));
          }
        },
      }
    );
  };

  const departmentOptions = departments.map((department: Department) => ({
    value: department.id.toString(),
    label: department.name.toString(),
  }));

  return (
    <Modal
      centered
      data-testid="create-modal"
      size="lg"
      title={t("Users:modals.invitePeople")}
      opened={opened}
      onClose={handleClose}
    >
      <Box>
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <Text>{t("Users:form.department.label")}</Text>
          {departmentOptions && (
            <Select
              placeholder={t("Users:form.department.placeholder")}
              data={departmentOptions}
              {...form.getInputProps("departmentId")}
              styles={{
                input: {
                  fontFamily: "Montserrat",
                  fontWeight: 600,
                  color: Color.DARK,
                  borderColor: Color.DARK,
                  borderWidth: 1,
                  marginTop: 5,
                },
              }}
            />
          )}
          <Space h="md" />
          <Text>{t("Users:modals.inviteEmails")}</Text>
          <Textarea
            placeholder={t("Users:modals.inviteEmailsPlaceholder")}
            label={t("Users:modals.inviteEmails")}
            description={t("Users:modals.inviteEmailsDesc")}
            {...form.getInputProps("emails")}
            autosize
            minRows={2}
            required
            styles={{
              input: {
                fontFamily: "Montserrat",
                fontWeight: 600,
                color: Color.DARK,
                borderColor: Color.DARK,
                borderWidth: 1,
                marginTop: 5,
              },
            }}
          />

          <Space h="md" />

          <RadioGroup
            label={"Level"}
            orientation="horizontal"
            {...form.getInputProps("level")}
            required
            styles={{
              label: { fontSize: 14, color: Color.DARK },
              radio: {
                fontSize: 14,
                color: Color.DARK,
              },
              inner: { fontSize: 14, color: Color.DARK },
              icon: { fontSize: 14, color: Color.DARK },
              radioWrapper: { fontSize: 14, color: Color.DARK },
            }}
          >
            <Radio value={"1"} label={t("Users:expertLevel.beginner")} />
            <Radio value={"2"} label={t("Users:expertLevel.intermediate")} />
            <Radio value={"3"} label={t("Users:expertLevel.advanced")} />
          </RadioGroup>

          <Group position="right" mt="md">
            <Button
              data-testid="submit-button"
              type="submit"
              styles={() => ({
                root: {
                  fontSize: 14,
                  fontFamily: "Montserrat",
                  fontWeight: 600,
                  backgroundColor: Color.DARK,

                  "&:hover": {
                    backgroundColor: Color.DARK,
                  },
                },
              })}
            >
              {t("Common:actions.submit")}
            </Button>
          </Group>
        </form>
      </Box>
    </Modal>
  );
};
