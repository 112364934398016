import React, { useState } from "react";
import { Skeleton } from "@mantine/core";

// Components
import { CoursesMenu } from "../CoursesMenu/CoursesMenu";
import { CoursesTable } from "../CoursesTable/CoursesTable";
import { Pagination } from "../../common";

// Hooks
import { useDebouncedValue } from "@mantine/hooks";
import { useCloneCourse } from "hooks/courses/useCloneCourse";
import { useCourses } from "hooks/courses/useCourses";
import { useDeleteCourse } from "hooks/courses/useDeleteCourse";

export const AllCoursesTab: React.FC = () => {
  const [searchKey, setSearchKey] = useState("");
  const [debouncedSearchKey] = useDebouncedValue(searchKey, 300);
  const [page, setPage] = useState(1);
  const [resultCount, setResultCount] = useState(15);

  const deleteCourseMutation = useDeleteCourse();

  const [categoryFilter, setCategoryFilter] = useState<string>("");

  const { data, isLoading } = useCourses(
    page,
    debouncedSearchKey,
    resultCount,
    categoryFilter
  );

  const cloneMutation = useCloneCourse();

  return (
    <>
      {isLoading && (
        <Skeleton height={550} radius="sm" style={{ marginTop: 30 }} />
      )}

      <CoursesMenu
        searchKey={searchKey}
        setSearchKey={setSearchKey}
        categoryFilter={categoryFilter}
        setCategoryFilter={setCategoryFilter}
      />
      {data && (
        <>
          <CoursesTable
            courses={data.elements}
            deleteMutation={deleteCourseMutation}
            cloneMutation={cloneMutation}
          />
          <Pagination
            page={page}
            totalItems={data.total}
            itemsPerPage={resultCount}
            setItemsPerPage={setResultCount}
            setPage={setPage}
          />
        </>
      )}
    </>
  );
};
