import React from "react";
import { useTranslation } from "react-i18next";
import { AxiosError } from "axios";
import {
  Modal,
  TextInput,
  Textarea,
  Group,
  Button,
  Space,
} from "@mantine/core";
import { useForm } from "@mantine/form";

import { ApiError } from "../../../types/api/ApiError";

import { ErrorCode } from "../../../enums/api/ErrorCode";
import { Color } from "enums/common";

export interface CreateModalProps {
  opened: boolean;
  mutation: any;
  setCreatedCategorySelected: (id: number) => void;
  onClose: () => void;
}

export const CreateCategoryModal: React.FC<CreateModalProps> = ({
  opened,
  mutation,
  onClose,
  setCreatedCategorySelected,
}) => {
  const { t } = useTranslation();

  const form = useForm({
    initialValues: {
      id: Number,
      name: "",
      summary: "",
    },
  });

  const handleClose = () => {
    form.reset();
    onClose();
  };

  const handleSubmit = () => {
    mutation.mutate(
      {
        ...form.values,
      },
      {
        onSuccess: (id: number) => {
          setCreatedCategorySelected(id);
          handleClose();
        },
        onError: (error: AxiosError<ApiError>) => {
          if (
            error.response?.data.errorCode === ErrorCode.NAME_ALREADY_EXISTS
          ) {
            form.setFieldError("name", t("Categories:errors.nameExists"));
          }
        },
      }
    );
  };

  return (
    <Modal
      centered
      data-testid="create-modal"
      size="lg"
      title={t("Courses:categoryModal.title")}
      opened={opened}
      onClose={handleClose}
    >
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <TextInput
          required
          label={t("Courses:categoryModal.name")}
          placeholder={t("Courses:categoryModal.name")}
          styles={() => ({
            input: {
              fontSize: 14,
              color: Color.DARK,
              fontFamily: "Montserrat",
              borderColor: Color.DARK,
              borderWidth: 1,
              backgroundColor: Color.WHITE,
              "&:focus": {
                borderColor: Color.DARK,
                borderWidth: 1,
              },
            },
          })}
          {...form.getInputProps("name")}
        />
        <Space h="md" />
        <Textarea
          required
          label={t("Courses:categoryModal.description")}
          placeholder={t("Courses:categoryModal.description")}
          styles={() => ({
            input: {
              fontSize: 14,
              color: Color.DARK,
              fontFamily: "Montserrat",
              borderColor: Color.DARK,
              borderWidth: 1,
              backgroundColor: Color.WHITE,
              "&:focus": {
                borderColor: Color.DARK,
                borderWidth: 1,
              },
            },
          })}
          mb={3}
          {...form.getInputProps("summary")}
        />
        <Space h="md" />
        <Group position="right" mt="md">
          <Button
            data-testid="submit-button"
            type="submit"
            styles={(theme) => ({
              root: {
                fontSize: 16,
                fontFamily: "Montserrat",
                backgroundColor: Color.DARK,
                fontWeight: 600,

                "&:hover": {
                  backgroundColor: theme.fn.lighten(Color.DARK, 0.1),
                },
              },
            })}
          >
            {t("Common:actions.submit")}
          </Button>
        </Group>
      </form>
    </Modal>
  );
};
