import React from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";
import { Box, Container, Skeleton } from "@mantine/core";

import { BasePage } from "../../components/common/BasePage/BasePage";
import { Banner } from "../../components/common";

import { useParams } from "react-router-dom";
import { useExercise } from "../../hooks/exercises/useExercise";
import { useUpdateExercise } from "../../hooks/exercises/useUpdateExercise";
import { EditChatBot3Form } from "containers/EditExercise/EditChatBot3Form";

const StyledBanner = styled(Banner)`
  margin: 34px 0 40px 0;
`;

export const EditExercise: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { data, isLoading } = useExercise(Number(id));
  const updateExerciseMutation = useUpdateExercise();

  return (
    <BasePage>
      <Container data-testid="exercises-page">
        <Box sx={{ maxWidth: 800 }} mx="auto">
          <StyledBanner title={t("Exercises:modals.edit")} text={""}>
            {isLoading && (
              <Skeleton height={550} radius="sm" style={{ marginTop: 30 }} />
            )}
            {data?.templateId === 3 && data && (
              <EditChatBot3Form
                exercise={data}
                mutation={updateExerciseMutation}
              />
            )}
          </StyledBanner>
        </Box>
      </Container>
    </BasePage>
  );
};
