import { useMutation } from "@tanstack/react-query";

import { queryClient } from "../../../App";
import i18n from "../../../i18n";

import { updateExercisePage } from "../../../api/exercises/exercisepages";

import { UpdateExercisePagesObject } from "../../../types/exercises/exercisepages";
import {
  endNotification,
  generateRandomString,
  startNotification,
} from "../../../utils/common";

export const useUpdateExercisePage = () => {
  const randomId = generateRandomString(20);

  return useMutation(
    (object: UpdateExercisePagesObject) => updateExercisePage(object),
    {
      onMutate: () => {
        startNotification(randomId);
      },
      onSuccess: () => {
        endNotification(
          randomId,
          i18n.t("Exercises:notifications.updatePageSuccess"),
          true
        );
        void queryClient.invalidateQueries(["exercise-page"]);
      },
      onError: () => {
        endNotification(
          randomId,
          i18n.t("Exercises:notifications.updatePageError"),
          false
        );
      },
    }
  );
};
