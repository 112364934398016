import React from "react";

import { useTranslation } from "react-i18next";

import { Container, Space, Text } from "@mantine/core";
import styled from "styled-components";

import { Banner, BasePage } from "../../../components/common";
import { Color } from "enums/common";
import { CreateQuizForm } from "../../../components/chapters/CreateQuizForm/CreateQuizForm";
import { useCreateChapterQuiz } from "../../../hooks/chapters/chapterquizes/useChapterQuizes";

const StyledBanner = styled(Banner)`
  margin: 34px 0 40px 0;
`;

export const CreateQuiz: React.FC = () => {
  const { t } = useTranslation();

  const createQuizMutation = useCreateChapterQuiz();

  return (
    <BasePage>
      <Space h="xl" />
      <Container>
        <StyledBanner title="" text="">
          <Text
            styles={() => ({
              root: {
                fontSize: 28,
                fontWeight: 600,
                fontFamily: "Montserrat",
                color: Color.DARK,
              },
            })}
          >
            {t("Chapters:quizPage.addQuiz")}
          </Text>
          <Space h="xl" />
          <CreateQuizForm mutation={createQuizMutation} />
        </StyledBanner>
      </Container>
    </BasePage>
  );
};
