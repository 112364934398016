import { Avatar, Group, Text } from "@mantine/core";
import styled from "styled-components";
import { Color } from "enums/common";
import { ExercisePages } from "../../../../../types/exercises/exercisepages";
import { RichTextContent } from "components/RichText";

const AccordionContainer = styled.div`
  flex: 1;
`;

export interface ExercisePagesProps {
  page: ExercisePages;
}

export function AccordionPageLabel({ page }: ExercisePagesProps) {
  return (
    <>
      <Group key={page.id}>
        <Avatar src="../images/defavatar.png" size="lg" />
        <AccordionContainer>
          <Text
            styles={() => ({
              root: {
                fontSize: 17,
                color: Color.DARK,
                fontWeight: 600,
              },
            })}
          >
            {page?.title}
          </Text>
          <Text
            styles={() => ({
              root: {
                fontSize: 15,
                fontWeight: 500,
                color: Color.DARK,
              },
            })}
          >
            <RichTextContent htmlContent={page?.slideText} />
          </Text>
        </AccordionContainer>
      </Group>
    </>
  );
}
