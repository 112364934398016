import { useMutation } from "@tanstack/react-query";

import i18n from "../../i18n";
import { queryClient } from "../../App";

import {
  startNotification,
  endNotification,
} from "../../utils/common/notifications";
import { generateRandomString } from "../../utils/common/commonUtils";

import { deleteDepartment } from "../../api/departments/departments";

export const useDeleteDepartment = () => {
  const randomId = generateRandomString(20);

  return useMutation((id: number) => deleteDepartment(id), {
    onMutate: () => {
      startNotification(randomId);
    },
    onSuccess: () => {
      endNotification(
        randomId,
        i18n.t("Departments:notifications.deleteSuccess"),
        true
      );
      queryClient.invalidateQueries(["departments"]);
    },
    onError: () => {
      endNotification(
        randomId,
        i18n.t("Departments:notifications.deleteError"),
        false
      );
    },
  });
};
