import {
  Button,
  Group,
  Radio,
  RadioGroup,
  Space,
  Textarea,
  Text,
  TextInput,
  Divider,
  NumberInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { AxiosError } from "axios";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { CircleX } from "tabler-icons-react";
import { ErrorCode } from "../../../enums/api/ErrorCode";
import { Color } from "enums/common";
import { ApiError } from "../../../types/api/ApiError";
import { v1 as uuidv1 } from "uuid";
import {
  endNotification,
  errorNotification,
} from "../../../utils/common/notifications";
import { generateRandomString } from "../../../utils/common/commonUtils";
import { Quiz, QuizDetail } from "../../../types/chapters/chapterDetails";
import { RichTextEditor } from "@mantine/rte";

const StyledAnswer = styled.div`
  display: flex;
  width: 100%;
`;

const StyledInput = styled.div`
  width: 87%;
  height: 100%;
`;

const StyledRadio = styled.div`
  width: 13%;
  height: 100%;
`;

const StyledAnswerButton = styled.div`
  flex: 30%;
  display: flex;
  justify-content: flex-end;
`;

export interface EditQuizFromProps {
  mutation: any;
  quiz: Quiz;
}

export const EditQuizFrom: React.FC<EditQuizFromProps> = ({
  mutation,
  quiz,
}) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { chapterId } = useParams();
  const navigate = useNavigate();

  const [questions, setQuestions] = useState<QuizDetail[]>([]);
  const [points, setPoints] = useState<number | undefined>(0);
  const [introduction, setIntroduction] = useState(quiz.introduction);

  useEffect(() => {
    setPoints(quiz.points);

    setIntroduction(quiz.introduction);

    if (quiz.quizDetails) {
      setQuestions(quiz.quizDetails);

      quiz.quizDetails.forEach((detail) => {
        detail.answers.forEach((answer) => {
          answer["key"] = uuidv1();
        });
      });
    }
  }, [quiz]);

  const form = useForm({
    initialValues: {
      chapterId: chapterId,
    },
  });

  // question stuff
  const handleAddQuestion = () => {
    var lastNumeration = questions[questions.length - 1].numeration;
    setQuestions([
      ...questions,
      {
        id: uuidv1(),
        question: "",
        answers: [
          { key: uuidv1(), answer: "", isCorrect: false, description: "" },
        ],
        numeration: lastNumeration + 1,
      },
    ]);
  };

  const questionValue = (questionKey: string | undefined) => {
    const questionValue = questions.filter((question) => {
      return question.id === questionKey;
    });
    return questionValue[0].question;
  };

  const setQuestionValue = (questionKey: string | undefined, value: string) => {
    var data = [...questions];

    var foundIndex = data.findIndex((x) => x.id === questionKey);
    data[foundIndex].question = value;

    setQuestions(data);
  };

  //remove question handler
  const handleRemoveQuestion = (questionId: string | undefined) => {
    let data = [...questions];
    data = data.filter((x) => x.id !== questionId);
    setQuestions(data);
  };

  // answer stuff
  const handleAddAnswer = (questionKey: string | undefined) => {
    var data = [...questions];

    // find question
    var foundIndex = data.findIndex((x) => x.id === questionKey);

    // add an answer
    data[foundIndex].answers = [
      ...data[foundIndex].answers,
      {
        key: uuidv1(),
        answer: "",
        isCorrect: false,
        description: "",
      },
    ];

    setQuestions(data);
  };

  //remove correct answer handler
  const handleRemoveAnswer = (
    questionKey: string | undefined,
    answerKey: string | undefined
  ) => {
    var data = [...questions];

    // find question
    var foundIndex = data.findIndex((x) => x.id === questionKey);

    // add an answer
    data[foundIndex].answers = data[foundIndex].answers.filter(
      (x) => x.key !== answerKey
    );

    setQuestions(data);
  };

  const setAnswerValue = (
    questionKey: string | undefined,
    answerKey: string | undefined,
    answerValue: string
  ) => {
    var data = [...questions];

    // find question
    var questionIndex = data.findIndex((x) => x.id === questionKey);

    // find answer index
    var answerIndex = data[questionIndex].answers.findIndex(
      (x) => x.key === answerKey
    );

    // assign value
    data[questionIndex].answers[answerIndex].answer = answerValue;

    setQuestions(data);
  };

  const answerValue = (
    questionKey: string | undefined,
    answerKey: string | undefined
  ) => {
    const question = questions.filter((question) => {
      return question.id === questionKey;
    });
    var answer = question[0].answers.filter((answer) => {
      return answer.key === answerKey;
    });
    return answer[0].answer;
  };

  // is correct
  const isCorrectValue = (
    questionKey: string | undefined,
    answerKey: string | undefined
  ) => {
    const question = questions.filter((question) => {
      return question.id === questionKey;
    });
    var answer = question[0].answers.filter((answer) => {
      return answer.key === answerKey;
    });
    return answer[0].isCorrect ? "true" : "false";
  };

  const setIsCorrectvalue = (
    questionKey: string | undefined,
    answerKey: string | undefined,
    isCorrectValue: string
  ) => {
    const isCorrect = isCorrectValue === "true" ? true : false;
    var data = [...questions];

    // find question
    var questionIndex = data.findIndex((x) => x.id === questionKey);

    // find answer index
    var answerIndex = data[questionIndex].answers.findIndex(
      (x) => x.key === answerKey
    );

    data[questionIndex].answers.forEach((answer) => {
      answer.isCorrect = false;
    });

    // assign value
    data[questionIndex].answers[answerIndex].isCorrect = isCorrect;

    setQuestions(data);
  };

  const answerDescription = (
    questionKey: string | undefined,
    answerKey: string | undefined
  ) => {
    const question = questions.filter((question) => {
      return question.id === questionKey;
    });
    var answer = question[0].answers.filter((answer) => {
      return answer.key === answerKey;
    });
    return answer[0].description;
  };

  const setAnswerDescription = (
    questionKey: string | undefined,
    answerKey: string | undefined,
    value: string
  ) => {
    var data = [...questions];

    // find question
    var questionIndex = data.findIndex((x) => x.id === questionKey);

    // find answer index
    var answerIndex = data[questionIndex].answers.findIndex(
      (x) => x.key === answerKey
    );
    // assign value
    data[questionIndex].answers[answerIndex].description = value;

    setQuestions(data);
  };

  const getNumeration = (questionKey: string | undefined) => {
    const questionValue = questions.filter((question) => {
      return question.id === questionKey;
    });
    return questionValue[0].numeration;
  };

  const setNumeration = (
    questionKey: string | undefined,
    value: number | undefined
  ) => {
    if (!value) return;

    var data = [...questions];

    var foundIndex = data.findIndex((x) => x.id === questionKey);
    data[foundIndex].numeration = value;

    setQuestions(data);
  };

  const isGuid = (value: string) => {
    var regex = /[a-f0-9]{8}(?:-[a-f0-9]{4}){3}-[a-f0-9]{12}/i;
    var match = regex.exec(value);
    return match != null;
  };

  const handleSubmit = () => {
    let questionsValidity = questions.map((question) => {
      return {
        id: question.id,
        hasCorrectAnswers: question.answers.some((answer) => answer.isCorrect),
        hasEmptyAnswers: question.answers.some(
          (answer) => answer.answer === ""
        ),
      };
    });

    questions.forEach((question) => {
      if (id && isGuid(question.id!)) delete question["id"];
      question.answers.forEach((answer) => {
        delete answer["key"];
      });
    });

    const hasCorrectAnswers = questionsValidity.some(
      (question) => question.hasCorrectAnswers
    );
    const hasEmptyAnswers = questionsValidity.some(
      (question) => question.hasEmptyAnswers
    );
    if (!hasCorrectAnswers || hasEmptyAnswers) {
      const randomId = generateRandomString(20);
      const message = hasEmptyAnswers
        ? t("Quiz:notifications.emptyAnswers")
        : t("Quiz:notifications.invalidQuestion");
      errorNotification(randomId, message);
      return;
    }

    mutation.mutate(
      {
        id: quiz.quizId,
        payload: {
          points: Number(points),
          quizDetails: questions,
          quizId: quiz.quizId,
          introduction: introduction,
        },
      },
      {
        onSuccess: () => {
          navigate(`/courses/${id}`);
        },
        onError: (error: AxiosError<ApiError>) => {
          questions.forEach((question) => {
            question.answers.forEach((answer) => {
              answer["key"] = uuidv1();
            });
          });
          if (
            error.response?.data.errorCode === ErrorCode.NAME_ALREADY_EXISTS
          ) {
            const randomId = generateRandomString(20);
            endNotification(
              randomId,
              t("Quiz:notifications.createError"),
              false
            );
          }
        },
      }
    );
  };

  return (
    <>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <NumberInput
          label={t("Quiz:question.pointsLabel")}
          value={points}
          onChange={(val) => setPoints(val)}
        />
        <Space h="xl" />

        <Text style={{ color: Color.DARK, fontWeight: 600 }}>
          {t("Exercises:createExercisePage.summary")}
        </Text>
        <RichTextEditor value={introduction} onChange={setIntroduction} />

        <Space h="xl" />
        {questions.map((question) => (
          <div key={question.id}>
            <Textarea
              value={questionValue(question.id)}
              onChange={(event) =>
                setQuestionValue(question.id, event.currentTarget.value)
              }
              label={
                <>
                  {t("Chapters:quizPage.addQuestion")}
                  <CircleX
                    style={{
                      marginBottom: -5,
                      marginLeft: 5,
                      cursor: "pointer",
                    }}
                    size={20}
                    strokeWidth={2}
                    color={"black"}
                    onClick={() => handleRemoveQuestion(question.id)}
                  />
                </>
              }
              placeholder={t("Chapters:quizPage.quizSummary")}
              mb={3}
            />
            <Space h="xl" />
            <NumberInput
              label={t("Quiz:question.numeration")}
              placeholder={t("Quiz:question.numerationPlaceholder")}
              value={getNumeration(question.id)}
              onChange={(val) => setNumeration(question.id, val)}
            />
            <Space h="xl" />
            {question.answers.map((answer) => (
              <Fragment key={answer.key}>
                <StyledAnswer>
                  <StyledInput>
                    <TextInput
                      onChange={(event) =>
                        setAnswerValue(
                          question.id,
                          answer.key,
                          event.currentTarget.value
                        )
                      }
                      label={
                        <>
                          {t("Chapters:quizPage.answer")}
                          <CircleX
                            style={{
                              marginBottom: -5,
                              marginLeft: 5,
                              cursor: "pointer",
                            }}
                            size={20}
                            strokeWidth={2}
                            color={"black"}
                            onClick={() =>
                              handleRemoveAnswer(question.id, answer.key)
                            }
                          />
                        </>
                      }
                      placeholder={t("Chapters:quizPage.correctSummary")}
                      value={answerValue(question.id, answer.key)}
                    />
                  </StyledInput>
                  <Space h="sm" />
                  <StyledRadio>
                    <RadioGroup
                      styles={() => ({
                        root: {
                          marginLeft: 10,
                          marginTop: 28,
                        },
                      })}
                      value={isCorrectValue(question.id, answer.key)}
                      onChange={(val) =>
                        setIsCorrectvalue(question.id, answer.key, val)
                      }
                      orientation="vertical"
                      required
                    >
                      <Radio
                        value="true"
                        label={
                          <>
                            <Text
                              styles={() => ({
                                root: {
                                  fontSize: 14,
                                  color: Color.DARK,
                                  marginLeft: -8,
                                },
                              })}
                            >
                              {t("Chapters:quizPage.correctAnswer")}
                            </Text>
                          </>
                        }
                      />
                    </RadioGroup>
                  </StyledRadio>
                </StyledAnswer>
                <Space h="xl" />
                <TextInput
                  label={t("Quiz:answer.description")}
                  placeholder={t("Quiz:answer.descriptionPlaceholder")}
                  value={answerDescription(question.id, answer.key)}
                  onChange={(event) =>
                    setAnswerDescription(
                      question.id,
                      answer.key,
                      event?.currentTarget.value
                    )
                  }
                />
                <Space h="xl" />
              </Fragment>
            ))}
            <Space h="sm" />
            <StyledAnswerButton>
              <Button
                styles={() => ({
                  root: {
                    fontFamily: "Montserrat",
                    backgroundColor: Color.DARK,

                    "&:hover": {
                      backgroundColor: Color.DARK,
                    },
                  },
                })}
                onClick={() => handleAddAnswer(question.id)}
              >
                {t("Chapters:quizPage.addAnswer")}
              </Button>
            </StyledAnswerButton>
            <Space h="xl" />
            <Divider size="sm" />
            <Space h="xl" />
          </div>
        ))}
        <Group>
          <Button
            styles={() => ({
              root: {
                fontFamily: "Montserrat",
                backgroundColor: Color.DARK,

                "&:hover": {
                  backgroundColor: Color.DARK,
                },
              },
            })}
            onClick={() => handleAddQuestion()}
          >
            {t("Chapters:quizPage.addQuestion")}
          </Button>
        </Group>
        <Space h="xl" />
        <Space h="xl" />
        <Group>
          <Button
            data-testid="submit-button"
            type="submit"
            styles={() => ({
              root: {
                fontFamily: "Montserrat",
                backgroundColor: Color.DARK,

                "&:hover": {
                  backgroundColor: Color.DARK,
                },
              },
            })}
          >
            {t("Chapters:quizPage.save")}
          </Button>
          <Button
            component={Link}
            to={`/courses/${id}`}
            variant="outline"
            styles={() => ({
              root: {
                fontFamily: "Montserrat",
                borderColor: Color.DARK,
                color: Color.DARK,
              },
            })}
          >
            {t("Chapters:quizPage.cancel")}
          </Button>
        </Group>
      </form>
    </>
  );
};
