import {
  Box,
  Button,
  Container,
  Grid,
  Group,
  Select,
  Space,
} from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";
import { BasePage, Pagination } from "components/common";
import { AssignExerciseUsersMenu } from "components/exercises/AssignExercise/AssignExerciseUsersMenu";
import { AssignExerciseUsersTable } from "components/exercises/AssignExercise/AssignExerciseUsersTable";
import { AssignExercisesMenu } from "components/exercises/AssignExercise/AssignExersisesMenu";
import { Color } from "enums/common";
import { useOrganizationExercises } from "hooks/exercises/useExercises";
import { useAllOrganizations } from "hooks/organizations/useAllOrganizations";
import { useAllUsersOrganization } from "hooks/organizations/useAllUsersOrganization";
import { useAssignUserExercise } from "hooks/userExercises/assignUsersToExercises";
import { AssignExercisesTable } from "pages/Chapters/AssignExerciseTable";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ExercisesObject } from "types/exercises/exercises";
import { Organization } from "types/organizations";
import { UsersObject } from "types/user/User";

const AssignExerciseTitle = styled.div`
  color: ${Color.DARK};
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 10px;
`;

const Label = styled.label`
  color: ${Color.DARK};
  font-size: 14px;
  font-weight: 500;
  margin-bottom: -30 !important;
`;

export const AssignExerciseToUserPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const assignCourseMutation = useAssignUserExercise();

  const [selectedUsers, setSelectedUsers] = useState<UsersObject[]>([]);
  const [selectedExercises, setSelectedExercises] = useState<ExercisesObject[]>(
    []
  );
  const [usersPage, setUsersPage] = useState(1);
  const [usersResultCount, setUsersResultCount] = useState(15);
  const [exercisesPage, setExercisesPage] = useState(1);
  const [exercisesResultCount, setExercisesResultCount] = useState(15);
  const [usersSearchKey, setUsersSearchKey] = useState("");
  const [debouncedUsersSearchKey] = useDebouncedValue(usersSearchKey, 300);
  const [exercisesSearchKey, setExercisesSearchKey] = useState("");
  const [debouncedExercisesSearchKey] = useDebouncedValue(
    exercisesSearchKey,
    300
  );
  const [categoryFilter, setCategoryFilter] = useState<string>("");

  const [organizationFilter, setOrganizationFilter] = useState<string>("");

  const organizationQuery = useAllOrganizations();

  const organizationOptions = organizationQuery.data
    ? organizationQuery.data.map((organization: Organization) => ({
        value: organization.id.toString(),
        label: organization.name,
      }))
    : [];

  const organizationId = Number(organizationFilter);

  const { data } = useOrganizationExercises(
    organizationId,
    exercisesPage,
    debouncedExercisesSearchKey,
    exercisesResultCount,
    categoryFilter
  );

  const handleOrganizationChange = (organizationId: string | null) => {
    if (organizationId) setOrganizationFilter(organizationId);
  };

  const organizationUsers = useAllUsersOrganization(
    organizationId,
    usersPage,
    debouncedUsersSearchKey,
    usersResultCount
  );

  const handleUserSelection = (user: UsersObject) => {
    if (!user.checked) {
      setSelectedUsers(
        selectedUsers.filter((selectedUser) => selectedUser.id !== user.id)
      );
    } else {
      setSelectedUsers((current) => [...current, user]);
    }
  };

  const handleExerciseSelection = (exercise: ExercisesObject) => {
    if (!exercise.checked) {
      setSelectedExercises(
        selectedExercises.filter(
          (selectedExercise) => selectedExercise.id !== exercise.id
        )
      );
    } else {
      setSelectedExercises((current) => [...current, exercise]);
    }
  };

  const handleSubmit = () => {
    assignCourseMutation.mutate(
      {
        userIds: selectedUsers?.map((user) => user.id),
        itemIds: selectedExercises?.map((exercise) => exercise.id),
      },
      {
        onSuccess: () => {
          navigate(`/exercises/all`);
          setOrganizationFilter("");
          setSelectedExercises([]);
          setSelectedUsers([]);
        },
      }
    );
  };

  return (
    <BasePage>
      <Container data-testid="exercises-page">
        <Box
          mx="auto"
          style={{ backgroundColor: "white", padding: 40, margin: 20 }}
        >
          <AssignExerciseTitle>{t("AssignExercise:title")}</AssignExerciseTitle>
          <Space h="md" />
          <Grid>
            <Grid.Col span={4}>
              <Label>{t("AssignExercise:organization")}</Label>
              {organizationOptions.length && (
                <Select
                  value={organizationFilter}
                  placeholder={t("AssignExercise:organization")}
                  data={organizationOptions}
                  onChange={(organizationId) =>
                    handleOrganizationChange(organizationId)
                  }
                  styles={{
                    input: {
                      fontFamily: "Montserrat",
                      color: Color.DARK,
                      borderColor: Color.DARK,
                      borderWidth: 1,
                      marginTop: 5,
                      fontSize: 14,
                      "&:focus": {
                        borderColor: Color.DARK,
                        borderWidth: 1,
                      },
                    },
                  }}
                />
              )}
            </Grid.Col>
          </Grid>
          <Space h="md" />
          <hr />
          <Label>{t("AssignExercise:selectUsers")}</Label>
          <Space h="md" />
          <AssignExerciseUsersMenu
            searchKey={usersSearchKey}
            setSearchKey={setUsersSearchKey}
          />
          <Space h="xs" />
          {organizationUsers.data && (
            <>
              <AssignExerciseUsersTable
                organizationUsers={organizationUsers.data.elements}
                selectedOrganization={Number(organizationFilter)}
                setSelectedUsers={handleUserSelection}
              />
              <Space h="xl" />
              <Pagination
                page={usersPage}
                totalItems={organizationUsers.data.total}
                itemsPerPage={usersResultCount}
                setItemsPerPage={setUsersResultCount}
                setPage={setUsersPage}
              />
            </>
          )}
          <hr />
          <Space h="md" />
          <Label>{t("AssignExercise:selectExercises")}</Label>
          <Space h="md" />
          <AssignExercisesMenu
            searchKey={exercisesSearchKey}
            categoryFilter={categoryFilter}
            setSearchKey={setExercisesSearchKey}
            setCategoryFilter={setCategoryFilter}
          />
          <Space h="md" />
          {data && (
            <>
              <AssignExercisesTable
                exercises={data.elements}
                selectedExercises={selectedExercises}
                setSelectedExercises={handleExerciseSelection}
              />
              <Space h="xl" />
              <Pagination
                page={exercisesPage}
                totalItems={data.total}
                itemsPerPage={exercisesResultCount}
                setItemsPerPage={setExercisesResultCount}
                setPage={setExercisesPage}
              />
            </>
          )}
          <Space h="xl" />
          <Group position="right" mt="md">
            <Button
              data-testid="submit-button"
              type="submit"
              onClick={handleSubmit}
              styles={(theme) => ({
                root: {
                  fontSize: 16,
                  fontFamily: "Montserrat",
                  backgroundColor: Color.DARK,
                  fontWeight: 600,

                  "&:hover": {
                    backgroundColor: theme.fn.lighten(Color.DARK, 0.1),
                  },
                },
              })}
            >
              {t("Common:actions.submit")}
            </Button>
          </Group>
        </Box>
      </Container>
    </BasePage>
  );
};
