import React, { useState } from "react";
import { useDebouncedValue } from "@mantine/hooks";
import { Skeleton } from "@mantine/core";
import { useSearchParams } from "react-router-dom";

// Hooks
import { useUserCourses } from "hooks/userCourses/useUserCourses";
import { useAssignUserCourse } from "hooks/userCourses/assignUsersToCourses";
import { useDeleteUserCourse } from "hooks/userCourses/useDeleteUserCourse";

// Components
import { Pagination } from "../../common";
import { UserCoursesMenu } from "../UserCoursesMenu/UserCoursesMenu";
import { UserCoursesTable } from "../UserCoursesTable/UserCoursesTable";
import { AssignCourseToUserModal } from "../AssignCourse/AssignCourseToUser";

export interface SortParams {
  sortBy: string;
  organizationId?: number | null;
}

export const UsersCoursesTab: React.FC = () => {
  const [searchKey, setSearchKey] = useState("");
  const [debouncedSearchKey] = useDebouncedValue(searchKey, 300);
  const [page, setPage] = useState(1);
  const [resultCount, setResultCount] = useState(15);
  const [isAssignCourseModalOpen, setIsAssignCourseModalOpen] = useState(false);
  const [sortKey, setSortKey] = useState<number | null>(null);
  const [organizationId, setOrganizationId] = useState<number | null>(null);

  // Queries
  const deleteCourseMutation = useDeleteUserCourse();
  const assignCourseMutation = useAssignUserCourse();
  const { data, isLoading } = useUserCourses(
    page,
    debouncedSearchKey,
    resultCount,
    sortKey,
    organizationId
  );

  // Params
  const [searchParams, setSearchParams] = useSearchParams();
  const sortByParam = searchParams.get("sortBy");
  const organizationIdParam = searchParams.get("organizationId");

  // Handlers
  const handleOnParamsChange = ({
    sortBy = "",
    organizationId = null,
  }: SortParams) =>
    setSearchParams({
      ...(sortBy && { sortBy: sortBy.toString() }),
      ...(!sortBy && sortByParam && { sortBy: sortByParam.toString() }),
      ...(organizationId &&
        organizationId !== 0 && { organizationId: organizationId.toString() }),
      ...(!organizationId &&
        organizationIdParam &&
        organizationId !== 0 && {
          organizationId: organizationIdParam.toString(),
        }),
    });

  return (
    <>
      {isLoading && (
        <Skeleton height={550} radius="sm" style={{ marginTop: 30 }} />
      )}
      <UserCoursesMenu
        searchKey={searchKey}
        setSearchKey={setSearchKey}
        setIsModalOpen={setIsAssignCourseModalOpen}
        setSortByOrganisationId={setOrganizationId}
        handleOnChangeParams={handleOnParamsChange}
      />
      {data && (
        <>
          <UserCoursesTable
            userCourses={data.elements}
            deleteMutation={deleteCourseMutation}
            setSortKey={setSortKey}
            handleOnChangeParams={handleOnParamsChange}
          />
          <Pagination
            page={page}
            totalItems={data.total}
            itemsPerPage={resultCount}
            setItemsPerPage={setResultCount}
            setPage={setPage}
          />
        </>
      )}
      <AssignCourseToUserModal
        mutation={assignCourseMutation}
        opened={isAssignCourseModalOpen}
        onClose={() => setIsAssignCourseModalOpen(false)}
      ></AssignCourseToUserModal>
    </>
  );
};
