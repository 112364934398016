import { CHAPTERPAGES } from "../../enums/chapters/urls";
import {
  CreateChapterPagePayload,
  Page,
  UpdateChapterPageObject,
} from "../../types/chapters/chapterpages";
import { createAxiosInstance } from "api/config";

const axios = createAxiosInstance();

export const getChapterPages = async (chapterId: number): Promise<Page[]> => {
  const { data } = await axios.get(
    `/${CHAPTERPAGES.GET_CHAPTERSPAGES}/${chapterId}/all`,
    { headers: { "Content-Type": "application/json;" }, data: {} }
  );

  return data;
};

export const getChapterPage = async (id: number): Promise<Page> => {
  const { data } = await axios.get(`/${CHAPTERPAGES.CHAPTERPAGES}/${id}`);

  return data;
};

export const createChapterPage = async (
  payload: CreateChapterPagePayload
): Promise<number> => {
  const { data } = await axios.post(
    `/${CHAPTERPAGES.CREATE_CHAPTERPAGES}`,
    payload
  );

  return data;
};

export const deleteChapterPage = async (id: number): Promise<void> => {
  const { data } = await axios.delete(`/${CHAPTERPAGES.CHAPTERPAGES}/${id}`);

  return data;
};

export const updateChapterPage = async (
  page: UpdateChapterPageObject
): Promise<number> => {
  const { data } = await axios.put(
    `/${CHAPTERPAGES.CHAPTERPAGES}/${page.id}`,
    page.payload,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return data;
};
