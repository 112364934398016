import { USER } from "../../enums/users/urls";
import { ApiResponse } from "../../types/api/ApiResponse";
import { User } from "../../types/user/User";
import { UserDetails } from "../../types/UserDetails/UserDetails";
import { createAxiosInstance } from "api/config";

const axios = createAxiosInstance();

export const getUsers = async (
  page: number,
  searchKey: string,
  size: number,
  roleId: number
): Promise<ApiResponse<User[]>> => {
  const { data } = await axios.get(
    `/${USER.USERS}/${roleId}/all/?page=${page}&search-key=${searchKey}&size=${size}`,
    { headers: { "Content-Type": "application/json;" }, data: {} }
  );

  return data;
};

export const getUserDetails = async (
  id: number,
  page: number,
  size: number
): Promise<UserDetails> => {
  const { data } = await axios.get(
    `/${USER.USERS}/${id}/details/?page=${page}&size=${size}`
  );

  return data;
};
