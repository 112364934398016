import { useMutation } from "@tanstack/react-query";

import { deleteChapterPage } from "../../../api/chapters/pages";
import { queryClient } from "../../../App";
import i18n from "../../../i18n";
import { generateRandomString } from "../../../utils/common/commonUtils";
import {
  endNotification,
  startNotification,
} from "../../../utils/common/notifications";

export const useDeleteChapterPage = () => {
  const randomId = generateRandomString(20);

  return useMutation((id: number) => deleteChapterPage(id), {
    onMutate: () => {
      startNotification(randomId);
    },
    onSuccess: () => {
      endNotification(
        randomId,
        i18n.t("Chapters:pages.notifications.deleteSuccess"),
        true
      );
      queryClient.invalidateQueries(["chapter-pages"]);
    },
    onError: () => {
      endNotification(
        randomId,
        i18n.t("Chapters:pages.notifications.deleteError"),
        false
      );
    },
  });
};
