import {
  Box,
  Button,
  Group,
  NumberInput,
  Space,
  Textarea,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ErrorCode } from "../../../enums/api/ErrorCode";
import { Color } from "enums/common";
import { ApiError } from "../../../types/api/ApiError";
import { Chapter } from "../../../types/chapters/chapters";

export interface EditChapterFormProps {
  chapter: Chapter;
  mutation: any;
  courseId: number;
}

export const EditChapterForm: React.FC<EditChapterFormProps> = ({
  chapter,
  mutation,
  courseId,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [points, setPoints] = useState<number | undefined>(0);
  const [duration, setDuration] = useState<number | undefined>(0);

  useEffect(() => {
    setPoints(chapter.points);
    setDuration(chapter.duration);
  }, [chapter]);

  const form = useForm({
    initialValues: {
      title: chapter.name,
      description: chapter.summary,
      courseId: courseId,
    },
  });

  const handleSubmit = () => {
    mutation.mutate(
      {
        id: chapter.id,
        payload: {
          ...form.values,
          duration: duration,
          points: points,
        },
      },
      {
        onSuccess: () => {
          navigate(`/courses/${courseId}`);
        },
        onError: (error: AxiosError<ApiError>) => {
          if (
            error.response?.data.errorCode === ErrorCode.NAME_ALREADY_EXISTS
          ) {
            form.setFieldError("title", t("Courses:errors.nameExists"));
          }
        },
      }
    );
  };

  return (
    <Box sx={{ maxWidth: 800 }} mx="auto">
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <TextInput
          size="sm"
          required
          label={t("Courses:createCourse.chapterTitle")}
          placeholder={t("Courses:createCourse.nameChapter")}
          styles={() => ({
            label: {
              fontSize: 16,
              fontWeight: 600,
              color: Color.DARK,
            },
            input: {
              fontSize: 14,
              color: Color.DARK,
              fontFamily: "Montserrat",
              borderColor: Color.DARK,
              borderWidth: 1,
              backgroundColor: Color.WHITE,
              "&:focus": {
                borderColor: Color.DARK,
                borderWidth: 1,
              },
            },
          })}
          {...form.getInputProps("title")}
        />
        <Space h="md" />
        <Textarea
          required
          label={t("Courses:createCourse.introduction")}
          placeholder={t("Courses:createCourse.shortintroduction")}
          mb={3}
          styles={() => ({
            label: {
              fontSize: 16,
              fontWeight: 600,
              color: Color.DARK,
            },
            input: {
              fontSize: 14,
              color: Color.DARK,
              fontFamily: "Montserrat",
              borderColor: Color.DARK,
              borderWidth: 1,
              backgroundColor: Color.WHITE,
              "&:focus": {
                borderColor: Color.DARK,
                borderWidth: 1,
              },
            },
          })}
          {...form.getInputProps("description")}
        />
        <Space h="md" />
        <Group position="apart" grow>
          <NumberInput
            required
            label={t("Courses:createCourse.learningscore")}
            placeholder={t("Courses:createCourse.score")}
            styles={() => ({
              label: {
                fontSize: 16,
                fontWeight: 600,
                color: Color.DARK,
              },
              input: {
                fontSize: 14,
                color: Color.DARK,
                fontFamily: "Montserrat",
                borderColor: Color.DARK,
                borderWidth: 1,
                backgroundColor: Color.WHITE,
                "&:focus": {
                  borderColor: Color.DARK,
                  borderWidth: 1,
                },
              },
            })}
            value={points}
            onChange={(val) => setPoints(val)}
          />
          <NumberInput
            required
            label={t("Courses:createCourse.learningtime")}
            placeholder={t("Courses:createCourse.time")}
            styles={() => ({
              label: {
                fontSize: 16,
                fontWeight: 600,
                color: Color.DARK,
              },
              input: {
                fontSize: 14,
                color: Color.DARK,
                fontFamily: "Montserrat",
                borderColor: Color.DARK,
                borderWidth: 1,
                backgroundColor: Color.WHITE,
                "&:focus": {
                  borderColor: Color.DARK,
                  borderWidth: 1,
                },
              },
            })}
            value={duration}
            onChange={(val) => setDuration(val)}
          />
        </Group>
        <Space h="md" />
        <Group position="right" mt="md">
          <Button
            data-testid="submit-button"
            type="submit"
            size="sm"
            styles={(theme) => ({
              root: {
                fontSize: 16,
                fontFamily: "Montserrat",
                backgroundColor: Color.DARK,
                fontWeight: 600,

                "&:hover": {
                  backgroundColor: theme.fn.lighten(Color.DARK, 0.1),
                },
              },
            })}
          >
            {t("Courses:createCourse.submitChapter")}
          </Button>
        </Group>
      </form>
    </Box>
  );
};
