import { useTranslation } from "react-i18next";

import styled from "styled-components";
import { Group, Table, Menu } from "@mantine/core";
import { Eye, Pencil, Trash } from "tabler-icons-react";

import { Department } from "../../../types/department/Department";

import { Color } from "enums/common";
import { Link } from "react-router-dom";

interface CircleProps {
  color: string;
}

const SmallCircle = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ color }: CircleProps) => color};
`;

export interface DepartmentsTableProps {
  departments: Department[];
  onEditDepartment: (department: Department) => void;
  onDeleteDepartment: (department: Department) => void;
}

export const DepartmentsTable: React.FC<DepartmentsTableProps> = ({
  departments,
  onEditDepartment,
  onDeleteDepartment,
}) => {
  const { t } = useTranslation();

  return (
    <Table
      data-testid="departments-table"
      highlightOnHover
      verticalSpacing={6}
      style={{ marginTop: 20, marginBottom: 20 }}
      sx={() => ({
        backgroundColor: Color.WHITE,
        boxShadow: "box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.15)",
      })}
    >
      <thead>
        <tr>
          <th>{t("Departments:table.name")}</th>
          <th>{t("Departments:table.description")}</th>
          <th>{t("Departments:table.avatar")}</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {departments.map((department) => (
          <tr key={department.id}>
            <td>{department.name}</td>
            <td>{department.summary}</td>
            <td>
              {department.avatar && (
                <img
                  alt={`${department.name}-avatar}`}
                  src={department.avatar}
                  width="48"
                  height="48"
                />
              )}
            </td>
            <td>
              <Group spacing="xl">
                <Menu
                  control={
                    <Group
                      data-testid="menu-control"
                      spacing={3}
                      style={{ cursor: "pointer" }}
                    >
                      <SmallCircle color={Color.DARK} />
                      <SmallCircle color={Color.DARK} />
                      <SmallCircle color={Color.DARK} />
                    </Group>
                  }
                >
                  <Menu.Item
                    icon={<Eye size={14} />}
                    component={Link}
                    to={`${department.id?.toString()}/details`}
                  >
                    {t("Common:view")}
                  </Menu.Item>
                  <Menu.Item
                    icon={<Pencil size={14} />}
                    onClick={() => onEditDepartment(department)}
                  >
                    {t("Common:edit")}
                  </Menu.Item>
                  <Menu.Item
                    icon={<Trash size={14} />}
                    onClick={() => onDeleteDepartment(department)}
                  >
                    {t("Common:delete")}
                  </Menu.Item>
                </Menu>
              </Group>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
