import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { Box, Container, Skeleton, Space, Text } from "@mantine/core";

import { Banner, BasePage } from "../../components/common";
import { EditExercisePageForm } from "../../components/exercises/EditExercisePageForm/EditExercisePageForm";

import { Color } from "enums/common";

import { useExercisePage } from "../../hooks/exercises/exercisepages/useExercisePage";
import { useUpdateExercisePage } from "../../hooks/exercises/exercisepages/useUpdateExercisePage";

const StyledBanner = styled(Banner)`
  margin: 34px 0 40px 0;
`;

export const EditPage: React.FC = () => {
  const { pageId } = useParams();
  const { id } = useParams();
  const { t } = useTranslation();
  const { data, isLoading } = useExercisePage(Number(pageId));

  const updateExercisePageMutation = useUpdateExercisePage();

  return (
    <BasePage>
      <Container>
        <Box sx={{ maxWidth: 800 }} mx="auto">
          <StyledBanner title="" text="">
            <Box sx={{ maxWidth: 800 }} mx="auto">
              <Space h="xs" />
              <Text
                styles={() => ({
                  root: {
                    fontSize: 28,
                    fontWeight: 600,
                    fontFamily: "Montserrat",
                    color: Color.DARK,
                  },
                })}
              >
                {t("Exercises:createExercisePage.editPage")}
              </Text>
              <Space h="xl" />
              {isLoading && (
                <Skeleton height={550} radius="sm" style={{ marginTop: 30 }} />
              )}
              {data && (
                <EditExercisePageForm
                  page={data}
                  mutation={updateExercisePageMutation}
                  exerciseId={Number(id)}
                />
              )}
            </Box>
            <Space h="xl" />
          </StyledBanner>
        </Box>
      </Container>
    </BasePage>
  );
};
