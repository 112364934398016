import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import {
  Anchor,
  Breadcrumbs,
  Button,
  Container,
  Group,
  Space,
} from "@mantine/core";

import { Banner, BasePage } from "components/common";
import { OrganizationDetailsPreview } from "../../components/organizations/OrganizationDetailsPreview/OrganizationDetailsPreview";
import { OrganizationTabMenu } from "../../components/organizations/OrganizationTabMenu/OrganizationTabMenu";
import { InviteUsersModal } from "../../components/users/InviteUsersModal";

import { Color } from "enums/common";

import { useOrganization } from "hooks/organizations/useOrganization";
import { useInvitePeople } from "../../hooks/organizations/users/useInvitePeople";

const StyledBanner = styled(Banner)`
  margin: 34px 0 40px 0;
`;

const StyledOrganizationDetailsPreview = styled(OrganizationDetailsPreview)`
  margin: 34px 0 40px 0;
`;

const StyledOrganizationTabMenu = styled(OrganizationTabMenu)`
  margin: 34px 0 40px 0;
`;

const BreadcrumbPannel = styled.div`
  margin: 34px 0 40px 0;
`;

export const Organization: React.FC = () => {
  const { t } = useTranslation("Organization");

  const { organizationId } = useParams();
  const { data } = useOrganization(Number(organizationId));

  const [isInviteUsersModalOpen, setIsInviteUsersModalOpen] = useState(false);
  const createUsersInviteMutation = useInvitePeople();

  const items = [
    { title: "Organizations", href: "/organizations" },
    {
      title: data ? data.name : "",
      href: `/organizations/${data ? data.id : ""}`,
    },
  ].map((item, index) => (
    <Anchor href={item.href} key={index}>
      {item.title}
    </Anchor>
  ));

  return (
    <BasePage>
      <Container data-testid="dashboard-page">
        <BreadcrumbPannel>
          <Breadcrumbs
            styles={{
              breadcrumb: { color: Color.DARK, fontWeight: "bold" },
              separator: { color: Color.DARK, fontWeight: "bold" },
            }}
          >
            {items}
          </Breadcrumbs>
        </BreadcrumbPannel>
        <StyledBanner title={t("banner.title")} text={t("banner.text")}>
          <Group spacing="xs">
            <Button
              onClick={() => {
                setIsInviteUsersModalOpen(true);
              }}
              size="xl"
              styles={(theme) => ({
                root: {
                  fontSize: 18,
                  fontFamily: "Montserrat",
                  backgroundColor: Color.DARK,
                  fontWeight: 600,

                  "&:hover": {
                    backgroundColor: theme.fn.lighten(Color.DARK, 0.1),
                  },
                },
              })}
            >
              {t("banner.invitePeople")}
            </Button>
            <Space w="md" />
            <Button
              component={Link}
              to="departments"
              size="xl"
              variant="outline"
              styles={() => ({
                root: {
                  fontSize: 18,
                  color: Color.DARK,
                  fontFamily: "Montserrat",
                  fontWeight: 700,
                  borderColor: Color.DARK,
                  borderWidth: 3,
                },
              })}
            >
              {t("banner.manageDepartments")}
            </Button>
          </Group>
        </StyledBanner>
        <StyledOrganizationDetailsPreview
          totalDepartments={data ? data.departments.length : 0}
          totalUsers={data ? data.usersNum : 0}
          avatarURL={data ? data.imageUrl : ""}
          organizationName={data ? data.name : ""}
          organizationSummary={data ? data.summary : ""}
        ></StyledOrganizationDetailsPreview>
        {data && (
          <StyledOrganizationTabMenu
            organization={data}
          ></StyledOrganizationTabMenu>
        )}
        <InviteUsersModal
          mutation={createUsersInviteMutation}
          opened={isInviteUsersModalOpen}
          onClose={() => setIsInviteUsersModalOpen(false)}
          departments={data?.departments ? data?.departments : []}
          organizationId={Number(organizationId)}
        />
      </Container>
    </BasePage>
  );
};
