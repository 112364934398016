import { useMutation } from "@tanstack/react-query";

import i18n from "../../i18n";
import { queryClient } from "../../App";

import {
  startNotification,
  endNotification,
} from "../../utils/common/notifications";
import { generateRandomString } from "../../utils/common/commonUtils";

import { updateDepartment } from "../../api/departments/departments";

import { UpdateDepartmentObject } from "../../types/department/Department";

export const useUpdateDepartment = () => {
  const randomId = generateRandomString(20);

  return useMutation(
    (object: UpdateDepartmentObject) => updateDepartment(object),
    {
      onMutate: () => {
        startNotification(randomId);
      },
      onSuccess: () => {
        endNotification(
          randomId,
          i18n.t("Departments:notifications.updateSuccess"),
          true
        );
        queryClient.invalidateQueries(["departments"]);
      },
      onError: () => {
        endNotification(
          randomId,
          i18n.t("Departments:notifications.updateError"),
          false
        );
      },
    }
  );
};
