import { useMutation } from "@tanstack/react-query";

import { deleteChapterQuiz } from "../../../api/chapters/quizes";
import { queryClient } from "../../../App";
import i18n from "../../../i18n";
import { generateRandomString } from "../../../utils/common/commonUtils";
import {
  endNotification,
  startNotification,
} from "../../../utils/common/notifications";

export const useDeleteChapterQuiz = () => {
  const randomId = generateRandomString(20);

  return useMutation((id: number) => deleteChapterQuiz(id), {
    onMutate: () => {
      startNotification(randomId);
    },
    onSuccess: () => {
      endNotification(
        randomId,
        i18n.t("Quiz:notifications.deleteSuccess"),
        true
      );
      queryClient.invalidateQueries(["chapters"]);
    },
    onError: () => {
      endNotification(
        randomId,
        i18n.t("Quiz:notifications.deleteError"),
        false
      );
    },
  });
};
