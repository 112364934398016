import React from "react";
import { Box, Container } from "@mantine/core";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { Banner, BasePage } from "../../components/common";

import { useCreateTemplate } from "../../hooks/templates/useCreateTemplate";
import { CreateTemplatePage } from "./CreateTemplatePage";

const StyledBanner = styled(Banner)`
  margin: 34px 0 40px 0;
`;

export const CreateTemplate: React.FC = () => {
  const { t } = useTranslation();
  const createTemplateMutation = useCreateTemplate();

  return (
    <BasePage>
      <Container data-testid="templates-page">
        <Box sx={{ maxWidth: 800 }} mx="auto">
          <StyledBanner title={t("Templates:modals:create")} text={""}>
            <CreateTemplatePage
              mutation={createTemplateMutation}
            ></CreateTemplatePage>
          </StyledBanner>
        </Box>
      </Container>
    </BasePage>
  );
};
