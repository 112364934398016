import { useMutation } from "@tanstack/react-query";

import { assignCoursesToUsers } from "../../api/userCourses/userCourses";
import { queryClient } from "../../App";
import i18n from "../../i18n";
import { AssignUserCoursePayload } from "../../types/userCourse/assignCourse";
import { generateRandomString } from "../../utils/common/commonUtils";
import {
  endNotification,
  startNotification,
} from "../../utils/common/notifications";

export const useAssignUserCourse = () => {
  const randomId = generateRandomString(20);

  return useMutation(
    (payload: AssignUserCoursePayload) => assignCoursesToUsers(payload),
    {
      onMutate: () => {
        startNotification(randomId);
      },
      onSuccess: () => {
        endNotification(
          randomId,
          i18n.t("Courses:notifications.assignSuccess"),
          true
        );
        queryClient.invalidateQueries(["userCourses"]);
      },
      onError: () => {
        endNotification(
          randomId,
          i18n.t("Courses:notifications.deleteError"),
          false
        );
      },
    }
  );
};
