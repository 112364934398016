import { Container } from "@mantine/core";
import { BasePage } from "components/common";
import { DepartmentDetailsPage } from "components/department/DepartmentDetails/DepartmentDetailsPage";

export const DepartmentDetails: React.FC = () => {
  return (
    <BasePage>
      <Container>
        <DepartmentDetailsPage />
      </Container>
    </BasePage>
  );
};
