import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDeleteExercisePage } from "../../../../hooks/exercises/exercisepages/useDeleteExercisePage";
import { useExercisePages } from "../../../../hooks/exercises/exercisepages/useExercisePages";
import { ExercisePages } from "../../../../types/exercises/exercisepages";
import { DeleteExercisePageModal } from "../../DeleteModal/DeleteExercisePageModal";
import { AccordionPageDropdown } from "./Accordion/AccordionPageDropdown";

export interface ExercisePagesOverviewProps {
  exerciseId: number;
  onRefresh?: () => void;
}

export const ExercisePagesOverview: React.FC<ExercisePagesOverviewProps> = ({
  exerciseId,
  onRefresh,
}) => {
  // Hooks
  const deleteChapterMutation = useDeleteExercisePage();
  const { data, refetch } = useExercisePages(Number(exerciseId));
  const { t } = useTranslation();

  // State
  const [selectedExercisePage, setSelectedExercisePage] =
    useState<ExercisePages>();
  const [isDeleteExercisePageModalOpen, setIsDeleteExercisePageModalOpen] =
    useState(false);

  // Handlers
  const handleDeleteExercisePage = (exercisepage: ExercisePages) => {
    setSelectedExercisePage(exercisepage);
    setIsDeleteExercisePageModalOpen(true);
  };

  const handleOnDelete = () => {
    refetch({ type: "all" });
    onRefresh?.();
  };

  return (
    <>
      {data && (
        <AccordionPageDropdown
          pages={data}
          exerciseId={exerciseId}
          onDeleteExercisePage={handleDeleteExercisePage}
        />
      )}
      {selectedExercisePage && (
        <DeleteExercisePageModal
          page={selectedExercisePage}
          title={t("Exercises:modals.deleteModalPageTitle")}
          text={t("Exercises:modals.deleteModalPageText")}
          onClose={() => setIsDeleteExercisePageModalOpen(false)}
          opened={isDeleteExercisePageModalOpen}
          mutation={deleteChapterMutation}
          onDeleteCompleted={handleOnDelete}
        />
      )}
    </>
  );
};
