import { useQuery } from "@tanstack/react-query";

import { getOrganizationUsers } from "../../api/organizations/users";

export const useOrganizationUsers = (
  organizationId: number,
  page: number,
  searchKey: string,
  size: number,
  roleId: number,
  department: string,
  status: string
) => {
  return useQuery(
    [
      "organizationUsers",
      organizationId,
      page,
      searchKey,
      size,
      roleId,
      department,
      status,
    ],
    () =>
      getOrganizationUsers(
        organizationId,
        page,
        searchKey,
        size,
        roleId,
        department,
        status
      )
  );
};
