import { createAxiosInstance } from "api/config";
import { UserExercises } from "enums/userExercises/userExercises";
import { ApiResponse } from "types/api/ApiResponse";
import { AssignUserExercisePayload } from "types/userExercise/assignExercise";
import { UserExercise } from "types/userExercise/userExercise";

const axios = createAxiosInstance();

export const getUserExercise = async (
  page: number,
  searchKey: string,
  size: number
): Promise<ApiResponse<UserExercise[]>> => {
  const { data } = await axios.get(
    `/${UserExercises.GET_USER_EXERCISES}?page=${page}&search-key=${searchKey}&size=${size}`,
    { headers: { "Content-Type": "application/json;" }, data: {} }
  );

  return data;
};

export const deleteUserExercise = async (id: number): Promise<void> => {
  const { data } = await axios.delete(`/${UserExercises.USER_EXERCISES}/${id}`);

  return data;
};

export const assignExerciseToUsers = async (
  userExercise: AssignUserExercisePayload
): Promise<UserExercise[]> => {
  const { data } = await axios.post(
    `/${UserExercises.ASSIGN_EXERCISES}`,
    userExercise
  );

  return data;
};
