import React, { useRef, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Photo, Trash, Video, Headphones } from "tabler-icons-react";

import { Stack, Text, Group, Button } from "@mantine/core";
import { Dropzone } from "@mantine/dropzone";

import { useUploadResource } from "hooks/resources";

import { Color } from "enums/common";
import { mapMimeTypeToFileType } from "utils/common";

const dropzoneChildren = () => null;

const FileUploadContainer = styled.div`
  max-width: 300px;
`;

const FileTile = styled.div`
  display: flex;
  align-items: center;
  padding: 15px;
  background-color: ${Color.GRAY};
  border-radius: 5px;
`;

export interface FileUploadProps {
  fileId?: string | undefined;
  type: "image" | "video" | "audio";
  label?: string;
  setFileId: (file: string | undefined) => void;
}

export const FileUpload: React.FC<FileUploadProps> = ({
  label,
  type,
  setFileId,
}) => {
  const { t } = useTranslation("Common");
  const openRef = useRef<any>();

  const [fileName, setFileName] = useState("");
  const [fileid, setFileid] = useState("");

  const useUploadMutation = useUploadResource();

  const handleUploadFile = (uploadedFile: File) => {
    const formData = new FormData();
    formData.append("file", uploadedFile);

    useUploadMutation.mutate(formData, {
      onSuccess: (data) => {
        setFileName(data.originalName);
        setFileId(data.storageIdentifier);
      },
    });
  };

  const handleRemoveFile = () => {
    setFileName("");
    setFileid("");
  };

  return (
    <FileUploadContainer data-testid="image-upload">
      <Stack spacing={2}>
        <Text weight={500} size="sm">
          {label}
        </Text>
        {fileName ? (
          <FileTile>
            {type === "image" && <Photo style={{ marginRight: "5px" }} />}
            {type === "video" && <Video style={{ marginRight: "5px" }} />}
            {type === "audio" && <Headphones style={{ marginRight: "5px" }} />}
            <Text style={{ pointerEvents: "none" }}>{fileName || fileid}</Text>
            <Trash
              style={{ marginLeft: "5px", cursor: "pointer" }}
              onClick={handleRemoveFile}
            />
          </FileTile>
        ) : (
          <Group position="left">
            <Button color="gray" onClick={() => openRef.current()}>
              {t("actions.selectFile")}
            </Button>
          </Group>
        )}
      </Stack>
      <Dropzone
        accept={mapMimeTypeToFileType(type)}
        openRef={openRef}
        style={{ display: "none" }}
        onDrop={(files) => handleUploadFile(files[0])}
        onReject={(files) => console.log("rejected files", files)}
      >
        {() => dropzoneChildren()}
      </Dropzone>
    </FileUploadContainer>
  );
};
