import { useMutation } from "@tanstack/react-query";

import { queryClient } from "../../../App";
import i18n from "../../../i18n";

import { updateChapterPage } from "../../../api/chapters/pages";

import { UpdateChapterPageObject } from "../../../types/chapters/chapterpages";

import {
  endNotification,
  generateRandomString,
  startNotification,
} from "../../../utils/common";

export const useUpdateChapterPage = () => {
  const randomId = generateRandomString(20);

  return useMutation(
    (object: UpdateChapterPageObject) => updateChapterPage(object),
    {
      onMutate: () => {
        startNotification(randomId);
      },
      onSuccess: () => {
        endNotification(
          randomId,
          i18n.t("Chapters:notifications.updateSuccess"),
          true
        );
        queryClient.invalidateQueries(["accordions"]);
        queryClient.invalidateQueries(["pages"]);
      },
      onError: () => {
        endNotification(
          randomId,
          i18n.t("Chapters:notifications.updateError"),
          false
        );
      },
    }
  );
};
