import React from "react";
import { useTranslation } from "react-i18next";

import { InputWrapper, Table } from "@mantine/core";
import { Template, TemplateObject } from "../../../types/template/template";

import { Color } from "enums/common";

export interface TemplatesTableProps {
  templates: Template[];
  selectedTemplate: TemplateObject | null;
  setSelectedTemplate: (template: TemplateObject | null) => void;
  isLoading: boolean;
}

export const TemplatesModalTable: React.FC<TemplatesTableProps> = ({
  templates,
  selectedTemplate,
  setSelectedTemplate,
  isLoading,
}) => {
  const { t } = useTranslation();

  const handleTemplateSelection = (templateId: string) => {
    const template = templates.find((x) => x.id.toString() === templateId);
    if (template) {
      setSelectedTemplate({ ...template });
    }
  };

  if (isLoading) return null;

  return (
    <Table
      data-testid="templates-table"
      highlightOnHover
      verticalSpacing={6}
      style={{ marginTop: 20, marginBottom: 20 }}
      sx={() => ({
        backgroundColor: Color.WHITE,
        boxShadow: "box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.15)",
      })}
    >
      <thead>
        <tr>
          <th></th>
          <th>{t("Templates:table.name")}</th>
          <th>{t("Templates:table.summary")}</th>
          <th>{t("Templates:table.type")}</th>
          <th>{t("Templates:table.image")}</th>
          <th>{t("Templates:table.keywords")}</th>
          <th>{t("Templates:table.skills")}</th>
        </tr>
      </thead>
      <tbody>
        {templates.map((template) => (
          <tr
            key={template.id}
            onClick={() => handleTemplateSelection(`${template.id}`)}
            style={{ cursor: "pointer" }}
          >
            <td>
              <InputWrapper labelElement="div">
                <input
                  type="radio"
                  name="radio"
                  id={template.id.toString()}
                  onChange={(event) => handleTemplateSelection(event.target.id)}
                  checked={selectedTemplate?.id === template.id}
                />
              </InputWrapper>
            </td>
            <td>{template.title}</td>
            <td>{template.description}</td>
            <td>{template.type.name}</td>
            <td>
              {template.coverImage && (
                <img
                  alt={`${template.title}-avatar}`}
                  src={template.coverImage}
                  width="48"
                  height="48"
                />
              )}
            </td>
            <td>{template.keywordList}</td>
            <td>
              {template.skillList && template.skillList.length
                ? template.skillList.join(", ")
                : ""}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
