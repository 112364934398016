import React, { useState } from "react";
import { useTranslation } from "react-i18next";

// Types
import { Exercise } from "types/exercises/exercises";

// Components
import { Pagination } from "../../common";
import { Skeleton } from "@mantine/core";
import { ExercisesMenu } from "../ExercisesMenu/ExercisesMenu";
import { ExercisesTable } from "../ExercisesTable/ExercisesTable";
import { DeleteExerciseModal } from "../DeleteModal/DeleteExerciseModal";

// Hooks
import { useExercises } from "hooks/exercises/useExercises";
import { useDeleteExercise } from "hooks/exercises/useDeleteExercise";
import { useDebouncedValue } from "@mantine/hooks";
import { useCloneExercise } from "hooks/exercises/useCloneExercise";

export const AllExercisesTab: React.FC = () => {
  const { t } = useTranslation();
  const [searchKey, setSearchKey] = useState("");
  const [debouncedSearchKey] = useDebouncedValue(searchKey, 300);
  const [page, setPage] = useState(1);
  const [resultCount, setResultCount] = useState(15);
  const [isDeleteExciseModalOpen, setIsDeleteExciseModalOpen] = useState(false);

  const deleteExerciseMutation = useDeleteExercise();

  const [selectedExercise, setSelectedExercise] = useState<Exercise>();

  const [categoryFilter, setCategoryFilter] = useState<string>("");

  // Services
  const cloneMutation = useCloneExercise();

  // Handlers
  const handleEditExercise = (exercise: Exercise) => {
    setSelectedExercise(exercise);
  };

  const handleDeleteExercise = (exercise: Exercise) => {
    setSelectedExercise(exercise);
    setIsDeleteExciseModalOpen(true);
  };

  const { data, isLoading } = useExercises(
    page,
    debouncedSearchKey,
    resultCount,
    categoryFilter
  );

  return (
    <>
      {isLoading && (
        <Skeleton height={550} radius="sm" style={{ marginTop: 30 }} />
      )}
      <ExercisesMenu
        categoryFilter={categoryFilter}
        setCategoryFilter={setCategoryFilter}
        setSearchKey={setSearchKey}
        searchKey={searchKey}
      />
      {data && (
        <>
          <ExercisesTable
            exercises={data.elements}
            onDeleteExercise={handleDeleteExercise}
            onEditExercise={handleEditExercise}
            cloneMutation={cloneMutation}
          />
          <Pagination
            page={page}
            totalItems={data.total}
            itemsPerPage={resultCount}
            setItemsPerPage={setResultCount}
            setPage={setPage}
          />
        </>
      )}
      {selectedExercise && (
        <DeleteExerciseModal
          exercise={selectedExercise}
          title={t("Exercises:modals.deleteModalTitle")}
          text={t("Exercises:modals.deleteModalText")}
          onClose={() => setIsDeleteExciseModalOpen(false)}
          opened={isDeleteExciseModalOpen}
          mutation={deleteExerciseMutation}
        />
      )}
    </>
  );
};
