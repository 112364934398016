import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Eye, Pencil, Trash } from "tabler-icons-react";

import { Group, Table, Menu, Image } from "@mantine/core";

import { Organization } from "types/organizations";

import { Color } from "enums/common";

const SmallCircle = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${Color.DARK};
`;

export interface OrganizationsTableProps {
  organizations: Organization[];
  deleteMutation: any;
  onEditOrganization: (organization: Organization) => void;
}

export const OrganizationsTable: React.FC<OrganizationsTableProps> = ({
  organizations,
  deleteMutation,
  onEditOrganization,
}) => {
  const { t } = useTranslation("Organizations");

  return (
    <Table
      highlightOnHover
      data-testid="organizations-table"
      verticalSpacing={6}
      style={{
        marginTop: 20,
        marginBottom: 20,
        backgroundColor: Color.WHITE,
        boxShadow: "box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.15)",
      }}
    >
      <thead>
        <tr>
          <th>{t("table.name")}</th>
          <th>{t("table.description")}</th>
          <th>{t("table.avatar")}</th>
          <th>{t("table.actions")}</th>
        </tr>
      </thead>
      <tbody>
        {organizations.map((organization) => (
          <tr key={organization.id}>
            <td>{organization.name}</td>
            <td>{organization.summary}</td>
            <td>
              {organization.imageUrl && (
                <div
                  style={{
                    width: 48,
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  <Image
                    src={organization.imageUrl}
                    alt={`organization-${organization.name}-avatar`}
                  />
                </div>
              )}
            </td>
            <td>
              <Group spacing="xl">
                <Menu
                  control={
                    <Group
                      data-testid="menu-control"
                      spacing={3}
                      style={{ cursor: "pointer" }}
                    >
                      <SmallCircle color={Color.DARK} />
                      <SmallCircle color={Color.DARK} />
                      <SmallCircle color={Color.DARK} />
                    </Group>
                  }
                >
                  <Menu.Item
                    icon={<Eye size={14} />}
                    component={Link}
                    to={organization.id.toString()}
                  >
                    {t("view", { ns: "Common" })}
                  </Menu.Item>
                  <Menu.Item
                    icon={<Pencil size={14} />}
                    onClick={() => onEditOrganization(organization)}
                  >
                    {t("edit", { ns: "Common" })}
                  </Menu.Item>
                  <Menu.Item
                    icon={<Trash size={14} />}
                    onClick={() => deleteMutation.mutate(organization.id)}
                  >
                    {t("delete", { ns: "Common" })}
                  </Menu.Item>
                </Menu>
              </Group>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
