import React from "react";
import styled from "styled-components";

import { Color } from "enums/common/Color";

const BannerContainer = styled.div`
  padding: 20px 40px;
  background-color: ${Color.WHITE};
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.05);
`;

const Title = styled.div`
  font-size: 36px;
  line-height: 43.88px;
  color: ${Color.DARK};
  font-weight: 600;
`;

const Text = styled.div`
  max-width: 750px;
  margin: 10px 0 23px 0;
  font-size: 16px;
  line-height: 24px;
  color: ${Color.DARK};
  line-break: auto;
`;

export interface BannerProps {
  title: string;
  text: string;
  className?: string;
  children?: React.ReactNode;
}

export const Banner: React.FC<BannerProps> = ({
  title,
  text,
  className,
  children,
}) => {
  return (
    <BannerContainer data-testid="banner" className={className}>
      <Title>{title}</Title>
      <Text>{text}</Text>
      {children}
    </BannerContainer>
  );
};
