import { AxiosError } from "axios";

import { createAxiosInstance } from "api/config";
import { Auth } from "enums/api/urls/Auth";

import { ApiDataResponse } from "types/api/ApiResponse";
import {
  ChangePasswordPayload,
  LoginPayload,
  LoginResponse,
  ResetPasswordPayload,
} from "types/auth";

import { Role } from "../../enums/users";

const axios = createAxiosInstance();

export const login = async (payload: LoginPayload) => {
  const { data } = await axios.post<ApiDataResponse<LoginResponse>>(
    `/${Auth.LOGIN}`,
    payload
  );

  if (data.data.authority.includes(Role.MEMBER)) {
    throw new Error("Members should not be able to log in");
  }

  return data;
};

export const validateToken = async (token: string | null) => {
  if (!token) {
    throw new AxiosError("Token is required");
  }

  const { data } = await axios.get<ApiDataResponse<LoginResponse>>(
    `/${Auth.VALIDATE_TOKEN}`,
    {
      headers: {
        token,
      },
    }
  );

  if (data.data.authority.includes(Role.MEMBER)) {
    throw new Error("Members should not be able to log in");
  }

  return data;
};

export const changePassword = async (payload: ChangePasswordPayload) => {
  const { data } = await axios.put(`/${Auth.CHANGE_PASSWORD}`, payload);

  return data;
};

export const resetPassword = async (payload: ResetPasswordPayload) => {
  const { data } = await axios.put(`/${Auth.RESET_PASSWORD}`, payload);

  return data;
};
