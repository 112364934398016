import { useMutation } from "@tanstack/react-query";

import { deleteUserDepartmentAssignment } from "api/departments/departments";
import { queryClient } from "App";
import i18n from "i18n";
import {
  endNotification,
  generateRandomString,
  startNotification,
} from "utils/common";

export const useDeleteUserDepartmentMutation = () => {
  const randomId = generateRandomString(20);

  return useMutation((id: number) => deleteUserDepartmentAssignment(id), {
    onMutate: () => {
      startNotification(randomId);
    },
    onSuccess: () => {
      endNotification(
        randomId,
        i18n.t("Departments:notifications.excludeUserFromDepartmentSuccess"),
        true
      );
      queryClient.invalidateQueries(["users-assigned-to-department"]);
    },
    onError: () => {
      endNotification(
        randomId,
        i18n.t("Departments:notifications.excludeUserFromDepartmentError"),
        false
      );
    },
  });
};
