import { CHAPTER_EXERCISES } from "../../enums/chapters/urls";
import { AssignExercisePayload } from "../../types/chapters/assignExercise";
import { createAxiosInstance } from "api/config";

const axios = createAxiosInstance();

export const assignExercise = async (
  payload: AssignExercisePayload
): Promise<number> => {
  const { data } = await axios.post(
    `/${CHAPTER_EXERCISES.CHAPTER_EXERCISES}`,
    payload
  );

  return data;
};

export const deleteChapterExercise = async (id: number[]): Promise<void> => {
  const { data } = await axios.delete(
    `/${CHAPTER_EXERCISES.CHAPTER_EXERCISES}`,
    { data: id }
  );

  return data;
};
