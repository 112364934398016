import React, { useState } from "react";

import { Skeleton } from "@mantine/core";
import { Pagination } from "../../common";
import { TemplatesMenu } from "../../templates/TemplatesMenu/TemplatesMenu";
import { TemplatesTable } from "../../templates/TemplatesTable/TemplatesTable";
import { useDeleteTemplate } from "../../../hooks/templates/useDeleteTemplate";
import { useTemplates } from "../../../hooks/templates/useTemplates";
import { useDebouncedValue } from "@mantine/hooks";

export const TemplatesTab: React.FC = () => {
  const [searchKey, setSearchKey] = useState("");
  const [debouncedSearchKey] = useDebouncedValue(searchKey, 300);
  const [page, setPage] = useState(1);
  const [resultCount, setResultCount] = useState(15);

  const deleteTemplateMutation = useDeleteTemplate();

  const { data, isLoading } = useTemplates(
    page,
    debouncedSearchKey,
    resultCount
  );

  return (
    <>
      {isLoading && (
        <Skeleton height={550} radius="sm" style={{ marginTop: 30 }} />
      )}
      <TemplatesMenu setSearchKey={setSearchKey} searchKey={searchKey} />
      {data && (
        <>
          <TemplatesTable
            templates={data.elements}
            deleteMutation={deleteTemplateMutation}
          />
          <Pagination
            page={page}
            totalItems={data.total}
            itemsPerPage={resultCount}
            setItemsPerPage={setResultCount}
            setPage={setPage}
          />
        </>
      )}
    </>
  );
};
