import { useMutation, useQuery } from "@tanstack/react-query";

import {
  createExercise,
  getExercises,
  getOrganizationExercises,
} from "../../api/exercises/exercises";
import { queryClient } from "../../App";
import i18n from "../../i18n";
import { CreateExercisePayload } from "../../types/exercises/exercises";
import { generateRandomString } from "../../utils/common/commonUtils";
import {
  endNotification,
  startNotification,
} from "../../utils/common/notifications";

export const useExercises = (
  page: number,
  searchKey: string,
  size: number,
  category: string
) => {
  return useQuery(["exercises", page, searchKey, size, category], () =>
    getExercises(page, searchKey, size, category)
  );
};

export const useOrganizationExercises = (
  organizationId: number,
  page: number,
  searchKey: string,
  size: number,
  category: string
) => {
  return useQuery(
    ["exercises", organizationId, page, searchKey, size, category],
    () =>
      getOrganizationExercises(organizationId, page, searchKey, size, category)
  );
};

export const useCreateExercise = () => {
  const randomId = generateRandomString(20);

  return useMutation(
    (payload: CreateExercisePayload) => createExercise(payload),
    {
      onMutate: () => {
        startNotification(randomId);
      },
      onSuccess: () => {
        endNotification(
          randomId,
          i18n.t("Exercises:notifications.createSuccess"),
          true
        );
        queryClient.invalidateQueries(["exercises"]);
      },
      onError: () => {
        endNotification(
          randomId,
          i18n.t("Exercises:notifications.createError"),
          false
        );
      },
    }
  );
};
