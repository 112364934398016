import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Lock, User } from "tabler-icons-react";

import { Anchor, Button, Group, Input, Text } from "@mantine/core";
import { useForm } from "@mantine/hooks";

import { Color } from "enums/common";

export interface LoginFormProps {
  mutation: any;
}

export const LoginForm: React.FC<LoginFormProps> = ({ mutation }) => {
  const { t } = useTranslation("Auth");
  const navigate = useNavigate();

  const form = useForm({
    initialValues: {
      account: "",
      password: "",
    },
  });

  const handleSubmit = () => {
    mutation.mutate(
      {
        ...form.values,
      },
      {
        onSuccess: () => {
          navigate(`/`);
        },
      }
    );
  };

  return (
    <>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Group direction="column">
          <Text size="xl" color="white">
            {t("loginPage.userName")}
          </Text>
          <Input
            {...form.getInputProps("account")}
            required
            size="lg"
            radius="xl"
            placeholder={t("loginPage.userName")}
            icon={<User size={18} />}
            sx={{
              input: {
                fontSize: 14,
                width: 300,
              },
            }}
          />
          <Text size="xl" color="white">
            {t("loginPage.password")}
          </Text>
          <Input
            {...form.getInputProps("password")}
            required
            size="lg"
            radius="xl"
            type="password"
            placeholder={t("loginPage.password")}
            icon={<Lock size={18} />}
            sx={{
              input: {
                fontSize: 14,
                width: 300,
              },
            }}
          />
          <Anchor component={Link} to={"/reset-password"}>
            Forgot password?
          </Anchor>
          <Button
            type="submit"
            radius="xl"
            size="lg"
            styles={() => ({
              root: {
                width: 300,
                backgroundColor: Color.ORANGE,
                "&:hover": {
                  backgroundColor: Color.ORANGE,
                },
              },
            })}
          >
            {t("loginPage.loginButton")}
          </Button>
        </Group>
      </form>
    </>
  );
};
