import { useQuery } from "@tanstack/react-query";

import { getDepartments } from "../../api/departments/departments";

export const useDepartments = (
  page: number,
  searchKey: string,
  size: number,
  organizationId: number
) => {
  return useQuery(["departments", page, searchKey, size], () =>
    getDepartments(page, searchKey, size, organizationId)
  );
};
