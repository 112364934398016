import { useMutation, useQuery } from "@tanstack/react-query";

import { queryClient } from "App";
import i18n from "i18n";
import { createChapterPage, getChapterPages } from "api/chapters/pages";

import { CreateChapterPagePayload } from "types/chapters/chapterpages";

import { generateRandomString } from "utils/common/commonUtils";
import { endNotification, startNotification } from "utils/common/notifications";

export const useChapterPages = (chapterId: number) => {
  return useQuery(["chapter-pages", chapterId], () =>
    getChapterPages(chapterId)
  );
};

export const useCreateChapterPage = () => {
  const randomId = generateRandomString(20);

  return useMutation(
    (payload: CreateChapterPagePayload) => createChapterPage(payload),
    {
      onMutate: () => {
        startNotification(randomId);
      },
      onSuccess: () => {
        endNotification(
          randomId,
          i18n.t("notifications.createPageSuccess", { ns: "Chapters" }),
          true
        );
        queryClient.invalidateQueries(["chapter-pages"]);
      },
      onError: () => {
        endNotification(
          randomId,
          i18n.t("notifications.createPageError", { ns: "Chapters" }),
          false
        );
      },
    }
  );
};
