import React, { useState } from "react";
import { useTranslation } from "react-i18next";

// Components
import { Box, Button, Group, Space, Textarea, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { TemplatesModal } from "components/templates/TemplatesModal";

// Types
import { TemplateObject } from "types/template/template";
import { Color } from "enums/common";

// Containers
import { ChatBot3Form } from "containers/CreateExercises/CreateChatBot3Form";

export interface CreateModalProps {
  mutation: any;
}

const DEFAULT_TEMPLATE_OBJECT = {
  id: 3,
  title: "Chatbot 3.0",
  type: {
    id: 0,
  },
};

export const CreateExercisePage: React.FC<CreateModalProps> = ({
  mutation,
}) => {
  // Hooks
  const { t } = useTranslation();

  // State
  const [isTemplateModalOpen, setIsTemplateModalOpen] = useState(false);
  const [selectedTemplate, setSelectedTemplate] =
    useState<TemplateObject | null>(DEFAULT_TEMPLATE_OBJECT);

  const emptyTemplate = !selectedTemplate || selectedTemplate.id === 0;
  const isVoiceBot3 =
    selectedTemplate && selectedTemplate.title === "Chatbot 3.0";

  const form = useForm({
    initialValues: {
      title: "",
      subtitle: "",
      description: "",
    },
  });

  return (
    <Box sx={{ maxWidth: 800 }} mx="auto">
      {emptyTemplate ? (
        <form>
          <TextInput
            required
            label={t("Exercises:createExercise.title")}
            styles={() => ({
              input: {
                fontSize: 14,
                color: Color.DARK,
                fontFamily: "Montserrat",
                borderColor: Color.DARK,
                borderWidth: 1,
                backgroundColor: Color.WHITE,
                "&:focus": {
                  borderColor: Color.DARK,
                  borderWidth: 1,
                },
              },
            })}
            {...form.getInputProps("title")}
          />
          <Space h="md" />
          <TextInput
            label={t("Exercises:createExercise.subtitle")}
            styles={() => ({
              input: {
                fontSize: 14,
                color: Color.DARK,
                fontFamily: "Montserrat",
                borderColor: Color.DARK,
                borderWidth: 1,
                backgroundColor: Color.WHITE,
                "&:focus": {
                  borderColor: Color.DARK,
                  borderWidth: 1,
                },
              },
            })}
            {...form.getInputProps("subtitle")}
          />
          <Space h="md" />
          <Textarea
            required
            label={t("Exercises:createExercise.description")}
            styles={() => ({
              input: {
                fontSize: 14,
                color: Color.DARK,
                fontFamily: "Montserrat",
                borderColor: Color.DARK,
                borderWidth: 1,
                backgroundColor: Color.WHITE,
                "&:focus": {
                  borderColor: Color.DARK,
                  borderWidth: 1,
                },
              },
            })}
            mb={3}
            {...form.getInputProps("description")}
          />
          <Space h="md" />

          <TextInput
            label={t("Exercises:createExercise.template")}
            placeholder={t("Exercises:createExercise.template")}
            required
            readOnly
            value={selectedTemplate?.title}
            onChange={() => selectedTemplate?.id}
            onClick={() => {
              setIsTemplateModalOpen(true);
            }}
            styles={() => ({
              input: {
                fontSize: 14,
                color: Color.DARK,
                fontFamily: "Montserrat",
                borderColor: Color.DARK,
                borderWidth: 1,
                backgroundColor: Color.WHITE,
                "&:focus": {
                  borderColor: Color.DARK,
                  borderWidth: 1,
                },
              },
            })}
          />
          <Space h="md" />

          <Group position="right" mt="md">
            <Button
              data-testid="submit-button"
              type="submit"
              disabled={true}
              size="sm"
              styles={(theme) => ({
                root: {
                  fontSize: 16,
                  fontFamily: "Montserrat",
                  backgroundColor: Color.DARK,
                  fontWeight: 600,

                  "&:hover": {
                    backgroundColor: theme.fn.lighten(Color.DARK, 0.1),
                  },
                },
              })}
            >
              {t("Common:actions.submit")}
            </Button>
          </Group>
        </form>
      ) : (
        isVoiceBot3 && (
          <ChatBot3Form
            title={form.values.title}
            subtitle={form.values.subtitle}
            description={form.values.description}
            template={selectedTemplate}
            mutation={mutation}
            openTemplateSelectionModal={() => setIsTemplateModalOpen(true)}
          />
        )
      )}

      <TemplatesModal
        opened={isTemplateModalOpen}
        onClose={() => setIsTemplateModalOpen(false)}
        setSelectedTemplate={setSelectedTemplate}
        selectedTemplate={selectedTemplate}
      />
    </Box>
  );
};
