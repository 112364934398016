import { Container, Space, Tabs, TabsProps } from "@mantine/core";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { BasePage } from "../../components/common";
import { CoursesDetailsTable } from "../../components/userManagement/UserDetails/CoursesDetailsTable";
import { PracticesDetailsTable } from "../../components/userManagement/UserDetails/PracticesDetailsTable";
import { UserDetails } from "../../components/userManagement/UserDetails/UserDetails";
import { Color } from "enums/common";

export const UserManagementDetails: React.FC = () => {
  const { t } = useTranslation();

  const UserManagementTabMenuContainer = styled.div`
    height: 115px;
    width: 100%;
    float: left;
  `;

  function StyledTabs(props: TabsProps) {
    return (
      <Tabs
        variant="unstyled"
        styles={(theme) => ({
          tabControl: {
            backgroundColor: Color.GRAY,
            color: Color.DARK,
            fontSize: "20px",
            lineHeight: "24px",
            padding: `${theme.spacing.lg}px ${theme.spacing.xl}px`,

            "&:not(:first-of-type)": {
              borderLeft: 0,
            },
          },

          tabActive: {
            borderColor: Color.DARK,
            borderBottom: `3px solid ${Color.DARK}`,
            fontWeight: 700,
          },
        })}
        {...props}
      />
    );
  }

  return (
    <BasePage>
      <Container data-testid="userManagementDetails-page">
        <UserDetails />
        <Space h="lg" />
        <UserManagementTabMenuContainer>
          <StyledTabs color={Color.DARK}>
            <Tabs.Tab label={t("UserManagement:coursesDetails")}>
              <Space h="md" />
              {/* <UserDetailsMenu setSearchKey={setCourseSearchKey} /> */}
              <Space h="md" />
              <CoursesDetailsTable />
            </Tabs.Tab>
            <Tabs.Tab label={t("UserManagement:practicesDetails")}>
              <Space h="md" />
              {/* <UserDetailsMenu setSearchKey={setExerciseSearchKey} /> */}
              <Space h="md" />
              <PracticesDetailsTable />
            </Tabs.Tab>
          </StyledTabs>
        </UserManagementTabMenuContainer>
      </Container>
    </BasePage>
  );
};
