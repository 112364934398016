import { useMutation } from "@tanstack/react-query";

import i18n from "../../i18n";
import { queryClient } from "../../App";

import {
  startNotification,
  endNotification,
} from "../../utils/common/notifications";
import { generateRandomString } from "../../utils/common/commonUtils";

import { deleteTemplate } from "../../api/templates/templates";

export const useDeleteTemplate = () => {
  const randomId = generateRandomString(20);

  return useMutation((id: number) => deleteTemplate(id), {
    onMutate: () => {
      startNotification(randomId);
    },
    onSuccess: () => {
      endNotification(
        randomId,
        i18n.t("Templates:notifications.deleteSuccess"),
        true
      );
      queryClient.invalidateQueries(["templates"]);
    },
    onError: () => {
      endNotification(
        randomId,
        i18n.t("Templates:notifications.deleteError"),
        false
      );
    },
  });
};
