import React from "react";
import { useTranslation } from "react-i18next";

import { Clock, Lock, LockOpen } from "tabler-icons-react";

import {
  Card,
  Image,
  Text,
  Space,
  Group,
  useMantineTheme,
} from "@mantine/core";

import { Exercise } from "types/exercises/exercises";

const TextStyle = { fontFamily: "Montserrat", fontSize: 14 };

interface ExerciseTileProps {
  exercise: Exercise;
  onClick?: () => void;
}

export const ExerciseTile: React.FC<ExerciseTileProps> = ({
  exercise,
  onClick,
}) => {
  const theme = useMantineTheme();
  const { t } = useTranslation("Exercises");

  return (
    <Card
      key={exercise.id}
      shadow="sm"
      p="lg"
      style={{ height: 320, cursor: "pointer", maxWidth: "262px" }}
      onClick={onClick}
    >
      <Card.Section>
        <Image src="../images/exercises-image.png" height={130} alt="Norway" />
      </Card.Section>
      <Text
        weight={500}
        style={{ marginBottom: 5, marginTop: theme.spacing.sm }}
      >
        {exercise.title}
      </Text>
      <Space h="md" />
      {exercise.description && (
        <>
          <Text size="sm" style={{ lineHeight: 1.5, fontSize: 14 }}>
            {exercise.description}
          </Text>
          <Space h="md" />
        </>
      )}
      <Group position="apart">
        <Group spacing={5}>
          <Clock size={22} strokeWidth={1} color={"black"} />
          <Text style={TextStyle}>{`${exercise.duration} min`}</Text>
        </Group>
        <Group spacing={5}>
          {exercise.isPublic ? (
            <>
              <LockOpen size={22} strokeWidth={1} color={"green"} />
              <Text style={TextStyle}>{t("public", { ns: "Coruses" })}</Text>
            </>
          ) : (
            <>
              <Lock size={22} strokeWidth={1} color={"gray"} />
              <Text style={TextStyle}>{t("private", { ns: "Coruses" })}</Text>
            </>
          )}
        </Group>
      </Group>
    </Card>
  );
};
