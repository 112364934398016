import { useMutation, useQuery } from "@tanstack/react-query";
import { createVoicebot } from "api/exercises/exercises";
import { getVoiceBots } from "api/voicebots/voicebots";
import { queryClient } from "App";
import i18n from "i18n";
import { UpdateVoicebotObject } from "types/exercises/exercises";
import {
  endNotification,
  generateRandomString,
  startNotification,
} from "utils/common";

export const useVoiceBots = () => {
  return useQuery(["voicebots"], () => getVoiceBots());
};

export const useCreateVoicebot = () => {
  const randomId = generateRandomString(20);

  return useMutation(
    (exercise: UpdateVoicebotObject) => createVoicebot(exercise),
    {
      onMutate: () => {
        startNotification(randomId);
      },
      onSuccess: () => {
        endNotification(
          randomId,
          i18n.t("Common:notifications.voicebotSuccess"),
          true
        );
        queryClient.invalidateQueries(["voicebots"]);
      },
      onError: () => {
        endNotification(
          randomId,
          i18n.t("Common:notifications.voicebotError"),
          false
        );
      },
    }
  );
};
