import { useMutation } from "@tanstack/react-query";

import { deleteExercisePage } from "../../../api/exercises/exercisepages";
import { queryClient } from "../../../App";
import i18n from "../../../i18n";
import { generateRandomString } from "../../../utils/common/commonUtils";
import {
  endNotification,
  startNotification,
} from "../../../utils/common/notifications";

export const useDeleteExercisePage = () => {
  const randomId = generateRandomString(20);

  return useMutation((id: number) => deleteExercisePage(id), {
    onMutate: () => {
      startNotification(randomId);
    },
    onSuccess: () => {
      endNotification(
        randomId,
        i18n.t("Exercises:notifications.deletePageSuccess"),
        true
      );
      queryClient.invalidateQueries(["exercise-page"]);
    },
    onError: () => {
      endNotification(
        randomId,
        i18n.t("Exercises:notifications.deletePageError"),
        false
      );
    },
  });
};
