import { Grid } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { Color } from "enums/common";
import { useUserManagement } from "../../../hooks/UserManagement/useUseManagement";
import { Banner } from "../../common";
import { Fragment } from "react";

const StyledBanner = styled(Banner)`
  margin: 34px 0 40px 0;
`;

const Text = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: ${Color.DARK};
`;

export const UserDetails: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { data } = useUserManagement(Number(id), 1, 1);

  const departmentName = data?.user.departments.map(
    (department: any, index) => (
      <Fragment key={index}>{department.name}</Fragment>
    )
  );

  const roleNames = data?.user.roles.map((role) => role.name);

  return (
    <StyledBanner title={t("UserManagement:userDetailsTitle")} text={""}>
      <Grid>
        <Grid.Col span={6}>
          <Text color={Color.DARK}>
            {t("UserManagement:userDetails.name")}: {data?.user.fullName}
          </Text>
        </Grid.Col>
        <Grid.Col span={6}>
          <Text color={Color.DARK}>
            {t("UserManagement:userDetails.nickname")}: {data?.user.nickName}
          </Text>
        </Grid.Col>
        <Grid.Col span={6}>
          <Text color={Color.DARK}>
            {t("UserManagement:userDetails.gender")}:{" "}
            {data?.user.gender ? data?.user.gender.name : ""}
          </Text>
        </Grid.Col>
        <Grid.Col span={6}>
          <Text color={Color.DARK}>
            {t("UserManagement:userDetails.email")}: {data?.user.email}
          </Text>
        </Grid.Col>
        <Grid.Col span={6}>
          <Text color={Color.DARK}>
            {t("UserManagement:userDetails.departments")}: {departmentName}
          </Text>
        </Grid.Col>
        <Grid.Col span={6}>
          <Text color={Color.DARK}>
            {t("UserManagement:userDetails.organization")}:
            {data?.user.organization ? data?.user.organization.name : ""}
          </Text>
        </Grid.Col>
        <Grid.Col span={6}>
          <Text color={Color.DARK}>
            {t("UserManagement:userDetails.status")}:{" "}
            {data?.user.status
              ? t(`Common:${data?.user.status.name.toLowerCase()}`)
              : ""}
          </Text>
        </Grid.Col>
        <Grid.Col span={6}>
          <Text color={Color.DARK}>
            {t("UserManagement:userDetails.role")}:
            {roleNames && roleNames.length
              ? roleNames
                  .map((name) => t(`Users:userRoles.${name.toLowerCase()}`))
                  .join(", ")
              : ""}
          </Text>
        </Grid.Col>
      </Grid>
    </StyledBanner>
  );
};
