import { useQuery } from "@tanstack/react-query";

import { getDepartmentUsers } from "api/departments/departments";

export const useDepartmentUsers = (
  id: number,
  page: number,
  searchKey: string,
  size: number
) => {
  return useQuery(
    ["users-assigned-to-department", id, page, searchKey, size],
    () => getDepartmentUsers(id, page, searchKey, size)
  );
};
