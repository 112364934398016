import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { Stack, Text, Image, Group, Button } from "@mantine/core";
import { Dropzone, MIME_TYPES } from "@mantine/dropzone";
import { useUploadResource } from "hooks/resources/useUploadResource";

const dropzoneChildren = () => null;

export interface ImageUploadProps {
  avatarPreviewUrl?: string;
  setAvatarIdentifier: (avatarUrl: string | undefined) => void;
}

export const ImageUpload: React.FC<ImageUploadProps> = ({
  avatarPreviewUrl,
  setAvatarIdentifier,
}) => {
  const { t } = useTranslation("Common");
  const openRef = useRef<any>();

  const [avatar, setAvatar] = useState("");

  const useUploadMutation = useUploadResource();

  const handleUploadFile = (file: File) => {
    const formData = new FormData();
    formData.append("file", file);

    useUploadMutation.mutate(formData, {
      onSuccess: (data) => {
        setAvatar(URL.createObjectURL(file));
        setAvatarIdentifier(data.storageIdentifier);
      },
    });
  };

  return (
    <div data-testid="image-upload">
      <Stack spacing={2}>
        <Text weight={500} size="sm">
          {t("selectImage")}
        </Text>
        {avatar || avatarPreviewUrl ? (
          <Image
            width={350}
            src={avatar || avatarPreviewUrl}
            onClick={() => openRef.current()}
            style={{ cursor: "pointer" }}
          />
        ) : (
          <Group position="left">
            <Button color="gray" onClick={() => openRef.current()}>
              {t("selectImage")}
            </Button>
          </Group>
        )}
      </Stack>
      <Dropzone
        openRef={openRef}
        onDrop={(files) => handleUploadFile(files[0])}
        onReject={(files) => console.log("rejected files", files)}
        maxSize={10 * 1024 ** 2}
        accept={[
          MIME_TYPES.png,
          MIME_TYPES.jpeg,
          MIME_TYPES.mp4,
          "audio/mp3",
          "audio/x-mp3",
          "audio/x-wav",
          "audio/wav",
        ]}
        style={{ display: "none" }}
      >
        {() => dropzoneChildren()}
      </Dropzone>
    </div>
  );
};
