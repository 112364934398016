import { Box, Button, Group, Space, Textarea, TextInput } from "@mantine/core";
import { useForm } from "@mantine/hooks";
import { AxiosError } from "axios";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ErrorCode } from "../../../enums/api/ErrorCode";

import { Color } from "enums/common";
import { ApiError } from "../../../types/api/ApiError";

export interface CreateChapterFormProps {
  mutation: any;
  id: number;
}

export const CreateChapterForm: React.FC<CreateChapterFormProps> = ({
  mutation,
  id,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const form = useForm({
    initialValues: {
      courseId: id,
      duration: 0,
      points: 0,
      title: "",
      description: "",
    },
  });

  const handleSubmit = () => {
    mutation.mutate(
      {
        ...form.values,
        duration: +form.values.duration,
        points: +form.values.points,
      },
      {
        onSuccess: () => {
          navigate(`/courses/${id}`);
        },
        onError: (error: AxiosError<ApiError>) => {
          if (
            error.response?.data.errorCode === ErrorCode.NAME_ALREADY_EXISTS
          ) {
            form.setFieldError("title", t("Courses:errors.nameExists"));
          }
        },
      }
    );
  };

  return (
    <Box sx={{ maxWidth: 800 }} mx="auto">
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <TextInput
          size="sm"
          required
          label={t("Courses:createCourse.chapterTitle")}
          placeholder={t("Courses:createCourse.nameChapter")}
          styles={() => ({
            label: {
              fontSize: 16,
              fontWeight: 600,
              color: Color.DARK,
            },
            input: {
              fontSize: 14,
              color: Color.DARK,
              fontFamily: "Montserrat",
              borderColor: Color.DARK,
              borderWidth: 1,
              backgroundColor: Color.WHITE,
              "&:focus": {
                borderColor: Color.DARK,
                borderWidth: 1,
              },
            },
          })}
          {...form.getInputProps("title")}
        />
        <Space h="md" />
        <Textarea
          required
          label={t("Courses:createCourse.introduction")}
          placeholder={t("Courses:createCourse.shortintroduction")}
          mb={3}
          styles={() => ({
            label: {
              fontSize: 16,
              fontWeight: 600,
              color: Color.DARK,
            },
            input: {
              fontSize: 14,
              color: Color.DARK,
              fontFamily: "Montserrat",
              borderColor: Color.DARK,
              borderWidth: 1,
              backgroundColor: Color.WHITE,
              "&:focus": {
                borderColor: Color.DARK,
                borderWidth: 1,
              },
            },
          })}
          {...form.getInputProps("description")}
        />
        <Space h="md" />
        <Group position="apart" grow>
          <TextInput
            required
            label={t("Courses:createCourse.learningscore")}
            placeholder={t("Courses:createCourse.score")}
            styles={() => ({
              label: {
                fontSize: 16,
                fontWeight: 600,
                color: Color.DARK,
              },
              input: {
                fontSize: 14,
                color: Color.DARK,
                fontFamily: "Montserrat",
                borderColor: Color.DARK,
                borderWidth: 1,
                backgroundColor: Color.WHITE,
                "&:focus": {
                  borderColor: Color.DARK,
                  borderWidth: 1,
                },
              },
            })}
            {...form.getInputProps("points")}
          />
          <TextInput
            required
            label={t("Courses:createCourse.learningtime")}
            placeholder={t("Courses:createCourse.time")}
            styles={() => ({
              label: {
                fontSize: 16,
                fontWeight: 600,
                color: Color.DARK,
              },
              input: {
                fontSize: 14,
                color: Color.DARK,
                fontFamily: "Montserrat",
                borderColor: Color.DARK,
                borderWidth: 1,
                backgroundColor: Color.WHITE,
                "&:focus": {
                  borderColor: Color.DARK,
                  borderWidth: 1,
                },
              },
            })}
            {...form.getInputProps("duration")}
          />
        </Group>
        <Space h="md" />
        <Group position="right" mt="md">
          <Button
            data-testid="submit-button"
            type="submit"
            size="sm"
            styles={(theme) => ({
              root: {
                fontSize: 16,
                fontFamily: "Montserrat",
                backgroundColor: Color.DARK,
                fontWeight: 600,

                "&:hover": {
                  backgroundColor: theme.fn.lighten(Color.DARK, 0.1),
                },
              },
            })}
          >
            {t("Courses:createCourse.submitChapter")}
          </Button>
        </Group>
      </form>
    </Box>
  );
};
