import { Tabs, TabsProps } from "@mantine/core";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Color } from "enums/common";
import { UserManagerAdminsTab } from "../Admins/UserManagerAdminsTab";
import { UserManagerMembersTab } from "../Members/UserManagerMembersTab";
import { UserManagerSysAdminTab } from "../SysAdmins/UserManagerSysAdminsTab";
import { UserManagerManagersTab } from "../Managers/UserManagerManagersTab";

const UserManagementTabMenuContainer = styled.div`
  height: 115px;
  width: 100%;
  float: left;
`;

export interface UserManagementTabMenuProps {
  className?: string;
  children?: React.ReactNode;
}

function StyledTabs(props: TabsProps) {
  return (
    <Tabs
      variant="unstyled"
      styles={(theme) => ({
        tabControl: {
          backgroundColor: Color.GRAY,
          color: Color.DARK,
          fontSize: "20px",
          lineHeight: "24px",
          padding: `${theme.spacing.lg}px ${theme.spacing.xl}px`,

          "&:not(:first-of-type)": {
            borderLeft: 0,
          },
        },

        tabActive: {
          borderColor: Color.DARK,
          borderBottom: `3px solid ${Color.DARK}`,
          fontWeight: 700,
        },
      })}
      {...props}
    />
  );
}

export const UserManagementTabMenu: React.FC<UserManagementTabMenuProps> = ({
  className,
  children,
}) => {
  const { t } = useTranslation();

  return (
    <UserManagementTabMenuContainer
      data-testid="userManagementTab"
      className={className}
    >
      <StyledTabs color={Color.DARK}>
        <Tabs.Tab label={t("UserManagement:tab.members")}>
          <UserManagerMembersTab />
        </Tabs.Tab>
        <Tabs.Tab label={t("UserManagement:tab.managers")}>
          <UserManagerManagersTab />
        </Tabs.Tab>
        <Tabs.Tab label={t("UserManagement:tab.admins")}>
          <UserManagerAdminsTab />
        </Tabs.Tab>
        <Tabs.Tab label={t("UserManagement:tab.sysAdmins")}>
          <UserManagerSysAdminTab />
        </Tabs.Tab>
      </StyledTabs>
      {children}
    </UserManagementTabMenuContainer>
  );
};
