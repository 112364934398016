import { Organizations } from "enums/api/urls";
import { USER } from "../../enums/users/urls";
import { ApiResponse } from "../../types/api/ApiResponse";
import { User } from "../../types/user/User";
import { createAxiosInstance } from "api/config";

const axios = createAxiosInstance();

export const getOrganizationUsers = async (
  organizationId: number,
  page: number,
  searchKey: string,
  size: number,
  roleId: number,
  department: string,
  status: string
): Promise<ApiResponse<User[]>> => {
  const { data } = await axios.get(
    `/${Organizations.ORGANIZATIONS}/${organizationId}/members/${roleId}` +
      `?page=${page}&search-key=${searchKey}&size=${size}&department-id=${department}&status=${status}      `,
    { headers: { "Content-Type": "application/json;" }, data: {} }
  );

  return data;
};

export const getAllUsersOrganization = async (
  organizationId: number,
  page: number,
  searchKey: string,
  size: number
): Promise<ApiResponse<User[]>> => {
  const { data } = await axios.get(
    `/${USER.USERS_BY_ORGANIZATION}${organizationId}?page=${page}&search-key=${searchKey}&size=${size}`,
    { headers: { "Content-Type": "application/json;" }, data: {} }
  );

  return data;
};
