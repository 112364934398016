import { Group, Menu, Table } from "@mantine/core";
import { Color } from "enums/common";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Trash } from "tabler-icons-react";
import { DepartmentUser } from "types/user/User";

const SmallCircle = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${Color.DARK};
`;
export interface DepartmentUsersTableProps {
  departmentUsers: DepartmentUser[];
  departmentId: number;
  deleteMutation: any;
}

export const DepartmentUsersTable: React.FC<DepartmentUsersTableProps> = ({
  departmentUsers,
  departmentId,
  deleteMutation,
}) => {
  const { t } = useTranslation();

  return (
    <Table
      data-testid="assignDepartment-table"
      highlightOnHover
      verticalSpacing={6}
      style={{ marginTop: 5, marginBottom: 20, textAlign: "center" }}
      sx={() => ({
        backgroundColor: Color.WHITE,
        boxShadow: "box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.15)",
      })}
    >
      <thead>
        <tr>
          <th>{t("Department:table.name")}</th>
          <th>{t("Department:table.nickName")}</th>
          <th>{t("Department:table.email")}</th>
          <th>{t("Department:table.actions")}</th>
        </tr>
      </thead>
      <tbody>
        {departmentUsers?.map((departmentUser) => (
          <tr key={departmentUser.id}>
            <td> {departmentUser.fullName}</td>
            <td>{departmentUser.nickName}</td>
            <td>{departmentUser.email}</td>
            <td>
              <Group spacing="xl">
                <Menu
                  control={
                    <Group
                      data-testid="menu-control"
                      spacing={3}
                      style={{ cursor: "pointer" }}
                    >
                      <SmallCircle color={Color.DARK} />
                      <SmallCircle color={Color.DARK} />
                      <SmallCircle color={Color.DARK} />
                    </Group>
                  }
                >
                  <Menu.Item
                    icon={<Trash size={14} />}
                    onClick={() => {
                      var data = departmentUser.departments.find(
                        (x) => x.departmentId === departmentId
                      );

                      deleteMutation.mutate(data?.userDepartmentId);
                    }}
                  >
                    {t("delete", { ns: "Common" })}
                  </Menu.Item>
                </Menu>
              </Group>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
