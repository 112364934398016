import { useMutation } from "@tanstack/react-query";

import { changePassword } from "api/auth";

import {
  endNotification,
  generateRandomString,
  startNotification,
} from "utils/common";

import { ChangePasswordPayload } from "../../types/auth";
import i18n from "i18n";

export const useChangePassword = () => {
  const randomId = generateRandomString(20);

  return useMutation(
    (payload: ChangePasswordPayload) => changePassword(payload),
    {
      onMutate: () => {
        startNotification(randomId);
      },
      onSuccess: () => {
        endNotification(
          randomId,
          i18n.t("passwordChange.success", { ns: "Auth" }),
          true
        );
      },
      onError: () => {
        endNotification(
          randomId,
          i18n.t("passwordChange.failed", { ns: "Auth" }),
          false
        );
      },
    }
  );
};
