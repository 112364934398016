import { useMutation, useQuery } from "@tanstack/react-query";
import {
  createCourse,
  getCourses,
  getOrganizationCourses,
} from "../../api/courses/courses";
import { queryClient } from "../../App";
import i18n from "../../i18n";

import { CreateCoursePayload } from "../../types/courses/courses";

import { generateRandomString } from "../../utils/common/commonUtils";
import {
  endNotification,
  startNotification,
} from "../../utils/common/notifications";

export const useCourses = (
  page: number,
  searchKey: string,
  size: number,
  category: string
) => {
  return useQuery(["courses", page, searchKey, size, category], () =>
    getCourses(page, searchKey, size, category)
  );
};

export const useOrganizationCourses = (
  organizationId: number,
  page: number,
  searchKey: string,
  size: number,
  category: string
) => {
  return useQuery(
    ["courses", organizationId, page, searchKey, size, category],
    () =>
      getOrganizationCourses(organizationId, page, searchKey, size, category)
  );
};

export const useCreateCourse = () => {
  const randomId = generateRandomString(20);

  return useMutation((payload: CreateCoursePayload) => createCourse(payload), {
    onMutate: () => {
      startNotification(randomId);
    },
    onSuccess: () => {
      endNotification(
        randomId,
        i18n.t("Courses:notifications.createSuccess"),
        true
      );
      queryClient.invalidateQueries(["courses"]);
    },
    onError: () => {
      endNotification(
        randomId,
        i18n.t("Courses:notifications.createError"),
        false
      );
    },
  });
};
