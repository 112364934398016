import React from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";
import { Box, Container } from "@mantine/core";

import { BasePage } from "../../components/common/BasePage/BasePage";
import { Banner } from "../../components/common";

import { useCreateExercise } from "../../hooks/exercises/useExercises";

import { CreateExercisePage } from "./CreateExercisePage";

const StyledBanner = styled(Banner)`
  margin: 34px 0 40px 0;
`;

export const CreateExercise: React.FC = () => {
  const { t } = useTranslation();
  const createExerciseMutation = useCreateExercise();

  return (
    <BasePage>
      <Container data-testid="exercises-page">
        <Box sx={{ maxWidth: 800 }} mx="auto">
          <StyledBanner title={t("Exercises:modals.create")} text={""}>
            <CreateExercisePage mutation={createExerciseMutation} />
          </StyledBanner>
        </Box>
      </Container>
    </BasePage>
  );
};
