import { createAxiosInstance } from "api/config";
import { UploadUrls } from "enums/imageupload/urls";
import { Resource } from "types/resources";

const axios = createAxiosInstance();

export const upload = async (formData: FormData): Promise<Resource> => {
  const { data } = await axios.post(`/${UploadUrls.UPLOAD}`, formData);

  return data;
};
