import { Button, Group, Modal } from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";
import { AxiosError } from "axios";
import { ErrorCode } from "enums/api";
import { Color } from "enums/common";
import { Roles } from "enums/users";
import { useDepartmentManager } from "hooks/departments/assignDepartment/departmentManager";
import { useOrganizationUsers } from "hooks/organizations/useOrganizationUsers";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ApiError } from "types/api";
import { UsersObject } from "types/user/User";
import {
  endNotification,
  generateRandomString,
  warningNotification,
} from "utils/common";
import { AssignManagerTable } from "./AssignManagerTable";

export interface AssignManagerDepartmentModalProps {
  opened: boolean;
  mutation: any;
  onClose: () => void;
  setManagerIsSelected: (isSelected: boolean) => void;
}

export const AssignManagerDepartmentModal: React.FC<
  AssignManagerDepartmentModalProps
> = ({ opened, mutation, onClose, setManagerIsSelected }) => {
  const { t } = useTranslation();

  const [page] = useState(1);
  const [resultCount] = useState(20);
  const [searchKey] = useState("");
  const [debouncedSearchKey] = useDebouncedValue(searchKey, 300);
  const [statusFilter] = useState<string>("");
  const [selectedManager, setSelectedManager] = useState<UsersObject>();
  const { organizationId } = useParams();
  const { departmentId } = useParams();

  const { data } = useOrganizationUsers(
    Number(organizationId),
    page,
    debouncedSearchKey,
    resultCount,
    Roles.MANAGER,
    "",
    statusFilter
  );

  const selectedDepartmentManager = useDepartmentManager(
    Number(departmentId),
    page,
    "",
    resultCount
  );

  const handleClose = () => {
    onClose();
  };

  const handleManagerSelection = (manager: UsersObject) => {
    setSelectedManager(manager);
  };

  const handleSubmit = () => {
    if (!selectedManager) {
      const randomId = generateRandomString(20);
      warningNotification(
        randomId,
        t("Department:notifications.assignManagerDepartmentWarning")
      );
    } else {
      mutation.mutate(
        {
          departmentId: Number(departmentId),
          managerId: selectedManager?.id,
        },
        {
          onSuccess: () => {
            setManagerIsSelected(true);
            handleClose();
          },
          onError: (error: AxiosError<ApiError>) => {
            if (
              error.response?.data.errorCode === ErrorCode.NAME_ALREADY_EXISTS
            ) {
              const randomId = generateRandomString(20);
              endNotification(
                randomId,
                t("Department:notifications.assignManagerError"),
                false
              );
            }
          },
        }
      );
    }
  };

  return (
    <Modal
      centered
      data-testid="assignManagerToDepartment-modal"
      size={1200}
      title={t("Department:modal:assignManagerToDepartment")}
      opened={opened}
      onClose={handleClose}
    >
      {data && (
        <AssignManagerTable
          organizationManagers={data.elements}
          selectedManager={selectedDepartmentManager}
          setSelectedManager={handleManagerSelection}
        />
      )}
      <Group position="right" mt="md">
        <Button
          data-testid="submit-button"
          type="submit"
          onClick={handleSubmit}
          styles={(theme) => ({
            root: {
              fontSize: 16,
              fontFamily: "Montserrat",
              backgroundColor: Color.DARK,
              fontWeight: 600,

              "&:hover": {
                backgroundColor: theme.fn.lighten(Color.DARK, 0.1),
              },
            },
          })}
        >
          {t("Common:actions.submit")}
        </Button>
      </Group>
    </Modal>
  );
};
