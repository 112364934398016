import React from "react";
import { Link } from "react-router-dom";
import { Eye, Pencil, Trash, Lock, LockOpen, Copy } from "tabler-icons-react";

// Hooks
import { useTranslation } from "react-i18next";
import { UseMutationResult } from "@tanstack/react-query";

//
import styled from "styled-components";
import { Group, Table, Menu, Badge } from "@mantine/core";

// Types
import { Course } from "../../../types/courses/courses";
import { Color } from "enums/common";

interface CircleProps {
  color: string;
}

const SmallCircle = styled.div`
  text-align: center;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ color }: CircleProps) => color};
`;

export interface CoursesTableProps {
  courses: Course[];
  deleteMutation: any;
  cloneMutation: UseMutationResult<void, unknown, number>;
}

export const CoursesTable: React.FC<CoursesTableProps> = ({
  courses,
  deleteMutation,
  cloneMutation,
}) => {
  const { t } = useTranslation();

  const getBadgeColor = (id: number) => {
    let color = "";
    switch (id) {
      case 1:
        color = "green";
        break;
      case 2:
        color = "blue";
        break;
    }
    return color;
  };

  // Handlers
  const getCoursePublicIcon = (isPublic: Boolean) => {
    return isPublic ? (
      <LockOpen size={28} strokeWidth={2} color={"green"} />
    ) : (
      <Lock size={28} strokeWidth={2} color={"gray"} />
    );
  };

  const handleCloneCourse = (id: number) => {
    cloneMutation.mutate(id);
  };

  return (
    <Table
      data-testid="courses-table"
      highlightOnHover
      verticalSpacing={6}
      style={{ marginTop: 20, marginBottom: 20, textAlign: "center" }}
      sx={() => ({
        backgroundColor: Color.WHITE,
        boxShadow: "box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.15)",
      })}
    >
      <thead>
        <tr>
          <th>{t("Courses:table.name")}</th>
          <th>{t("Courses:table.organization")}</th>
          <th>{t("Courses:table.status")}</th>
          <th>{t("Courses:table.public")}</th>
          <th>{t("Courses:table.actions")}</th>
        </tr>
      </thead>
      <tbody>
        {courses.map((course, index) => (
          <tr key={course.id}>
            <td>{course.name}</td>
            <td>{course.organization ? course.organization.name : ""}</td>
            <td>
              <Badge color={getBadgeColor(course.activationStatus.id)}>
                {t(`Courses:status.${course.activationStatus.name}`)}
              </Badge>
            </td>
            <td>{getCoursePublicIcon(course.isPublic)}</td>
            <td>
              <Group spacing="xl">
                <Menu
                  control={
                    <Group
                      data-testid="menu-control"
                      spacing={3}
                      style={{ cursor: "pointer" }}
                    >
                      <SmallCircle color={Color.DARK} />
                      <SmallCircle color={Color.DARK} />
                      <SmallCircle color={Color.DARK} />
                    </Group>
                  }
                >
                  <Menu.Item
                    icon={<Eye size={14} />}
                    component={Link}
                    to={`/courses/${course.id.toString()}`}
                  >
                    {t("Common:view")}
                  </Menu.Item>
                  <Menu.Item
                    icon={<Pencil size={14} />}
                    component={Link}
                    to={"/courses/" + course.id.toString() + "/edit"}
                  >
                    {t("Common:edit")}
                  </Menu.Item>
                  {/* <Menu.Item
                    icon={<UserPlus size={14} />}
                    onClick={() => onEditCourse(course)}
                  >
                    {t("Common:addUser")}
                  </Menu.Item> */}
                  <Menu.Item
                    icon={<Trash size={14} />}
                    onClick={() => deleteMutation.mutate(course.id)}
                  >
                    {t("Common:delete")}
                  </Menu.Item>

                  <Menu.Item
                    icon={<Copy size={14} />}
                    onClick={() => handleCloneCourse(course.id)}
                  >
                    {t("Common:clone")}
                  </Menu.Item>
                </Menu>
              </Group>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
