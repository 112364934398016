import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Container, Skeleton } from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";

import {
  useOrganizations,
  useCreateOrganization,
  useUpdateOrganization,
  useDeleteOrganization,
} from "hooks/organizations";

import {
  OrganizationsMenu,
  OrganizationsTable,
  CreateModal,
  EditModal,
} from "components/organizations";
import { BasePage, Pagination } from "components/common";

import { Color } from "enums/common";

import { Organization } from "types/organizations";

const OrganizationsTitle = styled.div`
  color: ${Color.DARK};
  font-size: 36px;
  font-weight: 600;
  margin-top: 105px;
  margin-bottom: 10px;
`;

export const Organizations: React.FC = () => {
  const { t } = useTranslation("Common");

  const [searchKey, setSearchKey] = useState("");
  const [debouncedSearchKey] = useDebouncedValue(searchKey, 300);

  const [page, setPage] = useState(1);
  const [resultCount, setResultCount] = useState(15);

  const [selectedOrganization, setSelectedOrganization] =
    useState<Organization>();

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);

  const createOrganizationMutation = useCreateOrganization();
  const updateOrganizationMutation = useUpdateOrganization();
  const deleteOrganizationMutation = useDeleteOrganization();

  const { data, isLoading } = useOrganizations({
    page,
    "search-key": debouncedSearchKey,
    size: resultCount,
  });

  const handleEditOrganization = (organization: Organization) => {
    setSelectedOrganization(organization);
    setIsUpdateModalOpen(true);
  };

  return (
    <BasePage>
      <Container data-testid="organizations-page">
        <OrganizationsTitle>{t("organizations")}</OrganizationsTitle>
        <OrganizationsMenu
          searchKey={searchKey}
          setSearchKey={setSearchKey}
          setIsModalOpen={setIsCreateModalOpen}
        />
        {isLoading && (
          <Skeleton height={550} radius="sm" style={{ marginTop: 30 }} />
        )}
        {data && (
          <>
            <OrganizationsTable
              organizations={data.elements}
              deleteMutation={deleteOrganizationMutation}
              onEditOrganization={handleEditOrganization}
            />
            <Pagination
              page={page}
              totalItems={data.total}
              itemsPerPage={resultCount}
              setItemsPerPage={setResultCount}
              setPage={setPage}
            />
          </>
        )}
        <CreateModal
          mutation={createOrganizationMutation}
          opened={isCreateModalOpen}
          onClose={() => setIsCreateModalOpen(false)}
        />
        {selectedOrganization && (
          <EditModal
            organization={selectedOrganization}
            mutation={updateOrganizationMutation}
            opened={isUpdateModalOpen}
            onClose={() => {
              setIsUpdateModalOpen(false);
              setSelectedOrganization(undefined);
            }}
          />
        )}
      </Container>
    </BasePage>
  );
};
