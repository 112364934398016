import { Container } from "@mantine/core";
import styled from "styled-components";
import { BasePage } from "../../components/common";
import { UserManagementTabMenu } from "../../components/userManagement/UserManagementTabMenu/UserManagementTabMenu";
import { Color } from "enums/common";
import { useTranslation } from "react-i18next";

const StyledUserManagementTabMenu = styled(UserManagementTabMenu)`
  margin: 34px 0 40px 0;
`;

const Title = styled.div`
  color: ${Color.DARK};
  font-size: 36px;
  font-weight: 600;
  margin-top: 105px;
  margin-bottom: 10px;
`;

export const UserManagement: React.FC = () => {
  const { t } = useTranslation();

  return (
    <BasePage>
      <Container data-testid="userManagement-page">
        <Title>{t("Common:userManagement")}</Title>
        <StyledUserManagementTabMenu />
      </Container>
    </BasePage>
  );
};
