import { Box, Container, Skeleton, Space, Text } from "@mantine/core";
import { AudioResourcesModal } from "components/chapters/ResourceModals/AudioResourcesModal";
import { ImageResourcesModal } from "components/chapters/ResourceModals/ImageResourcesModal";
import { VideoResourcesModal } from "components/chapters/ResourceModals/VideoResourcesModal";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { EditChapterPageForm } from "../../../components/chapters/EditChapterPageForm/EditChapterPageForm";
import { Banner, BasePage } from "../../../components/common";
import { Color } from "enums/common";
import { useChapterPage } from "../../../hooks/chapters/chapterpages/useChapterPage";
import { useUpdateChapterPage } from "../../../hooks/chapters/chapterpages/useUpdateChapterPage";
import { Resource } from "types/resources/Resource";

const StyledBanner = styled(Banner)`
  margin: 34px 0 40px 0;
`;

export const EditChapterPage: React.FC = () => {
  const { t } = useTranslation();
  const { pageId } = useParams();

  const { data, isLoading } = useChapterPage(Number(pageId));

  const [selectedImage, setSelectedImage] = useState<Resource | undefined>();
  const [selectedVideo, setSelectedVideo] = useState<Resource | undefined>();
  const [selectedAudio, setSelectedAudio] = useState<Resource | undefined>();
  const [resourcesLoaded, setResourcesLoaded] = useState(false);

  const [isVideoResourcesModalOpen, setIsVideoResourcesModalOpen] =
    useState(false);
  const [isImageResourcesModalOpen, setIsImageResourcesModalOpen] =
    useState(false);
  const [isAudioResourcesModalOpen, setIsAudioResourcesModalOpen] =
    useState(false);

  const updateChapterPageMutation = useUpdateChapterPage();

  useEffect(() => {
    if (data && data.resources.length) {
      const selectedImage = data?.resources.filter(
        (x) => x.resourceType.id === 0
      )[0];

      const selectedVideo = data?.resources.filter(
        (x) => x.resourceType.id === 2
      )[0];

      const selectedAudio = data?.resources.filter(
        (x) => x.resourceType.id === 1
      )[0];

      setSelectedVideo(selectedVideo);
      setSelectedImage(selectedImage);
      setSelectedAudio(selectedAudio);
    }
    setResourcesLoaded(true);
  }, [data]);

  return (
    <BasePage>
      <Space h="xl" />
      <Container>
        <StyledBanner title="" text="">
          <Box sx={{ maxWidth: 800 }} mx="auto">
            <Text
              styles={() => ({
                root: {
                  fontSize: 28,
                  fontWeight: 600,
                  fontFamily: "Montserrat",
                  color: Color.DARK,
                },
              })}
            >
              {t("Courses:createCourse.editPage")}
            </Text>
            <Space h="xl" />
            {isLoading && (
              <Skeleton height={550} radius="sm" style={{ marginTop: 30 }} />
            )}
            {data && resourcesLoaded && (
              <EditChapterPageForm
                page={data}
                mutation={updateChapterPageMutation}
                setIsVideoResourcesModalOpen={setIsVideoResourcesModalOpen}
                setIsImageResourcesModalOpen={setIsImageResourcesModalOpen}
                setIsAudioResourcesModalOpen={setIsAudioResourcesModalOpen}
                selectedImage={selectedImage}
                selectedVideo={selectedVideo}
                selectedAudio={selectedAudio}
                setSelectedVideo={setSelectedVideo}
                setSelectedImage={setSelectedImage}
                setSelectedAudio={setSelectedAudio}
              />
            )}
            {isVideoResourcesModalOpen && (
              <VideoResourcesModal
                setVideo={setSelectedVideo}
                video={selectedVideo}
                opened={isVideoResourcesModalOpen}
                onClose={() => setIsVideoResourcesModalOpen(false)}
              />
            )}
            {isImageResourcesModalOpen && (
              <ImageResourcesModal
                setImage={setSelectedImage}
                image={selectedImage}
                opened={isImageResourcesModalOpen}
                onClose={() => setIsImageResourcesModalOpen(false)}
              />
            )}
            {isAudioResourcesModalOpen && (
              <AudioResourcesModal
                setAudio={setSelectedAudio}
                audio={selectedAudio}
                opened={isAudioResourcesModalOpen}
                onClose={() => setIsAudioResourcesModalOpen(false)}
              />
            )}
          </Box>
        </StyledBanner>
      </Container>
    </BasePage>
  );
};
