import { CHAPTERS } from "../../enums/chapters/urls";

import {
  Chapter,
  CreateChapterPayload,
  UpdateChapterObject,
} from "../../types/chapters/chapters";
import { ChapterDetails } from "../../types/chapters/chapterDetails";

import { createAxiosInstance } from "api/config";

const axios = createAxiosInstance();

export const getChapters = async (
  courseId: number
): Promise<ChapterDetails[]> => {
  const { data } = await axios.get(`/${CHAPTERS.CHAPTERS}/${courseId}/all`, {
    headers: { "Content-Type": "application/json;" },
    data: {},
  });

  return data;
};

export const getChapter = async (id: number): Promise<Chapter> => {
  const { data } = await axios.get(`/${CHAPTERS.CHAPTERS}/${id}`);

  return data;
};

export const createChapter = async (
  payload: CreateChapterPayload
): Promise<number> => {
  const { data } = await axios.post(`/${CHAPTERS.CREATE_CHAPTER}`, payload);

  return data;
};

export const deleteChapter = async (id: number): Promise<void> => {
  const { data } = await axios.delete(`/${CHAPTERS.CHAPTERS}/${id}`);

  return data;
};

export const updateChapter = async (
  chapter: UpdateChapterObject
): Promise<number> => {
  const { data } = await axios.put(
    `/${CHAPTERS.CHAPTERS}/${chapter.id}`,
    chapter.payload,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return data;
};
