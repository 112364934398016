import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { AlignRight, Users } from "tabler-icons-react";
import { Image } from "@mantine/core";

import { Color } from "enums/common";

const OrganizationDetailsPreviewContainer = styled.div`
  height: 100px;
  padding: 0 40px 0 0;
  background-color: ${Color.LIGHT_GRAY};
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.05);
`;

const Avatar = styled.div`
  height: 100%;
  width: 100px;
  float: left;
`;

const InfoPanel = styled.div`
  height: 100%;
  background-color: ${Color.LIGHT_GRAY};
  padding: 12px 40px 0 40px;
  float: left;
  box-sizing: border-box;
`;

const PanelDetails = styled.div`
  float: right;
`;

const Details = styled.div`
  width: 180px;
  margin: 43px 15px 10px 15px;
  display: inline;
  float: left;
`;

const Icon = styled.div`
  color: ${Color.DARK};
  float: left;
`;

const Title = styled.div`
  font-size: 18px;
  line-height: 28px;
  color: ${Color.DARK};
  font-weight: 600;
`;

const Text = styled.div`
  max-width: 681px;
  margin: 0px 0 16px 0;
  font-size: 16px;
  line-height: 150%;
  color: ${Color.DARK};
`;

export interface OrganizationDetailsPreviewProps {
  className?: string;
  totalDepartments: number;
  totalUsers: number;
  avatarURL: string;
  organizationName: string;
  organizationSummary: string;
  children?: React.ReactNode;
}

export const OrganizationDetailsPreview: React.FC<
  OrganizationDetailsPreviewProps
> = ({
  className,
  totalDepartments,
  totalUsers,
  avatarURL,
  organizationName,
  organizationSummary,
  children,
}) => {
  const { t } = useTranslation("Organization");

  return (
    <OrganizationDetailsPreviewContainer
      data-testid="organizationDetailsPreview"
      className={className}
    >
      {avatarURL && (
        <Avatar>
          <div
            style={{
              marginTop: 10,
              width: 100,
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <Image src={avatarURL} alt="organization-avatar" />
          </div>
        </Avatar>
      )}

      <InfoPanel>
        <Title>{organizationName}</Title>
        <Text>{organizationSummary}</Text>
      </InfoPanel>
      <PanelDetails>
        <Details>
          <Icon>
            <AlignRight size={23} strokeWidth={2} color={Color.DARK} />
          </Icon>
          <Text>{`${totalDepartments} ${t("panelDetails.departments")}`}</Text>
        </Details>
        <Details>
          <Icon>
            <Users size={23} strokeWidth={2} color={Color.DARK} />
          </Icon>
          <Text>{`${totalUsers} ${t("panelDetails.users")}`}</Text>
        </Details>
      </PanelDetails>
      {children}
    </OrganizationDetailsPreviewContainer>
  );
};
