import { Table } from "@mantine/core";
import { Color } from "enums/common";
import { useTranslation } from "react-i18next";
import { User, UsersObject } from "types/user/User";

export interface AssignExerciseUsersTableProps {
  organizationUsers: User[];
  selectedOrganization: number;
  setSelectedUsers: (user: UsersObject) => void;
}

export const AssignExerciseUsersTable: React.FC<
  AssignExerciseUsersTableProps
> = ({ organizationUsers, setSelectedUsers }) => {
  const { t } = useTranslation();

  const handleUsersSelection = (userId: string, checked: boolean) => {
    const user = organizationUsers?.find((x) => x.id?.toString() === userId);

    if (user) {
      const customUser = {
        ...user,
        checked: checked,
      };
      setSelectedUsers(customUser);
    }
  };

  return (
    <Table
      data-testid="exercises-table"
      highlightOnHover
      verticalSpacing={6}
      style={{ marginTop: 5, marginBottom: 20, textAlign: "center" }}
      sx={() => ({
        backgroundColor: Color.WHITE,
        boxShadow: "box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.15)",
      })}
    >
      <thead>
        <tr>
          <th></th>
          <th>{t("Exercises:table.name")}</th>
          <th>{t("Exercises:table.nickName")}</th>
          <th>{t("Exercises:table.userEmail")}</th>
        </tr>
      </thead>
      <tbody>
        {organizationUsers?.map((organizationUser, index) => (
          <tr key={organizationUser.id}>
            <td>
              <input
                type="checkbox"
                name="checkbox"
                id={organizationUser.id.toString()}
                onChange={(event) =>
                  handleUsersSelection(event.target.id, event.target.checked)
                }
              />
            </td>
            <td> {organizationUser.fullName}</td>
            <td>{organizationUser.nickName}</td>
            <td>{organizationUser.email}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
