import { Button, Group, Modal } from "@mantine/core";
import { AxiosError } from "axios";
import { Pagination } from "components/common";
import { ErrorCode } from "enums/api/ErrorCode";
import { Color } from "enums/common";
import { useDepartmentUsers } from "hooks/departments/assignDepartment/departmentUsers";
import { useAllUsersOrganizationDepartment } from "hooks/organizations/useAllOrganizationUsersDepartment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ApiError } from "types/api";
import { UsersObject } from "types/user/User";
import {
  endNotification,
  generateRandomString,
  warningNotification,
} from "utils/common";
import { AssignDepartmentTable } from "./AssignDepartmentTable";

export interface AssignUsersDepartmentModalProps {
  opened: boolean;
  mutation: any;
  onClose: () => void;
  setSelectedUsers: (user: UsersObject) => void;
}

export const AssignUsersDepartmentModal: React.FC<
  AssignUsersDepartmentModalProps
> = ({ opened, mutation, onClose }) => {
  const { t } = useTranslation();
  const { departmentId } = useParams();
  const [page, setPage] = useState(1);
  const [resultCount, setResultCount] = useState(20);
  const [selectedUsers, setSelectedUsers] = useState<UsersObject[]>([]);
  const { organizationId } = useParams();

  const handleClose = () => {
    onClose();
  };

  const selectedDepartmentUsers = useDepartmentUsers(
    Number(departmentId),
    page,
    "",
    resultCount
  );

  const { data } = useAllUsersOrganizationDepartment(
    Number(organizationId),
    1,
    "",
    15
  );

  const handleUserSelection = (user: UsersObject) => {
    if (!user.checked) {
      setSelectedUsers(
        selectedUsers.filter((selectedUser) => selectedUser.id !== user.id)
      );
    } else {
      setSelectedUsers((current) => [...current, user]);
    }
  };

  const handleSubmit = () => {
    if (selectedUsers.length === 0) {
      const randomId = generateRandomString(20);
      warningNotification(
        randomId,
        t("Department:notifications.assignUsersDepartmentWarning")
      );
    } else {
      mutation.mutate(
        {
          departmentId: Number(departmentId),
          userIds: selectedUsers?.map((user) => user.id),
        },
        {
          onSuccess: () => {
            setSelectedUsers([]);
            handleClose();
          },
          onError: (error: AxiosError<ApiError>) => {
            if (
              error.response?.data.errorCode === ErrorCode.NAME_ALREADY_EXISTS
            ) {
              const randomId = generateRandomString(20);
              endNotification(
                randomId,
                t("Department:notifications.assignError"),
                false
              );
            }
          },
        }
      );
    }
  };

  return (
    <Modal
      centered
      data-testid="assignUsersToDepartment-modal"
      size={1200}
      title={t("Department:modal:assignUsersToDepartment")}
      opened={opened}
      onClose={handleClose}
    >
      {data && (
        <>
          <AssignDepartmentTable
            organizationUsers={data.elements}
            selectedUsers={selectedDepartmentUsers}
            setSelectedUsers={handleUserSelection}
          />
          <Pagination
            page={page}
            totalItems={Number(data.elements.length)}
            itemsPerPage={resultCount}
            setItemsPerPage={setResultCount}
            setPage={setPage}
          />
        </>
      )}
      <Group position="right" mt="md">
        <Button
          data-testid="submit-button"
          type="submit"
          onClick={handleSubmit}
          styles={(theme) => ({
            root: {
              fontSize: 16,
              fontFamily: "Montserrat",
              backgroundColor: Color.DARK,
              fontWeight: 600,

              "&:hover": {
                backgroundColor: theme.fn.lighten(Color.DARK, 0.1),
              },
            },
          })}
        >
          {t("Common:actions.submit")}
        </Button>
      </Group>
    </Modal>
  );
};
