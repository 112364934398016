import React, { useState } from "react";
import { Box, Container, Space, Text } from "@mantine/core";
import { Banner, BasePage } from "../../../components/common";
import { Color } from "enums/common";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { CreateChapterPageForm } from "../../../components/chapters/CreateChapterPageForm/CreateChapterPageForm";
import { useCreateChapterPage } from "../../../hooks/chapters/chapterpages/useChapterPages";
import { VideoResourcesModal } from "../../../components/chapters/ResourceModals/VideoResourcesModal";
import { ImageResourcesModal } from "../../../components/chapters/ResourceModals/ImageResourcesModal";
import { AudioResourcesModal } from "../../../components/chapters/ResourceModals/AudioResourcesModal";
import { Resource } from "types/resources/Resource";

const StyledBanner = styled(Banner)`
  margin: 34px 0 40px 0;
`;

export const CreateChapterPage: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const [selectedImage, setSelectedImage] = useState<Resource | undefined>();
  const [selectedVideo, setSelectedVideo] = useState<Resource | undefined>();
  const [selectedAudio, setSelectedAudio] = useState<Resource | undefined>();

  const [isVideoResourcesModalOpen, setIsVideoResourcesModalOpen] =
    useState(false);
  const [isImageResourcesModalOpen, setIsImageResourcesModalOpen] =
    useState(false);
  const [isAudioResourcesModalOpen, setIsAudioResourcesModalOpen] =
    useState(false);

  const createChapterMutation = useCreateChapterPage();

  return (
    <BasePage>
      <Space h="xl" />
      <Container>
        <StyledBanner title="" text="">
          <Box sx={{ maxWidth: 800 }} mx="auto">
            <Text
              styles={() => ({
                root: {
                  fontSize: 28,
                  fontWeight: 600,
                  fontFamily: "Montserrat",
                  color: Color.DARK,
                },
              })}
            >
              {t("Courses:createCourse.newPage")}
            </Text>
            <Space h="xl" />
            <CreateChapterPageForm
              id={Number(id)}
              mutation={createChapterMutation}
              setIsVideoResourcesModalOpen={setIsVideoResourcesModalOpen}
              setIsImageResourcesModalOpen={setIsImageResourcesModalOpen}
              setIsAudioResourcesModalOpen={setIsAudioResourcesModalOpen}
              selectedImage={selectedImage}
              selectedVideo={selectedVideo}
              selectedAudio={selectedAudio}
              setSelectedAudio={setSelectedAudio}
              setSelectedImage={setSelectedImage}
              setSelectedVideo={setSelectedVideo}
            />
            {isVideoResourcesModalOpen && (
              <VideoResourcesModal
                setVideo={setSelectedVideo}
                video={selectedVideo}
                opened={isVideoResourcesModalOpen}
                onClose={() => setIsVideoResourcesModalOpen(false)}
              />
            )}
            {isImageResourcesModalOpen && (
              <ImageResourcesModal
                setImage={setSelectedImage}
                image={selectedImage}
                opened={isImageResourcesModalOpen}
                onClose={() => setIsImageResourcesModalOpen(false)}
              />
            )}
            {isAudioResourcesModalOpen && (
              <AudioResourcesModal
                setAudio={setSelectedAudio}
                audio={selectedAudio}
                opened={isAudioResourcesModalOpen}
                onClose={() => setIsAudioResourcesModalOpen(false)}
              />
            )}
          </Box>
        </StyledBanner>
      </Container>
    </BasePage>
  );
};
