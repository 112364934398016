export enum Color {
  DARK = "#26145C",
  DARK_LIGHTER = "#26145CCC",
  WHITE = "#FFFFFF",
  GRAY = "#E5E5E5",
  LIGHT_GRAY = "#E3E1EA",
  BITTER_SWEET = "#F2675A",
  MONTE_CARLO = "#75C3B8",
  PASTEL_BLUE = "#D2E8E5",
  PASTEL_GREEN = "#C9DED5",
  HOLLOW_BROWN = "#9289AD",
  GREEN_CYAN = "#80C7BE",
  ORANGE = "#F36767",
}
