import React from "react";
import styled from "styled-components";

import { Image, Space } from "@mantine/core";

import { useLogin } from "hooks/auth/useLogin";

import { LoginForm } from "components/login";
import { LanguageChangeMenu } from "../../components/common";

const StyledBackground = styled.div`
  background-size: auto;
  background-image: url("../images/loginbg.jpg");
  min-height: 100vh;
`;

const StyledBody = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 12em;
`;

export const Login: React.FC = () => {
  const createLoginMutation = useLogin();

  return (
    <StyledBackground>
      <LanguageChangeMenu />
      <StyledBody>
        <Image src="../images/future-ready.png" />
        <Space w="xl" />
        <LoginForm mutation={createLoginMutation} />
      </StyledBody>
    </StyledBackground>
  );
};
