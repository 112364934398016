import { useQuery } from "@tanstack/react-query";

import { getUsers } from "../../api/users/users";

export const useUserManagements = (
  page: number,
  searchKey: string,
  size: number,
  roleId: number
) => {
  return useQuery(["users", page, searchKey, size, roleId], () =>
    getUsers(page, searchKey, size, roleId)
  );
};
