import { useQuery } from "@tanstack/react-query";

import { getAllOrganizationsUsers } from "api/organizations";

export const useAllUsersOrganizationDepartment = (
  organizationId: number,
  page: number,
  searchKey: string,
  size: number
) => {
  return useQuery(
    ["organizationUsers", organizationId, page, searchKey, size],
    () => getAllOrganizationsUsers(organizationId, page, searchKey, size)
  );
};
