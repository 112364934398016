import { Box, Container, Skeleton } from "@mantine/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { Banner, BasePage } from "../../components/common";
import { useTemplate } from "../../hooks/templates/useTemplate";
import { useUpdateTemplate } from "../../hooks/templates/useUpdateTemplate";
import { UpdateTemplatePage } from "./UpdateTemplatePage";

const StyledBanner = styled(Banner)`
  margin: 34px 0 40px 0;
`;

export const UpdateTemplate: React.FC = () => {
  const { t } = useTranslation();
  const updateTemplateMutation = useUpdateTemplate();
  const { id } = useParams();
  const { data, isLoading } = useTemplate(Number(id));

  return (
    <BasePage>
      <Container data-testid="templates-page">
        <Box sx={{ maxWidth: 800 }} mx="auto">
          <StyledBanner title={t("Templates:modals:edit")} text={""}>
            {isLoading && (
              <Skeleton height={550} radius="sm" style={{ marginTop: 30 }} />
            )}
            {data && (
              <UpdateTemplatePage
                template={data}
                mutation={updateTemplateMutation}
              ></UpdateTemplatePage>
            )}
          </StyledBanner>
        </Box>
      </Container>
    </BasePage>
  );
};
