import { useMutation } from "@tanstack/react-query";

import { resetPassword } from "api/auth";

import {
  endNotification,
  generateRandomString,
  startNotification,
} from "utils/common";

import { ResetPasswordPayload } from "types/auth";

import i18n from "i18n";

export const useResetPassword = () => {
  const randomId = generateRandomString(20);

  return useMutation(
    (payload: ResetPasswordPayload) => resetPassword(payload),
    {
      onMutate: () => {
        startNotification(randomId);
      },
      onSuccess: () => {
        endNotification(
          randomId,
          i18n.t("resetPassword.success", { ns: "Auth" }),
          true
        );
      },
      onError: () => {
        endNotification(
          randomId,
          i18n.t("resetPassword.failed", { ns: "Auth" }),
          false
        );
      },
    }
  );
};
