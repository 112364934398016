import { QUIZZES } from "../../enums/chapters/urls";
import { Quiz } from "../../types/chapters/chapterDetails";
import {
  QuizPayload,
  UpdateQuizObject,
} from "../../types/chapters/chapterpages";
import { createAxiosInstance } from "api/config";

const axios = createAxiosInstance();

export const getChapterQuiz = async (id: number): Promise<Quiz> => {
  const { data } = await axios.get(`/${QUIZZES.GET_QUIZZES}/${id}`);

  return data;
};

export const createChapterQuiz = async (
  payload: QuizPayload
): Promise<number> => {
  const { data } = await axios.post(`/${QUIZZES.CREATE_QUIZZES}`, payload);

  return data;
};

export const deleteChapterQuiz = async (id: number): Promise<void> => {
  const { data } = await axios.delete(`/${QUIZZES.QUIZZES}/${id}`);

  return data;
};

export const updateChapterQuiz = async (
  quiz: UpdateQuizObject
): Promise<number> => {
  const { data } = await axios.put(
    `/${QUIZZES.QUIZZES}/${quiz.id}`,
    quiz.payload,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return data;
};
