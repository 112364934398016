import { Button, Modal, TextInput, Title } from "@mantine/core";
import React from "react";
import { useTranslation } from "react-i18next";

import { useForm } from "@mantine/form";

import { useChangePassword } from "hooks/auth";

interface ChangePasswordModalProps {
  opened: boolean;
  onClose: () => void;
}

export const ChangePasswordModal: React.FC<ChangePasswordModalProps> = ({
  opened,
  onClose,
}) => {
  const { t } = useTranslation("Auth");

  const changePasswordMutation = useChangePassword();

  const form = useForm({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      newPasswordConfirm: "",
    },

    validate: {
      newPassword: (value) => {
        if (value === form.values.oldPassword) {
          return t("changePassword.samePasswordError");
        }
      },
      newPasswordConfirm: (value) => {
        if (value !== form.values.newPassword) {
          return t("changePassword.passwordMatchError");
        }
      },
    },
  });

  const handleSubmit = (oldPassword: string, newPassword: string) => {
    changePasswordMutation.mutate(
      {
        password: oldPassword,
        newPassword,
      },
      {
        onSuccess: () => {
          onClose();
        },
      }
    );
  };

  return (
    <Modal
      size="md"
      title={<Title order={3}>{t("changePassword.title")}</Title>}
      opened={opened}
      onClose={onClose}
    >
      <form
        onSubmit={form.onSubmit((values) =>
          handleSubmit(values.oldPassword, values.newPassword)
        )}
      >
        <TextInput
          required
          autoComplete="current-password"
          type="password"
          label={t("changePassword.oldPassword")}
          placeholder={t("changePassword.oldPasswordPlaceholder")}
          {...form.getInputProps("oldPassword")}
        />
        <TextInput
          required
          autoComplete="new-password"
          type="password"
          label={t("changePassword.newPassword")}
          placeholder={t("changePassword.newPasswordPlaceholder")}
          mt="lg"
          {...form.getInputProps("newPassword")}
        />
        <TextInput
          required
          autoComplete="new-password"
          type="password"
          label={t("changePassword.confirmPassword")}
          placeholder={t("changePassword.confirmPasswordPlaceholder")}
          {...form.getInputProps("newPasswordConfirm")}
        />
        <Button mt="xl" type="submit">
          {t("actions.submit", { ns: "Common" })}
        </Button>
      </form>
    </Modal>
  );
};
