import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { AxiosError } from "axios";

import { Modal, TextInput, Textarea, Group, Button } from "@mantine/core";
import { useForm } from "@mantine/form";

import { ImageUpload } from "components/common";

import { ApiError } from "types/api";
import { Organization } from "types/organizations";

import { ErrorCode } from "enums/api";

export interface EditModalProps {
  organization: Organization;
  opened: boolean;
  mutation: any;
  onClose: () => void;
}

export const EditModal: React.FC<EditModalProps> = ({
  organization,
  opened,
  mutation,
  onClose,
}) => {
  const { t } = useTranslation("Organizations");
  const [avatarIdentifier, setAvatarIdentifier] = useState<string | undefined>(
    organization.imageId
  );

  const form = useForm({
    initialValues: {
      name: organization.name,
      summary: organization.summary,
      avatar: organization.imageId,
    },
  });

  const handleClose = () => {
    setAvatarIdentifier(undefined);
    form.reset();
    onClose();
  };

  const handleSubmit = () => {
    mutation.mutate(
      {
        id: organization.id,
        payload: { ...form.values, avatar: avatarIdentifier },
      },
      {
        onSuccess: () => {
          handleClose();
        },
        onError: (error: AxiosError<ApiError>) => {
          if (
            error.response?.data.errorCode === ErrorCode.NAME_ALREADY_EXISTS
          ) {
            form.setFieldError("name", t("errors.nameExists"));
          }
        },
      }
    );
  };

  return (
    <Modal
      centered
      data-testid="edit-modal"
      size="lg"
      title={t("modals.update")}
      opened={opened}
      onClose={handleClose}
    >
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <TextInput
          required
          label={t("words.name", { ns: "Common" })}
          placeholder={t("words.name", { ns: "Common" })}
          {...form.getInputProps("name")}
        />
        <Textarea
          required
          label={t("modals.summary")}
          placeholder={t("modals.summary")}
          mb={3}
          {...form.getInputProps("summary")}
        />
        <ImageUpload
          avatarPreviewUrl={organization.imageUrl}
          setAvatarIdentifier={setAvatarIdentifier}
        />
        <Group position="right" mt="md">
          <Button data-testid="submit-button" type="submit">
            {t("actions.submit", { ns: "Common" })}
          </Button>
        </Group>
      </form>
    </Modal>
  );
};
