import { Space } from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";
import { Pagination } from "components/common";
import { Roles } from "enums/users";
import { useUserManagements } from "hooks/UserManagement/useUserManagements";
import { useState } from "react";

import { UserManagementManagersMenu } from "./UserManagerManagersMenu";
import { UserManagementManagersTable } from "./UserManagerManagersTable";

export const UserManagerManagersTab: React.FC = () => {
  const [page, setPage] = useState(1);
  const [searchKey, setSearchKey] = useState("");
  const [debouncedSearchKey] = useDebouncedValue(searchKey, 300);
  const [resultCount, setResultCount] = useState(20);

  const { data } = useUserManagements(
    page,
    debouncedSearchKey,
    resultCount,
    Number(Roles.MANAGER)
  );

  return (
    <>
      <Space h="lg" />
      <UserManagementManagersMenu
        setSearchKey={setSearchKey}
        searchKey={searchKey}
      />
      <Space h="md" />
      {data && (
        <>
          <UserManagementManagersTable managers={data.elements} />
          <Pagination
            page={page}
            totalItems={data.total}
            itemsPerPage={resultCount}
            setItemsPerPage={setResultCount}
            setPage={setPage}
          />
        </>
      )}
    </>
  );
};
