import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import styled from "styled-components";

import { Banner, BasePage } from "../../components/common";

import { Color } from "enums/common";
import { Container, Text, Space, Box } from "@mantine/core";
import { CreateExercisePageForm } from "../../components/exercises/CreateExercisePageForm/CreateExercisePageForm";
import { useCreateExercisePages } from "../../hooks/exercises/exercisepages/useExercisePages";

const StyledBanner = styled(Banner)`
  margin: 34px 0 40px 0;
`;

export const CreatePage: React.FC = () => {
  const { id } = useParams();
  const { t } = useTranslation();

  const createExercisePageMutation = useCreateExercisePages();

  return (
    <BasePage>
      <Container>
        <Box sx={{ maxWidth: 800 }} mx="auto">
          <StyledBanner title="" text="">
            <Box sx={{ maxWidth: 800 }} mx="auto">
              <Text
                styles={() => ({
                  root: {
                    fontSize: 28,
                    fontWeight: 600,
                    fontFamily: "Montserrat",
                    color: Color.DARK,
                  },
                })}
              >
                {t("Exercises:createExercisePage.createPage")}
              </Text>
              <Space h="lg" />
              <CreateExercisePageForm
                mutation={createExercisePageMutation}
                id={Number(id)}
              />
            </Box>
            <Space h="xl" />
          </StyledBanner>
        </Box>
      </Container>
    </BasePage>
  );
};
