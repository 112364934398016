import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { Trash, ChevronDown } from "tabler-icons-react";
import { Group, Table, Menu } from "@mantine/core";

// Types
import { Color } from "enums/common";
import { SortParams } from "../CoursesTabs/UsersCoursesTab";

// Components
import styled from "styled-components";
import { UserCourse } from "types/userCourse/userCourse";

interface CircleProps {
  color: string;
}

const SmallCircle = styled.div`
  text-align: center;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ color }: CircleProps) => color};
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export interface CoursesTableProps {
  userCourses: UserCourse[];
  deleteMutation: any;
  setSortKey: (value: number) => void;
  handleOnChangeParams: (value: SortParams) => void;
}

export const SortKeysMap: { [key: string]: number } = {
  userEmail: 0,
  userOrganization: 1,
  userName: 2,
  courseName: 3,
  courseOrganization: 4,
};

export const UserCoursesTable: React.FC<CoursesTableProps> = ({
  userCourses,
  deleteMutation,
  setSortKey,
  handleOnChangeParams,
}) => {
  // Params
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const sortBy: string | null = searchParams.get("sortBy");

  // Effects
  useEffect(() => {
    if (sortBy) setSortKey(SortKeysMap[sortBy]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy]);

  return (
    <Table
      data-testid="userCourses-table"
      highlightOnHover
      verticalSpacing={6}
      style={{ marginTop: 20, marginBottom: 20, textAlign: "center" }}
      sx={() => ({
        backgroundColor: Color.WHITE,
        boxShadow: "box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.15)",
      })}
    >
      <thead>
        <tr>
          <th
            style={{ cursor: "pointer" }}
            onClick={() => handleOnChangeParams({ sortBy: "userEmail" })}
          >
            {" "}
            <Flex>
              {t("UserCourses:table.email")}{" "}
              {sortBy === "userEmail" && <ChevronDown />}
            </Flex>
          </th>
          <th
            style={{ cursor: "pointer" }}
            onClick={() => handleOnChangeParams({ sortBy: "userOrganization" })}
          >
            <Flex>
              {t("UserCourses:table.userOrganization")}{" "}
              {sortBy === "userOrganization" && <ChevronDown />}
            </Flex>
          </th>
          <th
            style={{ cursor: "pointer" }}
            onClick={() => handleOnChangeParams({ sortBy: "userName" })}
          >
            <Flex>
              {t("UserCourses:table.fullName")}{" "}
              {sortBy === "userName" && <ChevronDown />}
            </Flex>
          </th>
          <th
            style={{ cursor: "pointer" }}
            onClick={() => handleOnChangeParams({ sortBy: "courseName" })}
          >
            <Flex>
              {t("UserCourses:table.courseName")}{" "}
              {sortBy === "courseName" && <ChevronDown />}
            </Flex>
          </th>
          <th
            style={{ cursor: "pointer" }}
            onClick={() =>
              handleOnChangeParams({ sortBy: "courseOrganization" })
            }
          >
            <Flex>
              {t("UserCourses:table.courseOrganization")}{" "}
              {sortBy === "courseOrganization" && <ChevronDown />}
            </Flex>
          </th>
          <th>{t("UserCourses:table.actions")}</th>
        </tr>
      </thead>
      <tbody>
        {userCourses.map((userCourse) => (
          <tr key={userCourse.id}>
            <td>{userCourse.email}</td>
            <td>{userCourse.userOrganizationName}</td>
            <td>{userCourse.fullName}</td>
            <td>{userCourse.courseName}</td>
            <td>{userCourse.courseOrganizationName}</td>
            <td>
              <Group spacing="xl">
                <Menu
                  control={
                    <Group
                      data-testid="menu-control"
                      spacing={3}
                      style={{ cursor: "pointer" }}
                    >
                      <SmallCircle color={Color.DARK} />
                      <SmallCircle color={Color.DARK} />
                      <SmallCircle color={Color.DARK} />
                    </Group>
                  }
                >
                  <Menu.Item
                    icon={<Trash size={14} />}
                    onClick={() => deleteMutation.mutate(userCourse.id)}
                  >
                    {t("Common:removeAccess")}
                  </Menu.Item>
                </Menu>
              </Group>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
