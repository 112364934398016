import { useQuery } from "@tanstack/react-query";

import { getUserCourses } from "../../api/userCourses/userCourses";

export const useUserCourses = (
  page: number,
  searchKey: string,
  size: number,
  sortKey: number | null,
  organizationId: number | null
) => {
  return useQuery(
    ["userCourses", page, searchKey, size, sortKey, organizationId],
    () => getUserCourses(page, searchKey, size, sortKey, organizationId)
  );
};
