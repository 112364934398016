import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { InfoCircle, Logout } from "tabler-icons-react";

import { Avatar, Group, Menu } from "@mantine/core";

import { AvatarPlaceholder, ExpandMenu } from "components/Icons";

import { Color } from "enums/common";

import { defaultLoginResponse, UserContext } from "contexes";

const UserAvatarContainer = styled.div`
  display: flex;
  align-items: center;
`;

const UserNameContainer = styled.div`
  user-select: none;
  color: ${Color.WHITE};
  font-weight: bold;
  font-size: 18px;
  margin-left: 20px;
`;

const StyledGroup = styled(Group)`
  cursor: pointer;
`;

export interface UserAvatarProps {
  userName: string;
}

export const UserAvatar: React.FC<UserAvatarProps> = ({ userName }) => {
  const [, setUserContext] = useContext(UserContext);

  const { t } = useTranslation("Common");
  const navigate = useNavigate();

  const logout = () => {
    localStorage.removeItem("authToken");
    setUserContext(defaultLoginResponse);
    navigate("/login");
  };

  return (
    <UserAvatarContainer data-testid="user-avatar">
      <Avatar radius="xl" size="lg">
        <AvatarPlaceholder />
      </Avatar>
      <Menu
        control={
          <StyledGroup data-testid="clickable-group" spacing="xs">
            <UserNameContainer>{userName}</UserNameContainer>
            <ExpandMenu />
          </StyledGroup>
        }
      >
        <Menu.Item
          onClick={() => navigate("/my-profile")}
          icon={<InfoCircle size={14} />}
        >
          {t("myProfile")}
        </Menu.Item>
        <Menu.Item onClick={logout} icon={<Logout size={14} />}>
          {t("actions.signOut")}
        </Menu.Item>
      </Menu>
    </UserAvatarContainer>
  );
};
