import { useMutation } from "@tanstack/react-query";
import { queryClient } from "App";

import i18n from "../../i18n";

import { upload } from "api/resources";

import {
  generateRandomString,
  startNotification,
  endNotification,
} from "utils/common";

export const useUploadResource = () => {
  const randomId = generateRandomString(20);

  return useMutation((formData: FormData) => upload(formData), {
    onMutate: () => {
      startNotification(randomId);
    },
    onSuccess: () => {
      endNotification(
        randomId,
        i18n.t("notifications.createSuccess", { ns: "Uploader" }),
        true
      );
      queryClient.invalidateQueries(["resources"]);
    },
    onError: () => {
      endNotification(
        randomId,
        i18n.t("notifications.createError", { ns: "Uploader" }),
        false
      );
    },
  });
};
