import React from "react";
import styled from "styled-components";
import { Group, Button, TextInput } from "@mantine/core";
import { useTranslation } from "react-i18next";

import { Color } from "enums/common";
import { Link } from "react-router-dom";

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export interface TemplatesMenuProps {
  searchKey: string;
  setSearchKey: (searchKey: string) => void;
}

export const TemplatesMenu: React.FC<TemplatesMenuProps> = ({
  searchKey,
  setSearchKey,
}) => {
  const { t } = useTranslation();

  return (
    <FlexContainer data-testid="templates-menu">
      <Group spacing={0}>
        <TextInput
          value={searchKey}
          onChange={(event) => setSearchKey(event.currentTarget.value)}
          placeholder={t("Templates:searchPlaceholder")}
          styles={{
            root: { width: 598 },
            input: { borderRadius: 20 },
          }}
        />
        <Button
          variant="filled"
          onClick={() => setSearchKey(searchKey)}
          styles={(theme) => ({
            root: {
              fontSize: 14,
              textTransform: "uppercase",
              fontFamily: "Montserrat",
              fontWeight: 600,
              backgroundColor: Color.DARK,
              borderRadius: 20,
              left: -30,

              "&:hover": {
                backgroundColor: theme.fn.lighten(Color.DARK, 0.1),
              },
            },
          })}
        >
          {t("Common:search")}
        </Button>
      </Group>
      <Button
        component={Link}
        to={"/exercises/createTemplate"}
        variant="outline"
        styles={() => ({
          root: {
            fontSize: 14,
            textTransform: "uppercase",
            color: Color.DARK,
            fontFamily: "Montserrat",
            fontWeight: 600,
            borderColor: Color.DARK,
            borderWidth: 3,
          },
        })}
      >
        {t("Common:createNew")}
      </Button>
    </FlexContainer>
  );
};
