import React, { useContext } from "react";

import { UserContext } from "contexes";

import { Header } from "components/common";

import { convertEmailToUsername } from "utils/common";

export interface BasePageProps {
  children?: React.ReactNode;
}

export const BasePage: React.FC<BasePageProps> = ({ children }) => {
  const [userContext] = useContext(UserContext);

  return (
    <div data-testid="base-page">
      <Header userName={convertEmailToUsername(userContext.nickName)} />
      {children}
    </div>
  );
};
