import { Templates } from "../../enums/templates/urls";
import { ApiResponse } from "../../types/api/ApiResponse";
import {
  CreateTemplatePayload,
  Template,
  UpdateTemplateObject,
} from "../../types/template/template";
import { createAxiosInstance } from "api/config";

const axios = createAxiosInstance();

export const getTemplates = async (
  page: number,
  searchKey: string,
  size: number
): Promise<ApiResponse<Template[]>> => {
  const { data } = await axios.get(
    `/${Templates.GET_TEMPLATES}/?page=${page}&searchKey=${searchKey}&size=${size}`
  );

  return data;
};

export const getTemplate = async (templateId: number): Promise<Template> => {
  const { data } = await axios.get(`/${Templates.TEMPLATES}/${templateId}`);

  return data;
};

export const createTemplate = async (
  payload: CreateTemplatePayload
): Promise<number> => {
  const { data } = await axios.post(`/${Templates.CREATE_TEMPLATE}`, payload);

  return data;
};

export const deleteTemplate = async (id: number): Promise<void> => {
  const { data } = await axios.delete(`/${Templates.TEMPLATES}/${id}`);

  return data;
};

export const updateTemplate = async (
  template: UpdateTemplateObject
): Promise<number> => {
  const { data } = await axios.put(
    `/${Templates.TEMPLATES}/${template.id}`,
    { ...template },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return data;
};
