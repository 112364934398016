import {
  Card,
  Group,
  useMantineTheme,
  Image,
  Text,
  Anchor,
  Space,
} from "@mantine/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { LockOpen, Lock, Clock } from "tabler-icons-react";
import { Color } from "enums/common";
import { Course } from "../../../types/courses/courses";

export interface CoursesListProps {
  courses: Course[];
}

const FlexContainer = styled.div`
  margin-top: 50px;
  color: ${Color.DARK};
`;

export const CoursesList: React.FC<CoursesListProps> = ({ courses }) => {
  const theme = useMantineTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const iconStrokeWidth = 1;
  const iconSize = 22;

  return (
    <FlexContainer>
      <h3>{t("Courses:cart-title")}</h3>
      <Group spacing="lg">
        {courses.length &&
          courses.slice(0, 5).map((course) => (
            <Card
              shadow="sm"
              p="lg"
              style={{ height: 320, cursor: "pointer" }}
              key={course.id}
              onClick={() => navigate(`/courses/${course.id.toString()}`)}
            >
              <Card.Section>
                <Image
                  src="../images/courses-image.png"
                  height={130}
                  alt="Norway"
                />
              </Card.Section>

              <Group
                position="apart"
                style={{ marginBottom: 5, marginTop: theme.spacing.sm }}
              >
                <Text weight={500}>{course.name}</Text>
              </Group>

              <Space h="md" />

              <Group position="apart">
                <Group spacing={5}>
                  <Clock
                    size={iconSize}
                    strokeWidth={iconStrokeWidth}
                    color={"black"}
                  />
                  <Text style={{ fontFamily: "Montserrat", fontSize: 14 }}>
                    {course.duration} {t("Common:minutes")}
                  </Text>
                </Group>

                <Group spacing={5}>
                  {course.isPublic ? (
                    <>
                      <LockOpen
                        size={iconSize}
                        strokeWidth={iconStrokeWidth}
                        color={"green"}
                      />
                      <Text style={{ fontFamily: "Montserrat", fontSize: 14 }}>
                        {t("Courses:public")}
                      </Text>
                    </>
                  ) : (
                    <>
                      <Lock
                        size={iconSize}
                        strokeWidth={iconStrokeWidth}
                        color={"gray"}
                      />
                      <Text style={{ fontFamily: "Montserrat", fontSize: 14 }}>
                        {t("Courses:private")}
                      </Text>
                    </>
                  )}
                </Group>
              </Group>
            </Card>
          ))}
      </Group>

      <Group position="right" mt="md">
        <Anchor
          component={Link}
          to="all"
          styles={(theme) => ({
            root: {
              marginTop: "10px",
              color: Color.DARK,
              fontWeight: "bold",
            },
          })}
        >
          {t("Courses:showMore")}
        </Anchor>
      </Group>
    </FlexContainer>
  );
};
