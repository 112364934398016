import { Grid } from "@mantine/core";
import { Banner } from "components/common";
import { Color } from "enums/common";
import { useDepartment } from "hooks/departments/useDepartment";
import { useOrganization } from "hooks/organizations/useOrganization";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const StyledBanner = styled(Banner)`
  margin: 34px 0 40px 0;
`;

const Text = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: ${Color.DARK};
`;

export const DepartmentDetailsBanner: React.FC = () => {
  const { t } = useTranslation();
  const { departmentId } = useParams();
  const { data } = useDepartment(Number(departmentId));
  const { organizationId } = useParams();

  const organizationQuery = useOrganization(Number(organizationId));

  return (
    <StyledBanner title={t("Department:details.title")} text={""}>
      <Grid>
        <Grid.Col span={6}>
          <Text color={Color.DARK}>
            {t("Department:details.organization")}:{" "}
            {organizationQuery.data?.name}
          </Text>
        </Grid.Col>
      </Grid>
      <Grid>
        <Grid.Col span={6}>
          <Text color={Color.DARK}>
            {t("Department:details.name")}: {data?.name}
          </Text>
        </Grid.Col>
        <Grid.Col span={6}>
          <Text color={Color.DARK}>
            {t("Department:details.summary")}: {data?.summary}
          </Text>
        </Grid.Col>
      </Grid>
      <Grid>
        <Grid.Col span={6}>
          <Text color={Color.DARK}>
            {t("Department:details.status")}:{" "}
            {t(`UserManagement:state.${data?.activityStatus.toLowerCase()}`)}
          </Text>
        </Grid.Col>
        <Grid.Col span={6}>
          <Text color={Color.DARK}>
            {t("Department:details.manager")}:{" "}
            {data?.manager
              ? `${data?.manager.fullName} (${data?.manager.nickName})`
              : ""}
          </Text>
        </Grid.Col>
      </Grid>
    </StyledBanner>
  );
};
