import { createAxiosInstance } from "api/config";
import { VOICEBOTS } from "enums/voicebots/urls";
import { configuration } from "config";

const axios = createAxiosInstance(configuration().baseConversationsUrl);

export const getVoiceBots = async () => {
  const { data } = await axios.get(`/${VOICEBOTS.GET_VOICEBOTS}`);

  var voicebots = Object.keys(data).map((id: string) => ({
    key: id,
    description: data[id].description,
  }));

  return voicebots;
};
