import React, { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Mail } from "tabler-icons-react";

import { Button, Group, Image, Space, Text, TextInput } from "@mantine/core";

import { LanguageChangeMenu } from "components/common";

import { useResetPassword } from "hooks/auth";

import { Color } from "enums/common";

const StyledBackground = styled.div`
  background-size: auto;
  background-image: url("../images/loginbg.jpg");
  min-height: 100vh;
`;

const StyledBody = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 12em;
`;

export const ResetPassword: React.FC = () => {
  const { t } = useTranslation("Auth");
  const resetPasswordMutation = useResetPassword();

  const [isResetButtonVisible, setIsResetButtonVisible] = useState(true);
  const [email, setEmail] = useState("");
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [isSuccessMessageVisible, setIsSuccessMessageVisible] = useState(false);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setEmailErrorMessage("");
  };

  const handleSubmit = () => {
    if (!email.includes("@")) {
      setEmailErrorMessage(t("resetPassword.invalidEmail"));
    } else {
      resetPasswordMutation.mutate(
        {
          email,
        },
        {
          onSuccess: () => {
            setIsResetButtonVisible(false);
            setIsSuccessMessageVisible(true);
          },
        }
      );
    }
  };

  return (
    <StyledBackground>
      <LanguageChangeMenu />
      <StyledBody>
        <Image src="../images/future-ready.png" />
        <Space w="xl" />
        <Group direction="column">
          <Text size="xl" color="white">
            {t("resetPassword.email")}
          </Text>
          <TextInput
            required
            value={email}
            error={emailErrorMessage}
            onChange={handleEmailChange}
            size="lg"
            radius="xl"
            placeholder={t("resetPassword.email")}
            icon={<Mail size={18} />}
            sx={{
              input: {
                fontSize: 14,
                width: 300,
              },
            }}
          />
          {isResetButtonVisible && (
            <Button
              radius="xl"
              size="lg"
              styles={() => ({
                root: {
                  width: 300,
                  backgroundColor: Color.ORANGE,
                  "&:hover": {
                    backgroundColor: Color.ORANGE,
                  },
                },
              })}
              onClick={handleSubmit}
            >
              {t("resetPassword.resetButton")}
            </Button>
          )}
          {isSuccessMessageVisible && (
            <Text size="xl" color="white">
              {t("resetPassword.checkEmail")}
            </Text>
          )}
        </Group>
      </StyledBody>
    </StyledBackground>
  );
};
