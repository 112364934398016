import { useMutation } from "@tanstack/react-query";

import { updateChapterQuiz } from "../../../api/chapters/quizes";
import { queryClient } from "../../../App";
import i18n from "../../../i18n";
import { UpdateQuizObject } from "../../../types/chapters/chapterpages";
import { generateRandomString } from "../../../utils/common/commonUtils";
import {
  endNotification,
  startNotification,
} from "../../../utils/common/notifications";

export const useUpdateChapterQuiz = () => {
  const randomId = generateRandomString(20);

  return useMutation((object: UpdateQuizObject) => updateChapterQuiz(object), {
    onMutate: () => {
      startNotification(randomId);
    },
    onSuccess: () => {
      endNotification(
        randomId,
        i18n.t("Quiz:notifications.updateSuccess"),
        true
      );
      queryClient.invalidateQueries(["chapters"]);
    },
    onError: () => {
      endNotification(
        randomId,
        i18n.t("Quiz:notifications.updateError"),
        false
      );
    },
  });
};
