import React from "react";
import { useTranslation } from "react-i18next";

import { Table, Badge } from "@mantine/core";
import { Lock, LockOpen } from "tabler-icons-react";

import { Course, CoursesObject } from "../../../types/courses/courses";

import { Color } from "enums/common";

export interface AssignCoursesTableProps {
  courses: Course[];
  selectedCourses: CoursesObject[];
  setSelectedCourses: (course: CoursesObject) => void;
}

export const AssignCoursesTable: React.FC<AssignCoursesTableProps> = ({
  courses,
  setSelectedCourses,
}) => {
  const { t } = useTranslation();

  const handleCourseSelection = (courseId: string, checked: boolean) => {
    const course = courses.find((x) => x.id.toString() === courseId);

    if (course) {
      const customCourse = {
        ...course,
        checked: checked,
      };
      setSelectedCourses(customCourse);
    }
  };

  const getBadgeColor = (id: number) => {
    let color = "";
    switch (id) {
      case 1:
        color = "green";
        break;
      case 2:
        color = "blue";
        break;
    }
    return color;
  };

  const getCoursePublicIcon = (isPublic: Boolean) => {
    return isPublic ? (
      <LockOpen size={28} strokeWidth={2} color={"green"} />
    ) : (
      <Lock size={28} strokeWidth={2} color={"gray"} />
    );
  };

  return (
    <Table
      data-testid="courses-table"
      highlightOnHover
      verticalSpacing={6}
      style={{ marginTop: 5, marginBottom: 20, textAlign: "center" }}
      sx={() => ({
        backgroundColor: Color.WHITE,
        boxShadow: "box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.15)",
      })}
    >
      <thead>
        <tr>
          <th></th>
          <th>{t("Courses:table.name")}</th>
          <th>{t("Courses:table.organization")}</th>
          <th>{t("Courses:table.status")}</th>
          <th>{t("Courses:table.public")}</th>
        </tr>
      </thead>
      <tbody>
        {courses.map((course, index) => (
          <tr key={course.id}>
            <td>
              <input
                type="checkbox"
                name="checkbox"
                id={course.id.toString()}
                onChange={(event) =>
                  handleCourseSelection(event.target.id, event.target.checked)
                }
              ></input>
            </td>
            <td>{course.name}</td>
            <td>{course.organization ? course.organization.name : ""}</td>
            <td>
              <Badge color={getBadgeColor(course.activationStatus.id)}>
                {t(`Courses:status.${course.activationStatus.name}`)}
              </Badge>
            </td>
            <td>{getCoursePublicIcon(course.isPublic)}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
