import { Button, Group, Modal, Space, Text } from "@mantine/core";
import { FileUpload } from "components/common/FileUpload/FileUpload";
import { Pagination } from "components/common/Pagination/Pagination";
import { Color } from "enums/common";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Resource } from "types/resources/Resource";
import { useResources } from "../../../hooks/resources/useResources";
import { AudioList } from "./AudioList";
import { warningNotification } from "../../../utils/common/notifications";
import { generateRandomString } from "../../../utils/common/commonUtils";

export interface AudioResourcesModalProps {
  opened: boolean;
  onClose: () => void;
  setAudio: (resource: Resource | undefined) => void;
  audio: Resource | undefined;
}

export const AudioResourcesModal: React.FC<AudioResourcesModalProps> = ({
  setAudio,
  audio,
  opened,
  onClose,
}) => {
  const [selectedAudio, setSelectedAudio] = useState<Resource | undefined>();
  const [page, setPage] = useState(1);
  const [resultCount, setResultCount] = useState(15);
  const [fileId, setFileId] = useState<string | undefined>();
  const [audios, setAudios] = useState<Resource[]>([]);

  const { data } = useResources(page, resultCount, 1);

  const { t } = useTranslation();

  useEffect(() => {
    if (audio) setSelectedAudio(audio);
  }, [audio]);

  useEffect(() => {
    if (data?.elements) {
      setAudios(data?.elements);
      setSelectedAudio(audio);
    }
  }, [data?.elements, audio]);

  useEffect(() => {
    var selectedVideo = audios.find((x) => x.storageIdentifier === fileId);
    setSelectedAudio(selectedVideo);
  }, [fileId, audios]);

  const handleClose = () => {
    onClose();
  };

  const handleSelectedAudio = () => {
    if (!selectedAudio) {
      const randomId = generateRandomString(20);
      warningNotification(
        randomId,
        t("Chapters:notifications.createResourceFileError")
      );
    } else {
      setAudio(selectedAudio);
      onClose();
    }
  };

  useEffect(() => {
    setSelectedAudio(audio);
  }, [audio]);

  const handleFileId = (fileId: string | undefined) => {
    if (!fileId) return;
    setFileId(fileId);
  };

  return (
    <Modal
      centered
      data-testid="uploadvideo-modal"
      size="lg"
      title="Add Audio"
      opened={opened}
      onClose={handleClose}
    >
      <FileUpload
        type="audio"
        fileId={fileId}
        setFileId={(fileId) => handleFileId(fileId)}
      />
      <Space h="xl" />
      <Text
        styles={() => ({
          root: {
            fontSize: 16,
            color: Color.DARK,
            fontWeight: 600,
          },
        })}
      >
        {t("Common:select")}
      </Text>
      <Space h="md" />
      {data && (
        <>
          <AudioList
            selectedAudio={selectedAudio}
            setSelectedAudio={setSelectedAudio}
            resources={audios}
          />
          <Space h="xl" />
          <Pagination
            page={page}
            totalItems={data.total}
            itemsPerPage={resultCount}
            setItemsPerPage={setResultCount}
            setPage={setPage}
          />
        </>
      )}
      <Space h="md" />
      <Group>
        <Button
          onClick={handleSelectedAudio}
          styles={() => ({
            root: {
              fontSize: 14,
              fontFamily: "Montserrat",
              backgroundColor: Color.DARK,
              fontWeight: 600,

              "&:hover": {
                backgroundColor: Color.DARK,
              },
            },
          })}
        >
          {t("Common:save")}
        </Button>
        <Button
          onClick={handleClose}
          styles={() => ({
            root: {
              fontSize: 14,
              fontFamily: "Montserrat",
              fontWeight: 600,
              borderColor: Color.DARK,
              backgroundColor: Color.WHITE,
              color: Color.DARK,
              marginRight: 10,

              "&:hover": {
                backgroundColor: Color.WHITE,
              },
            },
          })}
        >
          {t("Common:cancel")}
        </Button>
      </Group>
    </Modal>
  );
};
