import { useMutation } from "@tanstack/react-query";
import { deleteUserExercise } from "api/userExercise/userExercise";
import { queryClient } from "App";
import i18n from "i18n";
import {
  endNotification,
  generateRandomString,
  startNotification,
} from "utils/common";

export const useDeleteUserExercise = () => {
  const randomId = generateRandomString(20);

  return useMutation((id: number) => deleteUserExercise(id), {
    onMutate: () => {
      startNotification(randomId);
    },
    onSuccess: () => {
      endNotification(
        randomId,
        i18n.t("Exercises:notifications.deletePageSuccess"),
        true
      );
      queryClient.invalidateQueries(["userExercises"]);
    },
    onError: () => {
      endNotification(
        randomId,
        i18n.t("Exercises:notifications.deletePageError"),
        false
      );
    },
  });
};
