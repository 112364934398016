import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";

// Components
import { Text, Button, Container, Space } from "@mantine/core";
import { BasePage } from "components/common/BasePage/BasePage";
import { Banner } from "components/common";
import { ExercisePagesOverview } from "components/exercises/exercisePages/ExercisePageOverview/ExercisePagesOverview";
import { VoicebotPage } from "components/voicebot/VoicebotPage";
import { RichTextContent } from "components/RichText";

// Hooks
import { useExercise } from "hooks/exercises/useExercise";

// Types
import { Color } from "enums/common";

// Style
const StyledBanner = styled(Banner)`
  margin: 34px 0 40px 0;
`;

const Content = styled.div`
  h4 {
    font-size: 15px;
    font-weight: 400;
  }
  span {
    font-size: 17;
    color: rgb(38, 20, 92);
    font-weight: 700;
  }
`;

export const ExerciseDetails: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { data, refetch } = useExercise(Number(id));
  const [selectedVoicebot, setSelectedVoicebot] = useState("");

  const handleRefreshDetails = () => {
    refetch({ type: "all" });
  };

  useEffect(() => {
    if (data) {
      setSelectedVoicebot(data.rasaBotKey);
    }
  }, [data]);

  const { description, subtitle, limitOfMessages, endSummaryPrompt } =
    data || {};

  return (
    <BasePage>
      <Container data-testid="exercise-page">
        {data?.templateId === 3 && (
          <StyledBanner title={data.title} text={""}>
            <Content>
              {description && (
                <h4>
                  <span style={{ verticalAlign: "top" }}>
                    {t("Exercises:createExercise.description")}:
                  </span>{" "}
                  <div
                    style={{ display: "inline-block", whiteSpace: "pre-line" }}
                  >
                    <RichTextContent htmlContent={description} />
                  </div>{" "}
                </h4>
              )}
              {subtitle && (
                <h4>
                  <span style={{ verticalAlign: "top" }}>
                    {t("Exercises:createExercise.subtitle")}
                  </span>
                  : {subtitle}
                </h4>
              )}
              {data.prompt && (
                <h4 style={{ whiteSpace: "pre-line", verticalAlign: "top" }}>
                  <span>{t("Exercises:createExercise.prompt")}</span>:{" "}
                  {data.prompt}
                </h4>
              )}
              {limitOfMessages && (
                <h4>
                  <span>{t("Exercises:createExercise.numberOfMessages")}</span>{" "}
                  : {limitOfMessages}
                </h4>
              )}
              {endSummaryPrompt && (
                <h4>
                  <span>{t("Exercises:createExercise.endSummaryPrompt")}</span>{" "}
                  : {endSummaryPrompt}
                </h4>
              )}
            </Content>
          </StyledBanner>
        )}
        {data?.templateId === 1 && (
          <StyledBanner title={data.title} text={data.description}>
            <Space h="lg" />
            <Button
              component={Link}
              to={`/exercises/${id}/pages/create`}
              size="xl"
              styles={(theme) => ({
                root: {
                  fontSize: 18,
                  fontFamily: "Montserrat",
                  backgroundColor: Color.DARK,
                  fontWeight: 600,

                  "&:hover": {
                    backgroundColor: theme.fn.lighten(Color.DARK, 0.1),
                  },
                },
              })}
            >
              {t("Exercise:banner.createPage")}
            </Button>
          </StyledBanner>
        )}
        {data?.templateId === 1 && (
          <Text weight={500} size="xl" color="#2B1467;">
            {t("Exercise:banner.pages")}
          </Text>
        )}
      </Container>
      <Container data-testid="exercises-page">
        {data?.templateId === 1 && (
          <ExercisePagesOverview
            exerciseId={data.id}
            onRefresh={handleRefreshDetails}
          />
        )}
      </Container>
      {data?.templateId === 2 && (
        <VoicebotPage
          selectedVoicebot={selectedVoicebot}
          setSelectedVoicebot={setSelectedVoicebot}
        />
      )}
    </BasePage>
  );
};
