import { Avatar, Group, Text, Space } from "@mantine/core";
import styled from "styled-components";
import { AlignRight, Atom2 } from "tabler-icons-react";
import { Color } from "enums/common";
import { ChapterDetails } from "../../../../types/chapters/chapterDetails";

const AccordionContainer = styled.div`
  flex: 1;
`;

const Icon = styled.div`
  color: ${Color.DARK};
  float: left;
  display: flex;
  padding-right: 16px;
  margin-right: 34px;
`;

export interface ChapterProps {
  chapter: ChapterDetails;
  setAccordionOpen?: (chapterId: number) => void;
}

export function DeptsIcon() {
  return <AlignRight size={23} strokeWidth={2} color={Color.DARK} />;
}
export function AtomIcon() {
  return <Atom2 size={23} strokeWidth={2} color={Color.DARK} />;
}

export function AccordionLabel({ chapter, setAccordionOpen }: ChapterProps) {
  return (
    <>
      <Group>
        <Avatar src="../images/defavatar.png" size="lg" />
        <AccordionContainer>
          <Text color="#2B1467;">{chapter?.name}</Text>
          <Text size="sm" color="#2B1467;" weight={400}>
            {chapter?.summary}
          </Text>
        </AccordionContainer>
        <Icon>
          <DeptsIcon></DeptsIcon>
        </Icon>
        <Icon>
          <AtomIcon></AtomIcon>
          <Space w="xs" />
          <Text color="#2B1467;">{chapter?.duration} min</Text>
        </Icon>
      </Group>
    </>
  );
}
