import { createContext } from "react";

import { LoginResponse } from "types/auth";

export const defaultLoginResponse: LoginResponse = {
  access_token: "",
  authority: [],
  nickName: "",
  orgAdmin: false,
};

export const UserContext = createContext<
  [LoginResponse, (loginResponse: LoginResponse) => void]
>([defaultLoginResponse, () => null]);
