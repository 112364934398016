import { UserContext } from "contexes";
import { useContext } from "react";
import { useMutation } from "@tanstack/react-query";

import { login } from "api/auth";

import {
  endNotification,
  generateRandomString,
  startNotification,
} from "utils/common";

import { LoginPayload } from "types/auth";

import i18n from "../../i18n";

export const useLogin = () => {
  const randomId = generateRandomString(20);
  const [, setUserContext] = useContext(UserContext);

  return useMutation((payload: LoginPayload) => login(payload), {
    onMutate: () => {
      startNotification(randomId);
    },
    onSuccess: (response) => {
      localStorage.setItem("authToken", response.data.access_token);

      endNotification(
        randomId,
        i18n.t("loginPage.success", { ns: "Auth" }),
        true
      );

      setUserContext(response.data);
    },
    onError: () => {
      endNotification(
        randomId,
        i18n.t("loginPage.failed", { ns: "Auth" }),
        false
      );
    },
  });
};
