import { Badge, Space, Table } from "@mantine/core";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { LockOpen, Lock } from "tabler-icons-react";
import { Color } from "enums/common";
import { useUserManagement } from "../../../hooks/UserManagement/useUseManagement";
import { Pagination } from "components/common";

export const CoursesDetailsTable: React.FC = () => {
  const [usersPage, setUsersPage] = useState(1);
  const [usersResultCount, setUsersResultCount] = useState(15);

  const { t } = useTranslation();
  const { id } = useParams();

  const { data } = useUserManagement(Number(id), usersPage, usersResultCount);

  const getCoursePublicIcon = (isPublic: Boolean) => {
    return isPublic ? (
      <LockOpen size={28} strokeWidth={2} color={"green"} />
    ) : (
      <Lock size={28} strokeWidth={2} color={"gray"} />
    );
  };

  const getBadgeColor = (id: number) => {
    let color = "";
    switch (id) {
      case 1:
        color = "green";
        break;
      case 2:
        color = "blue";
        break;
    }
    return color;
  };

  console.log("courses", data?.courses.elements);

  const coursesDetails =
    data &&
    data.courses.total &&
    data.courses &&
    data.courses.elements &&
    data.courses.elements.map((course, index) => (
      <Fragment key={index}>
        <tr key={course.id}>
          <td>{course.name}</td>
          <td>{course.summary}</td>
          <td>{course.organization ? course.organization.name : ""}</td>
          <td>{course.duration ? course.duration : ""}</td>
          <td>{course.skills}</td>
          <td>
            <Badge color={getBadgeColor(course.activationStatus.id)}>
              {t(`UserManagement:status.${course.activationStatus.name}`)}
            </Badge>
          </td>
          <td>{getCoursePublicIcon(course.isPublic)}</td>
        </tr>
      </Fragment>
    ));

  return (
    <>
      <Table
        data-testid="coursesDetails-table"
        highlightOnHover
        verticalSpacing={6}
        style={{ marginTop: 5, marginBottom: 20, textAlign: "center" }}
        sx={() => ({
          backgroundColor: Color.WHITE,
          boxShadow: "box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.15)",
        })}
      >
        <thead>
          <tr>
            <th>{t("UserManagement:courseDetails.name")}</th>
            <th>{t("UserManagement:courseDetails.summary")}</th>
            <th>{t("UserManagement:courseDetails.organization")}</th>
            <th>{t("UserManagement:courseDetails.duration")}</th>
            <th>{t("UserManagement:courseDetails.skills")}</th>
            <th>{t("UserManagement:courseDetails.status")}</th>
            <th>{t("UserManagement:courseDetails.public")}</th>
          </tr>
        </thead>
        <tbody>{coursesDetails}</tbody>
      </Table>
      <Space h="xl" />
      {data && (
        <Pagination
          page={usersPage}
          totalItems={data.courses.total}
          itemsPerPage={usersResultCount}
          setItemsPerPage={setUsersResultCount}
          setPage={setUsersPage}
        />
      )}
    </>
  );
};
