import { Exercises } from "../../enums/exercises/urls";
import { ApiResponse } from "../../types/api/ApiResponse";
import {
  CreateExercisePayload,
  Exercise,
  UpdateExerciseObject,
  UpdateVoicebotObject,
} from "../../types/exercises/exercises";

import { createAxiosInstance } from "api/config";

const axios = createAxiosInstance();

export const getExercises = async (
  page: number,
  searchKey: string,
  size: number,
  category: string
): Promise<ApiResponse<Exercise[]>> => {
  const { data } = await axios.get(
    `/${Exercises.GET_EXERCISES}?page=${page}&search-key=${searchKey}&size=${size}&category-id=${category}`,
    { headers: { "Content-Type": "application/json;" }, data: {} }
  );

  return data;
};

export const getOrganizationExercises = async (
  organizationId: number,
  page: number,
  searchKey: string,
  size: number,
  category: string
): Promise<ApiResponse<Exercise[]> | undefined> => {
  var result = undefined;

  if (!organizationId || organizationId === 0) {
    result = getExercises(page, searchKey, size, category);
  } else {
    const { data } = await axios.get(
      `/${Exercises.EXERCISES}/${organizationId}/all?page=${page}&search-key=${searchKey}&size=${size}&category-id=${category}`,
      { headers: { "Content-Type": "application/json;" }, data: {} }
    );
    result = data;
  }

  return result;
};

export const getExercise = async (id: number): Promise<Exercise> => {
  const { data } = await axios.get(`/${Exercises.EXERCISES}/${id}`);

  return data;
};

export const createExercise = async (
  payload: CreateExercisePayload
): Promise<number> => {
  const { data } = await axios.post(`/${Exercises.CREATE_EXERCISES}`, payload);

  return data;
};

export const deleteExercise = async (id: number): Promise<void> => {
  const { data } = await axios.delete(`/${Exercises.EXERCISES}/${id}`);

  return data;
};

export const updateExercise = async (
  exercise: UpdateExerciseObject
): Promise<number> => {
  const { data } = await axios.put(
    `/${Exercises.EXERCISES}/${exercise.id}`,
    { ...exercise },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return data;
};

export const createVoicebot = async (
  exercise: UpdateVoicebotObject
): Promise<number> => {
  const { data } = await axios.put(
    `/${Exercises.CREATE_EXERCISE_VOICEBOT}/${exercise.id}/add-bot`,
    exercise.payload
  );

  return data;
};

export const cloneExercise = async (id: number): Promise<void> => {
  await axios.post(`/${Exercises.EXERCISES}/${id}/clone`, id);
};
