import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";
import { Container, Skeleton } from "@mantine/core";

import { BasePage } from "../../components/common/BasePage/BasePage";
import { Pagination } from "../../components/common/Pagination/Pagination";

import { Color } from "enums/common";

import { TemplatesMenu } from "../../components/templates/TemplatesMenu/TemplatesMenu";
import { TemplatesTable } from "../../components/templates/TemplatesTable/TemplatesTable";
import { useDeleteTemplate } from "../../hooks/templates/useDeleteTemplate";
import { useTemplates } from "../../hooks/templates/useTemplates";

const TemplatesTitle = styled.div`
  color: ${Color.DARK};
  font-size: 36px;
  font-weight: 600;
  margin-top: 105px;
  margin-bottom: 10px;
`;

export const Templates: React.FC = () => {
  const { t } = useTranslation();
  const [searchKey, setSearchKey] = useState("");
  const [page, setPage] = useState(1);
  const [resultCount, setResultCount] = useState(15);

  const deleteTemplateMutation = useDeleteTemplate();
  const { data, isLoading } = useTemplates(page, searchKey, resultCount);

  return (
    <BasePage>
      <Container data-testid="templates-page">
        <TemplatesTitle>{t("Common:templates")}</TemplatesTitle>
        <TemplatesMenu setSearchKey={setSearchKey} searchKey={searchKey} />
        {isLoading && (
          <Skeleton height={550} radius="sm" style={{ marginTop: 30 }} />
        )}
        {data && (
          <>
            <TemplatesTable
              templates={data.elements}
              deleteMutation={deleteTemplateMutation}
            />
            <Pagination
              page={page}
              totalItems={data.total}
              itemsPerPage={resultCount}
              setItemsPerPage={setResultCount}
              setPage={setPage}
            />
          </>
        )}
      </Container>
    </BasePage>
  );
};
