import { useContext } from "react";
import { useQuery } from "@tanstack/react-query";

import { UserContext } from "contexes";

import { validateToken } from "api/auth/auth";

import { ApiDataResponse } from "types/api/ApiResponse";
import { LoginResponse } from "types/auth";

export const useToken = (token: string | null) => {
  const [, setUserContext] = useContext(UserContext);

  return useQuery(["validateToken", token], () => validateToken(token), {
    onSuccess(data: ApiDataResponse<LoginResponse>) {
      setUserContext(data.data);
    },
    retry: false,
  });
};
