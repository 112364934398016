import { Exercises } from "../../enums/exercises/urls";
import { ApiResponse } from "../../types/api/ApiResponse";
import { UserExercise } from "../../types/exercises/exercises";
import { createAxiosInstance } from "api/config";

const axios = createAxiosInstance();

export const getUserExercises = async (
  page: number,
  searchKey: string,
  size: number
): Promise<ApiResponse<UserExercise[]>> => {
  const { data } = await axios.get(
    `/${Exercises.GET_USER_EXERCISES}?page=${page}&search-key=${searchKey}&size=${size}`,
    { headers: { "Content-Type": "application/json;" }, data: {} }
  );

  return data;
};
