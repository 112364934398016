import { useMutation, useQuery } from "@tanstack/react-query";

import {
  createExercisePage,
  getExercisePages,
} from "../../../api/exercises/exercisepages";
import { queryClient } from "../../../App";
import i18n from "../../../i18n";
import { CreateExercisePagesPayload } from "../../../types/exercises/exercisepages";
import { generateRandomString } from "../../../utils/common/commonUtils";
import {
  endNotification,
  startNotification,
} from "../../../utils/common/notifications";

export const useExercisePages = (exerciseId: number) => {
  return useQuery(["exercise-pages", exerciseId], () =>
    getExercisePages(exerciseId)
  );
};

export const useCreateExercisePages = () => {
  const randomId = generateRandomString(20);

  return useMutation(
    (payload: CreateExercisePagesPayload) => createExercisePage(payload),
    {
      onMutate: () => {
        startNotification(randomId);
      },
      onSuccess: () => {
        endNotification(
          randomId,
          i18n.t("Exercises:notifications.createPageSuccess"),
          true
        );
        queryClient.invalidateQueries(["exercise-page"]);
      },
      onError: () => {
        endNotification(
          randomId,
          i18n.t("Exercises:notifications.createPageError"),
          false
        );
      },
    }
  );
};
