import { Button, Group, Modal, Space, Text } from "@mantine/core";
import { FileUpload } from "components/common/FileUpload/FileUpload";
import { Color } from "enums/common";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Resource } from "types/resources/Resource";
import { useResources } from "../../../hooks/resources/useResources";
import { Pagination } from "../../common/Pagination/Pagination";
import { VideoList } from "./VideoList";
import { warningNotification } from "../../../utils/common/notifications";
import { generateRandomString } from "../../../utils/common/commonUtils";

export interface VideoResourcesModalProps {
  opened: boolean;
  onClose: () => void;
  setVideo: (resource: Resource | undefined) => void;
  video: Resource | undefined;
}

export const VideoResourcesModal: React.FC<VideoResourcesModalProps> = ({
  video,
  setVideo,
  opened,
  onClose,
}) => {
  const [selectedVideo, setSelectedVideo] = useState<Resource | undefined>();
  const [page, setPage] = useState(1);
  const [resultCount, setResultCount] = useState(15);
  const [fileId, setFileId] = useState<string | undefined>();
  const [videos, setVideos] = useState<Resource[]>([]);

  const { data } = useResources(page, resultCount, 2);

  const { t } = useTranslation();

  useEffect(() => {
    if (video) setSelectedVideo(video);
  }, [video]);

  useEffect(() => {
    if (data?.elements) {
      setVideos(data?.elements);
      setSelectedVideo(video);
    }
  }, [data?.elements, video]);

  useEffect(() => {
    var selectedVideo = videos.find((x) => x.storageIdentifier === fileId);
    setSelectedVideo(selectedVideo);
  }, [fileId, videos]);

  const handleClose = () => {
    onClose();
  };

  const handleSelectedVideo = () => {
    if (!selectedVideo) {
      const randomId = generateRandomString(20);
      warningNotification(
        randomId,
        t("Chapters:notifications.createResourceFileError")
      );
    } else {
      setVideo(selectedVideo);
      onClose();
    }
  };

  useEffect(() => {
    setSelectedVideo(video);
  }, [video]);

  const handleFileId = (fileId: string | undefined) => {
    if (!fileId) return;
    setFileId(fileId);
  };

  return (
    <Modal
      centered
      data-testid="upload-modal"
      size="lg"
      title="Add Video"
      opened={opened}
      onClose={handleClose}
    >
      <FileUpload
        fileId={fileId}
        setFileId={(fileId) => handleFileId(fileId)}
        type="video"
      />
      <Space h="xl" />
      <Text
        styles={() => ({
          root: {
            fontSize: 16,
            color: Color.DARK,
            fontWeight: 600,
          },
        })}
      >
        {t("Common:select")}
      </Text>
      <Space h="md" />
      {data && (
        <>
          <VideoList
            selectedVideo={selectedVideo}
            setSelectedVideo={setSelectedVideo}
            resources={videos}
          />
          <Space h="xl" />
          <Pagination
            page={page}
            totalItems={data.total}
            itemsPerPage={resultCount}
            setItemsPerPage={setResultCount}
            setPage={setPage}
          />
        </>
      )}
      <Space h="xs" />
      <Group>
        <Button
          onClick={handleSelectedVideo}
          styles={() => ({
            root: {
              fontSize: 14,
              fontFamily: "Montserrat",
              backgroundColor: Color.DARK,
              fontWeight: 600,

              "&:hover": {
                backgroundColor: Color.DARK,
              },
            },
          })}
        >
          {t("Common:save")}
        </Button>
        <Button
          onClick={handleClose}
          styles={() => ({
            root: {
              fontSize: 14,
              fontFamily: "Montserrat",
              fontWeight: 600,
              borderColor: Color.DARK,
              backgroundColor: Color.WHITE,
              color: Color.DARK,
              marginRight: 10,

              "&:hover": {
                backgroundColor: Color.WHITE,
              },
            },
          })}
        >
          {t("Common:cancel")}
        </Button>
      </Group>
    </Modal>
  );
};
