import { Text, Checkbox } from "@mantine/core";
import { Color } from "enums/common";
import { Fragment, useEffect, useState } from "react";
import styled from "styled-components";
import { Resource } from "types/resources/Resource";

const StyledVideos = styled.div`
  display: flex;
  flex-direction: row;
`;

export interface VideoListProps {
  resources: Resource[];
  selectedVideo: Resource | undefined;
  setSelectedVideo: (resource: Resource) => void;
}

export function VideoList({
  resources,
  selectedVideo,
  setSelectedVideo,
}: VideoListProps) {
  const [video, setVideo] = useState<Resource>();

  useEffect(() => {
    if (selectedVideo) setVideo(selectedVideo);
  }, [selectedVideo]);

  return (
    <div>
      {resources.map((resource, index) => (
        <Fragment key={index}>
          <StyledVideos>
            <Checkbox
              onChange={() =>
                setSelectedVideo({ ...resource, resourceId: resource.id })
              }
              checked={video?.id === resource.id}
            />
            <Text
              onClick={() => setSelectedVideo(resource)}
              styles={() => ({
                root: {
                  fontSize: 16,
                  color: Color.DARK,
                  marginLeft: 10,
                  marginTop: 2,
                  cursor: "pointer",
                },
              })}
            >
              {resource.originalName}
            </Text>
          </StyledVideos>
        </Fragment>
      ))}
    </div>
  );
}
