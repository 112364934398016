import { Courses } from "enums/api/urls";
import { ApiResponse } from "../../types/api/ApiResponse";
import {
  Course,
  CreateCoursePayload,
  UpdateCourseObject,
} from "types/courses/courses";
import { createAxiosInstance } from "api/config";

const axios = createAxiosInstance();

export const getCourses = async (
  page: number,
  searchKey: string,
  size: number,
  category: string
): Promise<ApiResponse<Course[]>> => {
  const { data } = await axios.get(
    `/${Courses.GET_COURSES}/?page=${page}&search-key=${searchKey}&size=${size}&category-id=${category}`,
    { headers: { "Content-Type": "application/json;" }, data: {} }
  );

  return data;
};

export const getOrganizationCourses = async (
  organizationId: number,
  page: number,
  searchKey: string,
  size: number,
  category: string
): Promise<ApiResponse<Course[]> | undefined> => {
  var result = undefined;

  if (!organizationId || organizationId === 0) {
    result = getCourses(page, searchKey, size, category);
  } else {
    const { data } = await axios.get(
      `/${Courses.COURSES}/${organizationId}/all?page=${page}&search-key=${searchKey}&size=${size}&category-id=${category}`,
      { headers: { "Content-Type": "application/json;" }, data: {} }
    );
    result = data;
  }

  return result;
};

export const getCourse = async (id: number): Promise<Course> => {
  const { data } = await axios.get(`/${Courses.COURSES}/${id}`);

  return data;
};

export const createCourse = async (
  payload: CreateCoursePayload
): Promise<number> => {
  const { data } = await axios.post(`/${Courses.CREATE_COURSE}`, payload);

  return data;
};

export const deleteCourse = async (id: number): Promise<void> => {
  const { data } = await axios.delete(`/${Courses.COURSES}/${id}`);

  return data;
};

export const updateCourse = async (
  course: UpdateCourseObject
): Promise<number> => {
  const { data } = await axios.put(
    `/${Courses.COURSES}/${course.id}`,
    { ...course },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return data;
};

export const cloneCourse = async (id: number): Promise<void> => {
  await axios.post(`/${Courses.COURSES}/${id}/clone`, id);
};
