import { Button, Group, TextInput } from "@mantine/core";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Color } from "enums/common";

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export interface SysAdminsMenuProps {
  searchKey: string;
  setSearchKey: (searchKey: string) => void;
}

export const UserManagementSysAdminsMenu: React.FC<SysAdminsMenuProps> = ({
  searchKey,
  setSearchKey,
}) => {
  const { t } = useTranslation();

  return (
    <FlexContainer data-testid="sysAdmins-menu">
      <Group spacing={0}>
        <TextInput
          value={searchKey}
          onChange={(event) => setSearchKey(event.currentTarget.value)}
          placeholder={t("Users:sysAdmins.tab.searchPlaceholder")}
          styles={{
            root: { width: 640 },
            input: { borderRadius: 20 },
          }}
        />
        <Button
          variant="filled"
          onClick={() => setSearchKey(searchKey)}
          styles={(theme) => ({
            root: {
              fontSize: 14,
              textTransform: "uppercase",
              fontFamily: "Montserrat",
              fontWeight: 600,
              backgroundColor: Color.DARK,
              borderRadius: 20,
              left: -30,

              "&:hover": {
                backgroundColor: theme.fn.lighten(Color.DARK, 0.1),
              },
            },
          })}
        >
          {t("Common:search")}
        </Button>
      </Group>
    </FlexContainer>
  );
};
