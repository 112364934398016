import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import styled from "styled-components";
import { Button, Container, Group, Skeleton, Space } from "@mantine/core";

import { BasePage } from "../../components/common/BasePage/BasePage";
import { Banner } from "../../components/common";
import { CoursesList } from "../../components/courses/CoursesList/CoursesList";
import { ExercisesList } from "../../components/exercises/ExercisesList/ExercisesList";

import { Color } from "enums/common";

import { useCourses } from "../../hooks/courses/useCourses";
import { useExercises } from "../../hooks/exercises/useExercises";

const StyledBanner = styled(Banner)`
  margin: 34px 0 40px 0;
`;

export const Courses: React.FC = () => {
  const { t } = useTranslation();
  const page = 1;
  const resultCount = 15;

  const { data, isLoading } = useCourses(page, "", resultCount, "");

  const exercisesQuery = useExercises(page, "", resultCount, "");

  return (
    <BasePage>
      <Container data-testid="courses-page">
        <StyledBanner title={t("Courses:banner.title")} text="">
          <Group spacing="xs">
            <Button
              component={Link}
              to={"create"}
              size="xl"
              styles={(theme) => ({
                root: {
                  fontSize: 18,
                  fontFamily: "Montserrat",
                  backgroundColor: Color.DARK,
                  fontWeight: 600,

                  "&:hover": {
                    backgroundColor: theme.fn.lighten(Color.DARK, 0.1),
                  },
                },
              })}
            >
              {t("Courses:banner.newCourse")}
            </Button>
            <Space w="md" />
            <Button
              component={Link}
              to={"/exercises/createExercise"}
              size="xl"
              variant="outline"
              styles={() => ({
                root: {
                  fontSize: 18,
                  color: Color.DARK,
                  fontFamily: "Montserrat",
                  fontWeight: 700,
                  borderColor: Color.DARK,
                  borderWidth: 3,
                },
              })}
            >
              {t("Courses:banner.newExercise")}
            </Button>
          </Group>
        </StyledBanner>
        {isLoading && (
          <Skeleton height={550} radius="sm" style={{ marginTop: 30 }} />
        )}
        {!isLoading && data && (
          <CoursesList courses={data ? data.elements : []} />
        )}
        {exercisesQuery.isLoading && (
          <Skeleton height={550} radius="sm" style={{ marginTop: 30 }} />
        )}
        {!isLoading && data && (
          <ExercisesList
            exercises={exercisesQuery.data ? exercisesQuery.data.elements : []}
          />
        )}
      </Container>
    </BasePage>
  );
};
