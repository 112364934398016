import { useMutation } from "@tanstack/react-query";

import i18n from "../../i18n";
import { queryClient } from "../../App";

import {
  startNotification,
  endNotification,
  generateRandomString,
} from "utils/common";

import { updateOrganization } from "api/organizations";

import { UpdateOrganizationObject } from "types/organizations";

export const useUpdateOrganization = () => {
  const randomId = generateRandomString(20);

  return useMutation(
    (object: UpdateOrganizationObject) =>
      updateOrganization(object.id, object.payload),
    {
      onMutate: () => {
        startNotification(randomId);
      },
      onSuccess: () => {
        endNotification(
          randomId,
          i18n.t("notifications.updateSuccess", { ns: "Organizations" }),
          true
        );
        queryClient.invalidateQueries(["organizations"]);
      },
      onError: () => {
        endNotification(
          randomId,
          i18n.t("notifications.updateError", { ns: "Organizations" }),
          false
        );
      },
    }
  );
};
