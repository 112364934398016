import { useMutation } from "@tanstack/react-query";

import i18n from "../../i18n";
import { queryClient } from "../../App";

import {
  startNotification,
  endNotification,
} from "../../utils/common/notifications";
import { generateRandomString } from "../../utils/common/commonUtils";

import { deleteUserCourse } from "../../api/userCourses/userCourses";

export const useDeleteUserCourse = () => {
  const randomId = generateRandomString(20);

  return useMutation((id: number) => deleteUserCourse(id), {
    onMutate: () => {
      startNotification(randomId);
    },
    onSuccess: () => {
      endNotification(
        randomId,
        i18n.t("Courses:notifications.deleteSuccess"),
        true
      );
      queryClient.invalidateQueries(["userCourses"]);
    },
    onError: () => {
      endNotification(
        randomId,
        i18n.t("Courses:notifications.deleteError"),
        false
      );
    },
  });
};
