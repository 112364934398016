import { useDebouncedValue } from "@mantine/hooks";
import { Pagination } from "components/common";
import { useAssignDepartmentManager } from "hooks/departments/assignDepartment/assignDepartmentManager";
import { useAssignUsersDepartment } from "hooks/departments/assignDepartment/assignUsersToDepartment";
import { useDepartmentUsers } from "hooks/departments/assignDepartment/departmentUsers";
import { useDeleteUserDepartmentMutation } from "hooks/departments/assignDepartment/useDeleteUserDepartmentMutation";
import React from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { UsersObject } from "types/user/User";
import { AssignManagerDepartmentModal } from "./AssignDepartmentManagerModal";
import { AssignUsersDepartmentModal } from "./AssignUsersDepartmentModal";
import { DepartmentDetailsBanner } from "./DepartmentDetailsBanner";
import { DepartmentMenu } from "./DepartmentMenu";
import { DepartmentUsersTable } from "./DepartmentUsersTable";

export const DepartmentDetailsPage: React.FC = () => {
  const { departmentId } = useParams();
  const [page, setPage] = useState(1);
  const [resultCount, setResultCount] = useState(20);
  const [selectedUsers, setSelectedUsers] = useState<UsersObject[]>([]);
  const [searchKey, setSearchKey] = useState("");
  const [debouncedSearchKey] = useDebouncedValue(searchKey, 300);
  const [isManagerSelected, setIsManagerSelected] = useState(false);

  const [
    isAssignUsersDepartmentModalOpen,
    setIsAssignUsersDepartmentModalOpen,
  ] = useState(false);

  const [
    isAssignManagerDepartmentModalOpen,
    setIsAssignManagerDepartmentModalOpen,
  ] = useState(false);

  const assignDepartmentMutation = useAssignUsersDepartment();

  const assignDepartmentManagerMutation = useAssignDepartmentManager();

  const deleteMutation = useDeleteUserDepartmentMutation();

  const { data } = useDepartmentUsers(
    Number(departmentId),
    page,
    debouncedSearchKey,
    resultCount
  );

  const handleUserSelection = (user: UsersObject) => {
    if (!user.checked) {
      setSelectedUsers(
        selectedUsers.filter((selectedUser) => selectedUser.id !== user.id)
      );
    } else {
      setSelectedUsers((current) => [...current, user]);
    }
  };

  return (
    <>
      <DepartmentDetailsBanner />
      <DepartmentMenu
        setSearchKey={setSearchKey}
        searchKey={searchKey}
        setIsModalOpen={() => setIsAssignUsersDepartmentModalOpen(true)}
        setIsAssignManagerModalOpen={() =>
          setIsAssignManagerDepartmentModalOpen(true)
        }
        isManagerSelected={isManagerSelected}
      />
      {data && (
        <>
          <DepartmentUsersTable
            departmentUsers={data.elements}
            departmentId={Number(departmentId)}
            deleteMutation={deleteMutation}
          />
          <Pagination
            page={page}
            totalItems={data.elements.length}
            itemsPerPage={resultCount}
            setItemsPerPage={setResultCount}
            setPage={setPage}
          />
        </>
      )}
      {isAssignUsersDepartmentModalOpen && (
        <AssignUsersDepartmentModal
          opened={isAssignUsersDepartmentModalOpen}
          onClose={() => setIsAssignUsersDepartmentModalOpen(false)}
          mutation={assignDepartmentMutation}
          setSelectedUsers={handleUserSelection}
        />
      )}
      {isAssignManagerDepartmentModalOpen && (
        <AssignManagerDepartmentModal
          opened={isAssignManagerDepartmentModalOpen}
          onClose={() => setIsAssignManagerDepartmentModalOpen(false)}
          mutation={assignDepartmentManagerMutation}
          setManagerIsSelected={setIsManagerSelected}
        />
      )}
    </>
  );
};
