import { useMutation } from "@tanstack/react-query";

import i18n from "../../i18n";
import { queryClient } from "../../App";

import {
  startNotification,
  endNotification,
  generateRandomString,
} from "utils/common";

import { deleteOrganization } from "api/organizations";

export const useDeleteOrganization = () => {
  const randomId = generateRandomString(20);

  return useMutation((id: number) => deleteOrganization(id), {
    onMutate: () => {
      startNotification(randomId);
    },
    onSuccess: () => {
      endNotification(
        randomId,
        i18n.t("notifications.deleteSuccess", { ns: "Organizations" }),
        true
      );
      queryClient.invalidateQueries(["organizations"]);
    },
    onError: () => {
      endNotification(
        randomId,
        i18n.t("notifications.deleteError", { ns: "Organizations" }),
        false
      );
    },
  });
};
